export class Prefix {
    public static getInstance(data: Prefix): Prefix {
        return new Prefix(data.code, data.nextGtin);
    }

    constructor(
        public code: string,
        public nextGtin: string,
        public isMissingSorting = false
    ) {
    }

    public isPrefixFull(): boolean {
        return this.nextGtin === '-1';
    }

    public isPrefixInError(): boolean {
        return this.nextGtin === '-2';
    }
}

/* tslint:disable:max-classes-per-file */
export class SortedPrefixes {
    public static getInstance(data: SortedPrefixes): SortedPrefixes {
        const prefixesWithAvailableGtin = data.prefixesWithAvailableGtin.map(p => Prefix.getInstance(p));
        const prefixesWithErrorToRecheck = data.prefixesWithErrorToRecheck.map(p => Prefix.getInstance(p));
        const prefixesWithNoCapacity = data.prefixesWithNoCapacity.map(p => Prefix.getInstance(p));
        return new SortedPrefixes(prefixesWithAvailableGtin, prefixesWithErrorToRecheck, prefixesWithNoCapacity);
    }

    constructor(
        public prefixesWithAvailableGtin: Prefix[],
        public prefixesWithErrorToRecheck: Prefix[],
        public prefixesWithNoCapacity: Prefix[]
    ) {
    }

    public getPrefixes(): Prefix[] {
            return [...this.prefixesWithAvailableGtin, ...this.prefixesWithErrorToRecheck, ...this.prefixesWithNoCapacity];
    }
}
