export const SugarContentConstants = {
    sparklingWine : [
        {wording: 'Brut nature', code: '30014940'},
        {wording: 'Extra brut', code: '30000958'},
        {wording: 'Brut', code: '30000455'},
        {wording: 'Extra sec', code: '30000963'},
        {wording: 'Sec', code: '30002173'},
        {wording: 'Demi sec', code: '30002725'},
        {wording: 'Doux', code: '30000900'},
        {wording: 'Autre', code: '30002515'}
    ],
    stillWine: [
        {wording: 'Sec', code: '30002173'},
        {wording: 'Demi sec', code: '30002725'},
        {wording: 'Moelleux', code: '30015489'},
        {wording: 'Doux', code: '30000900'},
        {wording: 'Autre', code: '30002515'}
    ]
};
