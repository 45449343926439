import {Component, ViewChild, Output, EventEmitter, Input, OnInit} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {UserSvc, FeatureToggleSvc, CompanySvc} from '../../../shared/services/index';
import {LoginConstants} from '../../models/index';
import {AuthGuard} from '../../../shared/services/auth-guard';

@Component({
    selector: 'user-preference-modal',
    styleUrls: ['./user-preference-modal.less'],
    template: `
        <div class="UserPreferenceModal">
            <bs-modal class="userPreferenceModal" [animation]="true" [keyboard]="false"
                      [backdrop]="'static'" [size]="'lg'" #userPreferenceModal>
                <bs-modal-body>
                    <div class="UserPreferenceModal-body">
                        <div class="UserPreferenceModal-body-header">Bienvenue dans CodeOnline</div>
                        <div class="UserPreferenceModal-body-subheader">by GS1 France</div>
                        <div *ngIf="!featureToggleSvc.canSeeVcs()"
                             class="UserPreferenceModal-body-title">{{constants.disclaimer.title}}</div>
                        <div *ngIf="featureToggleSvc.canSeeVcs()"
                             class="UserPreferenceModal-body-title titleVcs">{{constants.disclaimer.titleVcs}}</div>
                        <div *ngIf="featureToggleSvc.canSeeVcs()"
                             class="UserPreferenceModal-body-title titleVcs col-md-offset-1">{{constants.disclaimer.filiere}}</div>
                        <div *ngIf="featureToggleSvc.canSeeVcs()" class="UserPreferenceModal-body-radio">
                            <div class="col-md-offset-5 col-sm-offset-5" *ngFor="let preference of preferences">
                                <input type="radio" name="preferenceCompany" [value]="preference.id"
                                       [(ngModel)]="preferenceCompany">
                                <label>{{preference.text}}</label>
                            </div>
                        </div>
                    </div>
                </bs-modal-body>
                <bs-modal-footer class="UserPreferenceModal-footer" [showDefaultButtons]="false">
                    <div class="UserPreferenceModal-footer-buttons" role="group">
                        <button type="button" class="cancelButton" (click)="rejectDisclaimer()">Annuler</button>
                        <button type="button" class="submitButton" [disabled]="preferenceCompany === null"
                                (click)="validateUserPreference()">Valider
                        </button>
                    </div>
                </bs-modal-footer>
            </bs-modal>
        </div>`
})
export class UserPreferenceModal implements OnInit {
    @ViewChild('userPreferenceModal', {static: true})
    public userPreferenceModal: BsModalComponent;
    public preferenceCompany: string = '';
    public constants = LoginConstants;
    public preferences: any[] = [];
    @Input() public isSso: boolean = false;

    @Output()
    public onValidate = new EventEmitter<any>();

    constructor(private userSvc: UserSvc, private companySvc: CompanySvc, public featureToggleSvc: FeatureToggleSvc, private authGuard: AuthGuard) {
        this.preferences = this.constants.disclaimer.preferences;
    }

    public ngOnInit() {
        if (this.isSso) {
            this.openModal();
        }
        this.userPreferenceModal.onDismiss.subscribe(() => {
            this.userSvc.disconnectUser(true);
        });
    }

    public openModal() {
        this.userPreferenceModal.open();
    }

    public validateUserPreference() {
        if (this.preferenceCompany) {
            this.validateUser()
                .then(() => {
                    this.validateCompany().then(() => {
                        this.userPreferenceModal.close();
                        this.onValidate.emit(null);
                    });
                })
                .catch((err) => console.error(err));
        }
    }

    public validateUser() {
        return this.userSvc.updateUser({userPreferenceChecked: true});
    }

    public validateCompany() {
        return this.companySvc.updateCompany({preferenceCompany: this.preferenceCompany});
    }

    public rejectDisclaimer() {
        this.userPreferenceModal.close();
        this.userSvc.disconnectUser(true);
    }

}
