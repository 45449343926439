import { ViewChild, Component, Output, EventEmitter } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
    selector: 'publishing-description',
    styleUrls: ['./publishing-description.less'],
    templateUrl: 'publishing-description.tpl.html'
})

export class PublishingDescriptionModal {
    @ViewChild('modal', {static: true})
    public modal: BsModalComponent;

    constructor() {
    }

    public openModal() {
        this.modal.open();
    }
}
