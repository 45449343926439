'use strict';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class DashboardHeaderSvc {
  public iconUrl: string;
  public title: string;
  public subTitle: string;
  public dialog: MatDialog;
  constructor() {}

  public setHeaderData(title: string, iconUrl: string, subTitle?: string) {
    this.title = title;
    this.iconUrl = iconUrl;
    this.subTitle = subTitle;
  }

  public setSubtitle(subtitle: string) {
    this.subTitle = subtitle;
  }
}
