'use strict';
import {Injectable} from '@angular/core';
import 'core-js/es7/reflect';
import {ActivatedRoute, Router} from '@angular/router';
import {BarcodeGenerationSvc, ProductsSvc} from './index';
import {CompanySvc, NotificationsSvc, UserSvc} from '../../shared/services';
import {FeatureToggleSvc} from '../../shared/services/feature-toggle.service';
import {BaseCreationUnityForm} from '../models/baseCreationUnityForm.model';
import {VintageSvc} from '../../shared/services/vintages.service';
import {ProductTypes} from '../models/productTypes.model';
import {FoodCreationUnityForm} from '../models/foodCreationUnityForm.model';
import {VcsCreationUnityForm} from '../models/vcsCreationUnityForm.model';
import {VcsCreationUnityOldForm} from '../models/vcsCreationUnityForm-old.model';
import {BaseEditionUnityFormModel, FoodEditionUnityFormModel, VcsEditionUnityForm} from '../models';
import {VcsEditionUnityOldForm} from '../models/vcsEditionUnityForm-old.model';

@Injectable()
export class UnityFormResolver {

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notifsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public vintageSvc: VintageSvc,
              public userSvc: UserSvc,
              public featureToggle: FeatureToggleSvc,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              private notificationsSvc: NotificationsSvc) {

  }

  public resolveCreationModel(productType: ProductTypes) {
    switch (productType) {

      case ProductTypes.VCS: {
        if (this.featureToggle.isWhalesUser()) {
          return new VcsCreationUnityForm(this.productsSvc,
            this.companySvc,
            this._router,
            this.notifsSvc,
            this.featureToggleSvc,
            this.vintageSvc, this.userSvc);
        }
        return new VcsCreationUnityOldForm(this.productsSvc,
          this.companySvc,
          this._router,
          this.notifsSvc,
          this.featureToggleSvc,
          this.vintageSvc, this.userSvc);
      }

      default: {
        return new BaseCreationUnityForm(this.productsSvc,
          this.companySvc,
          this._router,
          this.notifsSvc,
          this.featureToggleSvc, this.userSvc);
      }
    }
  }

  public resolveEditionModel(productType: ProductTypes) {
    switch (productType) {
      case ProductTypes.VCS: {
        if (this.featureToggle.isWhalesUser()) {
          return new VcsEditionUnityForm(
            this.productsSvc, this._router, this.route, this.featureToggleSvc, this.barcodeGenerationSvc,
            this.notificationsSvc, this.companySvc, this.vintageSvc, this.userSvc);
        }

        return new VcsEditionUnityOldForm(
          this.productsSvc, this._router, this.route, this.featureToggleSvc, this.barcodeGenerationSvc,
          this.notificationsSvc, this.companySvc, this.vintageSvc, this.userSvc);
      }

      default: {
        return new BaseEditionUnityFormModel(this.productsSvc, this._router,
          this.route,
          this.featureToggle,
          this.barcodeGenerationSvc,
          this.notificationsSvc,
          this.companySvc,
          this.vintageSvc,
          this.userSvc);
      }
    }
  }


}
