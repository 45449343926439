import {AfterContentInit, Component, EventEmitter, OnInit, Output, ViewEncapsulation, ViewChild} from '@angular/core';

import {DashboardConst} from '../../../../models/dashboard.constants';
import {CompanySvc, FeatureToggleSvc, NotificationsSvc, UserSvc} from '../../../../../../app/shared/services';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import gtinUtils from '../../../../../../app/shared/utils/gtin';
import {FormInputError, ProductResellerTypes, ProductTypes} from '../../../../../../app/dashboard/models';
import {ProductsSvc} from '../../../../../../app/dashboard/services';
import {InitiatorStepComponent} from '../InitiatorStepComponent';
import {
  PublishingDescriptionModal
} from '../../../../../../app/dashboard/components/publishing-description/publishing-description.component';

import * as _ from 'lodash';
import {environment} from '../../../../../../environments/environment';
import {Gtin} from '../../../../../shared/models';

@Component({
  selector: 'final-step',
  styleUrls: ['./final-step.less'],
  templateUrl: './final-step.tpl.html',
  encapsulation: ViewEncapsulation.None
})
export class FinalStepComponent extends InitiatorStepComponent implements AfterContentInit, OnInit {

  public constants = DashboardConst;
  public cipPattern: string;
  public barcodeUrl: string = '';
  public imgIsLoaded: boolean = false;
  public adhereToTheSectoralAgreement: boolean = true;
  public isGeneric: boolean;
  public isBundle: boolean;
  public isVcs: boolean;
  public isNotNew: boolean;
  @Output() public onReturnToFirstStep = new EventEmitter<any>();
  @Output() public onReturnToSectoralStep = new EventEmitter<any>();
  @ViewChild('PublishingDescriptionModal', {static: true})
  public publishingDescriptionModal: PublishingDescriptionModal;

  public hasAvailableGtin13;
  public hasAvailableGtin8;
  public gtin8AvailableCount;
  public gtin8choice = null;
  public gtin8: Gtin;
  public prefixGtin13: string;
  public showChoice: boolean;

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notificationsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public userSvc: UserSvc) {
    super(productsSvc, companySvc, _router, notificationsSvc, featureToggleSvc, userSvc);
  }

  public ngOnInit() {
    this.isGeneric = this.productType === ProductTypes.GENERIC;
    this.isVcs = this.productType === ProductTypes.VCS;
    this.isBundle = this.unityForm.productForm.productResellerType === ProductResellerTypes.BUNDLE;
    this.isNotNew = this.unityForm.productForm.productResellerType === ProductResellerTypes.NOT_NEW;
    this.gtin8AvailableCount = this.companySvc.company.newGtin8Available - this.productsSvc.totalNewGtin8Products;
    this.hasAvailableGtin8 = this.gtin8AvailableCount > 0 && !this.isNotNew;
    this.hasAvailableGtin13 = this.companySvc.company.codesAvailable > this.productsSvc.totalProducts;
    this.showChoice = this.hasAvailableGtin13 && this.hasAvailableGtin8;
    this.prefixGtin13 = this.unityForm.productForm.gtin.prefix;
    this.unityForm.isCipErrorNotifAllowed = true;
  }

  public ngAfterContentInit() {
    if (!this.showChoice) {
      this.onPrefixChange.emit({ prefix: this.unityForm.productForm.gtin.prefix });
    }
  }

  public checkCipAvailability(cip: string): Observable<boolean> {
    // Generating the control key
    this.unityForm.productForm.gtin.key = gtinUtils.generateEAN13ControlKey(this.unityForm.productForm.gtin.prefix + cip);
    // Getting the products with that GTIN
    const gtin = this.unityForm.productForm.gtin.prefix + cip + this.unityForm.productForm.gtin.key;
    return this.productsSvc.checkGtinAvailability(gtin);
  }

  public changePrefix(prefix: string = null) {
    if (prefix && this.unityForm.productForm.gtin.isGtin8) {
      return;
    }
    this.unityForm.isNoGtinAvailable = false;
    this.onPrefixChange.emit({prefix: prefix ? prefix : this.unityForm.productForm.gtin.prefix});
  }

  public onCIPChange(cip: string) {
    // Test if cip only contains numbers
    if (!/^([0-9]*)$/.test(cip)) {
      this.unityForm.productForm.cipError = new FormInputError('warning', this.constants.createForm.errors.cipType);
      return;
    } else if (!this.unityForm.isNoGtinAvailable) {
      this.unityForm.productForm.cipError.status = '';
    }

    // Do nothing if the cip length is not correct
    if (cip.length < this.unityForm.productForm.gtin.cipSize) {
      return;
    }
    return this.checkCipAvailability(cip)
      .subscribe(
        cipAvailable => {
          this.updateCipError(cipAvailable);
          this.unityForm.productForm.gtin.available = cipAvailable;
          this.unityForm.cipLoaded = true;
        },
        err => console.error(err)
      );
  }

  public updateCipError(cipAvailable: boolean) {
    this.unityForm.productForm.cipError = cipAvailable === true ? new FormInputError('valid', '') : new FormInputError('warning', this.constants.createForm.errors.cipUsed);
  }

  public showBarcode(): boolean {
    if (this.unityForm.productForm.gtin.cip) {
      this.barcodeUrl = `${environment.API_URL}/barcode/${this.unityForm.productForm.gtin}`;
      return true;
    }
    return false;
  }

  public onVisibilityChange(value: any) {
    this.unityForm.productForm.isPrivate = !value;
  }

  public gtin13Value(): string {
    return this.unityForm.productForm.gtin.toString();
  }

  public gtin8Value(): string {
    const usedGtin8List = _.map(this.productsSvc.myNewGtin8Products, (p) => p.gtin);
    const freeGtin8list = _.filter(this.companySvc.company.newGtin8, (el) => _.indexOf(usedGtin8List, el) === -1);
    const selectedGtin8 = freeGtin8list.sort((a, b) => a.localeCompare(b, undefined, {numeric: true})) [0];
    this.gtin8 = new Gtin(selectedGtin8, '', '', true, true);
    return selectedGtin8;
  }

  public isVbgAttributeCompleted(): boolean {
    return this.unityForm.productForm.vbGs1CompletionLevel === 6;
  }

  public isSectoralAttributeCompleted(): boolean {
    if (this.isVcs && this.featureToggleSvc.isWhalesUser()) {
      return this.unityForm.productForm.adhereToRegulatoryAgreement;
    }
    return this.unityForm.productForm.adhereToTheSectoralAgreement && !this.isGeneric;
  }

  public vbgBadgeTitle(): string {
    if (this.isVbgAttributeCompleted()) {
      return this.isBundle ? this.constants.vbgBadge.completed.title_assortment : this.constants.vbgBadge.completed.title_product;
    }
    return this.constants.vbgBadge.notCompleted.title;
  }

  public vbgBadgeBody(): string {
    if (this.isVbgAttributeCompleted()) {
      return this.constants.vbgBadge.completed.body;
    }
    return this.constants.vbgBadge.notCompleted.body;
  }

  public sectoralBadgeTitle(): string {
    if (this.featureToggleSvc.isWhalesUser() && this.isVcs) {
      if (this.isSectoralAttributeCompleted()) {
        return this.constants.sectoralBadge.completed.title;
      }
      return this.constants.sectoralBadge.notCompleted.title;
    }
    if (this.isSectoralAttributeCompleted()) {
      return this.constants.oldSectoralBadge.completed.title;
    }
    return this.constants.oldSectoralBadge.notCompleted.title;
  }

  public sectoralBadgeBody(): string {
    if (this.featureToggleSvc.isWhalesUser() && this.isVcs) {
      if (this.isSectoralAttributeCompleted()) {
        return this.constants.sectoralBadge.completed.body;
      }
      return this.constants.sectoralBadge.notCompleted.body;
    }
    if (this.isSectoralAttributeCompleted()) {
      return this.constants.oldSectoralBadge.completed.body;
    }
    return this.constants.oldSectoralBadge.notCompleted.body;
  }

  public btnSectoralWording(): string {
    if (this.featureToggleSvc.isWhalesUser() && this.isVcs) {
      return 'Compléter les informations réglementaires et sectorielles';
    }

    return 'Compléter les informations';
  }

  public titlePrivatePanel(): string {
    return this.constants.privatePanel.title;
  }

  public firstTextPrivatePanel(): string {
    return this.constants.privatePanel.firstText;
  }

  public secondTextPrivatePanel(): string {
    return this.constants.privatePanel.secondText;
  }

  public returnToFirstStep() {
    if (this.productType === ProductTypes.FOOD) {
      this.unityForm.lastVisitedStep = 1;
    }
    this.onReturnToFirstStep.emit();
  }

  public returnToSectoralStep() {
    if (this.productType === ProductTypes.FOOD) {
      this.unityForm.lastVisitedStep = 1;
    }
    this.onReturnToSectoralStep.emit();
  }

  public showPublishingDescriptionModal() {
    this.publishingDescriptionModal.openModal();
  }

  public isRiskyProduct(productForm: any): boolean {
    return this.productsSvc.isRiskyProduct(productForm.productResellerType);
  }

  public sendForm(submitting: boolean = true, form: HTMLFormElement = null, adhereToTheSectoralAgreement: boolean) {
    if (!this.hasAvailableGtin13 || this.gtin8choice) {
      this.unityForm.productForm.gtin = this.gtin8;
    }
    this.updateUser();
    this.submitForm(submitting, form, adhereToTheSectoralAgreement);
  }

  public screenResolution(): boolean {
    return window.innerWidth >= 1900;
  }
}
