export const CosmeticsCodeGpcConstants = ['10000320',
  '10000321',
  '10000324',
  '10000327',
  '10000328',
  '10000329',
  '10000330',
  '10000332',
  '10000333',
  '10000334',
  '10000335',
  '10000336',
  '10000337',
  '10000338',
  '10000340',
  '10000341',
  '10000342',
  '10000343',
  '10000344',
  '10000345',
  '10000346',
  '10000348',
  '10000349',
  '10000350',
  '10000355',
  '10000356',
  '10000358',
  '10000359',
  '10000360',
  '10000361',
  '10000362',
  '10000363',
  '10000365',
  '10000368',
  '10000369',
  '10000370',
  '10000373',
  '10000374',
  '10000375',
  '10000376',
  '10000377',
  '10000379',
  '10000381',
  '10000383',
  '10000384',
  '10000385',
  '10000388',
  '10000460',
  '10000461',
  '10000462',
  '10000481',
  '10000482',
  '10000483',
  '10000484',
  '10000485',
  '10000486',
  '10000489',
  '10000494',
  '10000495',
  '10000496',
  '10000505',
  '10000527',
  '10000532',
  '10000533',
  '10000534',
  '10000535',
  '10000536',
  '10000564',
  '10000566',
  '10000567',
  '10000573',
  '10000638',
  '10000639',
  '10000666',
  '10000668',
  '10000669',
  '10000671',
  '10000672',
  '10000674',
  '10000675',
  '10000676',
  '10000677',
  '10000678',
  '10000679',
  '10000680',
  '10000687',
  '10000688',
  '10000689',
  '10000709',
  '10000710',
  '10000712',
  '10000713',
  '10000714',
  '10000715',
  '10000716',
  '10000717',
  '10000719',
  '10000721',
  '10000722',
  '10000723',
  '10000728',
  '10000730',
  '10000731',
  '10000758',
  '10000759',
  '10000760',
  '10000761',
  '10000763',
  '10000764',
  '10000767',
  '10000768',
  '10000775',
  '10000778',
  '10000780',
  '10000781',
  '10000782',
  '10000783',
  '10000784',
  '10000785',
  '10000786',
  '10000806',
  '10000807',
  '10000808',
  '10000809',
  '10000810',
  '10000811',
  '10000812',
  '10000813',
  '10000814',
  '10000828',
  '10000829',
  '10000830',
  '10000831',
  '10000832',
  '10000833',
  '10000834',
  '10000835',
  '10000838',
  '10000885',
  '10000886',
  '10000887',
  '10000888',
  '10000889',
  '10000890',
  '10000891',
  '10000892',
  '10000893',
  '10000903',
  '10000904',
  '10000905',
  '10000906',
  '10000907',
  '10000911',
  '10000923',
  '10002423',
  '10005626',
  '10005627',
  '10005628',
  '10005631',
  '10005646',
  '10005727',
  '10005839',
  '10006201',
  '10006246',
  '10006247',
  '10006248',
  '10006249',
  '10006275',
  '10006821',
  '10006822',
  '10008023',
  '10008024',
  '10008090',
  '10008092',
  '10008093',
  '10008094',
  '10008095',
  '10008097',
  '10008096'
];
