import {ActivatedRoute, Router} from '@angular/router';
import GtinUtils from '../../shared/utils/gtin';
import {Gtin} from '../../shared/models/index';
import {NotificationsSvc, CompanySvc, FeatureToggleSvc, UserSvc} from '../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../services/index';
import {DashboardConst} from './dashboard.constants';
import {DateInput} from './dateInput.model';
import {Countries} from '../models/country.constants';
import {FormInputError} from './formInputError';
import {wineColorCodes} from './wineColor.constants';
import {Classification} from '../../shared/models/Classification.model';
import {VintageSvc} from '../../shared/services/vintages.service';
import {FoodProductEditionFormModel} from './foodProductEditionForm.model';
import {DietTypes} from './dietTypes.model';
import {dietTypesConst} from './dietTypes.constants';
import {Nutriment} from './nutriment.model';
import {NutrimentItem} from './nutrimentItem.model';
import {nutrimentMeasurementUnitConst} from './nutrimentMeasurementUnit.constants';
import {nutrimentMeasurementPrecisionConst} from './nutrimentMeasurementPrecision.constants';
import {nutrimentServingSizeConst} from './nutrimentServingSize.constants';
import {nutrimentItemsConst} from './nutrimentItems.constants';
import {AllergenFoodModel} from './allergenFood.model';
import {AllergensFoodConstants} from './allergenFood.constants';
import {drainedWeightMeasurementUnits} from './drainedWeightMeasurementUnit.constants';
import {StructuredAddress} from './StructuredAddress.model';
import {nutrimentMeasurementShortUnitConst} from './nutrimentMeasurementShortUnit.constants';
import {optionalNutrimentItemsConst} from './optionalNutrimentItems.constants';
import {BaseEditionUnityFormModel} from './baseEditionUnityForm.model';
import {finalize} from 'rxjs/operators';
import {ProductName} from './ProductName.model';

import * as _ from 'lodash';
const moment = require('moment');

export class FoodEditionUnityFormModel extends BaseEditionUnityFormModel {

    public DECIMAL_PATTERN = '^([0-9]{1,2}(,\\d{1})?|100)$';

    public productForm: FoodProductEditionFormModel;
    public constants = DashboardConst;
    public initialBrand: string;
    public initialName: string;
    public productDiet = [];
    public servingSizeDescription: string;
    public dailyValueIntakeReference: string;
    public submitting: boolean = false;
    public dateInput: DateInput = new DateInput('', '', '');
    public gpcCodes: Classification[];
    public countries = Countries;
    public wineColorCodes = wineColorCodes;
    public requiredNutrimentItems = nutrimentItemsConst;
    public dietTypes = dietTypesConst;
    public measurementPrecision = nutrimentMeasurementPrecisionConst;
    public measurementUnit = nutrimentMeasurementUnitConst;
    public measurementShortUnit = nutrimentMeasurementShortUnitConst;
    public nutrimentServingSize = nutrimentServingSizeConst;
    public requiredAllergenFoodItems = AllergensFoodConstants.requiredAllergen;
    public optionalAllergenFoodItems = AllergensFoodConstants.optionalAllergen;
    public mergedAllergenFoodItems = _.concat(this.requiredAllergenFoodItems, this.optionalAllergenFoodItems);
    public containmentLevelConst = AllergensFoodConstants.containmentLevel;
    public opticalSpecifiedAllergen: AllergenFoodModel [] = [];
    public drainedWeightMeasurementUnitConst = drainedWeightMeasurementUnits;
    public optionalNutrimentItems = optionalNutrimentItemsConst;

    public attributesToUpdate = {};


    constructor(public productsSvc: ProductsSvc,
                public _router: Router,
                public route: ActivatedRoute,
                public featureToggle: FeatureToggleSvc,
                public barcodeGenerationSvc: BarcodeGenerationSvc,
                public notifsSvc: NotificationsSvc,
                public companySvc: CompanySvc,
                public vintageSvc: VintageSvc,
                public userSvc: UserSvc) {
        super(productsSvc,
            _router,
            route,
            featureToggle,
            barcodeGenerationSvc,
            notifsSvc,
            companySvc,
            vintageSvc,
            userSvc);
        this.productForm = new FoodProductEditionFormModel(new Gtin(this.companySvc.company.mainPrefix, '', '',
            false, false), new FormInputError('', ''), '', '', '', '', '', [new ProductName('', 'fr')],
                [], [], '', '', 0, this.companySvc.company.id, ['']
            , '', false, null, new StructuredAddress('', '', '', ''), new DietTypes(), '', new Nutriment('100',
                'GRM', null, this.resetNutrimentItems()), '', [],
            '', this.resetAllergenFoodItems(), '', '', '');
    }

    public resetFormAsBaseProduct() {
        this.productForm = new FoodProductEditionFormModel(new Gtin(this.companySvc.company.mainPrefix, '', '',
            false, false), new FormInputError('', ''), '', '', '', '', '', [new ProductName('', 'fr')],
            [], [], '', '', 0, this.companySvc.company.id, ['']
            , '', false, true, new StructuredAddress('', '', '', ''), new DietTypes(), '', new Nutriment('100',
                'GRM', null, this.resetNutrimentItems()), '', [],
            '', this.resetAllergenFoodItems(), '', '', '');
    }

    public resetFormProductWitBaseAttribute() {
        this.productForm = new FoodProductEditionFormModel(this.productForm.gtin, this.productForm.cipError, this.productForm.mpn, this.productForm.sku, this.productForm.brand, this.productForm.subBrand, this.productForm.name, this.productForm.productNames,
            this.productForm.imagesToUpload, this.productForm.images, this.productForm.description, this.productForm.webLink, this.productForm.completionLevel, this.productForm.companyId, this.productForm.targetMarket
            , this.productForm.codeGpc, this.productForm.isPrivate, this.productForm.adhereToTheSectoralAgreement, null, null, null, null, this.productForm.category, this.productForm.netContents,
            null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, this.productForm.imagesAddedByUrl);
    }

    public resetNutrimentItems(): NutrimentItem[] {
        const toReturn: NutrimentItem[] = [];
        this.requiredNutrimentItems.forEach(nutrimentItem => {
            const item = new NutrimentItem(nutrimentItem.code, 'APPROXIMATELY', null,
                null, nutrimentItem.measurementUnitCode);
            toReturn.push(item);
        });

        return toReturn;
    }

    public resetAllergenFoodItems(): AllergenFoodModel[] {
        const toReturn: AllergenFoodModel[] = [];
        this.requiredAllergenFoodItems.forEach(allergen => {
            const item = new AllergenFoodModel(allergen.code, '');
            toReturn.push(item);
        });

        return toReturn;
    }

    public onDateChange(date: string): void {
        if (date.match(/[a-z]/g)) {
            [this.dateInput.status, this.dateInput.message] = ['warning', `La date doit être au format JJ/MM/AAAA`];
            return;
        }

        const pattern = /\d{1,2}\/\d{1,2}\/\d{4}/g;
        if (date.match(pattern)) {
            const momentDate = moment(date, 'DD/MM/YYYY');
            if (!momentDate.isValid()) {
                [this.dateInput.status, this.dateInput.message] = ['error', `La date saisie n'est pas valide`];
                return;
            }
            if (momentDate.isAfter(moment())) {
                [this.dateInput.status, this.dateInput.message] = ['error', `La date saisie doit être antérieure à aujourd'hui`];
                return;
            }
            this.dateInput.status = '';
            return;
        }

        [this.dateInput.status, this.dateInput.message] = ['', ''];
    }

    public loadProduct(id: string) {
        this.productsSvc.getProduct(id)
            .subscribe(
                product => {
                    _.assign(this.productForm, product);
                    this.productForm.gtin = GtinUtils.constructGTINObject(product.gtin, product.prefix);
                    // just to display target market label
                    // Saving values that should not be changed
                    this.initialBrand = product.brand;
                    this.initialName = product.name;
                    this.productForm.nutriment.items = this.productsSvc.formatNutrimentItems(this.productForm.nutriment.items);
                    if (_.isUndefined(product.codeGpc) || _.isEmpty(product.codeGpc)) {
                        this.productForm.codeGpc = '0';
                    }

                },
                err => {
                    if (err.status === 404) {
                        this._router.navigate(['/dashboard']);
                        this.notifsSvc.productNotFound();
                    }
                }
            );
    }

    public cancelCreation() {
        this._router.navigate(['/dashboard']);
    }

    public gotoDashborad() {
        this._router.navigate(['/dashboard/list']);
    }

    public submitForm() {
        if (!this.submitting) {
            this.submitting = true;

            if (this.productForm.codeGpc !== undefined && this.productForm.codeGpc === '0') {
                this.productForm.codeGpc = '';
            }

            this.attributesToUpdate['name'] = this.productForm.name;
            this.attributesToUpdate['brand'] = this.productForm.brand;
            this.attributesToUpdate['mpn'] = this.productForm.mpn;
            this.attributesToUpdate['sku'] = this.productForm.sku;
            this.attributesToUpdate['subBrand'] = this.productForm.subBrand;
            this.attributesToUpdate['description'] = this.productForm.description;
            this.attributesToUpdate['webLink'] = this.productForm.webLink;
            this.attributesToUpdate['completionLevel'] = this.productForm.completionLevel;
            this.attributesToUpdate['isPrivate'] = this.productForm.isPrivate;
            this.attributesToUpdate['structuredAddress'] = this.productForm.structuredAddress;
            this.attributesToUpdate['contactName'] = this.productForm.contactName;
            this.attributesToUpdate['category'] = this.productForm.category;
            this.attributesToUpdate['codeGpc'] = this.productForm.codeGpc;
            this.attributesToUpdate['nutriment'] = this.productForm.nutriment;
            this.attributesToUpdate['dietTypes'] = this.productForm.dietTypes;
            this.attributesToUpdate['netContents'] = this.productForm.netContents;
            this.attributesToUpdate['regulatedProductName'] = this.productForm.regulatedProductName;
            this.attributesToUpdate['allergensFood'] = this.productForm.allergensFood;
            this.attributesToUpdate['alcoholDegree'] = this.productForm.alcoholDegree;
            this.attributesToUpdate['preparationStateCode'] = this.productForm.preparationStateCode;
            this.attributesToUpdate['consumerUsageInstructions'] = this.productForm.consumerUsageInstructions;
            this.attributesToUpdate['consumerStorageInstructions'] = this.productForm.consumerStorageInstructions;
            this.attributesToUpdate['ingredientStatement'] = this.productForm.ingredientStatement;
            this.attributesToUpdate['preparationInstructions'] = this.productForm.preparationInstructions;
            this.attributesToUpdate['compulsoryAdditiveLabelInformation'] = this.productForm.compulsoryAdditiveLabelInformation;
            this.attributesToUpdate['packagingMarkedLabelAccreditationCode'] = this.productForm.packagingMarkedLabelAccreditationCode;
            this.attributesToUpdate['countryOfOrigin'] = this.productForm.countryOfOrigin;
            this.attributesToUpdate['drainedWeight'] = this.productForm.drainedWeight;
            this.attributesToUpdate['images'] = this.productForm.images;
            this.attributesToUpdate['contentDescription'] = this.productForm.contentDescription;
            this.attributesToUpdate['nutriScore'] = this.productForm.nutriScore;
            this.attributesToUpdate['dailyValueIntakeReference'] = this.productForm.dailyValueIntakeReference;
            this.attributesToUpdate['adhereToTheSectoralAgreement'] = this.productForm.adhereToTheSectoralAgreement;
            this.attributesToUpdate['imagesAddedByUrl'] = this.constructImagesAddedByUrl();
            this.attributesToUpdate['targetMarket'] = this.productForm.targetMarket;
            this.attributesToUpdate['productNames'] = this.productForm.productNames;

            this.productsSvc.updateProduct(this.id, this.attributesToUpdate, this.productForm.imagesToUpload)
                .pipe(finalize(() => this.submitting = false))
                .subscribe(
                    () => {
                        if (!this.userSvc.user.haveSeenProductNames) {
                            this.userSvc.updateUser({haveSeenProductNames: true});
                        }
                        this._router.navigate(['/dashboard'], {queryParams: {actionInProgress: true}
                    } ); },
                    err => {
                        switch (err.error.type) {
                            case 'IMPORT_IN_PROGRESS':
                                this.notifsSvc.importInProgress();
                                break;
                            case 'PRODUCT_VALIDATION_FAILED' :
                                this.notifsSvc.updateProductValidation();
                                break;

                            default:
                                console.error(err);
                                break;
                        }
                    }
                );
        }
    }

    public deleteProduct() {
        if (!this.submitting) {
            this.submitting = true;

            this.productsSvc.deleteProduct(this.id)
                .pipe(finalize(() => this.submitting = false))
                .subscribe(
                    () => this._router.navigate(['/dashboard'], {queryParams: {actionInProgress: true}}),
                    err => {
                        if (err.status === 404) {
                            return this._router.navigate(['/dashboard']);
                        }
                        if (err.status === 401) {
                            return;
                        }

                        switch (err.error.type) {
                            case 'IMPORT_IN_PROGRESS':
                                this.notifsSvc.importInProgress();
                                break;

                            default:
                                this.notifsSvc.serverError();
                                break;
                        }
                    }
                );
        }
    }

    public generateBarcode() {
        this.barcodeGenerationSvc.generateBarcode(this.id);
    }

    public onVisibilityChange(value) {
        this.productForm.isPrivate = !value;
    }

    public getTargetMarketLabel(code: string) {
        const targetMarket: any = _.find(Countries, function(targetMarketItem) {
            return targetMarketItem.code === code;
        }) || {};
        return targetMarket.wording || '';
    }

    public findAllergenWording(code: string): string {
        const allergen : any = _.find(this.mergedAllergenFoodItems, c => c.code === code) || {};
        return allergen.wording;
    }

    public isRequiredAllergen(code: string): boolean {
        const allergen = _.find(this.requiredAllergenFoodItems, c => c.code === code) || {};
        return !_.isEmpty(allergen);
    }

    public isAbleToAddOptionalAllergen(allergenTypeCode: string, levelOfContainmentCode: string): boolean {
        if (allergenTypeCode !== 'AN') {
            return false;
        }

        const authorizedLevelOfContainmentCodes = ['CONTAINS', 'MAY_CONTAIN'];
        if (authorizedLevelOfContainmentCodes.indexOf(levelOfContainmentCode) === -1) {
            return false;
        }
        const allergenFoodCodes = _.map(this.productForm.allergensFood, allergen => allergen.allergenTypeCode);
        const allergenFoodOptionalCodes = _.map(this.optionalAllergenFoodItems, allergen => allergen.code);
        const arrayIntersection = _.intersection(allergenFoodCodes, allergenFoodOptionalCodes);
        return arrayIntersection.length === 0;
    }

    public isAbleToRemoveOptionalAllergen(allergenTypeCode: string, levelOfContainmentCode: string): boolean {
        if (allergenTypeCode !== 'AN') {
            return false;
        }

        const authorizedLevelOfContainmentCodes = ['UNDECLARED', 'FREE_FROM'];
        if (authorizedLevelOfContainmentCodes.indexOf(levelOfContainmentCode) === -1) {
            return false;
        }
        const allergenFoodCodes = _.map(this.productForm.allergensFood, allergen => allergen.allergenTypeCode);
        const allergenFoodOptionalCodes = _.map(this.optionalAllergenFoodItems, allergen => allergen.code);
        const arrayIntersection = _.intersection(allergenFoodCodes, allergenFoodOptionalCodes);
        return arrayIntersection.length !== 0;
    }

    public addOrRemoveOptionalAllergen(allergenTypeCode: string, levelOfContainmentCode: string) {
        if (this.isAbleToAddOptionalAllergen(allergenTypeCode, levelOfContainmentCode)) {
            if (this.opticalSpecifiedAllergen.length === 0) {
                this.optionalAllergenFoodItems.forEach(allergen => {
                    const item = new AllergenFoodModel(allergen.code, '');
                    this.opticalSpecifiedAllergen.push(item);
                });
            }
            const allergenFood = this.productForm.allergensFood;
            this.productForm.allergensFood = _.concat(allergenFood, this.opticalSpecifiedAllergen);
        } else if (this.isAbleToRemoveOptionalAllergen(allergenTypeCode, levelOfContainmentCode)) {
            const index = _.findIndex(this.productForm.allergensFood, function(allergen) {
                return allergen.allergenTypeCode === 'AN';
            });
            this.opticalSpecifiedAllergen = this.productForm.allergensFood.splice(index + 1, this.productForm.allergensFood.length - index);
        }
    }

    public findNutrimentWording(code: string, measurementUnitCode: string): string {
        let nutriment : any = _.find(this.requiredNutrimentItems, c => c.code === code && c.measurementUnitCode === measurementUnitCode) || {};
        if (_.isEmpty(nutriment)) {
            nutriment = _.find(this.optionalNutrimentItems, c => c.code === code && c.measurementUnitCode === measurementUnitCode) || {};
        }
        return nutriment.wording || '';
    }

    public findMeasurementPrecisionWording(code: string): string {
        const measurementPrecision: any = _.find(this.measurementPrecision, c => c.code === code) || {};
        return measurementPrecision.wording;
    }

    public findServingSizeWording(code: string): string {
        const returnedObject: any = _.find(this.nutrimentServingSize, c => c.code === code) || {};
        return `pour ${returnedObject.wording}`;
    }

    public findMeasurementUnitWording(code: string): string {
        const measurementUnit: any = _.find(this.measurementUnit, c => c.code === code) || {};
        return measurementUnit.wording || '';
    }

    public findMeasurementShortUnitWording(code: string): string {
        const measurementShortUnit: any = _.find(this.measurementShortUnit, c => c.code === code) || {};
        return measurementShortUnit.wording || '';
    }

    public onQuantityContainedChange(value: number, index: number) {
        const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
        if (value && !decimalRegex.test(value.toString())) {
            this.productForm.nutriment.items[index].quantityContained = null;
        }
    }

    public onOptionalQuantityContainedChange(value: string, index: number) {
        const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
        if (value && !decimalRegex.test(value)) {
            this.productForm.nutriment.items[index].optionalQuantityContained = null;
        }
    }

    public findDrainedWeightMesurementWording(code: string): string {
        const returnedObject: any = _.find(this.drainedWeightMeasurementUnitConst, c => c.code === code) || {};
        return `${returnedObject.wording}`;
    }

    public constructImagesAddedByUrl() {
        const toReturn = [];
        this.productForm.imagesAddedByUrl.forEach(imagesAddedByUrl => {
            toReturn.push(imagesAddedByUrl.url);
        });
        return toReturn;
    }
}
