import { ViewChild, Component, Output, EventEmitter } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
    selector: 'food-onboarding-modal',
    styleUrls: ['./food-onboarding-modal.less'],
    template: ` <div class="FoodOnBoardingModalModalContainer">
                    <bs-modal #modal class="FoodOnBoardingModalModal" (onClose)="onClose()" (onDismiss)="onDismiss()" [animation]="false" [keyboard]="false" [backdrop]="'static'" [size]="'lg'">
                        <!--<bs-modal-header class="FoodOnBoardingModalModalHeader" [show-close]="false">C'est votre première visite</bs-modal-header>-->
                        <bs-modal-body>
                            <div class="FoodOnBoardingModalModalBody">
                                <h3>Bienvenue sur CodeOnline <span class="foodSpan">Food</span></h3>
                                <div class="new-flag">Nouveau</div>
                                <div class="FoodOnBoardingModalModalText">
                                    La version de CodeOnline dédiée aux produits alimentaires
                                </div>
                                <!--<div class="FoodOnBoardingModalModalText">
                                    L’exigence de transparence des consommateurs sur les produits et le besoin de digitalisation des entreprises ont conduit GS1
                                    France et les acteurs de la filière agroalimentaire à co-développer le service en ligne <span class="colSpan">CodeOnline <span class="foodSpan">Food</span></span>.
                                </div>-->
                                <div class="FoodOnBoardingModalModalOptions">
                                    <ul>
                                        <li>Créez vos codes articles et vos codes-barres.</li>
                                        <li>Mais pas que ! Gérez les 35 informations clés de vos packagings dans un seul endroit.</li>
                                        <li>Rendez accessibles vos données produits aux applications consommateurs et à vos partenaires commerciaux.</li>
                                    </ul>
                                </div>
                                <div class="FoodOnBoardingModalIcons">
                                    <h3>Une initiative collective de la filière agro-alimentaire soutenue par :</h3>
                                    <div class="icons">
                                        <div class="row">
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-ania.png"/>
                                            </div>
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-groupe-94.png"/>
                                            </div>
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-FCD.png"/>
                                            </div>
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-groupe-103.png"/>
                                            </div>
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-groupe-105.png"/>
                                            </div>
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-FFAS.png"/>
                                            </div>
                                            <div class="col-7-ic">
                                                <img class="collective-icons" src="assets/img/logo-groupe-95.png"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="FoodOnBoardingModalModalOptions">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="row">
                                                <div class="col-md-2"><img src="assets/icons/Confiance.png"/></div>
                                                <div class="col-md-10"><div class="option">Confiance</div></div>
                                            </div>
                                            <div class="row">
                                                <div class="optionText">
                                                    Des informations de qualité, fiables et à jour, disponibles pour les consommateurs et vos partenaires commerciaux.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="row">
                                                <div class="col-md-2"><img src="assets/icons/Collaboration.png"/></div>
                                                <div class="col-md-10"><div class="option">Collaboration</div></div>
                                            </div>
                                            <div class="row">
                                                <div class="optionText">
                                                    Un modèle de données co-élaboré et utilisé par GS1 France et les acteurs de la filière agro-alimentaire.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="row">
                                                <div class="col-md-2"><img src="assets/icons/Fiabilite.png"/></div>
                                                <div class="col-md-10"><div class="option">Fiabilite</div></div>
                                            </div>
                                            <div class="row">
                                                <div class="optionText">
                                                    Vous maîtrisez l’information qui circule sur vos produits et vos marques, car vous êtes à l’origine de son contenu.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="row">
                                                <div class="col-md-2"><img src="assets/icons/Opportunite.png"/></div>
                                                <div class="col-md-10"><div class="option">Opportunite</div></div>
                                            </div>
                                            <div class="row">
                                                <div class="optionText">
                                                    Soyez déjà prêts pour le futur du commerce connecté, véritable levier de croissance et de compétitivité.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                        </bs-modal-body>
                        <bs-modal-footer>
                            <div class="FoodOnBoardingModalModalFooter">
                                <div class="FoodOnBoardingModalModalCheckbox col-md-6">
                                    <input type="checkbox" [ngModel]="noShow" (click)="toggleNoShow()"/>
                                    <span class="checkmark"></span>
                                    <label class="toggleNutriScore">
                                        Ne plus afficher
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <a href="https://www.gs1.fr/Nos-filieres/P.G.C/Les-actualites/CodeOnlineFood" target="_blank">En savoir plus</a>
                                    <button class="FoodOnBoardingModalModal" (click)="understood()">Commencer</button>
                                </div>
                            </div>
                        </bs-modal-footer>
                    </bs-modal>
                </div>
            `
})

export class FoodOnBoardingModal {
    @ViewChild('modal', {static: true})
    public modal: BsModalComponent;
    @Output() public onCloseFoodModal = new EventEmitter<any>();
    public noShow: boolean = false;

    constructor() {
    }
    public openModal() {
        this.modal.open();
    }
    public toggleNoShow() {
        this.noShow = !this.noShow;
    }
    public understood() {
        this.modal.close();
    }
    public onClose() {
        this.onCloseFoodModal.emit({noShow: this.noShow});
    }
    public onDismiss() {
        this.onCloseFoodModal.emit({noShow: this.noShow});
    }
}
