import {Injectable} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';
import {AppConstants} from '../models/app.constants';
import {enableDebugTools} from '@angular/platform-browser';

@Injectable()
export class NotificationsSvc {
  private constants = AppConstants.notifications;

  constructor(private notifSvc: NotificationsService) {
  }

  public productNotFound() {
    this.notifSvc.error(this.constants.productNotFound.title, this.constants.productNotFound.content);
  }

  public gtinAlreadyExists() {
    this.notifSvc.info(this.constants.gtinAlreadyExists.title, this.constants.gtinAlreadyExists.content, {timeOut: 15000});
  }

  public serverError() {
    this.notifSvc.error(this.constants.serverError.title, this.constants.serverError.content);
  }

  public preferencesError() {
    this.notifSvc.error(this.constants.preferencesError.title, this.constants.preferencesError.content);
  }

  public companyMissing() {
    this.notifSvc.error(this.constants.companyMissing.title, this.constants.companyMissing.content, {timeOut: 15000});
  }

  public categoryUpdated() {
    this.notifSvc.success(this.constants.categoryUpdated.title,  this.constants.categoryUpdated.content, {timeOut: 15000, theClass: 'categoryUpdated'});
  }

  public noGtinAvailable() {
    this.notifSvc.error(this.constants.noGtinAvailable.title, this.constants.noGtinAvailable.content, {
      timeOut: 15000,
      theClass: 'noGtinAvailable'
    });
  }

  public wrongImageType() {
    this.notifSvc.info(this.constants.imagesUpload.wrongType.title, this.constants.imagesUpload.wrongType.content);
  }

  public imageTooBig() {
    this.notifSvc.info(this.constants.imagesUpload.wrongSize.title, this.constants.imagesUpload.wrongSize.content);
  }

  public queueLimitReached() {
    this.notifSvc.info(this.constants.imagesUpload.queueLimit.title, this.constants.imagesUpload.queueLimit.content);
  }

  public invalidImageUrl() {
    this.notifSvc.info(this.constants.invalidImageUrl.title, this.constants.invalidImageUrl.content);
  }

  public wrongImportFileType() {
    this.notifSvc.info(this.constants.productImport.wrongType.title, this.constants.productImport.wrongType.content);
  }

  public userDisconnected() {
    this.notifSvc.alert(this.constants.userDisconnected.title, this.constants.userDisconnected.content);
  }

  public wrongTime() {
    this.notifSvc.alert(this.constants.wrongTime.title, this.constants.wrongTime.content);
  }

  public importFileTooBig() {
    this.notifSvc.info(this.constants.productImport.wrongSize.title, this.constants.productImport.wrongSize.content);
  }

  public importFileValidationError(isBundle = false) {
    const content = isBundle ? this.constants.productImport.validationError.bundleContent : this.constants.productImport.validationError.content;
    this.notifSvc.info(this.constants.productImport.validationError.title, content);
  }

  public importFileUnsupportedEncodingError() {
    this.notifSvc.info(this.constants.productImport.unsupportedEncoding.title, this.constants.productImport.unsupportedEncoding.content);
  }

  public importFileEmptyError() {
    this.notifSvc.info(this.constants.productImport.emptyFile.title, this.constants.productImport.emptyFile.content);
  }

  public drainedWeightError() {
    this.notifSvc.info(this.constants.productImport.drainedWeight.title, this.constants.productImport.drainedWeight.content);
  }

  public importInProgress() {
    const notification = this.constants.productImport.importInProgress;
    this.notifSvc.info(notification.title, notification.content);
  }

  public createProductValidation() {

    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      dataLayer.push({
        event: 'creationFailed'
      });
    }
    this.notifSvc.info(this.constants.createProductValidation.title, this.constants.createProductValidation.content, {timeOut: 15000});
  }

  public updateProductValidation() {
    this.notifSvc.info(this.constants.updateProductValidation.title, this.constants.updateProductValidation.content, {timeOut: 15000});
  }

  public packingUnityEditionDisabled() {
    this.notifSvc.alert(this.constants.packingUnityEditionDisabled.title, this.constants.packingUnityEditionDisabled.content);
  }

  public noVcsCategoryChosen() {
    this.notifSvc.alert(this.constants.noClassificationChosen.title, this.constants.noClassificationChosen.content);
  }

  public userUnauthorizedToConnect() {
    this.notifSvc.alert(this.constants.userUnauthorizedToConnect.title, this.constants.userUnauthorizedToConnect.content);
  }

  public uncompletedInscriptionProcess() {
    this.notifSvc.error(this.constants.uncompletedInscriptionProcess.title, this.constants.uncompletedInscriptionProcess.content);
  }

  public shareUrlCopied() {
    this.notifSvc.info(this.constants.shareUrlCopied.title, this.constants.shareUrlCopied.content);
  }

  public selectPrefixMin() {
    this.notifSvc.error(this.constants.selectPrefixMin.title, this.constants.selectPrefixMin.content);
  }

  public wrongFoodAllergen() {
    this.notifSvc.warn(this.constants.wrongFoodAllergen.title, this.constants.wrongFoodAllergen.content);
  }

  public wrongNutriscore() {
    this.notifSvc.info(this.constants.wrongNutriscore.title, this.constants.wrongNutriscore.content);
  }

  public wrongFoodNutriment() {
    this.notifSvc.warn(this.constants.wrongFoodNutriment.title, this.constants.wrongFoodNutriment.content);
  }

  public sugarSupToCarbohydrates() {
    this.notifSvc.warn(this.constants.sugarSupToCarbohydrates.title, this.constants.sugarSupToCarbohydrates.content);
  }

  public wrongNumberOfDecimalsDigits() {
    this.notifSvc.warn(this.constants.nutrimentsDecimalsError.title, this.constants.nutrimentsDecimalsError.content);
  }

  public mandatoryProductName(isBundle: boolean) {
    const title = isBundle ? this.constants.mandatoryProductName.bundleTitle : this.constants.mandatoryProductName.title;
    const content = isBundle ? this.constants.mandatoryProductName.bundleContent : this.constants.mandatoryProductName.content;
    this.notifSvc.info(title, content);
  }

  public exportInProgress() {
    this.notifSvc.info(this.constants.exportInProgress.title, this.constants.exportInProgress.content);
  }
}
