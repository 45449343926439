import {
  Component, NgModule, ViewChild, ElementRef, OnInit, AfterViewInit, AfterViewChecked
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BarcodeGenerationSvc, ProductsSvc} from '../../services/index';
import {CompanySvc} from '../../../shared/services/company.service';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import {VintageSvc} from '../../../shared/services/vintages.service';
import * as _ from 'lodash';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {ProductCategorySvc} from '../../../shared/services/product-category.service';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {MatDialog} from '@angular/material/dialog';
import {UnreachableProductModalComponent} from '../unreachable-product-modal/unreachable-product-modal.component';
import {BannerService} from '../../../shared/services/banner.service';

@Component({
  selector: 'product-view-choice-category',
  styleUrls: ['./product-view-choice-category.less'],
  templateUrl: './product-view-choice-category.tpl.html',
  providers: [{provide: CarouselConfig, useValue: {interval: 5000, noPause: false, showIndicators: false}}]
})
export class ProductViewChoiceCategoryComponent implements OnInit {

  public isFood: boolean;
  public product: any;
  public isGeneric: boolean;
  public isVcs: boolean;
  public isBundle: boolean;
  public isNotNew: boolean;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public productCategorySvc: ProductCategorySvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public dialog: MatDialog,
              public bannerSvc: BannerService,
              private headerSvc: DashboardHeaderSvc
  ) {
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData('Votre produit', 'assets/icons/viewCode.svg');
    this.route.params.subscribe(
      (param: Params) => {
        const id = param['id'];
        this.productsSvc.getProduct(id)
          .subscribe(
            product => {
              const codeGpc = product.codeGpc;

              if (codeGpc === '99999999') {
                product.adhereToTheSectoralAgreement = false;
              }
              this.product = product;
              if (this.product.oldCodeGpc) {
                this.dialog.open(UnreachableProductModalComponent, {
                  id: 'unreachableProductModal',
                  data: {
                    productId: this.product.id
                  },
                  width: '615px',
                  hasBackdrop: true,
                  disableClose: true
                });

                this.headerSvc.dialog = this.dialog;
                this.dialog.getDialogById('unreachableProductModal').afterOpened().subscribe(result => {
                  const divElement: HTMLElement = document.querySelector('.cdk-overlay-backdrop-showing');
                  if (this.bannerSvc.canShowBanner) {
                    divElement.style.top = '178px';
                  }
                });
              }
              if (_.isEmpty(this.product.productNames)) {
                this.product.productNames = [{value: this.product.name, lang: 'fr'}];
              }

              this.product.targetMarket = _.isArray(this.product.targetMarket) && !_.isEmpty(this.product.targetMarket) ? this.product.targetMarket : ['250'];
              if (_.isEmpty(this.product.productNames)) {
                this.product.productNames = [{
                  value: this.product.name,
                  lang: 'fr'
                }];
              }
              this.isFood = false;
              this.isGeneric = false;
              this.isVcs = false;
              this.isBundle = this.product.productType === 'BUNDLE';
              this.isNotNew = this.product.productResellerType === 'NOT_NEW';
              if (this.isVcsCategory(codeGpc) &&   !this.isBundle && ! this.isNotNew) {
                this.isVcs = true;
                return;
              }

              this.isGeneric = true;
            },
            err => console.error(err)
          );
      });
  }

  private isVcsCategory(codeGpc: string): boolean {
    return this.productCategorySvc.isVcsCategory(codeGpc).isVcsCategory;
  }
}
