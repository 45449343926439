import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from './../environments/environment';


@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                forbiddenRoute: '/Forbidden',
                unauthorizedRoute: '/login',
                logLevel: environment.production ? LogLevel.Warn : LogLevel.Debug,
                authority: `${environment.SSO_DOMAIN}`,
                redirectUrl: `${environment.SSO_REDIRECT_URL}`,
                postLogoutRedirectUri: `${environment.SSO_LOGOUT_REDIRECT_URL}`,
                clientId: `${environment.SSO_CLIENT_ID}`,
                scope: 'profile openid',
                responseType: 'id_token token',
                silentRenew: false,
                silentRenewUrl: `${environment.SSO_REDIRECT_URL}`,
                silentRenewTimeoutInSeconds: 30,
                maxIdTokenIatOffsetAllowedInSeconds: 300,
                startCheckSession: false,
                triggerAuthorizationResultEvent: true,
                authWellknownEndpoints: {
                    issuer: `${environment.SSO_DOMAIN}`,
                    jwksUri: `${environment.SSO_DOMAIN}/.well-known/openid-configuration/jwks`,
                    authorizationEndpoint: `${environment.SSO_DOMAIN}/connect/authorize`,
                    tokenEndpoint: `${environment.SSO_DOMAIN}/connect/token`,
                    userInfoEndpoint: `${environment.SSO_DOMAIN}/connect/userinfo`,
                    endSessionEndpoint: `${environment.SSO_DOMAIN}/connect/endsession`,
                    checkSessionIframe: `${environment.SSO_DOMAIN}/connect/checksession`,
                    revocationEndpoint: `${environment.SSO_DOMAIN}/connect/revocation`,
                    introspectionEndpoint: `${environment.SSO_DOMAIN}/connect/introspect`
                }
            }
        })
    ],
    exports: [AuthModule]
})
export class AuthConfigModule {}



