import {Router} from '@angular/router';
import {DashboardConst, FormInputError} from './index';
import {ProductsSvc} from '../services/index';
import {CompanySvc, NotificationsSvc, UserSvc} from '../../shared/services/index';
import {Countries} from './country.constants';
import {ProductClassificationIndex} from './productClassification.constants';
import {Classification} from '../../shared/models/Classification.model';
import {Spirits} from './spirits.constants';
import {wineColorCodes} from './wineColor.constants';
import {FeatureToggleSvc} from '../../shared/services/feature-toggle.service';
import {BaseCreationUnityForm} from './baseCreationUnityForm.model';
import {VintageSvc} from '../../shared/services/vintages.service';
import {Aoc} from './aoc.constants' ;
import {GisCodes} from './gis.constants' ;

import {certificationsConstants} from './certifications.constants';
import {SugarContentConstants} from './sugarContent.constants';
import {SpiritStyles} from './spiritStyles.constants';
import {VcsProductFormOldModel} from './vcsProductCreationForm-old.model';
import {Gtin} from '../../shared/models';
import {NetContent} from './NetContent.model';
import {ProductFormModel} from './productCreationForm.model';
import {Allergen} from './Allergen.model';
import {ImageUrl} from './ImageUrl.model';
import {finalize} from 'rxjs/operators';
import {ProductName} from './ProductName.model';


import * as _ from 'lodash';
import {containerCodesOld} from './container-old.constants';

export class VcsCreationUnityOldForm extends BaseCreationUnityForm {

  public DECIMAL_PATTERN = '^([0-9]{1,2}(,\\d{1})?|100)$';
  public VOLUME_PATTERN = '^([0-9]{1,}(,\\d{1})?)$';
  public AGEOFSPIRIT_PATTERN = '^[1-9][0-9]?$|^100$';
  public productForm: VcsProductFormOldModel;
  public cipPattern: string;
  public constants = DashboardConst;
  public submitting: boolean = false;
  public cipLoaded: boolean = false;
  public countries = Countries;
  public spirits = Spirits;
  public spiritStyles = SpiritStyles;
  public gpcCodes: Classification[];
  public wineColorCodes = wineColorCodes;
  public containerCodes = containerCodesOld;
  public selectedCategorie = new Classification('', '', '');
  public productCategories = ProductClassificationIndex;
  public certificationsAndLabels = certificationsConstants;
  public sugarContentConstants = SugarContentConstants.sparklingWine;
  public aocCodes = Aoc;
  public gisCodes = GisCodes;

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notifsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public vintageSvc: VintageSvc, public userSvc: UserSvc) {
    super(productsSvc, companySvc, _router, notifsSvc, featureToggleSvc, userSvc);
  }

  public onGpcChange() {
    this.getClassification();
    //  this.productForm.allergen = {};

    if (this.isProductSparklingWine()) {
      this.sugarContentConstants = SugarContentConstants.sparklingWine;
    }

    if (this.isProductStillWine()) {
      this.sugarContentConstants = SugarContentConstants.stillWine;
    }
    const sugarContentFound = this.sugarContentConstants.filter(sugarContent => sugarContent.code === this.productForm.sugarContent);
    if (sugarContentFound.length === 0) {
      this.productForm.sugarContent = '';
    }
  }

  public prepareClassificationList(classifications: Classification[]) {
    this.gpcCodes = [new Classification('', '', '')];
    this.gpcCodes = this.gpcCodes.concat(classifications);
  }

  public onAlcoholDegreeChange(alcoholDegree: string) {
    const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
    if (!decimalRegex.test(alcoholDegree)) {
      this.productForm.alcoholDegreeError = new FormInputError('warning', this.constants.createForm.errors.alcoholDegreePattern);
    } else {
      this.productForm.alcoholDegreeError = new FormInputError('valid', '');
    }
  }

  public onVolumeChange(volume: string) {
    const decimalRegex = new RegExp(this.VOLUME_PATTERN);
    if (decimalRegex.test(volume)) {
      this.productForm.volumeError = new FormInputError('valid', '');
    } else {
      this.productForm.volumeError = new FormInputError('warning', '');
    }
  }

  public submitForm() {
    if (this.productForm.codeGpc === '0') {
      this.productForm.codeGpc = '';
    }

    if (!this.submitting && this.productForm.gtin.available) {
      this.submitting = true;
      this.productsSvc.createOldVcsProduct(this.productForm)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(
          product => {
            if (!this.userSvc.user.haveSeenProductNames) {
              this.userSvc.updateUser({haveSeenProductNames: true});
            }
            return this._router.navigate(['/dashboard/product/view', product.id]);
          },
          err => {
            if (err.status === 409) {
              switch (err.error.type) {
                case 'IMPORT_IN_PROGRESS' :
                  this.notifsSvc.importInProgress();
                  break;
                case 'PRODUCT_VALIDATION_FAILED' :
                  this.notifsSvc.createProductValidation();
                  this.getFirstGtinAvailable(this.productForm.gtin.prefix);
                  break;

                default:
                  console.error(err);
                  break;
              }
            } else {
              console.error('error to create form ', err);
            }
          }
        );
    }
  }

  public getClassification() {
    this.selectedCategorie = _.find(this.gpcCodes, {code: this.productForm.codeGpc});
  }

  public isProductSparklingWine() {
    return this.productForm.codeGpc === this.productCategories.SPARKLING_WINE_GPC_CODE;
  }

  public isProductStillWine() {
    return this.productForm.codeGpc === this.productCategories.STILL_WINE_GPC_CODE;
  }


  public isProductSparklingOrStillWine() {
    return this.isProductSparklingWine() || this.isProductStillWine();
  }

  public resetFormProduct() {
    this.productForm = new VcsProductFormOldModel(new Gtin(this.companySvc.company.mainPrefix, '', '',
        false, false), new FormInputError('', ''),
      new FormInputError('', ''), new FormInputError('', ''),
      '', '', '', '', '', [new ProductName('', 'fr')], [], '',
      '', 0, this.companySvc.company.id, ['250'], this.companySvc.company.selectedCategory.code, '',
      '', '250', '', -1, '', {}, '', ''
      , {}, [], '', '', '', '', false, this.companySvc.company.selectedCategory.text,
      [new NetContent('', 'CLT')], 0, false, [new ImageUrl('')]);
  }

  public isProductVcs() {
    return this.selectedCategorie.categorie === this.productCategories.SPI || this.selectedCategorie.categorie === this.productCategories.VIN;
  }

  public isProductWine() {
    return this.selectedCategorie.categorie === this.productCategories.VIN;
  }

  public formatAllergen(allergens: any = {}): Allergen {
    for (const key of Object.keys(allergens)) {
      allergens[key] = _.isBoolean(allergens[key]) ? allergens[key] : allergens[key].toLowerCase() === 'true';
    }
    return allergens;
  }

  public resetFormProductWitBaseAttribute(form: ProductFormModel) {
    this.productForm = new VcsProductFormOldModel(form.gtin, form.cipError, new FormInputError('', ''),
      new FormInputError('', ''),
      form.mpn, form.sku, form.brand, form.subBrand, form.name, form.productNames, form.imagesToUpload, form.description,
      form.webLink, form.completionLevel, form.companyId, form.targetMarket, form.codeGpc, this.productForm.spiritCodeGpc,
      this.productForm.alcoholDegree, this.productForm.productCountry, this.productForm.wineColor, this.productForm.vintage,
      this.productForm.ageOfSpirit, this.formatAllergen(this.productForm.allergen), this.productForm.vintageName, this.productForm.container
      , this.productForm.certifications, this.productForm.varietals, this.productForm.sugarContent, this.productForm.aoc, this.productForm.spiritStyle
      , this.productForm.gis, this.productForm.isPrivate, form.category,
      form.netContents, form.vbGs1CompletionLevel, form.adhereToTheSectoralAgreement, form.imagesAddedByUrl, form.productResellerType);
  }
}
