export const oldToNewGpcMapping = {
  10000111: ['10008178', '10008179', '10008180', '10008183'],
  10000398: [
    '10008134', '10008131',
    '10008137', '10008128',
    '10008135', '10008136',
    '10008123', '10000397',
    '10008130', '10008122',
    '10008124', '10008125',
    '10008129', '10008127',
    '10008126', '10005811'
  ],
  10000406: [
    '10008119',
    '10008144',
    '10008146',
    '10008147',
    '10008148',
    '10008149'
  ],
  10000636: ['10008145', '10008149'],
  10001811: [
    '10008259', '10008260',
    '10008261', '10008262',
    '10008263', '10008264',
    '10008265', '10008266',
    '10008267', '10008268',
    '10008269', '10008270'
  ],
  10002228: ['10002227', '10002223', '10008304'],
  10006310: ['10008181', '10008182'],
  10006963: ['10008065', '10008066', '10008067', '10008068'],
  10007010: [
    '10007979', '10007980',
    '10007981', '10007982',
    '10007983', '10007984',
    '10007985', '10007986',
    '10007987', '10007988',
    '10007990', '10007991',
    '10007992', '10007993',
    '10007994', '10007995',
    '10007996', '10007997',
    '10007998', '10007999'
  ],
  10006959: ['10007946'],
  10005813: ['10008028'],
  10001195: ['10008029'],
  10006912: ['10007447'],
  10003959: ['10003960'],
  10003962: ['10003961'],
  10002455: ['10002454'],
  10005343: ['10002454'],
  10006787: ['10006786'],
  10006788: ['10006786'],
  10006789: ['10006789'],
  10006790: ['10006786'],
  10003927: ['10002534'],
  10002229: ['10002220'],
  10002230: ['10002221'],
  10002215: ['10008013'],
  10001094: ['10001096']

};
