import {Component, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {BsModalComponent} from 'ng2-bs3-modal';
@Component({
    selector: `product-import-tutorial-modal`,
    styles: [`
      modal-header div {
        margin-bottom: 0.6em;
      }

      modal-body {
        text-align: center;
      }

    `],
    template: `
        <div>
            <bs-modal id="productImportTutorialModal" class="productImportTutorialModal" [keyboard]="false" [backdrop]="'static'" [size]="'lg'" #productImportTutorialModal>
                <bs-modal-header>
                    <button type="button" (click)="close()" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                    <div class="modal-title">Tutoriel d'import</div>
                </bs-modal-header>
                <bs-modal-body>
                    <iframe width="853" height="480" src="" frameborder="0" allowfullscreen></iframe>
                </bs-modal-body>
            </bs-modal>
        </div>
    `
  })
  export class ProductImportTutorialModal {

    @ViewChild(BsModalComponent, {static: true})
    public modal: BsModalComponent;

    public show() {
      $('#productImportTutorialModal iframe').attr('src', 'https://www.youtube.com/embed/iBzIyNwi5eo?autoplay=1&rel=0&disablekb=1&showinfo=0&vq=hd1080');
      this.modal.open();
    }

    public close() {
      $('#productImportTutorialModal iframe').attr('src', '');
    }

  }
