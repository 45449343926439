import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImagePreviewModel} from '../../models/imagePreviewModel.model';

@Component({
  selector: 'image-preview',
  styleUrls: ['./image-preview.less'],
  template: `
<div class="ImagePreview">

    <img id="{{id}}" class="ImagePreview-img" src="{{image.url}}">

    <div class="ImagePreview-content">
        <div class="ImagePreview-description">
            <div class="ImagePreview-description-name">{{image.name}}</div>
            <div class="ImagePreview-description-size">
                {{(image.width) ? '(' + image.width + ' x ' + image.height + ')' : ''}}
            </div>
        </div>
        <button *ngIf="showRemoveButton" type="button" class="ImagePreview-removeButton" (click)="removeImage(image)">X</button>
    </div>
</div>`
})
export class ImagePreview {

  @Input() public id: number;
  @Input() public image: ImagePreviewModel;
  @Output() public onRemoveImage = new EventEmitter<any>();
  @Input() public showRemoveButton: boolean = true;
  constructor() {
  }

  public ngOnChanges() {
    const img = new Image();
    img.src = this.image.url;

    img.addEventListener('load', () => {
      this.image.width = img.width;
      this.image.height = img.height;
    });
  }

  public removeImage(image: any): void {
    this.onRemoveImage.emit(image);
  }
}
