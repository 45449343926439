import {Component, ViewChild, Input, Output} from '@angular/core';

import {BsModalComponent} from 'ng2-bs3-modal';

import {DashboardConst, Product, ProductImportForm} from '../../models/index';
import {EventEmitter} from '@angular/core';
import {BarcodeGenerationSvc, ProductsSvc} from '../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {VintageSvc} from '../../../shared/services/vintages.service';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationsSvc} from '../../../shared/services';
import {CompanySvc} from '../../../shared/services/company.service';
import {ClassificationSvc} from '../../../shared/services/classification.service';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'product-import-confirmation-modal',
  styleUrls: ['./product-import-confirmation-modal.less'],
  template: `
      	<div class="productImportConfirmationModal">
          	<bs-modal class="productImportConfirmationModal" [keyboard]="false" [backdrop]="'static'"
                 [size]="'lg'" #productImportConfirmationModal>
              	<bs-modal-header [showDismiss]="true">
                  	<div *ngIf="productImportForm.totalProducts !== productImportForm.failedProducts" class="modal-title">
                     	 Confirmer vous l'import de {{ productImportForm.succeededProducts }} GTIN?
                  	</div>
                  	<div *ngIf="productImportForm.totalProducts === productImportForm.failedProducts" class="modal-title">
                      	Tous vos GTIN sont invalides.
					</div>
				</bs-modal-header>
				<bs-modal-body class="modal-body">
					<div *ngIf="isAllProductsValid()">
						<p>Vous pouvez <a [href]="getDownloadReportUrl()" target="_blank">télécharger le rapport</a> pour
							visualiser GTIN qui seront importés.</p>
					</div>
					<div *ngIf="isSomeProductsValid()">
						<p><b>{{ productImportForm.failedProducts }}</b> GTIN {{ productImportForm.failedProducts > 1 ? "contiennent" : "contient" }} des erreurs il est encore
							temps de les corriger afin qu'il puissent être importés.</p>
						<p>Pour cela, vous pouvez <a [href]="getDownloadReportUrl()" target="_blank">Télécharger le
							rapport</a>, corriger les lignes qui {{ productImportForm.failedProducts > 1 ? "contiennent" : "contient" }} des erreurs puis ré-importer le fichier
							corrigé.</p>
					</div>
					<div *ngIf="isAllProductsInvalid()">
						<p>Corriger vos produits en <a [href]="getDownloadReportUrl()" target="_blank">téléchargent le
							rapport</a>.</p>
					</div>
					<!-- <div class="row" *ngIf="!isAllProductsInvalid() && featureToggleSvc.canSeeChangeVisibility()">
						<div class="col-md-6 col-md-offset-3 divider"></div>
					</div> -->
					<div class="row" *ngIf="!isAllProductsInvalid() && featureToggleSvc.canSeeChangeVisibility()">
						<div class="col-md-3 switchContainer">
							<ui-switch [(ngModel)]="isPublic">
							</ui-switch>
							<span>Publier les <b>{{ productImportForm.succeededProducts }}</b> GTIN.</span>
						</div>
						<div class="col-md-8 messageContainer">
							<!--  -->
								<div class="messageContainerTitle">
									À quoi correspond la publication ?
								</div>
								<div class="messageContainerBody">
									<div class="intro">
										Publier vos informations produits, c'est :
									</div>
									<div class="points">
										- Mettre à disposition vos informations produits à nos 2 millions de membres dans le monde via les services de GS1 pour faciliter leur référencement
									</div>
									<!--
										ON ATTEND QUE LE MOTEUR DE RECHERCHE SOIT EN PROD
									<div class="points">
										- Rendre visible vos produits sur le web avec le moteur de recherche de CodedOnline (<a href="https://www.codeonline.fr/search" target="_blank">www.codeonline.fr/search</a>)
									</div> -->
									<div class="points">
										- Rendre visible vos produits sur le web avec le moteur de recherche de CodeOnline (à venir)
									</div>
									<div class="attention">
										<span style="font-weight: bold; text-decoration: underline;">Attention</span>: La fonctionnalité de publication n'envoie pas automatiquement vos informations produits à vos clients.
									</div>
									<div class="footer">
										Vous êtes toujours responsable de la déclaration des informations liées à vos produits. CodeOnline permet simplement de faciliter l'accès à vos informations produits pour améliorer les processus de référencement,
											de qualité et fluidifier les interactions avec vos différents partenaires commerciaux.
									</div>
								</div>
							<!--  -->
						</div>
					</div>
				</bs-modal-body>
				<bs-modal-footer [showDefaultButtons]="false" class="modal-footer">
					<button type="button" class="action-btn back" (click)="back()">Retour</button>
					<button *ngIf="productImportForm.totalProducts !== productImportForm.failedProducts"
							(click)="confirm()" type="button" class="action-btn confirm">Confirmer
					</button>

				</bs-modal-footer>
			</bs-modal>
		</div>
  `
})
export class ProductImportConfirmationModal {

  @Input()
  public productImportForm: ProductImportForm;

  @Output()
  public onConfirmClick = new EventEmitter();

  public isPublic: boolean = true;

  @ViewChild(BsModalComponent, {static: true})
  private modal: BsModalComponent;

  constructor(public featureToggleSvc: FeatureToggleSvc) {}

  public show() {
    this.modal.open();
  }

  public back() {
    this.modal.dismiss();
  }

  public confirm() {
    this.onConfirmClick.emit(this.isPublic);
    this.modal.dismiss();
  }

  public getDownloadReportUrl() {
    return `${environment.TEMPORARY_URL_PREFIX}/${this.productImportForm.reportUrl}`;
  }

  public isAllProductsValid() {
    return this.productImportForm.totalProducts === this.productImportForm.succeededProducts;
  }

  public isAllProductsInvalid() {
    return this.productImportForm.totalProducts === this.productImportForm.failedProducts;
  }

  public isSomeProductsValid() {
    return (this.productImportForm.succeededProducts > 0 && this.productImportForm.failedProducts > 0);
  }

}
