import {AfterViewChecked, Component, HostListener} from '@angular/core';

import {UserSvc, CompanySvc} from '../../../shared/services/index';
import {ProductsSvc} from '../../services/index';
import {DashboardConst} from '../../models/dashboard.constants';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {LoginConstants} from '../../../login/models';
import {Router} from '@angular/router';
import {FeatureToggleSvc} from '../../../shared/services';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {AuthGuard} from 'src/app/shared/services/auth-guard';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'dashboard-header',
  styleUrls: ['./dashboard-header.less'],
  template: `
    <div class="DashboardHeader">
      <div class="row">
        <div class="title col-xs-5">
          <img src="{{headerSvc.iconUrl}}" alt="">
          {{ headerSvc.title }}
          <span class="subtitle" [hidden]="headerSvc.subTitle === undefined">{{ headerSvc.subTitle }}</span>
        </div>
        <div class="menu col-xs-7">
          <div class="pull-right" role="group">
            <button type="button" class="menu-button header-help-btn" routerLink="helpcenter/topic/0"
                    (click)="headerSvc.dialog.closeAll()">
              <i class="bi bi-question-circle"></i>
              Centre d'aide
            </button>

            <div class="menu-user menuUserInfo">
              <span class="menuUserInfo">{{ userSvc.user.firstName }}</span>
              <button type="button" class="menu-button settings menuUserInfo"
                      (click)="displayUserAccount = !displayUserAccount">
                <img class="menuUserInfo" src="assets/icons/arrowDownButton.svg" alt="">
              </button>

              <!-- User account popover -->
              <div class="menu-userAccount menuUserInfo" *ngIf="displayUserAccount">
                <div class="menuUserInfo arrow"></div>
                <div class="menu-userAccount-name menuUserInfo">
                  {{ userSvc.user.firstName }} {{ userSvc.user.lastName }}
                </div>
                <div class="menu-userAccount-company menuUserInfo">{{ companySvc.company.companyName }}</div>
                <div class="menu-userAccount-email menuUserInfo">{{ userSvc.user.email }}</div>
                <a class="menu-userAccount-cgu" target="_blank" type="button"
                        (click)="goToCGUPage()">Conditions générales d'utilisation
                </a>
                <button *ngIf="featureToggleSvc.canSeeSettingsImport()"
                        class="menu-userAccount-settings-prefix"
                        type="button"
                        (click)="goToSettingPage('prefixes')">Paramètres de création de GTIN en nombre
                </button>
                <button class="menu-userAccount-change" type="button" *ngIf="showChangeCompany"
                        (click)="changeCompany()">Utiliser une autre entreprise
                </button>
                <button class="menu-userAccount-disconnect" type="button"
                        (click)="disconnect()">Se déconnecter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class DashboardHeader implements AfterViewChecked {
  public constants = DashboardConst;
  public displayUserAccount: boolean = false;
  public displayContactUs: boolean = false;
  public telIcon: string = '/assets/icons/tel.svg';
  public userOtherPref: any = {};
  public showChangeCompany = false;

  constructor(public headerSvc: DashboardHeaderSvc,
              public userSvc: UserSvc,
              public router: Router,
              public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public featureToggleSvc: FeatureToggleSvc,
              private authService: OidcSecurityService,
              public authGuard: AuthGuard) {
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (targetElement && targetElement.className.indexOf('contact') === -1) {
      this.closeContacUsPopin();
    }

    if (targetElement && targetElement.className.indexOf('menuUserInfo') === -1) {
      this.displayUserAccount = false;
    }
    this.showChangeCompany = this.authService.getUserData().companies_nb > 1;
  }

  public setUserOtherPreference(id: string) {
    if (this.featureToggleSvc.canSeeVcs()) {
      this.userOtherPref = LoginConstants.disclaimer.preferences.find(item => item.id !== id) || {};
    }
  }

  public changeUserPreference() {
    this.companySvc.updateCompany({preferenceCompany: this.userOtherPref.id})
      .then(() => {
        this.userOtherPref = {};
        this.router.navigate(['/dashboard'], {queryParams: {actionInProgress: true}});
        this.displayUserAccount = !this.displayUserAccount;
      })
      .catch((err) => console.error(err));
  }

  public toggleContactUs() {
    this.displayContactUs = !this.displayContactUs;
    this.telIcon = this.displayContactUs ? '/assets/icons/telActive.svg' : '/assets/icons/tel.svg';
  }

  public disconnect() {
    this.userSvc.disconnectUser(false);
  }

  public ngAfterViewChecked(): void {
    if (this.companySvc.company.preferenceCompany !== this.userOtherPref.id) {
      this.setUserOtherPreference(this.companySvc.company.preferenceCompany);
    }
  }

  public closeContacUsPopin() {
    this.displayContactUs = false;
    this.telIcon = '/assets/icons/tel.svg';
  }

  public goToSettingPage(page) {
    this.displayUserAccount = false;
    this.router.navigate([`dashboard/settings/${page}`]);
  }

  public changeCompany() {
    this.authGuard.removeJwt();
    this.authService.authorize(null, {customParams: {companies: true, prompt: 'login'}});
  }

  public goToCGUPage() {
    window.open(environment.CGU_PDF);
  }
}
