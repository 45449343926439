import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'danger-alert',
  templateUrl: './danger-alert.component.html',
  styleUrls: ['./danger-alert.component.less']
})
export class DangerAlertComponent implements OnInit {
  @Input() public title: any;
  @Input() public withIcon: Boolean;
  @Input() public cssClass : string;
  constructor() { }

  public ngOnInit(): void {
  }

}
