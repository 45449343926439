export const dietTypesConst = [
    {
        code: 'COELIAC',
        wording: 'Cœliaque'
    },
    {
        code: 'DIABETIC',
        wording: 'Diabétique'
    },
    {
        code: 'DIETETIC',
        wording: 'Diététique'
    },
    {
        code: 'FREE_FROM_GLUTEN',
        wording: 'Sans Gluten'
    },
    {
        code: 'HALAL',
        wording: 'Halal'
    },
    {
        code: 'KOSHER',
        wording: 'Kasher'
    },
    {
        code: 'VEGAN',
        wording: 'Végan'
    },
    {
        code: 'VEGETARIAN',
        wording: 'Végétarien'
    },
    {
        code: 'WITHOUT_BEEF',
        wording: 'Sans Bœuf '
    },
    {
        code: 'WITHOUT_PORK',
        wording: 'Sans Porc'
    }
];
