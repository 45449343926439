import {Component} from '@angular/core';
import {HelpCenterConstants} from '../models';
import {DashboardHeaderSvc} from '../services/dashboard-header.service';

@Component({
  selector: 'help-center',
  styleUrls: ['./helpCenter.less'],
  template: `<div class="HelpCenter">
    <div class="container">
        <div class="row">
            <div class="HelpCenter-menu col-xs-4">
                <help-center-menu></help-center-menu>
            </div>
            <div class="HelpCenter-content col-xs-8">
              <router-outlet></router-outlet>
            </div>
            <div class="row">
                <div class="col-xs-8 col-xs-offset-4">
                    <div class="HelpCenter-contactUs">
                        <div class="HelpCenter-contactUs-title">{{constants.contactUs.title}}</div>
                        <div class="HelpCenter-contactUs-content">{{constants.contactUs.content}}</div>
                        <div class="HelpCenter-contactUs-tel">Par téléphone: <span>{{constants.contactUs.tel}}</span>
                        </div>
                        <div class="HelpCenter-contactUs-email">Par email:
                            <a href="mailto:{{constants.contactUs.email}}?subject=Assistance%20CodeOnline">{{constants.contactUs.email}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
})
export class HelpCenterComponent {

  public constants = HelpCenterConstants;

  constructor(public dashboardHeaderSvc: DashboardHeaderSvc) {
  }

  public ngOnInit() {
    this.dashboardHeaderSvc.setHeaderData('Centre d\'aide', 'assets/icons/sauvetage.svg');
  }
}
