import {Component, ViewChild, Input, OnInit} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {LoginConstants} from '../../models';
import {Router} from '@angular/router';

@Component({
  selector: 'tutorial-modal',
  styleUrls: ['./tutorial-modal.less'],
  template: `
      <div class="TutorialModal">
          <bs-modal class="tutorialModal" [animation]="true" [keyboard]="false"
                 [backdrop]="'static'"
                 [size]="'lg'" #modal>
              <bs-modal-body>
                  <div class="TutorialModal-carousel">
                      <carousel [noWrap]="true">
                          <slide *ngFor="let slide of slides; let index=index" [active]="slide.active">
                              <div class="TutorialModal-carousel-image">
                                  <img [src]="'assets/img/' + slide.img">
                              </div>
                              <div class="TutorialModal-carousel-body">
                                  <div class="TutorialModal-carousel-body-title">{{slide.title}}</div>
                                  <div class="TutorialModal-carousel-body-text" [innerHtml]="slide.text"></div>
                              </div>
                              <div class="TutorialModal-carousel-link">
                                  <a [routerLink]="['/dashboard']"
                                     [hidden]="index === slides.length-1">{{slide.link}}</a>
                                  <button [routerLink]="['/dashboard']" [hidden]="index < slides.length-1">{{slide.link}}</button>
                              </div>
                          </slide>
                      </carousel>
                  </div>
              </bs-modal-body>
          </bs-modal>
      </div>
  `
})
export class TutorialModal implements OnInit {
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;
  public constants = LoginConstants;
  public slides: any[] = [];
  @Input() public isSso: boolean = false;

  constructor(private router: Router) {
    this.slides = this.constants.tutorialSlides;
  }

  public ngOnInit() {
    if (this.isSso) {
      this.openModal();
    }
    this.modal.onDismiss.subscribe(() => {
      this.router.navigate(['/dashboard']);
    });
  }

  public openModal() {
    this.modal.open();
  }
}
