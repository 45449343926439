import * as _ from 'lodash';
export class ProductName {
  constructor(
    public value : string = '',
    public lang : string = ''
  ) {}

  public isEmpty() {
    return _.isEmpty(this.value) || _.isEmpty(this.lang);
  }
}
