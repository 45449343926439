import {Router} from '@angular/router';
import {DashboardConst, FormInputError, ProductFormModel} from './index';
import {ProductsSvc} from '../services/index';
import {CompanySvc, NotificationsSvc, UserSvc} from '../../shared/services/index';

import {FeatureToggleSvc} from '../../shared/services/feature-toggle.service';
import {BaseCreationUnityForm} from './baseCreationUnityForm.model';

import {FoodProductFormModel} from './foodProductCreationForm.model';
import {Gtin} from '../../shared/models';
import {DietTypes} from './dietTypes.model';
import {Nutriment} from './nutriment.model';
import {NutrimentItem} from './nutrimentItem.model';
import {nutrimentItemsConst} from './nutrimentItems.constants';
import {AllergensFoodConstants} from './allergenFood.constants';

import {AllergenFoodModel} from './allergenFood.model';
import {StructuredAddress} from './StructuredAddress.model';
import {NetContent} from './NetContent.model';
import {ImageUrl} from './ImageUrl.model';
import {finalize} from 'rxjs/operators';
import {ProductName} from './ProductName.model';

import * as _ from 'lodash';

export class FoodCreationUnityForm extends BaseCreationUnityForm {

  public productForm: FoodProductFormModel;

  public hasDrainedWeight: boolean = false;
  public hasAlcohol: boolean = false;

  public constants = DashboardConst;
  public submitting: boolean = false;
  public cipLoaded: boolean = false;
  public requiredNutrimentItems = nutrimentItemsConst;
  public requiredAllergenFoodItems = AllergensFoodConstants.requiredAllergen;
  public optionalAllergenFoodItems = AllergensFoodConstants.optionalAllergen;
  public mergedAllergenFoodItems = _.concat(this.requiredAllergenFoodItems, this.optionalAllergenFoodItems);

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notifsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc, public userSvc: UserSvc) {
    super(productsSvc, companySvc, _router, notifsSvc, featureToggleSvc, userSvc);
  }

  public onGpcChange() {
  }

  public resetFormProduct() {
    this.productForm = new FoodProductFormModel(new Gtin(this.companySvc.company.mainPrefix, '',
        '', false, false), new FormInputError('', ''), '', '', '', '',
      '', [new ProductName('', 'fr')], [], '', '', 0, this.companySvc.company.id,
      ['250'], this.companySvc.getSelectedCategory().code, false, new StructuredAddress('', '', '', ''), new DietTypes(),
      '', new Nutriment('100', 'GRM', null,
        this.resetNutrimentItems()), this.companySvc.getSelectedCategory().text, [new NetContent('', 'H87')], false, 0, [new ImageUrl('')], '',
      this.resetAllergenFoodItems(), '', null, '');
  }

  public resetFormProductWitBaseAttribute(form: ProductFormModel) {
    this.productForm = new FoodProductFormModel(form.gtin, form.cipError, form.mpn, form.sku, form.brand, form.subBrand,
      form.name, form.productNames, form.imagesToUpload, form.description, form.webLink, form.completionLevel, form.companyId,
      form.targetMarket, form.codeGpc, form.isPrivate, this.productForm.structuredAddress, this.productForm.dietTypes,
      this.productForm.contactName, this.productForm.nutriment, form.category, form.netContents, form.adhereToTheSectoralAgreement, form.vbGs1CompletionLevel,
      this.productForm.imagesAddedByUrl, this.productForm.regulatedProductName, this.productForm.allergensFood, this.productForm.alcoholDegree,
      this.productForm.preparationStateCode, this.productForm.consumerUsageInstructions, this.productForm.consumerStorageInstructions, this.productForm.ingredientStatement,
      this.productForm.preparationInstructions, this.productForm.packagingMarkedLabelAccreditationCode, this.productForm.compulsoryAdditiveLabelInformation, this.productForm.countryOfOrigin,
      this.productForm.drainedWeight, this.productForm.dailyValueIntakeReference, this.productForm.contentDescription,
      this.productForm.nutriScore, form.productResellerType);
    this.initNutriment();
    this.initAllergenFood();
    this.initingredientStatement();
  }

  public resetNutrimentItems(): NutrimentItem[] {
    const toReturn: NutrimentItem[] = [];
    this.requiredNutrimentItems.forEach(nutrimentItem => {
      if (nutrimentItem.isRequired) {
        const item = new NutrimentItem(nutrimentItem.code, 'APPROXIMATELY', null, null, nutrimentItem.measurementUnitCode, null);
        toReturn.push(item);
      }

    });

    return toReturn;
  }


  public resetAllergenFoodItems(): AllergenFoodModel[] {
    const toReturn: AllergenFoodModel[] = [];
    this.requiredAllergenFoodItems.forEach(allergen => {
      const item = new AllergenFoodModel(allergen.code, '');
      toReturn.push(item);
    });

    return toReturn;
  }


  public submitForm() {
    if (!this.submitting && this.productForm.gtin.available) {
      this.submitting = true;
      this.productsSvc.createFoodProduct(this.productForm)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(product => {
            if (!this.userSvc.user.haveSeenProductNames) {
              this.userSvc.updateUser({haveSeenProductNames: true});
            }
            return this._router.navigate(['/dashboard/product/view', product.id]);
          },
          err => {
            if (err.status === 409) {
              switch (err.error.type) {
                case 'IMPORT_IN_PROGRESS' :
                  this.notifsSvc.importInProgress();
                  break;
                case 'PRODUCT_VALIDATION_FAILED' :
                  this.notifsSvc.createProductValidation();
                  if (!this.productForm.gtin.isGtin8) {
                    this.getFirstGtinAvailable(this.productForm.gtin.prefix);
                  }
                  break;

                default:
                  console.error(err);
                  break;
              }
            } else {
              console.error('error to create form ', err);
            }
          }
        );
    }
  }

  public findAllergenFoodWording(code: string): string {
    const allergen: any = _.find(this.mergedAllergenFoodItems, c => c.code === code) || {};
    return allergen.wording;
  }

  public onQuantityContainedChange(value: number, index: number) {
    const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
    if (value && !decimalRegex.test(value.toString())) {
      this.productForm.nutriment.items[index].quantityContained = null;
    }
  }

  public onOptionalQuantityContainedChange(value: string, index: number) {
    const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
    if (value && !decimalRegex.test(value)) {
      this.productForm.nutriment.items[index].optionalQuantityContained = null;
    }
  }

  private initNutriment() {
    if (_.isEmpty(this.productForm.nutriment.items)) {
      this.productForm.nutriment = new Nutriment('100', 'GRM', '',
        this.resetNutrimentItems());
      return;
    }
    const {servingSize, measurementUnitCode, servingSizeDescription, items} = this.productForm.nutriment;

    const builtItems = _.map(items, (item: NutrimentItem) => {
      const requiredItem: any = _.filter(this.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === item.nutrientTypeCode)[0] || {};
      const isRequired = requiredItem.isRequired ? requiredItem.isRequired : false;
      return new NutrimentItem(item.nutrientTypeCode, item.measurementPrecisionCode,
        this.productsSvc.replaceDotByCommaInString(item.quantityContained),
        this.productsSvc.replaceDotByCommaInString(item.optionalQuantityContained), item.measurementUnitCode,
        this.productsSvc.replaceDotByCommaInString(item.dailyValueIntakeReference), isRequired);
    });

    this.productForm.nutriment = new Nutriment(servingSize, measurementUnitCode, servingSizeDescription, builtItems);
  }

  private initAllergenFood() {
    if (_.isEmpty(this.productForm.allergensFood)) {
      this.productForm.allergensFood = this.resetAllergenFoodItems();
      return;
    }
    this.productForm.allergensFood = _.map(this.productForm.allergensFood, ({
                                                                              allergenTypeCode,
                                                                              levelOfContainmentCode
                                                                            }) => {
      return new AllergenFoodModel(allergenTypeCode, levelOfContainmentCode);
    });
  }

  private initingredientStatement() {
    const firstPattern = 'Ingrédient :'.toUpperCase();
    const secondPattern = 'Ingrédients :'.toUpperCase();
    const firstIndex = this.productForm.ingredientStatement.toUpperCase().indexOf(firstPattern);

    if (firstIndex !== -1) {
      this.productForm.ingredientStatement = this.productForm.ingredientStatement.slice(firstPattern.length, this.productForm.ingredientStatement.length).trim();
    } else {
      this.productForm.ingredientStatement = this.productForm.ingredientStatement.slice(secondPattern.length, this.productForm.ingredientStatement.length).trim();
    }
  }
}
