import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DashboardConst} from '../../models';

@Component({
  selector: 'barcode-modal-form-img',
  styleUrls: ['./barcode-modal.less'],
  template: `<div class="BarcodModalFormImg ImageForm">
    <div class="ImageForm-description">{{constants.generateForm.wording.description}}</div>
    <div class="ImageForm-section row">
        <div class="ImageForm-section-category col-xs-2">Dimensions</div>
        <div class="ImageForm-section-content dimension-content col-xs-10">
            <div class="row">
                <!-- Barcode preview -->
                <div class="ImageForm-section-content-barcode col-md-6 col-xs-7">
                    <div class="row">
                        <!-- Barcode and width input -->
                        <div class="col-xs-9">
                            <div class="ImageForm-section-content-barcode-withInput">
                                <label for="width">Largeur</label>
                                <input id="width" type="number" min="30" max="77" [(ngModel)]="codeWidth" step="1" (keyup)="onWidthChange()" (change)="onWidthChange()">
                                mm
                            </div>
                            <img src="assets/img/widthInfo.png" class="ImageForm-section-content-barcode-widthInfo">
                        </div>
                    </div>
                    <div class="row">
                        <!-- Height info -->
                        <div class="col-xs-9">
                            <img src="{{imgPreviewUrl}}" class="ImageForm-section-content-barcode-img">
                        </div>
                        <div class="ImageForm-section-content-barcode-heightInfo col-xs-3">
                            <label>Hauteur</label>
                            <div>{{codeHeight}} mm</div>

                        </div>
                    </div>
                </div>

                <!-- Help bloc -->
                <div class="ImageForm-help col-md-6 col-xs-5">
                    <img src="assets/icons/warningBlack.svg" alt="">
                    <div class="ImageForm-help-heading">
                        {{constants.generateForm.wording.help.heading}}
                    </div>
                    <div class="ImageForm-help-body">
                        {{constants.generateForm.wording.help.body}}
                    </div>
                    <a class="ImageForm-help-link" href="{{constants.generateForm.wording.help.link}}"
                       target="_blank">
                        En savoir plus
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>`
})
export class BarcodeModalFormImg {
  @Input() public codeWidth: number;
  @Input() public imgPreviewUrl: string;
  @Output() public codeWidthChange = new EventEmitter<number>();
  public codeHeight: number;

  public constants = DashboardConst;

  constructor() {}

  public ngOnChanges(changes) {
    if (changes.codeWidth) {
      this.onWidthChange();
    }
  }

  public onWidthChange() {
    this.codeWidthChange.emit(this.codeWidth);
    const nbPointTarget = this.codeWidth / (1 / 600 * 25.4 * 117);
    const widthModule = Math.floor(nbPointTarget) * (1 / 600 * 25.4);
    this.codeHeight = Math.ceil(widthModule * 79.61167432);
  }
}
