import {Gtin} from '../../shared/models/gtin.model';
import {FormInputError} from './formInputError';
import {Countries} from './country.constants';
import {NetContent} from './NetContent.model';
import {ImageUrl} from './ImageUrl.model';
import {ProductName} from './ProductName.model';
import {LanguageConstants} from './language.constants';

import * as _ from 'lodash';

export class BaseProductEditionFormModel {
    constructor(
        public gtin: Gtin,
        public mpn: string,
        public sku: string,
        public brand: string,
        public subBrand: string,
        public name: string,
        public productNames: ProductName[],
        public imagesToUpload: Object[],
        public images: Object[],
        public description: string,
        public webLink: string,
        public completionLevel: number,
        public companyId?: number,
        public targetMarket: string[] = ['250'],
        public codeGpc: string = '',
        public isPrivate: boolean = false,
        public category: string = '',
        public netContents: NetContent[] = [],
        public adhereToTheSectoralAgreement?: boolean,
        public imagesAddedByUrl: ImageUrl[] = [],
        public productResellerType? : string,
        public productType? : string,
        public originGtin? : string
    ) {
    }

    public getProductName(lang: string = 'fr') {
        if (!_.isEmpty(this.productNames)) {
            const productNames = _.find(this.productNames, (element) => element.lang === lang);
            return _.get(productNames, 'value', '');
        }

        return this.name;
    }

    public getProductNamesLanguage() {
        if (!_.isEmpty(this.productNames)) {
            return _.map(this.productNames, (e) => e.lang);
        }

        return ['fr'];
    }

    public getLanguageFromCode(code: string) {
        const langObject = _.find(LanguageConstants, (e) => e.code === code);
        return _.get(langObject, 'frenchName', '');
    }
}
