import {finalize, filter} from 'rxjs/operators';
import {Component, NgModule, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, Params, CanDeactivate, NavigationEnd, NavigationStart} from '@angular/router';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';
import {DashboardConst} from '../../models/dashboard.constants';
import {ProductImportForm} from '../../models/index';
import {ProductsSvc} from '../../services/index';
import {NotificationsSvc} from '../../../shared/services/index';
import {ImportTypes} from '../../../shared/models';
import {
  ProductImportConfirmationModal
} from '../product-import-confirmation-modal/product-import-confirmation-modal.component';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import {ConfirmationModal, FileUpload, Loader} from '../../../shared/components';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {ProductResellerTypes} from '../../models/productResellerTypes.model';
import {environment} from '../../../../environments/environment';
import * as _ from 'lodash';
@Component({
  selector: `product-import-download-template`,
  styleUrls: [`./product-import-download-template.less`, '../product-import/product-import-upload.less'],
  templateUrl: './product-import-download-template.tpl.html'
})

export class ProductImportDownloadTemplate implements OnInit {

  public constants = DashboardConst;
  public productImportForm: ProductImportForm;
  public submitting: boolean;
  public submitted: boolean;
  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public importStep: ImportSteps = ImportSteps.DOWNLOAD_TEMPLATE;
  public importType: ImportTypes = ImportTypes.IMPORT_MY_GTINS;
  public showProductReseller: boolean;
  public productResellerType: string = null;
  public isBundle: boolean;
  public isNotNew: boolean;
  public isAnalyseStep = true;

  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;
  public brandTypes = ProductResellerTypes;


  private fillTemplateActiveTab: string = 'withoutGtin';
  private isMoreSectionOpened: boolean = false;
  private previousUrl: string;
  private forceNavigation: boolean;
  @ViewChild(ProductImportConfirmationModal, {static: true})
  private productImportConfirmationModal: ProductImportConfirmationModal;

  constructor(private notificationsSvc: NotificationsSvc,
              private productsSvc: ProductsSvc,
              private router: Router,
              private route: ActivatedRoute,
              private featureToggleSvc: FeatureToggleSvc) {
    this.initForm();
    this.uploader = new FileUploader({
      maxFileSize: 30 * 1000 * 1000,
      filters: [{
        name: 'badExtension',
        fn: (item?: FileLikeObject, options?: FileUploaderOptions): boolean => {
          const types = {
            xls: true,
            xlsx: true,
            csv: true
          };
          const chunks = item.name.split('.');

          const extension = chunks[chunks.length - 1].toLowerCase();
          return types[extension] === true;
        }
      }]
    });
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.isAnalyseStep = true;
      this.productImportForm.importFile = fileItem._file;
      this.productImportForm.filename = this.truncateFilename(fileItem._file.name, 40);

      this.analyseProducts(this.importType);
    };
    this.uploader.onWhenAddingFileFailed = (item, f) => {
      switch (f.name) {
        case 'badExtension':
          this.notificationsSvc.wrongImportFileType();
          break;
        case 'fileSize':
          this.notificationsSvc.importFileTooBig();
          break;
        default:
          break;
      }
    };
  }


  public canDeactivate() {
    if (this.submitted || this.forceNavigation) {
      return true;
    }
    if (!this.isShowingDownloadTemplate() && this.productImportForm.importFile.size > 0) {
      this.confirmationModal.openModal();
      history.pushState(null, null, location.href);
      return false;
    }
    if (!this.isShowingDownloadTemplate()) {
      this.onConfirmModal();
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }

  public ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      switch (params['type']) {
        case '0':
          this.importType = ImportTypes.UPDATE_DATA_BASE;
          break;
        case '2':
          this.importType = ImportTypes.CREATE_GTINS;
          this.showProductReseller = this.importType === ImportTypes.CREATE_GTINS;
          break;
        case '1':
          this.importType = ImportTypes.IMPORT_MY_GTINS;
          break;
        default:
          this.importType = ImportTypes.DEFAULT;
          break;
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe(e => {
        const event = e as NavigationStart;
        this.previousUrl = event.url;
      });
  }

  public changeTab(tab: string) {
    this.fillTemplateActiveTab = tab;
    this.isMoreSectionOpened = false;
  }

  public downloadGenericTemplate() {
    window.open(environment.IMPORT_TEMPLATE_GENERIC_URL);
  }

  public downloadWineTemplate() {
    window.open(environment.IMPORT_TEMPLATE_WINE_URL);
  }

  public downloadSpiritTemplate() {
    window.open(environment.IMPORT_TEMPLATE_SPIRIT_URL);
  }


  public submit() {
    if (!this.isAnalyseStep) {
      this.productImportConfirmationModal.show();
    }
    return false;
  }

  public analyseProducts(importType: ImportTypes) {

    this.initAnalyseProducts();

    if (this.isBundle){
      this.productResellerType = ProductResellerTypes.BUNDLE;
    }else if (this.isNotNew) {
      this.productResellerType = ProductResellerTypes.NOT_NEW;
    }else {
      this.productResellerType = null;
    }

    this.productsSvc.streamProductAnalysis(this.productImportForm, this.productResellerType, importType)
      .subscribe(state => {
        if (state.error) {
          this.showError(state.error);
          return;
        }

        this.productImportForm.failedProducts = state.failedProducts || 0;
        this.productImportForm.succeededProducts = state.succeededProducts || 0;
        this.productImportForm.totalProducts = state.totalProducts || 0;
        this.productImportForm.reportUrl = state.reportUrl;
        this.productImportForm.lastFilledRowIndex = state.lastFilledRowIndex || 0;

        if (this.isAnalysisFinished()) {
          this.isAnalyseStep = false;
          this.submitting = false;
        }
      });
  }

  public importProducts(isPublic: boolean, importType: ImportTypes) {
    this.submitting = true;
    this.productsSvc.importProducts(this.productImportForm, isPublic, this.productResellerType, this.importType).pipe(
      finalize(() => {
        this.submitting = false;
        this.submitted = true;
      }))
      .subscribe(
        response => {
          this.router.navigate(['../../status', response.importId], {
            queryParams: {
              fileName: this.productImportForm.importFile['name'],
              lineCount: response.lineCount,
              isBundle: this.isBundle,
              importType
            }, relativeTo: this.route
          });
        },
        e => {
          const type = e?.error?.type;
          this.showError(type);
        }
      );
    return false;
  }

  public isShowingDownloadTemplate() {
    return this.importStep === ImportSteps.DOWNLOAD_TEMPLATE;
  }

  public goToDownloadTemplate() {
    this.importStep = ImportSteps.DOWNLOAD_TEMPLATE;
    this.isMoreSectionOpened = false;
  }

  public goToUploadFile() {
    this.importStep = ImportSteps.UPLOAD_FILE;
    this.isMoreSectionOpened = false;
  }

  public isShowingUploadCsv() {
    return this.importStep === ImportSteps.UPLOAD_FILE;
  }

  public goToUploadCsv() {
    this.importStep = ImportSteps.UPLOAD_FILE;
    this.isMoreSectionOpened = false;
  }

  public isLastStep() {
    return this.isShowingUploadCsv();
  }

  public goToNextStep() {
    if (!this.isLastStep()) {
      this.importStep++;
    }
  }

  public goToMassCreation(event) {
    this.productResellerType = event.productResellerType;
    this.isBundle = this.productResellerType === 'BUNDLE';
    this.isNotNew = this.productResellerType === 'NOT_NEW';
    if (this.productResellerType === this.brandTypes.THIRD_PARTY_BRAND || this.productResellerType === this.brandTypes.WITHOUT_BRAND_AND_ISNOT_MANUFACTURER) {
      this.router.navigate(['../../../product/create', this.productResellerType], {relativeTo: this.route});
      return;
    }
    this.showProductReseller = false;


  }

  public goToPreviousStep() {
    if (!this.isShowingDownloadTemplate() && this.productImportForm.importFile.size > 0) {
      this.confirmationModal.openModal();
    } else {
      this.onConfirmModal();
    }
  }

  public onConfirmModal() {
    this.initForm();
    if (this.previousUrl && this.previousUrl.indexOf('template') === -1) {
      this.router.navigateByUrl(this.previousUrl);
      this.forceNavigation = true;
      return;
    }
    if (!this.isShowingDownloadTemplate()) {
      this.importStep--;
    }

  }

  public getTemplateFileBackgroundImage(img) {
    return `url(${img})`;
  }

  public downloadReport() {
    if (this.productImportForm.reportUrl) {
      window.open(`${environment.TEMPORARY_URL_PREFIX}/${this.productImportForm.reportUrl}`);
    }
    return false;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;

  }

  public showCreateGtinsSection(): boolean {
    return this.importType === ImportTypes.CREATE_GTINS;
  }

  public showUpdateProductSection(): boolean {
    return this.importType === ImportTypes.UPDATE_DATA_BASE;
  }

  public showDefaultSection(): boolean {
    return this.importType === ImportTypes.DEFAULT;
  }

  public isShowingUploadFile() {
    return this.importStep === ImportSteps.UPLOAD_FILE;
  }

  public showImportMyGtinsSection(): boolean {
    return this.importType === ImportTypes.IMPORT_MY_GTINS;
  }

  public onImportConfirmClick(isPublic: boolean) {
    this.importProducts(isPublic, this.importType);
  }

  public isVcs(): boolean {
    return this.featureToggleSvc.canSeeVcs();
  }

  public updateAttributes(event) {
    this.isBundle = event.isBundle;
    this.isNotNew = event.isNotNew;
  }

  public productText(header: boolean = false): string {
    let assortment = 'assortiments';
    let product = 'produits';
    if (header) {
      assortment = assortment.charAt(0).toUpperCase() + assortment.slice(1);
      product = product.charAt(0).toUpperCase() + product.slice(1);
    }
    return this.isBundle ? assortment : product;
  }

  private initForm() {
    this.submitting = false;
    this.productImportForm = new ProductImportForm(new Blob(), 0, 0, 0);
  }

  private isAnalysisFinished() {
    return this.productImportForm.reportUrl != null;
  }

  private showError(type) {
    switch (type) {
      case 'IMPORT_IN_PROGRESS' :
        this.notificationsSvc.importInProgress();
        break;

      case 'UNSUPPORTED_ENCODING':
        this.notificationsSvc.importFileUnsupportedEncodingError();
        break;

      case 'EMPTY_FILE':
        this.notificationsSvc.importFileEmptyError();
        break;
      default:
        this.notificationsSvc.importFileValidationError(this.isBundle);
        break;
    }
  }

  private truncateFilename(n, len) {
    const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
    let filename = n.replace('.' + ext, '');
    if (filename.length <= len) {
      return n;
    }
    filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
    return filename + '.' + ext;
  }

  private initAnalyseProducts(): any {
    this.submitting = true;
    this.productImportForm.failedProducts = 0;
    this.productImportForm.succeededProducts = 0;
    this.productImportForm.totalProducts = 0;
    this.productImportForm.reportUrl = null;
  }
}

export enum ImportSteps {
  DOWNLOAD_TEMPLATE,
  UPLOAD_FILE
}
