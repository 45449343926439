import {
    Component, Input,
    OnInit
} from '@angular/core';


@Component({
    selector: 'codification-rule',
    styleUrls: ['./codification-rule.component.less'],
    templateUrl: './codification-rule.component.html'
})
export class CodificationRuleComponent implements OnInit {
    @Input() public title: any;

    constructor() {
    }

    public ngOnInit() {
    }

}
