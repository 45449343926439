import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';

@Component({
  selector: 'app-validation-creation-product',
  templateUrl: './validation-creation-product.component.html',
  styleUrls: ['./validation-creation-product.component.less']
})
export class ValidationCreationProductComponent implements OnInit {

  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;

  @Output() public confirmEvent = new EventEmitter<boolean>();
  constructor() { }

  public ngOnInit(): void {
  }

  public show() {
    this.modal.open();
  }

  public close(sendEvent = true) {
    if (sendEvent) {
      this.confirmEvent.emit(false);
    }
    this.modal.close();
  }

  public confirm() {
    this.confirmEvent.emit(true);
  }

}
