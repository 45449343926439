import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';

@Directive({
    selector: '[cleanInput]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputClean),
        multi: true
    }]
})
export class InputClean extends DefaultValueAccessor {

    private inputElement: HTMLInputElement;

    constructor(renderer: Renderer2, elementRef: ElementRef) {
        super(renderer, elementRef, true);
        this.inputElement = elementRef.nativeElement;
    }

    @HostListener('blur')
    public onBlur(): void {
        this.inputElement.value = this.inputElement.value.replace(/\t/g, ' ').trim();
        this.onChange(this.inputElement.value);
    }

    @HostListener('input')
    public onInput(): void {
        this.onChange(this.inputElement.value);
    }

}
