import { Output, EventEmitter, Input, Directive } from '@angular/core';
import {FoodProductFormModel} from '../../../../../../models/foodProductCreationForm.model';

@Directive()
export class StepComponent {

    @Input() public productForm: FoodProductFormModel;
    @Input() public hasDrainedWeight: boolean;
    @Input() public hasAlcohol: boolean;
    @Input() public submitting: boolean;
    @Output() public onHasDrainedWeightChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() public onHasAlcoholChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() public onNext = new EventEmitter<any>();
    @Output() public onPrevious = new EventEmitter<any>();


    public submitForm(submitting: boolean = true, form: HTMLFormElement) {
        if (form && form.checkValidity() === false) {
            return;
        }
        this.onNext.emit({submitting});
        return false;
    }

    public returnBack(submitting: boolean = true) {
        this.onPrevious.emit({submitting});
        return false;
    }
}
