import {Component} from '@angular/core';
import {HelpCenterConstants} from '../../models';

@Component({
  selector: 'help-center-menu',
  styleUrls: ['./helpCenter-menu.less'],
  template: `
    <div class="HelpCenterMenu">
      <ul class="HelpCenterMenu-listTopics">
        <button class="HelpCenterMenu-backButton HelpCenterMenu-listTopics-item" type="button" routerLink="..">
          <img src="/assets/icons/arrowLeft.svg" alt="">
          Retour
        </button>
        <li class="HelpCenterMenu-listTopics-item" *ngFor="let topic of constants.topics">
          <a [routerLink]="['topic', topic.id]"
             routerLinkActive="router-link-active"
          >{{ topic.title }}</a>
        </li>
      </ul>
    </div>`
})
export class HelpCenterMenu {

  public constants = HelpCenterConstants;

  constructor() {
  }

}
