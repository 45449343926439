import {Component, OnInit, Input, ElementRef, Output, EventEmitter} from '@angular/core';
import {DashboardConst} from '../../../models/dashboard.constants';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'create-gtin',
  styleUrls: ['./create-gtin.less'],
  templateUrl: './create-gtin.tpl.html'
})
export class CreateGtinComponent implements OnInit {
  public constants = DashboardConst;
  public isMoreSectionOpened: boolean = false;
  public isGeneric: boolean = false;
  public isFood: boolean = false;
  public isSpi: boolean = false;
  public isWine: boolean = false;
  @Input() public isBundle: boolean;
  @Input() public isNotNew: boolean;
  @Input() public productResellerType: string;
  @Input() public forImport: boolean;

  @Output() public onRedirectToMassCreation = new EventEmitter<any>();

  constructor(private headerSvc: DashboardHeaderSvc, private featureToggleSvc: FeatureToggleSvc,
              private router: Router) {
  }

  public ngOnInit() {
    if (!this.forImport) {
      this.headerSvc.setHeaderData('Créer mes GTIN en nombre', 'assets/icons/ico_import.svg');
    } else {
      this.headerSvc.setHeaderData('Importer mes GTIN', 'assets/icons/ico_import.svg');
    }
  }

  public toggleMoreSection() {
    $('#importPanel').animate({
      scrollTop: document.getElementById('moreSectionLink').offsetTop + 450
    }, 800);
    this.isMoreSectionOpened = !this.isMoreSectionOpened;
  }


  public downloadFileTemplate() {
    window.open(environment.IMPORT_TEMPLATE_GENERIC_URL);
  }

  public isVcs(): boolean {
    return this.featureToggleSvc.canSeeVcs();
  }

  public downloadTemplate() {

    if (this.isBundle) {
      window.open(environment.IMPORT_TEMPLATE_BUNDLE_URL);
      return;
    }
    if (this.isNotNew) {
      window.open(environment.IMPORT_TEMPLATE_NOTNEW_URL);
      return;
    }
    window.open(environment.IMPORT_TEMPLATE_GENERIC_URL);
  }

  public downloadWineTemplate() {
    window.open(environment.IMPORT_TEMPLATE_WINE_URL);
  }

  public downloadVcsTemplate() {
    if (this.isSpi) {
      this.downloadSpiritTemplate();
      return;
    }

    this.downloadWineTemplate();
  }

  public downloadSpiritTemplate() {
    window.open(environment.IMPORT_TEMPLATE_SPIRIT_URL);
  }

  public displayGenericTemplate() {
    this.isSpi = false;
    this.isWine = false;
    this.isFood = false;
    this.isGeneric = true;
    this.isBundle = false;
    this.isNotNew = false;
    this.onRedirectToMassCreation.emit({
      isBundle: this.isBundle,
      isNotNew: this.isNotNew
    });

    setTimeout(() => {
      this.scrollToAlert();
    }, 0);
  }

  public displayWineTemplate() {
    this.isSpi = false;
    this.isFood = false;
    this.isGeneric = false;
    this.isWine = true;
    this.isBundle = false;
    this.isNotNew = false;
    this.onRedirectToMassCreation.emit({
      isBundle: this.isBundle,
      isNotNew: this.isNotNew
    });

    setTimeout(() => {
      this.scrollToAlert();
    }, 0);
  }

  public displaySpiTemplate() {
    this.isFood = false;
    this.isGeneric = false;
    this.isWine = false;
    this.isSpi = true;
    this.isBundle = false;
    this.isNotNew = false;
    this.onRedirectToMassCreation.emit({
      isBundle: this.isBundle,
      isNotNew: this.isNotNew
    });

    setTimeout(() => {
      this.scrollToAlert();
    }, 0);
  }

  public displayNotNewTemplate() {
    this.isNotNew = true;
    this.isSpi = false;
    this.isWine = false;
    this.isFood = false;
    this.isGeneric = false;
    this.isBundle = false;
    this.onRedirectToMassCreation.emit({
      isBundle: this.isBundle,
      isNotNew: this.isNotNew
    });

    setTimeout(() => {
      this.scrollToAlert();
    }, 0);
  }

  public displayBundleTemplate() {
    this.isSpi = false;
    this.isWine = false;
    this.isFood = false;
    this.isGeneric = false;
    this.isBundle = true;
    this.isNotNew = false;
    this.onRedirectToMassCreation.emit({
      isBundle: this.isBundle,
      isNotNew: this.isNotNew
    });

    setTimeout(() => {
      this.scrollToAlert();
    }, 0);
  }

  public isDirtyForm() {
    return this.isSpi || this.isFood || this.isWine || this.isGeneric;
  }

  public scrollToAlert() {
    const container = document.getElementById('importPanel');
    if (container) {
      container.scrollTop = 150;
    }
  }

  public searchCategory() {
    if (this.isBundle) {
      window.open('/dashboard/product/categories/bundle', 'blank');
    } else if (this.isNotNew) {
      window.open('/dashboard/product/categories/non-new', 'blank');
    } else {
      window.open('/dashboard/product/categories', 'blank');
    }
  }

  public createGtin() {
    this.router.navigate(['/dashboard/product/create']);
  }
}
