export const onBoardingConstants = {
  onBoardingSlides: [
    {
      img: '',
      text: `Désormais, CodeOnline vous propose de renseigner les informations spécifiques pour vos produits vins & spiritueux.`,
      subtext: `                     `,
      detail: `Bon à savoir: Si vous avez déjà des produits enregistrés, il vous suffira de mettre à jour les informations de vos produits en modifiant la classification.`
    },
    {
      img: 'identifiez-produits.png',
      text: `Identifiez vos produits`,
      subtext: `Vos produits sont uniques`,
      detail: `Générez en un clic vos codes produits GS1 et vos codes-barres pour faciliter la vente de vos produits dans le monde  entier`
    },
    {
      img: 'digitalisez-produits.png',
      text: `Digitalisez votre étiquette`,
      subtext: `Gagnez du temps !`,
      detail: `Renseignez et maitrisez les informations clès de vos produits dans un langage commun pour valoriser leurs spécificités`
    },
    {
      img: 'partagez-informations.png',
      text: `Partagez vos informations produits`,
      subtext: `Soyez visible !`,
      detail: `Mettez à la disposition de vos partenaires commerciaux vos informations produits sans ressaisie`
    }
  ]
};
