import {Component, Input, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {Router} from '@angular/router';

@Component({
  selector: 'classification-categories-modal',
  styleUrls: ['./classification-categories-modal.less'],
  templateUrl: './classification-categories-modal.html'
})

export class ClassificationCategoriesModal implements OnInit {
  @Input() public categoryDetails: any;
  @Input() public showSelectCategory: any;
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;

  @Output() public onSelect = new EventEmitter<any>();
  @Output() public onCloseModal = new EventEmitter<any>();

  constructor(private router: Router) {
  }

  public ngOnInit(): void {
  }

  public openModal() {
    this.modal.open();
  }

  public saveSelectedCategory() {
    if (this.categoryDetails) {
      this.modal.close();
      this.onSelect.emit({categoryDetails: this.categoryDetails});
    }
  }

  public onClose() {
    this.onCloseModal.emit();
  }
}
