import {Component, Input, OnInit, ViewChild, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';

@Component({
  selector: 'classification-second-onboarding-modal',
  styleUrls: ['classification-second-onboarding-modal.less'],
  template: `
    <div class="ClassificationSecondOnBoardingModalContainer">
      <bs-modal #modal id="second-modal" class="ClassificationSecondOnBoardingModal" (onClose)="onClose()"
                (onDismiss)="onDismiss()" [animation]="false" [keyboard]="true" [backdrop]="'static'" [size]="'md'">
        <bs-modal-header class="ClassificationSecondOnBoardingModalHeader" [showDismiss]="false">Visite Guidée 2/2
        </bs-modal-header>
        <bs-modal-body>
          <div class="ClassificationSecondOnBoardingModalBody">
            <h3>Consultez l’arbre des catégories</h3>
            <div class="ClassificationSecondOnBoardingModalText">
              Si vous ne trouvez pas de résultat pertinent lors de
              votre recherche, alors vous pouvez consulter
              l’ensemble des catégories ici.
            </div>
          </div>
        </bs-modal-body>
        <bs-modal-footer>
          <div class="ClassificationSecondOnBoardingModalFooter">
            <div class="ClassificationSecondOnBoardingModalCheckbox col-md-6">
              <input type="checkbox" [ngModel]="noShow" (click)="toggleNoShow()"/>
              <span class="checkmark"></span>
              <label class="toggleNutriScore">
                Ne plus afficher
              </label>
            </div>
            <div class="col-md-6">
              <button class="ClassificationSecondOnBoardingModal" (click)="understood()">J'ai compris
              </button>
            </div>
          </div>
        </bs-modal-footer>
      </bs-modal>
    </div>`
})

export class ClassificationSecondOnBoardingModal implements OnInit, AfterViewInit {
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;
  public noShow: boolean = false;
  @Output()
  public onCloseSecondModal = new EventEmitter<any>();

  @Input() public isBundle: boolean = false;

  constructor() {
  }

  public ngOnInit() {
    this.modal.open();
  }

  public ngAfterViewInit() {
    const modal = document.getElementById('second-modal');
    const link = document.getElementById('classificationTree') || document.getElementById('classificationTree2');

    if (link) {
      const linkCoordinates = link.getBoundingClientRect();
      const linkTopCoordinates = Math.round(linkCoordinates.top);
      const modalTopInitial = 198;
      modal.style.top = (linkTopCoordinates - modalTopInitial) + 'px';
    }

  }

  public openModal() {
    this.modal.open();
  }

  public understood() {
    this.modal.close();
  }

  public toggleNoShow() {
    this.noShow = !this.noShow;
  }

  public onClose() {
    this.removeClass();
    this.onCloseSecondModal.emit({noShow: this.noShow});
  }

  public onDismiss() {
    this.removeClass();
    this.onCloseSecondModal.emit({noShow: this.noShow});
  }

  public removeClass() {
    if (document.getElementById('classificationTree')) {
      const classificationTree: HTMLElement = document.getElementById('classificationTree');
      classificationTree.className = 'findCategoryLink';
    }
    if (document.getElementById('classificationTree2')) {
      const classificationTree2: HTMLElement = document.getElementById('classificationTree2');
      classificationTree2.className = '';
    }
  }
}
