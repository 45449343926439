import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ProductTypes} from '../../../../models/index';
import {CompanySvc, NotificationsSvc, UserSvc} from '../../../../../shared/services/index';
import {ProductsSvc} from '../../../../services/index';
import {FormInputError} from '../../../../models/formInputError';
import {DashboardConst} from '../../../../models/dashboard.constants';
import URL from '../../../../../shared/utils/url';
import {FeatureToggleSvc} from '../../../../../shared/services/feature-toggle.service';
import {DashboardHeaderSvc} from '../../../../services/dashboard-header.service';
import {NetContent} from '../../../../../../app/dashboard/models/NetContent.model';
import {NetContentConst} from '../../../../../../app/dashboard/models/netContent.constants';
import {LanguageConstants} from '../../../../../../app/dashboard/models/language.constants';
import {InitiatorStepComponent} from '../InitiatorStepComponent';
import {ImageUrl} from '../../../../../../app/dashboard/models/ImageUrl.model';
import {TargetMarketCountries} from '../../../../../../app/dashboard/models/targetMarket-country.constants';
import {ProductName} from '../../../../models/ProductName.model';

import * as _ from 'lodash';
import {NetContentGenericProduct} from '../../../../models/NetContentGenericProduct.model';

@Component({
    selector: 'product-creation-generic-first-step',
    styleUrls: ['./product-creation-generic-first-step.less'],
    templateUrl: './product-creation-generic-first-step.tpl.html'
})
export class ProductCreationGenericFirstStep extends InitiatorStepComponent implements OnInit, AfterViewInit {
    @Input() public selectedCategory: {
        breadcrumbs: string,
        code: string,
        definition: string,
        parentIds: string[],
        text: string,
        type: string
    } = {
        breadcrumbs: '',
        code: '',
        definition: '',
        parentIds: [''],
        text: '',
        type: ''
    };
    @Input() public isNotNew: boolean;
    @ViewChild('formProduct', {static: true}) public form : any;
    @Output() public onModifyCategory = new EventEmitter<any>();
    public webLinkError: FormInputError;
    public showCategory: boolean;
    public nbrFiles: number;
    public nbrProductImages: number;
    public measurementUnitsList: any[][] = new Array();
    public languageList: any[][] = new Array();
    public canAddNetContent: boolean = true;
    public canAddProductName: boolean = true;
    public uploadLimit: number = 3;
    public isVcs: boolean = false;
    public VOLUME_PATTERN = '^([0-9]{1,}(,\\d{1})?)$';
    public netContentPattern: string[];
    public multiSelectConfig: any = Object.assign({}, {
        highlight: false,
        create: false,
        persist: true,
        plugins: ['remove_button'],
        dropdownDirection: 'down',
        wrapperClass: 'gs1selectize-control',
        inputClass: 'gs1selectize-input targetMarket-input',
        dropdownClass: 'gs1selectize-dropdown',
        dropdownContentClass: 'gs1selectize-dropdown-content'
    }, {
        labelField: 'wording-fr',
        valueField: 'code',
        searchField: ['wording-fr'],
        maxItems: 10
    });
    public multiSelectOptions = TargetMarketCountries;
    public firstTimeTargetMarketCSS = false;
    public  NetContentConstants = NetContentConst.all;
    constructor(public productsSvc: ProductsSvc,
                public companySvc: CompanySvc,
                public _router: Router,
                public notificationsSvc: NotificationsSvc,
                public featureToggleSvc: FeatureToggleSvc,
                public userSvc: UserSvc,
                private headerSvc: DashboardHeaderSvc,
                private elRef: ElementRef) {
        super(productsSvc, companySvc, _router, notificationsSvc, featureToggleSvc, userSvc);
        this.resetWebLinkError();
        this.isVcs = this.productType === ProductTypes.VCS;
        this.netContentPattern = [];
    }

    public ngOnInit() {
        this.resetWebLinkError();
        this.isVcs = this.isBundle ? false : this.productType === ProductTypes.VCS;
        if (this.unityForm.productForm.netContents.length === 0) {
            this.addNetContent();
        }
        if (this.isVcs){
          this.NetContentConstants = NetContentConst.vcs;
          this.netContentPattern[0] = '^([0-9]{1,}(,\\d{1,2})?)$';
        }else{
          this.changeBehaviorNetContent();
          const netContent = this.unityForm.productForm.netContents[0];
          if (netContent.isItemOrArticleUnits()){
            this.netContentPattern[0] = '^[0-9]*$';
          }else{
            this.netContentPattern[0] = '^([0-9]{1,}(,\\d{1,5})?)$';
          }
        }
        this.measurementUnitsList.push(this.NetContentConstants);
        this.updateMeasurementUnitList();

        if (this.unityForm.productForm.productNames && this.unityForm.productForm.productNames.length > 0) {
            this.updateListLang();
        }

        if (this.isNotNew && !this.unityForm.productForm.originGtin) {
          this.unityForm.productForm.originGtin = '';
        }

        this.initImagesAddedByUrl();
        this.nbrFiles = this.uploadLimit;
        if (!this.userSvc?.user.arrayTargetMarketSeen) {
            this.firstTimeTargetMarketCSS = true;
        }
    }

    public ngAfterViewInit() {
        this.form.control.valueChanges
            .subscribe(() => {
                this.unityForm.productForm.completionLevel = this.productsSvc.getLevelCompletion(this.unityForm.productForm);
                this.unityForm.productForm.vbGs1CompletionLevel = this.productsSvc.getVbgLevelCompletion(this.unityForm.productForm);
            });
    }

    public ngAfterViewChecked() {
        this.makeTargetMarketElementRequired();
    }

    public modifyCategory() {
        this.onModifyCategory.emit(true);
    }

    public validateURL() {
        this.resetWebLinkError();
        this.unityForm.productForm.webLink = this.unityForm.productForm.webLink.toLowerCase();
        if (this.unityForm.productForm.webLink) {
            if (URL.isNotPrefixedWithProtocol(this.unityForm.productForm.webLink)) {
                this.unityForm.productForm.webLink = 'http://' + this.unityForm.productForm.webLink;
            }

            if (URL.isValidURL(this.unityForm.productForm.webLink)) {
                this.webLinkError = new FormInputError('valid', '');
            } else {
                this.webLinkError = new FormInputError('warning', DashboardConst.createForm.errors.webLinkInvalid);
            }
        }
    }

    public removeNetContent(netContent: any, index: number) {
        this.unityForm.productForm.netContents = this.unityForm.productForm.netContents.filter((item: any) => {
            return netContent !== item;
        });

        this.measurementUnitsList = this.measurementUnitsList.filter((item: any, i) => {
            return index !== i;
        });

        this.updateMeasurementUnitList();
    }

    public addNetContent() {
      if (this.isVcs){
        this.unityForm.productForm.netContents.push(new NetContent());
        this.netContentPattern.push('^([0-9]{1,}(,\\d{1,2})?)$');
      }else{
        const netContent = new NetContentGenericProduct();
        this.unityForm.productForm.netContents.push(netContent);
        if (netContent.isItemOrArticleUnits()){
          this.netContentPattern.push('^[0-9]*$');
        }else{
          this.netContentPattern.push('^([0-9]{1,}(,\\d{1,5})?)$');
        }
      }
      this.measurementUnitsList.push(this.NetContentConstants);
      this.updateMeasurementUnitList();
    }

    public removeProductName(productName: any, index: number) {
        this.unityForm.productForm.productNames = this.unityForm.productForm.productNames.filter((item: any) => {
            return productName !== item;
        });

        this.languageList = this.languageList.filter((item: any, i) => {
            return index !== i;
        });
        this.netContentPattern = this.netContentPattern.filter((pattern: string, i) => {
          return index !== i;
        });

        this.checkError();
    }

    public addProductName() {
        const lastProductName: any = _.last(this.unityForm.productForm.productNames);
        if (!_.isEmpty(lastProductName.value) && !_.isEmpty(lastProductName.lang)) {
            this.unityForm.productForm.productNames.push(new ProductName());
            this.checkError();
            return;
        }

        this.showErrorNotificationAndFocusElement(lastProductName);
    }

    public changeStateLastProductName(state: boolean) {
        const index = _.size(this.unityForm.productForm.productNames) - 1;
        if (index !== 0) {
            const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-' + index);
            if (divElement) {
                const inputElement: HTMLInputElement = divElement.querySelector('input');
                const selectElement: HTMLSelectElement = divElement.querySelector('select');
                inputElement.required = state;
                selectElement.required = state;
            }
        }

    }

    public makeElementAsRequired(index: string) {
        const productName = this.unityForm.productForm.productNames[index];
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-' + index);
        if (divElement) {
            const inputElement: HTMLInputElement = divElement.querySelector('input');
            const selectElement: HTMLSelectElement = divElement.querySelector('select');
            if (!_.isEmpty(productName.value)) {
                selectElement.required = true;
                return;
            }

            if (!_.isEmpty(productName.lang)) {
                inputElement.required = true;
                return;
            }


            inputElement.required = false;
            selectElement.required = false;
        }

    }

    public makeNetContentElementAsRequired(index: number) {
      const obj = this.unityForm.productForm.netContents[index];
      const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#net-content-item-' + index);
      if (divElement) {
        const inputElement: HTMLInputElement = divElement.querySelector('input');
        const selectElement: HTMLSelectElement = divElement.querySelector('select');
        if (!_.isEmpty(obj.measurementUnitCode)) {
          inputElement.required = true;
          return;
        }

        if (!_.isEmpty(obj.netContent)) {
          selectElement.required = true;
          return;
        }

        inputElement.required = false;
        selectElement.required = false;
      }

    }


    public onChangeMeasurementUnit() {
      if (!this.isVcs){
        this.netContentPattern = [];
        this.unityForm.productForm.netContents.forEach((netContent) => {
          if (netContent.isItemOrArticleUnits()){
            this.netContentPattern.push('^[0-9]*$');
          }else{
            this.netContentPattern.push('^([0-9]{1,}(,\\d{1,5})?)$');
          }
        });
      }
      this.updateMeasurementUnitList();
    }

    public onChangeLanguageList() {
        this.checkError();
    }

    public updateMeasurementUnitList() {
        let hasAtLeastOneValidItem = false;
        const usedMeasurementUnitList = _.map(this.unityForm.productForm.netContents, 'measurementUnitCode');
        const usedMeasurementCategoriesList = _.reduce(this.NetContentConstants, (categories: any, item) => {
            if (usedMeasurementUnitList.indexOf(item.code) >= 0) {
                categories[item.category] = true;
            }
            return categories;
        }, {});
        for (let i = 0; i < this.unityForm.productForm.netContents.length; i++) {
            const selectedNetContent = this.unityForm.productForm.netContents[i];
            const selectedCategory = _.find(this.NetContentConstants, {code: selectedNetContent.measurementUnitCode}) || {
              category: ''
            };
            this.measurementUnitsList[i] = this.NetContentConstants.filter((item: any) => {
                return !usedMeasurementCategoriesList[item.category] || item.category === selectedCategory.category;
            });

            if (!selectedNetContent.isEmpty()) {
                hasAtLeastOneValidItem = true;
            }
        }
        this.makeNetContentRequired(!hasAtLeastOneValidItem);
        this.canAddNetContent = this.unityForm.productForm.netContents.length < 5;
    }

    public updateListLang() {
        const hasAtLeastOneValidItem = false;
        const usedLanguageList = _.map(this.unityForm.productForm.productNames, 'lang');
        for (let i = 0; i < this.unityForm.productForm.productNames.length; i++) {
            const selectedProductName = this.unityForm.productForm.productNames[i];
            this.languageList[i] = LanguageConstants.filter((item: any) => {
                return usedLanguageList.indexOf(item.code) === -1 || selectedProductName.lang === item.code;
            });
        }
    }

    public checkError() {
        let hasAtLeastOneValidItem = false;
        this.updateListLang();
        for (const item of this.unityForm.productForm.productNames) {
            if (!_.isEmpty(item)) {
                hasAtLeastOneValidItem = true;
            }
        }
        this.makeProductNameRequired(!hasAtLeastOneValidItem);
        return false;
    }

    public makeNetContentRequired(state: boolean) {
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#net-content-item-0');
        if (divElement) {
            const inputElement: HTMLInputElement = divElement.querySelector('input');
            const selectElement: HTMLSelectElement = divElement.querySelector('select');
            inputElement.required = state;
            selectElement.required = state;
        }
    }

    public makeProductNameRequired(state: boolean) {
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-0');
        if (divElement) {
            const inputElement: HTMLInputElement = divElement.querySelector('input');
            const selectElement: HTMLSelectElement = divElement.querySelector('select');
            inputElement.required = state;
            selectElement.required = state;
        }
    }

    public addImageUrl() {
        let firstIndexToFill = this.unityForm.productForm.imagesAddedByUrl.length;

        for (let i = 0; i < this.unityForm.productForm.imagesAddedByUrl.length; i++) {
            const image = this.unityForm.productForm.imagesAddedByUrl[i];
            if (image.isEmpty() || image.hasError()) {
                firstIndexToFill = i;
                break;
            }
        }

        if (firstIndexToFill === this.unityForm.productForm.imagesAddedByUrl.length) {
            this.unityForm.productForm.imagesAddedByUrl.push(new ImageUrl(''));
        } else {
            const lastUrlElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#image-url-item-input-${firstIndexToFill}`);
            lastUrlElement.focus();
        }
    }

    public getImageUrlInputStatus(index: number) {
        return index >= this.nbrFiles;
    }

    public nbrFilesChanged(nbr: number) {
        this.nbrFiles = this.uploadLimit - nbr;
    }

    public updateNbrProductImages() {
        this.nbrProductImages = this.unityForm.productForm.imagesAddedByUrl.filter((i: any) => i.url.length).length;
    }

    public canAddImageUrl() {
        return this.unityForm.productForm.imagesToUpload.length + this.unityForm.productForm.imagesAddedByUrl.length < 3;
    }

    public removeImageUrl(index: number) {
        this.unityForm.productForm.imagesAddedByUrl.splice(index, 1);
        if (this.unityForm.productForm.imagesAddedByUrl.length === 0) {
            this.unityForm.productForm.imagesAddedByUrl.push(new ImageUrl(''));
        }
    }

    public trackByIndex(index: number, obj: any): any {
        return index;
    }

    public cannotSeeBrand(productForm: any): boolean{
        return  this.productsSvc.isProductResellerWithoutBrand(productForm.productResellerType);
    }

    public isRiskyProduct(productForm: any): boolean {
        return  this.productsSvc.isRiskyProduct(productForm.productResellerType);
    }

    private makeTargetMarketElementRequired() {
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.targetMarket-input');
        if (divElement) {
            const inputElement: HTMLInputElement = divElement.querySelector('input');
            if (!this.unityForm.productForm.targetMarket || this.unityForm.productForm.targetMarket.length === 0) {
                inputElement.required = true;
                return;
            } else {
                inputElement.required = false;
                return;
            }
        }
    }

    private checkNetContents() {
        for (const currentNetContent of this.unityForm.productForm.netContents) {
            if (currentNetContent.hasError()) {
                return false;
            }
        }
        return true;
    }

    private resetWebLinkError() {
        this.webLinkError = new FormInputError('', '');
    }

    private initImagesAddedByUrl() {
        if (this.unityForm.productForm.imagesAddedByUrl.length === 0) {
            this.unityForm.productForm.imagesAddedByUrl.push(new ImageUrl(''));
        }
    }

    private showErrorNotificationAndFocusElement(lastProductName: { value: any; lang: any; }) {
        this.notificationsSvc.mandatoryProductName(this.isBundle);
        const index = _.size(this.unityForm.productForm.productNames) - 1;
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-' + index);
        if (divElement) {
            if (_.isEmpty(lastProductName.value)) {
                const inputElement: HTMLInputElement = divElement.querySelector('input');
                inputElement.focus({
                    preventScroll: true
                });
                return;
            }
            if (_.isEmpty(lastProductName.lang)) {
                const selectElement: HTMLSelectElement = divElement.querySelector('select');
                selectElement.focus({
                    preventScroll: true
                });

                return;
            }
        }
    }

    private changeBehaviorNetContent(){
      const instanceNetContent = this.unityForm.productForm.netContents[0];
      const instanceNetContentGenericProduct = new NetContentGenericProduct();
      Object.assign(instanceNetContentGenericProduct, instanceNetContent);
      this.unityForm.productForm.netContents[0] = instanceNetContentGenericProduct;
    }
}
