import {Component} from '@angular/core';

@Component({
  selector: 'not-found',
  styles: [`
.NotFound {
    width: 700px;
    margin: 100px auto;
    text-align: center;
}
.NotFound-image {
    display: block;
    background: url('/assets/icons/logoGs1.svg') center no-repeat;
    height: 150px;
}
.NotFound-title{
    font-size: 1.2rem;
    font-weight: 500;
    padding-bottom: 10px;
}
`],
  template: `
<div class="NotFound">
    <a class="NotFound-image"[routerLink]="['Login']"></a>
    <div class="NotFound-title">Cette page n'est pas disponible</div>
    <div class="NotFound-text">Le lien que vous avez suivi peut être incorrect ou la page peut avoir été supprimée.</div>
</div>
`
})
export class NotFound {
  constructor() {}
}
