import {Component, Input, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import diacritics from '../../utils/diacritic';
import {DashboardConst} from '../../../dashboard/models';

import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'search-engine',
    templateUrl: './search-engine.tpl.html',
    styleUrls: ['./search-engine.less']
})

export class SearchEngineComponent {
    @Input() public border: string = 'white';
    @Input() public internal: boolean = false;
    public searchedKey: string;
    public lastSearchedKey: string;
    public noResultSearchedKey: string;
    public page: Number = 0;
    public currentPage: Number = 1;
    public totalPages: Number;
    public total: Number;
    public searchInProgress: boolean = false;
    public rateLimitExceeded: boolean = false;
    public showResults: boolean = false;
    public showOneResult: boolean = false;
    public showNoResult: boolean = false;
    public showPagination: boolean = false;
    public results: any;
    public gtinCard: any;
    public url: string;
    public productName: string;
    public productImg: string;
    public companyName: string;
    public searchedGtin: string;
    public brandName: string;
    public gtinFound: string;
    public constants = DashboardConst;
    public copyGtinToolTip: string = this.constants.copyGtinLabel;

    constructor(private apollo: Apollo) {
    }


    public searchByKey() {
        this.rateLimitExceeded = false;

        this.showResults = false;
        this.showOneResult = false;
        this.showNoResult = false;
        let newKey = false;
        if (this.searchedKey !== this.lastSearchedKey) {
            newKey = true;
            this.showPagination = false;
        }

        const query: any = gql`{
            getProductsByKey(from: ${this.page}, key:  "${this.searchedKey}", internal: ${this.internal}, newKey: ${newKey}) {
                products {
                    brand
                    category
                    companyName
                    gtin
                    prefix
                    imageUrls
                    productNames {
                        lang
                        value
                    }
                    subBrand
                }
                total
            }
        }`;
        this.apollo.query<any>({
            fetchPolicy: 'network-only',
            query
        }).subscribe(
            (result: any) => {
                const products = result.data.getProductsByKey.products || [];
                const total = result.data.getProductsByKey.total || [];
                this.total = parseInt(total, 10);
                this.totalPages = (total / 30) + 1;
                if (!_.isEmpty(products) && _.size(products) > 1) {
                    this.results = products;
                    this.showResults = true;
                    // this.constructResults(products);
                    // if (this.searchedKey !== this.lastSearchedKey) {
                    this.showPagination = this.total > 30;
                    this.lastSearchedKey = this.searchedKey;
                    // }
                }

                if (!_.isEmpty(products) && _.size(products) === 1) {
                    // this.gtinCard = products[0];
                    this.showOneResult = true;
                    this.showPagination = false;
                    this.constructGtinCard(products[0]);
                }

                if (_.isEmpty(products)) {
                    this.showNoResult = true;
                    this.noResultSearchedKey = this.searchedKey;
                }
            },
            (err) => {
                console.error('error to fetch request :', err);
                this.searchInProgress = false;
                const graphQLErrors = err.graphQLErrors;
                const firstGraphQlErrors: any = _.first(graphQLErrors);
                const errorType = firstGraphQlErrors?.errorType;
                const errorMessage = firstGraphQlErrors?.message;

                if (errorType === 'UNAUTHORIZED' && errorMessage === 'rate limit exceeded') {
                    this.rateLimitExceeded = true;
                    return;
                }

                console.error('ERROR: ', err);

                // const errors = firstGraphQlErrors?.errorInfo;
                // const error: any = firstGraphQlErrors?.errorInfo;
                // const statusCode = error?.statusCode;
                // console.log('statusCode ===========>', statusCode);
                // if (statusCode === 429) {
                //     this.rateLimitExceeded = true;
                //     return;
                // }
            }
        );

    }

    public cleanView(e) {
        // this.showNoResult = false;
        // this.searchedKey = ''
        // e = e ||s window.event;
        // this.gtinExist = null;
        // this.rateLimitExceeded = null;
        // this.error = null;
        // this.isPrivate = false;
        // const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
        // if (charCode !== 13) {
        //     this.isValid = null;
        // }
    }

    public clickCopyGtin(event, button: HTMLButtonElement) {
        this.copyGtinToolTip = this.constants.copiedGtinLabel;
        setTimeout(() => {
            button.blur();
            button.focus();
        }, 100);
        setTimeout(() => {
            this.copyGtinToolTip = this.constants.copyGtinLabel;
            button.blur();
        }, 1000);
        event.stopPropagation();
    }

    public constructShareUrl(gtin: string) {
        return `${environment.SHARE_URL_BASE}/gtin/${gtin}`;
    }

    public onChangePage(pageOfItems: number) {
        const page = (pageOfItems - 1) * 30;
        if (page !== this.page) {
            this.page = page;
            this.currentPage = pageOfItems;
            this.searchByKey();
        }
    }

    public getProductName(productNames: any[]) {
        const formatedQuery = this.searchedKey.split(' ').map((word) => {
            return diacritics(word);
        }).join('|');

        const pattern = new RegExp('(' + formatedQuery + ')', 'gi');
        let toReturn = '';
        for (let i = 0; i < _.size(productNames); i++) {
            const productName = productNames[i];
            const value: string = productName.value || '';
            const matcher = value.match(pattern);
            if (!_.isEmpty(matcher)) {
                toReturn = value;
                break;
            }
        }

        if (_.isEmpty(toReturn)) {
            toReturn = _.get(_.first(productNames), 'value', '');
        }
        return toReturn;
    }


    public constructGtinCard(data) {
        this.searchedGtin = data.gtin;
        // const url = `${environment.SHARE_URL_BASE}/gtin/${this.searchedGtin}`;
        this.url = `${environment.SHARE_URL_BASE}/gtin/${this.searchedGtin}`;
        this.productName = this.getProductName(data.productNames);
        const imageUrl = _.get(data, 'imageUrls[0]', '/assets/img/nopicture.png');
        this.productImg = imageUrl;
        this.companyName = data.companyName;
        this.brandName = data.brand;
        this.gtinFound = data.gtin;
    }

}
