import {Component} from '@angular/core';
import {AppConstants} from '../shared/models/app.constants';
import {Router} from '@angular/router';
import {AuthGuard} from '../shared/services/auth-guard';

const UAParser = require('ua-parser-js');

@Component({
  selector: 'ie-restrict',
  styles: [`
.IERestrict {
    width: 700px;
    margin: 100px auto;
    text-align: center;
}
.IERestrict-image {
    display: block;
    background: url('/assets/icons/logoGs1.svg') center no-repeat;
    height: 150px;
}
.IERestrict-title{
    font-size: 1.2rem;
    font-weight: 500;
    padding-bottom: 10px;
}
.IERestrict-text{
    white-space: pre-line;
}
`],
  template: `
<div class="IERestrict">
    <div class="IERestrict-image"></div>
    <div class="IERestrict-title">{{wording.title}}</div>
    <div class="IERestrict-text" [innerHTML]="wording.content"></div>
</div>
`
})
export class IERestrict {
  public wording = AppConstants.restrictionIE;
  constructor(public router: Router, public authGuard: AuthGuard) {

    const browser = new UAParser().getBrowser();
    if (!browser.name.includes('IE')) {
      const route = this.authGuard.jwt === null ? 'login' : 'dashboard';
      this.router.navigate([(route)]);
    }
  }
}
