export const nutrimentMeasurementUnitConst = [
  {
    wording: 'Grammes',
    code: 'GRM'
  },
  {
    wording: 'Kilojoules',
    code: 'KJO'
  },
  {
    wording: 'Milligrammes',
    code: 'MGM'
  },
  {
    wording: 'Microgrammes',
    code: 'MC'
  },
  {
    wording: 'Kilocalories',
    code: 'E14'
  },
  {
    wording: 'Code GS1 temporaire sans unité de mesure recommandée',
    code: ''
  },
  {
    wording: 'Code GS1 sans unité de mesure recommandée',
    code: ''
  }
];
