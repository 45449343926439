import {
  Component, ViewChild, ElementRef, OnInit, Output, EventEmitter, Input, OnDestroy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationModal} from '../../../../shared/components/index';
import {NotificationsSvc, ProductCategorySvc} from '../../../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../../../services/index';
import {CompanySvc} from '../../../../shared/services/company.service';
import {FormInputError} from '../../../models/formInputError';
import {DashboardConst} from '../../../models/dashboard.constants';
import URL from '../../../../shared/utils/url';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {Classification} from '../../../../shared/models/Classification.model';
import {VintageSvc} from '../../../../shared/services/vintages.service';
import {AgeOfSpiritModel} from '../../../models/ageOfSpirit.model';
import {containerCodesOld} from '../../../models/container-old.constants' ;
import {Varietal} from '../../../models/varietals.constants';
import {Aoc} from '../../../models/aoc.constants';
import {GisCodes} from '../../../models/gis.constants' ;
import {VcsEditionUnityForm} from '../../../models/vcsEditionUnityForm.model';
import GtinUtils from '../../../../shared/utils/gtin';
import {NetContent} from '../../../models/NetContent.model';

import {Observable, Subscription} from 'rxjs';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';

import * as _ from 'lodash';
import {VcsEditionUnityOldForm} from '../../../models/vcsEditionUnityForm-old.model';


@Component({
  selector: 'unity-consumer-edition-vcs-old',
  styleUrls: ['./unity-consumer-edition-vcs-old.less'],
  templateUrl: './unity-consumer-edition-vcs-old.tpl.html'
})
export class UnityConsumerEditionVcsOldComponent implements OnInit, OnDestroy {

  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;
  @Input() public product: any;
  @Input() public isWine: boolean;
  @Input() public isSpi: boolean;
  @Input() public unityForm: VcsEditionUnityOldForm;
  @ViewChild('formProduct', {static: true})
  public form;
  public webLinkError: FormInputError;
  public multiSelectConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 15
  });
  public aocSelectConfig: any = Object.assign({}, {
    openOnFocus: false,
    highlight: false,
    create: false,
    persist: true,
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input aoc-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: 'wording',
    maxItems: 1
  });
  public multiSelectOptions: any = Varietal;
  public aocSelectOptions: any = Aoc;
  public containerCodes = containerCodesOld;
  public selectedCategory: string;
  public gisCodes = GisCodes;
  @Output() public onModifyCategory = new EventEmitter<any>();
  @Input() public submitEvent: Observable<void>;

  public submitEventSubscription: Subscription;

  private nbYears = new AgeOfSpiritModel().nbYears;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              private notificationsSvc: NotificationsSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              private productCategorySvc: ProductCategorySvc,
              private elRef: ElementRef,
              private headerSvc: DashboardHeaderSvc
  ) {

  }

  public ngOnDestroy() {
    this.submitEventSubscription?.unsubscribe();
  }

  public ngOnInit() {
    // subscribe to router event
    this.headerSvc.setHeaderData('Modification fiche produit', 'assets/icons/editCode.svg');

    this.submitEventSubscription = this.submitEvent.subscribe(() => this.submitForm());
    this.initProductForm();
    this.initClassification();
    this.resetWebLinkError();

  }

  public modifyCategory() {
    this.onModifyCategory.emit(true);
  }

  public validateURL() {
    this.resetWebLinkError();
    this.unityForm.productForm.webLink = this.unityForm.productForm.webLink.toLowerCase();
    if (this.unityForm.productForm.webLink) {
      if (URL.isNotPrefixedWithProtocol(this.unityForm.productForm.webLink)) {
        this.unityForm.productForm.webLink = 'http://' + this.unityForm.productForm.webLink;
      }

      if (URL.isValidURL(this.unityForm.productForm.webLink)) {
        this.webLinkError = new FormInputError('valid', '');
      } else {
        this.webLinkError = new FormInputError('warning', DashboardConst.editForm.errors.webLinkInvalid);
      }

    }
  }

  public submitForm() {
    this.unityForm.productForm.productNames = _.filter(this.unityForm.productForm.productNames, (productName) => !_.isEmpty(productName.value) && !_.isEmpty(productName.lang));
    if (!this.unityForm.productForm.adhereToTheSectoralAgreement) {
      this.unityForm.resetFormProductWitBaseAttribute();
      return this.unityForm.submitForm();
    }
    if (this.isWine) {
      this.unityForm.attributesToUpdate['wineColor'] = this.unityForm.productForm.wineColor;
    }

    if (this.isSpi) {
      this.unityForm.attributesToUpdate['spiritCodeGpc'] = this.unityForm.productForm.spiritCodeGpc;
    }

    if (this.unityForm.isProductSparklingOrStillWine()) {
      this.unityForm.attributesToUpdate['sugarContent'] = this.unityForm.productForm.sugarContent;
    }
    this.unityForm.submitForm();
  }

  public showIG() {
    const toReturn = this.isWine && this.unityForm.productForm.productCountry === '250';
    if (!toReturn) {
      this.unityForm.productForm.gis = '';
    }
    return this.isWine && this.unityForm.productForm.productCountry === '250';
  }

  public showAOC() {
    const toReturn = this.showIG() && ['AOC', 'AOP', 'IG', 'IGP'].indexOf(this.unityForm.productForm.gis) >= 0;
    if (!toReturn) {
      this.unityForm.productForm.aoc = '';
    } else {
      this.makeAocElementRequired();
    }
    return toReturn;
  }


  private resetWebLinkError() {
    this.webLinkError = new FormInputError('', '');
  }

  private makeAocElementRequired() {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.aoc-input');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      inputElement.required = !this.unityForm.productForm.aoc || this.unityForm.productForm.aoc.length === 0;
    }
  }

  private initProductForm() {
    this.unityForm.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
    // just to display target market label
    this.unityForm.productForm.targetMarketLabel = this.unityForm.getTargetMarketLabel(this.unityForm.productForm.targetMarket);

    this.unityForm.productForm.alcoholDegree = this.productsSvc.getFormatedAlcoholDegree(this.product.alcoholDegree);
    this.unityForm.productForm.netContents = [new NetContent(this.productsSvc.getFormatedVolume(_.get(_.head(this.product.netContents), 'netContent', '')), 'CLT')];
    this.unityForm.productForm.allergen = this.unityForm.formatAllergen(this.unityForm.productForm.allergen);
    // Saving values that should not be changed
    this.unityForm.initialBrand = this.product.brand;
    this.unityForm.initialName = this.product.name;
    this.unityForm.id = this.product.id;
    this.unityForm.getClassification();
    this.unityForm.initSugarContentList();
  }


  private initClassification() {
    const spiObject = _.find(this.productCategorySvc.categories, c => c.type === 'SPI') || {};
    const vinLiqueurObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_DE_LIQUEUR') || {};
    const vinEffervescentObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_EFFERVESCENT') || {};
    const vinTranquilleObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_TRANQUILLE') || {};
    const objectList = [spiObject, vinLiqueurObject, vinEffervescentObject, vinTranquilleObject];
    this.unityForm.gpcCodes = [new Classification('0', 'others', '')];
    objectList.forEach((obj: any) => {
      this.unityForm.gpcCodes.push(new Classification(obj.codes[0], obj.category, obj.wording));
    });
  }
}
