// resolver.ts
import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {ProductCategorySvc} from './product-category.service';
import {Category} from '../models';

@Injectable()
export class Resolver implements Resolve<any> {
    constructor(private productCategorySvc: ProductCategorySvc) {
    }

    public resolve() {
        if (this.productCategorySvc.categories.length > 0) {
            return this.productCategorySvc.categories;
        }
        return this.productCategorySvc.getCategoriesData();
    }
}
