import {Component} from '@angular/core';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';

@Component({
  selector: 'dashboard-lateral-menu',
  styleUrls: ['./dashboard-lateral-menu.less'],
  template: `
<div class="DashboardLateralMenu">
    <a class="DashboardLateralMenu-icon" routerLink="/dashboard" (click)="headerSvc.dialog?.closeAll()">
        <img alt="col-logo" src="assets/icons/gs1_logo_col.png">
    </a>
    <div class="DashboardLateralMenu-buttons"></div>
</div>`
})
export class DashboardLateralMenu {
  constructor(public headerSvc: DashboardHeaderSvc) {}
}
