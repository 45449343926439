export const NetContentConst = {
  vcs: [
    {code: 'CLT', wording: 'Centilitre', category: 'volume'},
    {code: 'LTR', wording: 'Litre', category: 'volume'},
    {code: 'MLT', wording: 'Millilitre', category: 'volume'}
  ],
  all: [
    { code: 'H87', wording: 'Pièce', category: 'piece'},
    { code: 'EA', wording: 'Unités d\'articles dans une unité consommateur type "prêt à vendre"', category: 'unit'},
    { code: 'MMT', wording: 'Millimètre', category: 'Dimension'},
    { code: 'MTR', wording: 'Mètre', category: 'Dimension'},
    { code: 'GRM', wording: 'Gramme', category: 'mass'},
    { code: 'KGM', wording: 'Kilogramme', category: 'mass'},
    { code: 'MC', wording: 'Microgramme', category: 'mass'},
    { code: 'MGM', wording: 'Milligramme', category: 'mass'},
    { code: 'MLT', wording: 'Millilitre', category: 'volume'},
    { code: 'LTR', wording: 'Litre', category: 'volume'},
    { code: 'CLT', wording: 'Centilitre', category: 'volume'},
    { code: 'MTQ', wording: 'Mètre cube', category: 'volume'}
  ]
};
