export const Varietal = [
    {code: 'PLNI', wording: 'Abondant'},
    {code: 'HZON', wording: 'Abouriou'},
    {code: 'WPVA', wording: 'Agiorgitiko'},
    {code: '30000150', wording: 'Aglianico'},
    {code: '30000152', wording: 'Albana'},
    {code: '30017888', wording: 'Albarino'},
    {code: '30000159', wording: 'Aleatico'},
    {code: 'YLKB', wording: 'Alicante Henri Bouschet'},
    {code: '30010445', wording: 'Aligote'},
    {code: 'FTJW', wording: 'Altesse'},
    {code: 'SYTI', wording: 'Aramon'},
    {code: 'EQOU', wording: 'Aramon Blanc'},
    {code: 'SPKF', wording: 'Aramon Gris'},
    {code: 'VBYH', wording: 'Aranel'},
    {code: 'GMWQ', wording: 'Arbane'},
    {code: 'VVVO', wording: 'Arinarnoa'},
    {code: '30017031', wording: 'Arneis'},
    {code: 'YBXC', wording: 'Arriloba'},
    {code: 'CUED', wording: 'Arrouya'},
    {code: 'SSGR', wording: 'Arrufiac'},
    {code: 'BDPR', wording: 'Artaban'},
    {code: 'SISD', wording: 'Arvine'},
    {code: 'NYKE', wording: 'Assyrtico'},
    {code: '30000067', wording: 'Asti'},
    {code: 'BPHI', wording: 'Aubin'},
    {code: 'NGPG', wording: 'Aubin Vert'},
    {code: 'QIUW', wording: 'Aubun'},
    {code: '30000230', wording: 'Auxerrois'},
    {code: '30010446', wording: 'Babeasca Neagra'},
    {code: '30000069', wording: 'Bacchus'},
    {code: 'XADB', wording: 'Bachet'},
    {code: 'BMYA', wording: 'Baco Blanc'},
    {code: '30015746', wording: 'Baco Noir'},
    {code: 'TPPC', wording: 'Barbaroux'},
    {code: '30000276', wording: 'Barbera'},
    {code: '30000281', wording: 'Bardolino'},
    {code: '30000279', wording: 'Barolo'},
    {code: 'TYJF', wording: 'Baroque'},
    {code: 'APFI', wording: 'Beaugaray'},
    {code: '30000301', wording: 'Beauvillon'},
    {code: 'XSHP', wording: 'Béclan'},
    {code: 'IZWI', wording: 'Béquignol'},
    {code: 'MRHX', wording: 'Bia'},
    {code: 'BRPI', wording: 'Biancu Gentile'},
    {code: '30016375', wording: 'Black Queen'},
    {code: 'BWNZ', wording: 'Blanc Dame'},
    {code: '30000367', wording: 'Blanco Colli Piacentini'},
    {code: '30002731', wording: 'Blanco Di Trevigiano'},
    {code: '30006733', wording: 'Blauburger'},
    {code: '30000369', wording: 'Blauer Portugieser'},
    {code: '30006734', wording: 'Blauer Zweigelt–Rotburger'},
    {code: '30003117', wording: 'Blaufrankisch'},
    {code: '30000389', wording: 'Bobal'},
    {code: '30000398', wording: 'Bombino'},
    {code: '30000399', wording: 'Bonarda'},
    {code: '30014767', wording: 'Botrytis Riesling'},
    {code: '30014766', wording: 'Botrytis Semillon'},
    {code: 'CJZQ', wording: 'Bouchalès'},
    {code: 'PJGC', wording: 'Bouillet'},
    {code: 'PVBC', wording: 'Bouquettraube'},
    {code: 'NURP', wording: 'Bourboulenc'},
    {code: 'GPMF', wording: 'Bouysselet'},
    {code: '30000418', wording: 'Brachetto'},
    {code: '30000419', wording: 'Brachetto D\'acqui'},
    {code: '30000443', wording: 'Briosello'},
    {code: 'GWRI', wording: 'Bronner'},
    {code: 'GTHJ', wording: 'Brun Argenté'},
    {code: 'OQCS', wording: 'Brun Fourca'},
    {code: '30000451', wording: 'Brunello'},
    {code: '30000452', wording: 'Brunello Montalcino'},
    {code: '30010447', wording: 'Busuioaca De Bohotin'},
    {code: '30000072', wording: 'Cabernet'},
    {code: 'NDMJ', wording: 'Cabernet Cortis'},
    {code: '30000480', wording: 'Cabernet Franc'},
    {code: '30000481', wording: 'Cabernet Lisonpramag'},
    {code: '30000482', wording: 'Cabernet Sauvignon'},
    {code: '30000489', wording: 'Cabernet Sauvignon Rouge'},
    {code: '30000490', wording: 'Cabernet Shiraz'},
    {code: '30014776', wording: 'Cabernet Shiraz Merlot'},
    {code: 'DRUO', wording: 'Calabrese'},
    {code: 'VQID', wording: 'Caladoc'},
    {code: 'UOKF', wording: 'Calitor'},
    {code: 'FNDB', wording: 'Camaralet'},
    {code: '30000516', wording: 'Campidano'},
    {code: '30002915', wording: 'Cannonau'},
    {code: '30000524', wording: 'Capitolare Biturica'},
    {code: 'KERM', wording: 'Carcajolo'},
    {code: 'CSAA', wording: 'Carcajolo Blanc'},
    {code: 'SNBG', wording: 'Carignan'},
    {code: 'QIUN', wording: 'Carignan Blanc'},
    {code: '30014777', wording: 'Carignano'},
    {code: '30000536', wording: 'Carmenere'},
    {code: 'IKFX', wording: 'Carmenère'},
    {code: '30000548', wording: 'Casteller'},
    {code: '30000549', wording: 'Castelli Romani'},
    {code: '30000550', wording: 'Castelmonte'},
    {code: 'ZARE', wording: 'Castets'},
    {code: '30000562', wording: 'Cerasuolo'},
    {code: 'LXYQ', wording: 'César N'},
    {code: 'YFBP', wording: 'Chambourcin'},
    {code: '30000574', wording: 'Chardonnay'},
    {code: '30000580', wording: 'Chardonnay Blanc'},
    {code: '30000581', wording: 'Chardonnay Chenin Bl'},
    {code: '30000582', wording: 'Chardonnay Di Puglia'},
    {code: '30000583', wording: 'Chardonnay Lison P'},
    {code: '30000584', wording: 'Chardonnay Sauvignon'},
    {code: '30000585', wording: 'Chardonnay Semillon'},
    {code: 'ERXK', wording: 'Chasan'},
    {code: 'HRUK', wording: 'Chatus'},
    {code: 'FDLX', wording: 'Chenanson'},
    {code: '30003575', wording: 'Chenin'},
    {code: '30000594', wording: 'Chenin Blanc'},
    {code: '30000597', wording: 'Chenin Blanc Colomba'},
    {code: '30000598', wording: 'Chenin Chardonnay'},
    {code: '30000599', wording: 'Chenin Semillon'},
    {code: 'WCBV', wording: 'Chouchillon'},
    {code: '30014780', wording: 'Cienna'},
    {code: '30000679', wording: 'Cinsault'},
    {code: 'NTLZ', wording: 'Clairette'},
    {code: 'XLEE', wording: 'Clairette Rose Rs'},
    {code: 'YZQI', wording: 'Clarin'},
    {code: 'OVLG', wording: 'Claverie'},
    {code: '30002830', wording: 'Clevener'},
    {code: '30002831', wording: 'Clevener Traminer'},
    {code: 'QJKG', wording: 'Codivarta'},
    {code: '30000711', wording: 'Colli Albani'},
    {code: 'KCKI', wording: 'Colobel'},
    {code: '30000713', wording: 'Colombard'},
    {code: '30000714', wording: 'Colombard Ugni Blanc'},
    {code: 'XXMA', wording: 'Colombaud'},
    {code: '30010449', wording: 'Columna'},
    {code: 'GQIS', wording: 'Corbeau'},
    {code: '30000764', wording: 'Cortese'},
    {code: '30000765', wording: 'Corvina'},
    {code: '30000768', wording: 'Costaiola'},
    {code: '30000770', wording: 'Costiera'},
    {code: 'SPKQ', wording: 'Couderc Noir'},
    {code: 'LDRH', wording: 'Counoise'},
    {code: 'FKUT', wording: 'Courbu'},
    {code: 'EZSA', wording: 'Courbu Noir'},
    {code: 'GPWI', wording: 'Couston'},
    {code: '30010450', wording: 'Creata'},
    {code: '30014786', wording: 'Crouchen Riesling'},
    {code: '30016376', wording: 'Delaware'},
    {code: '30000887', wording: 'Dolcetto'},
    {code: '30000888', wording: 'Dolcetto D\'alba'},
    {code: '30000889', wording: 'Dolcetto D\'asti'},
    {code: '30000890', wording: 'Domina'},
    {code: '30000893', wording: 'Dornfelder'},
    {code: '30002373', wording: 'Doux'},
    {code: '30002951', wording: 'Droe Steen'},
    {code: 'ZLIB', wording: 'Duras'},
    {code: 'MZTV', wording: 'Dureza'},
    {code: '30014788', wording: 'Durif'},
    {code: 'OMAJ', wording: 'Egiodola'},
    {code: '30000931', wording: 'Ehrenfelser'},
    {code: 'KTXE', wording: 'Ekigaïna'},
    {code: '30000933', wording: 'Elbling'},
    {code: '30000949', wording: 'Est Est Est'},
    {code: 'ZCYJ', wording: 'Etraire De La Dui'},
    {code: '30000966', wording: 'Faber'},
    {code: '30002926', wording: 'Falanghina'},
    {code: 'WEBO', wording: 'Fer'},
    {code: 'WXTK', wording: 'Ferradou'},
    {code: '30010453', wording: 'Feteasca Neagra'},
    {code: '30010452', wording: 'Feteasca Regala'},
    {code: 'NGMQ', wording: 'Feunate'},
    {code: '30000980', wording: 'Fiano'},
    {code: '30000982', wording: 'Fiano Di Avellino'},
    {code: 'THHE', wording: 'Floréal'},
    {code: 'YDQO', wording: 'Florental'},
    {code: 'QMLF', wording: 'Folignan'},
    {code: 'LTHB', wording: 'Folle Blanche'},
    {code: 'DONX', wording: 'Franc Noir De Haute-Saône'},
    {code: '30001035', wording: 'Franciacorta'},
    {code: '30010454', wording: 'Francusa'},
    {code: '30001037', wording: 'Frascati'},
    {code: '30001041', wording: 'French Colombard'},
    {code: '30014789', wording: 'Frontignac'},
    {code: 'VLNL', wording: 'Fuella Nera'},
    {code: '30001085', wording: 'Fume Blanc'},
    {code: '30010455', wording: 'Furmint'},
    {code: '30010456', wording: 'Galbena De Odobesti'},
    {code: 'JWCN', wording: 'Gamaret'},
    {code: '30003118', wording: 'Gamay'},
    {code: 'GANC', wording: 'Gamay De Bouze'},
    {code: 'CRMS', wording: 'Gamay De Chaudenay'},
    {code: 'MWUU', wording: 'Gamay Fréaux'},
    {code: 'UZFG', wording: 'Gaminot'},
    {code: 'ABGP', wording: 'Ganson'},
    {code: '30017889', wording: 'Garganega'},
    {code: 'KHPO', wording: 'Garonnet'},
    {code: 'YEIO', wording: 'Gascon'},
    {code: '30001102', wording: 'Gavi'},
    {code: 'CGMB', wording: 'Genouillet'},
    {code: 'DIFI', wording: 'Genovèse'},
    {code: '30001110', wording: 'Gewuerztram Riesling'},
    {code: '30001111', wording: 'Gewuerztraminer'},
    {code: '30014791', wording: 'Gewuerztraminer Riesling Pinot Gris'},
    {code: 'TZFB', wording: 'Gibert'},
    {code: '30015744', wording: 'Glera'},
    {code: '30001137', wording: 'Goldmuskateller'},
    {code: 'ABHI', wording: 'Goldriesling'},
    {code: 'QVXQ', wording: 'Gouget'},
    {code: 'WFPH', wording: 'Graisse'},
    {code: 'SJNO', wording: 'Gramon'},
    {code: 'IQAE', wording: 'Grand Noir De La Calmette'},
    {code: 'OVUA', wording: 'Granita'},
    {code: '30010457', wording: 'Grasa De Cotnari'},
    {code: 'DBYV', wording: 'Grassen'},
    {code: '30001167', wording: 'Grauer Burgunder'},
    {code: '30002738', wording: 'Grecanico'},
    {code: '30001169', wording: 'Grechetto'},
    {code: '30001171', wording: 'Greco'},
    {code: '30001097', wording: 'Grenache'},
    {code: '30002802', wording: 'Grenache'},
    {code: '30014792', wording: 'Grenache Cinsault Syrah'},
    {code: '30001190', wording: 'Grignolino'},
    {code: 'TPTK', wording: 'Gringet'},
    {code: 'BTCX', wording: 'Grolleau'},
    {code: 'HOOG', wording: 'Grolleau Gris'},
    {code: 'SDGR', wording: 'Gros Manseng'},
    {code: '30002991', wording: 'Gruener Silvaner'},
    {code: '30001200', wording: 'Gruener Veltliner'},
    {code: '30001215', wording: 'Gutedel'},
    {code: '30001284', wording: 'Huxelrebe'},
    {code: '30001285', wording: 'Huxelrebe Silvaner'},
    {code: '30001301', wording: 'Inferno'},
    {code: '30001303', wording: 'Inzolia'},
    {code: 'URUQ', wording: 'Jacquère'},
    {code: 'AIJN', wording: 'Johanniter'},
    {code: 'CDRK', wording: 'Joubertin'},
    {code: 'CLNV', wording: 'Jurançon Blanc'},
    {code: 'SKFQ', wording: 'Jurançon Noir'},
    {code: '30014793', wording: 'Kabinett'},
    {code: '30010448', wording: 'Kadarka'},
    {code: '30001347', wording: 'Kerner'},
    {code: '30001348', wording: 'Kerner Scheurebe'},
    {code: '30001361', wording: 'Klingelberger'},
    {code: '30001362', wording: 'Klingelberger Riesli'},
    {code: 'WEJZ', wording: 'Knipperlé'},
    {code: '30001371', wording: 'Korem Isola Nuraghi'},
    {code: '30016372', wording: 'Koshu'},
    {code: '30001378', wording: 'Lacrima Christi'},
    {code: '30001385', wording: 'Lambrusco'},
    {code: '30000087', wording: 'Lambrusco Di Emilia'},
    {code: 'ZHWQ', wording: 'Landal'},
    {code: 'WURK', wording: 'Lauzet'},
    {code: 'BABO', wording: 'Len De L\'el'},
    {code: 'ZQZD', wording: 'Léon Millot'},
    {code: 'LVOJ', wording: 'Liliorila'},
    {code: 'BRFX', wording: 'Listan'},
    {code: 'YJUG', wording: 'Lledoner Pelut'},
    {code: '30001480', wording: 'Macabeo'},
    {code: '30010458', wording: 'Majarca'},
    {code: '30001501', wording: 'Malbec'},
    {code: '30001510', wording: 'Malvasia'},
    {code: 'JSBN', wording: 'Mancin'},
    {code: '30001518', wording: 'Mandrolisai'},
    {code: 'UEIB', wording: 'Manseng Noir'},
    {code: 'ISYT', wording: 'Marechal Foch'},
    {code: '30014794', wording: 'Marsanne'},
    {code: '30014795', wording: 'Marsanne Rousanne Viognier'},
    {code: 'YTGZ', wording: 'Marselan'},
    {code: '30001553', wording: 'Mauzac'},
    {code: 'QZZW', wording: 'Mavrud'},
    {code: 'KJKT', wording: 'Mayorquin'},
    {code: 'GURK', wording: 'Mècle'},
    {code: '30000370', wording: 'Melange'},
    {code: 'OKCC', wording: 'Melon'},
    {code: '30012992', wording: 'Melon De Bourgogne'},
    {code: '30014797', wording: 'Mencia'},
    {code: 'APVV', wording: 'Mérille'},
    {code: '30001573', wording: 'Merlot'},
    {code: '30014798', wording: 'Merlot Cabernet Franc Cabernet Sauvignon Syrah'},
    {code: '30001577', wording: 'Merlot Del Grave'},
    {code: '30001578', wording: 'Merlot Di Lison'},
    {code: '30001579', wording: 'Merlot Di Veneto'},
    {code: '30001580', wording: 'Merlot Rouge'},
    {code: 'ZFSY', wording: 'Meslier Saint-François'},
    {code: 'BUZX', wording: 'Meunier'},
    {code: 'DENQ', wording: 'Milgranet'},
    {code: 'YYKZ', wording: 'Molette'},
    {code: 'WFWP', wording: 'Mollard'},
    {code: 'UUWG', wording: 'Monarch'},
    {code: 'FUPQ', wording: 'Monbadon'},
    {code: 'RKED', wording: 'Mondeuse'},
    {code: 'ODJH', wording: 'Mondeuse Blanche'},
    {code: 'AEMQ', wording: 'Monerac'},
    {code: '30001619', wording: 'Montefalco'},
    {code: '30001620', wording: 'Montepulciano'},
    {code: 'WDAR', wording: 'Montils'},
    {code: '30002999', wording: 'Morio Muskat'},
    {code: 'TZFJ', wording: 'Mornen'},
    {code: 'QGOE', wording: 'Morrastel'},
    {code: '30001630', wording: 'Moscatel'},
    {code: '30001632', wording: 'Moscato'},
    {code: 'HRAE', wording: 'Moschofilero Rs'},
    {code: 'WBVI', wording: 'Mourvaison'},
    {code: '30003119', wording: 'Mourvedre'},
    {code: 'VIDX', wording: 'Mouyssaguès'},
    {code: '30002898', wording: 'Mueller Thurgau'},
    {code: '30001640', wording: 'Mueller Thurgau Kern'},
    {code: '30001641', wording: 'Mueller Thurgau Mori'},
    {code: '30001642', wording: 'Mueller Thurgau Silv'},
    {code: 'OGAI', wording: 'Muresconu'},
    {code: '30001650', wording: 'Muscadelle'},
    {code: 'HBTP', wording: 'Muscardin'},
    {code: 'DFZO', wording: 'Muscaris'},
    {code: '30016377', wording: 'Muscat Bailey A'},
    {code: '30001654', wording: 'Muscat D\'alexandrie'},
    {code: '30010459', wording: 'Muscat Ottonel'},
    {code: '30001658', wording: 'Muskateller'},
    {code: '30010460', wording: 'Mustoasa'},
    {code: '30001672', wording: 'Nebbiolo'},
    {code: 'NBSC', wording: 'Négret De Banhars'},
    {code: 'RRPT', wording: 'Négrette'},
    {code: '30001676', wording: 'Nerello Mascalese'},
    {code: '30001677', wording: 'Nero D Avola'},
    {code: '30006732', wording: 'Neuburger'},
    {code: '30016373', wording: 'Niagara'},
    {code: 'NLNT', wording: 'Nielluccio'},
    {code: '30001684', wording: 'Nobling'},
    {code: 'VJVX', wording: 'Noir Fleurien'},
    {code: '30002515', wording: 'Non Classifie'},
    {code: '30002518', wording: 'Non Identifie'},
    {code: 'VPVA', wording: 'Noual'},
    {code: 'IRRA', wording: 'Oberlin Noir'},
    {code: 'IQTD', wording: 'Onchette'},
    {code: 'LDEV', wording: 'Ondenc'},
    {code: '30001726', wording: 'Optima'},
    {code: '30001727', wording: 'Optima Ortega'},
    {code: 'WRXT', wording: 'Orbois'},
    {code: '30001733', wording: 'Organza'},
    {code: '30001735', wording: 'Ortega'},
    {code: '30001736', wording: 'Ortega Mueller Thurg'},
    {code: '30001737', wording: 'Orvieto'},
    {code: 'ZDVQ', wording: 'Pagadebiti'},
    {code: 'GXBB', wording: 'Pardotte'},
    {code: 'PNDW', wording: 'Parellada'},
    {code: 'BDAC', wording: 'Pascal'},
    {code: '30017890', wording: 'Pedro Ximenez'},
    {code: 'NBTX', wording: 'Perdea'},
    {code: '30001811', wording: 'Perle'},
    {code: 'KAGO', wording: 'Persan'},
    {code: 'LUHP', wording: 'Petit Courbu'},
    {code: 'KPMQ', wording: 'Petit Manseng'},
    {code: 'TAGN', wording: 'Petit Meslier'},
    {code: '30014801', wording: 'Petit Verdot'},
    {code: '30001818', wording: 'Petite Syrah'},
    {code: 'VLVA', wording: 'Picardan'},
    {code: 'ZCHE', wording: 'Picarlat'},
    {code: '30001824', wording: 'Piceno'},
    {code: '30001825', wording: 'Picolit'},
    {code: '30001830', wording: 'Pigato'},
    {code: 'BNQC', wording: 'Pineau D\'aunis'},
    {code: '30001842', wording: 'Pinot'},
    {code: '30001843', wording: 'Pinot Bianco'},
    {code: '30001844', wording: 'Pinot Blanc'},
    {code: '30001845', wording: 'Pinot Chardonnay'},
    {code: '30001846', wording: 'Pinot Grave'},
    {code: '30001849', wording: 'Pinot Grigio Lisonpramag'},
    {code: '30001850', wording: 'Pinot Grigio Sauvign'},
    {code: '30001847', wording: 'Pinot Gris/Pinot Grigio'},
    {code: '30001852', wording: 'Pinot Nero'},
    {code: '30001853', wording: 'Pinot Noir'},
    {code: '30014805', wording: 'Pinot Shiraz'},
    {code: '30001855', wording: 'Pinotage'},
    {code: 'MPDV', wording: 'Pinotin'},
    {code: 'CUVY', wording: 'Piquepoul Blanc'},
    {code: 'PLAN', wording: 'Piquepoul Gris'},
    {code: 'QGXH', wording: 'Piquepoul Noir'},
    {code: 'SHWD', wording: 'Plant De Brunel'},
    {code: 'WLQO', wording: 'Plant Droit'},
    {code: 'UGSF', wording: 'Plantet'},
    {code: '30010461', wording: 'Plavaie'},
    {code: 'SSWN', wording: 'Portan'},
    {code: 'JJBC', wording: 'Portugais Bleu'},
    {code: '30001902', wording: 'Portugieser'},
    {code: 'LINV', wording: 'Poulsard'},
    {code: 'FWMF', wording: 'Précoce Bousquet'},
    {code: 'NIWW', wording: 'Précoce De Malingre'},
    {code: '30001929', wording: 'Primitivo'},
    {code: 'SXNQ', wording: 'Prior'},
    {code: '30003010', wording: 'Prosecco'},
    {code: 'VZQD', wording: 'Prunelard'},
    {code: '30001957', wording: 'Raboso'},
    {code: 'EYYG', wording: 'Raffiat De Moncade'},
    {code: '30003003', wording: 'Ramandolo'},
    {code: 'HMBG', wording: 'Ravat Blanc'},
    {code: 'XTEV', wording: 'Rayon D\'or'},
    {code: '30002767', wording: 'Refosco'},
    {code: '30015839', wording: 'Regent'},
    {code: 'OAZB', wording: 'Rèze'},
    {code: '30002002', wording: 'Rheinriesling'},
    {code: '30002017', wording: 'Rieslaner'},
    {code: '30002018', wording: 'Riesling'},
    {code: '30002020', wording: 'Riesling Kastellan'},
    {code: '30002021', wording: 'Riesling Muskat'},
    {code: '30002022', wording: 'Riesling Silvaner'},
    {code: 'ZMXK', wording: 'Riminèse'},
    {code: 'CBPE', wording: 'Rivairenc'},
    {code: 'MWZC', wording: 'Rivairenc Blanc'},
    {code: 'FCFQ', wording: 'Rivairenc Gris'},
    {code: '30002026', wording: 'Rivaner'},
    {code: '30002028', wording: 'Rivaner Morio Muskat'},
    {code: '30002029', wording: 'Rivaner Riesling'},
    {code: '30010462', wording: 'Rkatiteli'},
    {code: 'JEBF', wording: 'Robin Noir'},
    {code: 'THSY', wording: 'Roditis Rs'},
    {code: '30002039', wording: 'Roero Arneis'},
    {code: 'KKMB', wording: 'Romorantin'},
    {code: 'PVAR', wording: 'Rosé Du Var Rs'},
    {code: '30002055', wording: 'Rosenmuskateller'},
    {code: '30010463', wording: 'Rosioara Pamid'},
    {code: '30002057', wording: 'Rossese'},
    {code: '30002058', wording: 'Rosso'},
    {code: '30002059', wording: 'Rosso Colli Di Luni'},
    {code: '30002060', wording: 'Rosso Di Cardinale'},
    {code: '30002061', wording: 'Rosso Di Montalcino'},
    {code: '30002062', wording: 'Rosso Di Toscana'},
    {code: 'MEGW', wording: 'Roublot'},
    {code: '30014806', wording: 'Roussanne'},
    {code: 'ZAWR', wording: 'Roussette D\'ayze'},
    {code: 'RISJ', wording: 'Rubilande Rs'},
    {code: '30002751', wording: 'Rubino'},
    {code: '30002067', wording: 'Ruby Cabernet'},
    {code: '30002072', wording: 'Rulaender'},
    {code: '30016374', wording: 'Ryugan'},
    {code: 'YTMQ', wording: 'Sacy'},
    {code: '30002092', wording: 'Sagrantino'},
    {code: 'ZNLV', wording: 'Saint-Côme'},
    {code: 'HWLB', wording: 'Saint-Macaire'},
    {code: 'WERJ', wording: 'Saint-Pierre Doré'},
    {code: '30002109', wording: 'Samtrot'},
    {code: '30002113', wording: 'Sangiovese'},
    {code: 'OSWS', wording: 'Saperavi'},
    {code: 'UBNS', wording: 'Saphira'},
    {code: '30010465', wording: 'Sarba'},
    {code: '30000096', wording: 'Sauternes'},
    {code: '30002131', wording: 'Sauvignon'},
    {code: '30002132', wording: 'Sauvignon Blanc'},
    {code: '30014809', wording: 'Sauvignon Gris'},
    {code: 'OETQ', wording: 'Savagnin Blanc'},
    {code: 'UJFB', wording: 'Savagnin Rose'},
    {code: '30002145', wording: 'Scheurebe'},
    {code: '30002146', wording: 'Scheurebe Bacchus'},
    {code: '30002147', wording: 'Scheurebe Huxelrebe'},
    {code: '30002148', wording: 'Scheurebe Mueller Th'},
    {code: '30002150', wording: 'Schiava'},
    {code: '30002690', wording: 'Schwarzriesling'},
    {code: 'MYCR', wording: 'Sciaccarello'},
    {code: 'AOGZ', wording: 'Segalin'},
    {code: 'EZSX', wording: 'Seinoir'},
    {code: 'QAJW', wording: 'Select'},
    {code: 'YTYC', wording: 'Semebat'},
    {code: '30002178', wording: 'Semillon'},
    {code: 'FYBH', wording: 'Sérénèze'},
    {code: 'DGSR', wording: 'Servanin'},
    {code: 'TVGD', wording: 'Seyval'},
    {code: '30002674', wording: 'Shiraz'},
    {code: '30002215', wording: 'Shiraz Cabernet'},
    {code: '30014818', wording: 'Shiraz Mourvedre Grenache Tempranillo'},
    {code: '30002216', wording: 'Shiraz Rouge'},
    {code: '30002226', wording: 'Sieger Ortega'},
    {code: '30002227', wording: 'Siegerrebe'},
    {code: '30002229', wording: 'Silvaner'},
    {code: '30002230', wording: 'Silvaner Riesling'},
    {code: '30017032', wording: 'Smêderevka'},
    {code: '30002258', wording: 'Soave'},
    {code: 'QCLD', wording: 'Solaris'},
    {code: 'MULD', wording: 'Soreli'},
    {code: '30002269', wording: 'Sortiert'},
    {code: 'KUJW', wording: 'Souvignier Gris'},
    {code: '30002298', wording: 'Spatburgunder'},
    {code: '30014821', wording: 'Spatlese'},
    {code: '30002322', wording: 'Spumante'},
    {code: '30006731', wording: 'St Laurent'},
    {code: '30010464', wording: 'Steinschiller'},
    {code: 'LHZN', wording: 'Sylvaner'},
    {code: '30002382', wording: 'Syrah'},
    {code: '30002385', wording: 'Syrah Grenache'},
    {code: '30010466', wording: 'Tamaioasa Romaneasca'},
    {code: '30002405', wording: 'Tannat'},
    {code: 'ZXIN', wording: 'Tardif'},
    {code: '30014822', wording: 'Tarrango'},
    {code: '30002418', wording: 'Taurasi'},
    {code: '30002784', wording: 'Tempranillo'},
    {code: 'FQUO', wording: 'Téoulier'},
    {code: '30002431', wording: 'Teroldego'},
    {code: '30017033', wording: 'Terret'},
    {code: 'ABJT', wording: 'Tibouren'},
    {code: '30014824', wording: 'Tocai Friulano'},
    {code: '30002456', wording: 'Tokay Pinot Gris'},
    {code: '30014825', wording: 'Torbato'},
    {code: '30002470', wording: 'Torrontes'},
    {code: '30002475', wording: 'Toscano'},
    {code: 'YBGW', wording: 'Tourbat'},
    {code: 'CAYS', wording: 'Touriga Nacional'},
    {code: '30002939', wording: 'Traminer'},
    {code: '30002481', wording: 'Trebbiano'},
    {code: 'NPVO', wording: 'Tressot'},
    {code: '30002486', wording: 'Trollinger'},
    {code: '30002487', wording: 'Trollinger Lemberger'},
    {code: 'IAPY', wording: 'Trousseau'},
    {code: 'WHYV', wording: 'Trousseau Gris'},
    {code: '30002506', wording: 'Turriga Isola Nuraghi'},
    {code: '30014827', wording: 'Tyrian'},
    {code: 'IABQ', wording: 'Ugni Blanc'},
    {code: '30002789', wording: 'Unb'},
    {code: '30002527', wording: 'Utiel–Requena'},
    {code: 'WVMD', wording: 'Valdiguié'},
    {code: 'STHO', wording: 'Valérien'},
    {code: '30002529', wording: 'Valpolicella'},
    {code: '30002530', wording: 'Valpolicella Rosso'},
    {code: 'MAFA', wording: 'Varousset'},
    {code: 'XDTC', wording: 'Velteliner Rouge Précoce'},
    {code: 'PWFI', wording: 'Verdanel'},
    {code: 'GLMS', wording: 'Verdejo'},
    {code: '30002546', wording: 'Verdelho'},
    {code: 'OAQK', wording: 'Verdesse'},
    {code: '30002547', wording: 'Verdicchio'},
    {code: '30002685', wording: 'Verduzzo'},
    {code: '30002549', wording: 'Vermentino'},
    {code: '30002686', wording: 'Vermentino Maremma'},
    {code: '30002550', wording: 'Vermentino Sardo'},
    {code: '30002552', wording: 'Vernaccia'},
    {code: '30002553', wording: 'Vernaccia Di San Gimingnano'},
    {code: '30002554', wording: 'Vespaiolo'},
    {code: '30002555', wording: 'Vespolina'},
    {code: 'MUKU', wording: 'Vidoc'},
    {code: '30002560', wording: 'Vignanello'},
    {code: 'PJNH', wording: 'Villard Blanc'},
    {code: 'PSPV', wording: 'Villard Noir'},
    {code: '30002571', wording: 'Vinsanto'},
    {code: '30000104', wording: 'Viognier'},
    {code: '30002574', wording: 'Vivello Bianco'},
    {code: 'ZCGH', wording: 'Voltis'},
    {code: '30017034', wording: 'Vranec'},
    {code: '30002591', wording: 'Weisser Burgunder'},
    {code: '30002593', wording: 'Weisswein'},
    {code: '30003116', wording: 'Welschriesling'},
    {code: '30002635', wording: 'Woelfle'},
    {code: 'CPQW', wording: 'Xinomavro'},
    {code: '30010467', wording: 'Zghihara De Husi'},
    {code: '30002662', wording: 'Zibibbo'},
    {code: '30003205', wording: 'Zinfandel'},
    {code: '30006730', wording: 'Zweigelt'}
];
