import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {
  DashboardConst,
  ProductTypes,
  ProductResellerTypes
} from '../../../models/index';
import {CompanySvc, NotificationsSvc, ProductCategorySvc} from '../../../../shared/services/index';
import {ProductsSvc} from '../../../services/index';
import {FormInputError} from '../../../models/formInputError';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';
import {UnityFormResolver} from '../../../../dashboard/services/unityFormResolver.service';
import {Classification} from '../../../../shared/models/Classification.model';
import {ConfirmationModal} from '../../../../shared/components';
import {filter} from 'rxjs/operators';
import {AuthGuard} from '../../../../shared/services/auth-guard';
import { Prefix } from 'src/app/shared/models/prefix.model';

import * as _ from 'lodash';
import {ValidationCreationProductComponent} from './validation-creation-product/validation-creation-product.component';

@Component({
  selector: 'product-creation-new-way',
  styleUrls: ['./product-creation.less'],
  templateUrl: './product-creation.tpl.html'
})
export class ProductCreationComponent implements OnInit, AfterViewInit, OnDestroy {
  public constants = DashboardConst;
  @Input() public selectedCategory: {
    breadcrumbs: string,
    code: string,
    definition: string,
    parentIds: string[],
    text: string,
    type: string
  } = {
    breadcrumbs: '',
    code: '',
    definition: '',
    parentIds: [''],
    text: '',
    type: ''
  };
  @Input() public productType: ProductTypes;
  @Input() public isSpi: boolean;
  @Input() public isWine: boolean;
  @Input() public isBundle: boolean;
  @Input() public isNotNew: boolean;
  @ViewChild('formProduct', {static: true}) public form: any;
  @Output() public onModifyCategory = new EventEmitter<any>();
  public webLinkError: FormInputError;
  public showCategory: boolean;
  public measurementUnitsList: Array<Array<{}>> = new Array();
  public canAddNetContent: boolean = true;
  public currentStep: number = 1;
  public lastVisitedStep: number = 1;
  public gpcCodes: Classification[];
  public unityForm: any;
  @Input() public productResellerType: string;
  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;
  public cannotSeeBrand: boolean;
  @ViewChild(ValidationCreationProductComponent, {static: true})
  public validationCreationProductComponent: ValidationCreationProductComponent;

  private subscription = new Subscription();
  private forceReturn: boolean = false;
  private previousUrl: string;

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notificationsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              private headerSvc: DashboardHeaderSvc,
              private elRef: ElementRef,
              private productCategorySvc: ProductCategorySvc,
              private unityFormResolver: UnityFormResolver,
              private authGuard: AuthGuard) {
    this.headerSvc.setHeaderData('Nouveau code', 'assets/icons/newCodeOrange.svg');

  }

  public ngOnInit() {
    this.unityForm = this.unityFormResolver.resolveCreationModel(this.productType);
    this.unityForm.resetFormProduct();
    this.subscription.add(this.unityForm.setSortedPrefixes());
    this.subscription.add(this.companySvc.companyDataSource.subscribe(
      company => {
        this.unityForm.updateFormWithCompanyFields(company);
      },
      error => this._router.navigate(['/dashboard'])
    ));
    this.initClassification();
    this._router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe(e => {
        const event = e as NavigationStart;
        this.previousUrl = event.url;
      });
  }


  public ngAfterViewInit() {
    this.unityForm.productForm.codeGpc = this.selectedCategory.code;
    this.unityForm.productForm.category = this.selectedCategory.text;

    if (!this.isNotNew) {
      delete this.unityForm.productForm.originGtin;
    }

    if (this.productResellerType) {
      this.unityForm.productForm.productResellerType = this.productResellerType;
    }

    if (this.productsSvc.isProductResellerWithoutBrand(this.unityForm.productForm.productResellerType)) {
      this.unityForm.productForm.brand = 'SANS MARQUE';
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onPrefixChange(prefix: string) {
    this.unityForm.imgIsLoaded = false;
    this.unityForm.productForm.gtin.cip = '';
    if (this.productsSvc.totalProducts < this.companySvc.company.codesAvailable && !_.isNil(prefix)) {
      this.unityForm.processSelectedPrefix(prefix);
    }
  }

  public modifyCategory() {
    this.onModifyCategory.emit(true);
  }

  public onNext(submitting: boolean = true, adhereToTheSectoralAgreement: boolean, forceToGoToLastStep: boolean = false) {
    const urlsWithErrors = _.filter(this.unityForm.productForm.imagesAddedByUrl, i => !i.isEmpty() && i.hasError());
    if (this.currentStep === 1 && !_.isEmpty(urlsWithErrors)) {
      // Clean urls needed
      this.notificationsSvc.invalidImageUrl();
      return;
    }
    this.cleanImages();
    this.cleanProductNames();
    this.unityForm.submitting = submitting;
    this.unityForm.productForm.adhereToTheSectoralAgreement = adhereToTheSectoralAgreement;
    const isGeneric = this.productType === ProductTypes.GENERIC;
    const isBundle = this.productResellerType === ProductResellerTypes.BUNDLE;
    const isVcs = this.productType === ProductTypes.VCS;

    if (this.currentStep === 4) {
      this.forceReturn = true;
      this.validationCreationProductComponent.show();
    }
    this.lastVisitedStep = this.currentStep;

    if (forceToGoToLastStep && this.unityForm.productForm.adhereToTheSectoralAgreement) {
      this.unityForm.resetFormProductWitBaseAttribute(this.unityForm.productForm);
      this.currentStep = 4;
      return;
    }

    if ((this.currentStep === 1 && (isGeneric || isBundle)) || (this.currentStep === 2 && !isGeneric && !adhereToTheSectoralAgreement)) {
      this.unityForm.resetFormAsBaseProduct();
      this.currentStep = 4;
      return;
    }

    if (this.featureToggleSvc.isWhalesUser() && this.currentStep === 1 && isVcs) {
      this.unityForm.resetFormProductWitBaseAttribute(this.unityForm.productForm);
      this.currentStep = 3;
      return;
    }

    if ((this.currentStep < 4) || (this.currentStep === 2 && !isGeneric && adhereToTheSectoralAgreement)) {
      this.unityForm.resetFormProductWitBaseAttribute(this.unityForm.productForm);
      this.currentStep++;
      return;
    }

  }

  public forceReturnToFirstStep() {
    if (this.productType !== ProductTypes.GENERIC) {
      this.unityForm.resetFormProductWitBaseAttribute(this.unityForm.productForm);
    }
    this.currentStep = 1;
    return;
  }

  public forceReturnToSectoralStep() {
    this.unityForm.resetFormProductWitBaseAttribute(this.unityForm.productForm);
    this.currentStep = 3;
    return;
  }

  public onPrevious(submitting: boolean = true) {
    const isVcs = this.productType === ProductTypes.VCS;
    this.unityForm.submitting = submitting;
    if ((this.currentStep === 4 && this.productType === ProductTypes.GENERIC)) {
      this.currentStep = 1;
      return;
    }

    if (this.currentStep === 4 && this.productType !== ProductTypes.GENERIC) {
      this.unityForm.resetFormProductWitBaseAttribute(this.unityForm.productForm);
      this.currentStep = this.lastVisitedStep;
      return;
    }

    if (this.currentStep === 3) {
      if (isVcs && this.featureToggleSvc.isWhalesUser()) {
        this.currentStep = 1;
        return;
      }
      this.unityForm.productForm.adhereToTheSectoralAgreement = false;
      this.currentStep--;
      return;
    }

    if (this.currentStep > 0) {
      this.currentStep--;
    }
    if (this.currentStep === 0) {
      this.unityForm.cancelCreation();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadNotification($event: any) {
    if (this.currentStep === 4 && !_.isEmpty(this.authGuard.jwt)) {
      $event.preventDefault();
      $event.returnValue = false;
      return true;
    }

    return false;
  }

  public canDeactivate() {
    if (this.currentStep === 4 && !this.forceReturn) {
      this.confirmationModal.openModal();
      return false;
    }

    return true;
  }

  public onConfirmModal() {
    this.forceReturn = true;
    if (this.previousUrl) {
      this._router.navigateByUrl(this.previousUrl);
      return true;
    }
    return false;
  }

  public validCreation(event: boolean) {
    if (event) {
      if (!this.unityForm.submitForm()){
        this.validationCreationProductComponent.close(false);
      }
    }else {
      this.currentStep = 1;
    }
  }

  private initClassification() {
    const spiObject = _.find(this.productCategorySvc.categories, c => c.type === 'SPI') || {};
    const vinLiqueurObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_DE_LIQUEUR') || {};
    const vinEffervescentObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_EFFERVESCENT') || {};
    const vinTranquilleObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_TRANQUILLE') || {};
    const objectList = [spiObject, vinLiqueurObject, vinEffervescentObject, vinTranquilleObject];
    this.gpcCodes = [new Classification('0', 'others', '')];
    objectList.forEach((obj: any) => {
      this.gpcCodes.push(new Classification(obj.codes[0], obj.category, ''));
    });
  }

  private cleanImages() {
    this.unityForm.productForm.imagesAddedByUrl = _.filter(this.unityForm.productForm.imagesAddedByUrl, imageUrl => !imageUrl.isEmpty() && !imageUrl.hasError());
  }

  private cleanProductNames() {
    this.unityForm.productForm.productNames = _.filter(this.unityForm.productForm.productNames, productName => !_.isEmpty(productName.value) && !_.isEmpty(productName.lang));
  }

}
