import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DashboardConst} from '../../models/index';

@Component({
  selector: 'barcode-modal-form-vecto',
  styleUrls: ['./barcode-modal.less'],
  template: `<div class="BarcodModalFormVecto ImageForm">
    <div class="ImageForm-description">{{constants.generateForm.wording.description}}</div>
    <div class="ImageForm-section row">
        <div class="ImageForm-section-category col-xs-2">Format</div>
        <div class="ImageForm-section-content col-xs-10">
            <label class="radio-inline">
                <input #eps type="radio" name="format" id="png" value="eps" (click)="changeFormat(eps.value)" checked>
                <span class="radio-inline-text"> EPS</span>
                <span class="check"></span>
            </label>
            <label class="radio-inline">
                <input #svg type="radio" name="format" id="svg" value="svg" (click)="changeFormat(svg.value)">
                <span class="radio-inline-text"> SVG</span>
                <span class="check"></span>
            </label>
        </div>
    </div>
    <div class="ImageForm-section row">
        <div class="ImageForm-section-category col-xs-2">Information</div>
        <div class="ImageForm-section-content dimension-content col-xs-10">
            <div class="row">
                <!-- Help bloc -->
                <div class="ImageForm-help col-xs-12">
                    <img src="assets/icons/warningBlack.svg" alt="">
                    <div class="ImageForm-help-heading">
                        {{constants.generateForm.wording.help.heading}}
                    </div>
                    <div class="ImageForm-help-body">
                        {{constants.generateForm.wording.help.body}}
                    </div>
                    <a class="ImageForm-help-link" href="{{constants.generateForm.wording.help.link}}"
                       target="_blank">
                        En savoir plus
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>`
})
export class BarcodeModalFormVecto {
  @Input() public format: string;
  @Output() public formatChange = new EventEmitter<string>();

  public constants = DashboardConst;

  constructor() {}

  public changeFormat(value) {
    this.format = value;
    this.formatChange.emit(this.format);
  }
}
