import {Injectable} from '@angular/core';
import {UserSvc} from '../../shared/services';

@Injectable()
export class FeatureToggleSvc {

  constructor(private userSvc: UserSvc) {
  }

  public canSeeTargetMarket(): boolean {
    return this.getAutorization('targetMarket');
  }

  public canSeeVcs(): boolean {
    return this.getAutorization('producteurVcs');
  }

  public canVcsImport(): boolean {
    return this.getAutorization('importVcs');
  }

  public canExport(): boolean {
    return this.getAutorization('export');
  }


  public canSeeOnBoarding(): boolean {
    return this.getAutorization('onBoarding');
  }

  public canSeeModeView(): boolean {
    return this.getAutorization('modeView');
  }

  public canSeeNewImportWay(): boolean {
    return this.getAutorization('newImportWay');
  }

  public canSeeChangeVisibility(): boolean {
    return this.getAutorization('changeVisibility');
  }

  public showNewFeatureOnBoarding(): boolean {
    return this.getAutorization('showNewFeatureOnBoarding');
  }

  public showNewCreationWay(): boolean {
    const authorizedUser = ['codeonline@gs1fr.org'];
    return !this.canSeeVcs();
  }

  public canSeeSettingsImport(): boolean {
    return this.getAutorization('importSettings');
  }

  public isWhalesUser(): boolean {
    return this.getAutorization('isWhalesUser');
  }

  private getAutorization(key: string) {
    const user: any = this.userSvc.user;
    if (user.authorizations && user.authorizations[key]) {
      return user.authorizations[key];
    } else {
      return false;
    }
  }
}
