import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';

import * as _ from 'lodash';

@Component({
    selector: 'pagination',
    // styleUrls: ['./pagination.less'],
    template: `
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
            <li *ngIf="extremBtns" [ngClass]="{disabled:currentPage === 1}" class="page-item first-item">
                <a (click)="setPage(1)" class="page-link">Premier</a>
            </li>
            <li [ngClass]="{disabled:currentPage === 1}" class="page-item previous-item">
                <a (click)="currentPage !== 1 && setPage(currentPage - 1)" class="page-link">Précédent</a>
            </li>
            <li *ngFor="let page of pager.pages" [ngClass]="{active:currentPage === page}" class="page-item number-item" id="page-{{page}}">
                <a (click)="setPage(page)" class="page-link">{{page}}</a>
            </li>
            <li [ngClass]="{disabled:currentPage === pager.totalPages}" class="page-item next-item">
                <a (click)="currentPage !== pager.totalPages && setPage(currentPage + 1)" class="page-link">Suivant</a>
            </li>
            <li *ngIf="extremBtns" [ngClass]="{disabled:currentPage === pager.totalPages}" class="page-item last-item">
                <a (click)="setPage(pager.totalPages)" class="page-link">Dernier</a>
            </li>
        </ul>
        `
})
export class Pagination implements OnInit {
    @Input() public nbrItems: Number;
    @Output() public changePage = new EventEmitter<any>(true);
    @Input() public initialPage = 1;
    @Input() public pageSize = 30;
    @Input() public maxPages = 5;
    @Input() public currentPage;
    @Input() public extremBtns = false;
    public pager: any = {};

    public ngOnInit() {
        if (this.nbrItems) {
            // this.pager = this.paginate(this.nbrItems, this.initialPage, this.pageSize, this.maxPages);
            this.setPage(this.initialPage);
        }

    }

    public setPage(page: number) {
        this.pager = this.paginate(this.nbrItems, page, this.pageSize, this.maxPages);
        this.changePage.emit(page);
    }

    public paginate(totalItems, currentPage, pageSize, maxPages) {
        if (currentPage === void 0) { currentPage = 1; }
        if (pageSize === void 0) { pageSize = 10; }
        if (maxPages === void 0) { maxPages = 10; }
        // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);
        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        }else if (currentPage > totalPages) {
            currentPage = totalPages;
        }
        let startPage;
        let endPage;
        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        }else {
            // total pages more than max so calculate start and end pages
            const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            }else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            }else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }
        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(function(i) { return startPage + i; });
        // return object with all pager properties required by the view
        this.currentPage = currentPage;
        return {
            totalItems,
            currentPage,
            pageSize,
            totalPages,
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages
        };
    }
}
