
import {tap} from 'rxjs/operators';
import {forwardRef, Inject, Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserSvc} from '../services/user.service';
import {NotificationsSvc} from '../services/notifications.service';
import {AuthGuard} from '../services/auth-guard';
import {environment} from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userSvc = this.injector.get(UserSvc);
    const notifSvc = this.injector.get(NotificationsSvc);
    const authGuard = this.injector.get(AuthGuard);
    const token = authGuard.jwt;
    if (token && !request.url.includes(environment.SSO_DOMAIN)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          notifSvc.userDisconnected();
          userSvc.disconnectUser(false);
        }
        if (err.status === 503) {
          const win = (window as any);
          win.location.href = '/maintenance.html';
        }
      }
    }));
  }
}
