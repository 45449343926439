import {
  Component, ElementRef,
  ViewEncapsulation
} from '@angular/core';

import {NotificationsSvc} from '../../../../../../../shared/services';
import {DashboardConst} from '../../../../../../models/dashboard.constants';
import {AllergensFoodConstants} from '../../../../../../../dashboard/models';
import {AllergenFoodModel} from '../../../../../../../dashboard/models/allergenFood.model';
import {StepComponent} from './StepComponent';

import * as _ from 'lodash';

@Component({
  selector: 'diet-step',
  styleUrls: ['../unity-consumer-creation-food.less'],
  templateUrl: './diet-step.tpl.html',
  encapsulation: ViewEncapsulation.None
})
export class DietStepComponent extends StepComponent {

  public stepCount: number = 2;
  public constants = DashboardConst;
  public requiredAllergenFoodItems = AllergensFoodConstants.requiredAllergen;
  public optionalAllergenFoodItems = AllergensFoodConstants.optionalAllergen;
  public containmentLevelConst = AllergensFoodConstants.containmentLevel;
  public mergedAllergenFoodItems = _.concat(this.requiredAllergenFoodItems, this.optionalAllergenFoodItems);
  private opticalSpecifiedAllergen: AllergenFoodModel [] = [];

  constructor(public notificationsSvc: NotificationsSvc, private elRef: ElementRef) {
    super();
  }

  public updateLabel(div: HTMLDivElement) {
    div.classList.remove('radioBtnError');
  }

  public submitForm(submitting: boolean = true, form: HTMLFormElement) {
    if (form && form.checkValidity() === false) {
      return;
    }
    if (this.checkAllergen()) {
      super.submitForm(submitting, form);
    }
    return false;
  }

  public checkAllergen(): boolean {
    for (const allergen of this.productForm.allergensFood) {
      if (!this.isValidAllergenValues(allergen.allergenTypeCode ?? '', allergen.levelOfContainmentCode)) {
        this.notificationsSvc.wrongFoodAllergen();
        return false;
      }
    }
    return true;
  }

  public isValidAllergenValues(allergenTypeCode: string, levelOfContainmentCode: string): boolean {
    const coqFruitsObj: any = _.find(this.productForm.allergensFood, (obj) => obj.allergenTypeCode === 'AN');
    const allergenFoodOptionalCodes = _.map(this.optionalAllergenFoodItems, allergen => allergen.code);
    if (allergenFoodOptionalCodes.indexOf(allergenTypeCode) !== -1) {
      if (coqFruitsObj.levelOfContainmentCode !== 'CONTAINS' && levelOfContainmentCode === 'CONTAINS') {
        return false;
      }
    }

    if (coqFruitsObj.allergenTypeCode === allergenTypeCode) {
      let total = 0;
      allergenFoodOptionalCodes.forEach(code => {
        const data: any = this.productForm.allergensFood.reduce((count: number, el: any) => {
          return el.allergenTypeCode === code && el.levelOfContainmentCode === 'CONTAINS' ? count + 1 : count;
        }, 0);

        total += data;
      });
      if (coqFruitsObj.levelOfContainmentCode !== 'CONTAINS' && total !== 0) {
        return false;
      }
    }
    return true;

  }

  public isRequiredAllergen(code: string): boolean {
    const allergen = _.find(this.requiredAllergenFoodItems, c => c.code === code) || {};
    return !_.isEmpty(allergen);
  }


  public addOrRemoveOptionalAllergen(allergenTypeCode: string, levelOfContainmentCode: string) {
    if (this.isAbleToAddOptionalAllergen(allergenTypeCode, levelOfContainmentCode)) {
      if (this.opticalSpecifiedAllergen.length === 0) {
        this.optionalAllergenFoodItems.forEach((allergen: { code: any; }) => {
          const item = new AllergenFoodModel(allergen.code, '');
          this.opticalSpecifiedAllergen.push(item);
        });
      }
      const allergenFood = this.productForm.allergensFood;
      this.productForm.allergensFood = _.concat(allergenFood, this.opticalSpecifiedAllergen);
    } else if (this.isAbleToRemoveOptionalAllergen(allergenTypeCode, levelOfContainmentCode)) {
      const index = _.findIndex(this.productForm.allergensFood, function(allergen: any) {
        return allergen.allergenTypeCode === 'AN';
      });
      this.opticalSpecifiedAllergen = this.productForm.allergensFood.splice(index + 1, this.productForm.allergensFood.length - index);
    }
  }

  public isAbleToAddOptionalAllergen(allergenTypeCode: string, levelOfContainmentCode: string): boolean {
    if (allergenTypeCode !== 'AN') {
      return false;
    }

    const authorizedLevelOfContainmentCodes = ['CONTAINS', 'MAY_CONTAIN'];
    if (authorizedLevelOfContainmentCodes.indexOf(levelOfContainmentCode) === -1) {
      return false;
    }
    const allergenFoodCodes = _.map(this.productForm.allergensFood, allergen => allergen.allergenTypeCode);
    const allergenFoodOptionalCodes = _.map(this.optionalAllergenFoodItems, allergen => allergen.code);
    const arrayIntersection = _.intersection(allergenFoodCodes, allergenFoodOptionalCodes);
    if (arrayIntersection.length !== 0) {
      return false;
    }
    return true;
  }

  public isAbleToRemoveOptionalAllergen(allergenTypeCode: string, levelOfContainmentCode: string): boolean {
    if (allergenTypeCode !== 'AN') {
      return false;
    }

    const authorizedLevelOfContainmentCodes = ['UNDECLARED', 'FREE_FROM'];
    if (authorizedLevelOfContainmentCodes.indexOf(levelOfContainmentCode) === -1) {
      return false;
    }
    const allergenFoodCodes = _.map(this.productForm.allergensFood, allergen => allergen.allergenTypeCode);
    const allergenFoodOptionalCodes = _.map(this.optionalAllergenFoodItems, allergen => allergen.code);
    const arrayIntersection = _.intersection(allergenFoodCodes, allergenFoodOptionalCodes);
    if (arrayIntersection.length === 0) {
      return false;
    }
    return true;
  }

  public findAllergenWording(code: string): string {
    const allergen: any = _.find(this.mergedAllergenFoodItems, c => c.code === code) || {};
    return allergen.wording;
  }

  public resetAllergenFoodItems(): AllergenFoodModel[] {
    const toReturn: AllergenFoodModel[] = [];
    this.requiredAllergenFoodItems.forEach((allergen: any) => {
      const item = new AllergenFoodModel(allergen.code, '');
      toReturn.push(item);
    });

    return toReturn;
  }

  public checkRequiredInputs(form: HTMLFormElement, event: Event) {
    if (form.checkValidity() === false) {
      const codes = this.requiredAllergenFoodItems.map(allergen => allergen.code);
      const itemSpaceDivElements: HTMLDivElement[] = this.elRef.nativeElement
        .querySelectorAll('.itemSpace');
      itemSpaceDivElements.forEach((el: HTMLDivElement, index) => {
        const children = el.children;
        const row: any = children.namedItem(`row_${index}`);
        const inputRadioItems = row.getElementsByTagName(`input`);
        const checkedInput = _.filter(inputRadioItems, (test => test.checked === true));
        if (checkedInput.length === 0) {
          const divElements = row.getElementsByTagName(`div`) || [];
          codes.forEach(code => {
            if (divElements[0] && divElements[0].className.indexOf(code) !== -1) {
              divElements[0].className = `${divElements[0].className} radioBtnError`;
            }
          });
        }
      });
      const scrollToElement: HTMLElement = this.elRef.nativeElement.querySelector('.radioBtnError');
      if (scrollToElement) {
        scrollToElement.scrollIntoView({
          block: 'center'
        });
      }
    }

    // document.querySelector('form')
    //     .addEventListener('invalid', function (event) {
    //         $('.itemSpace').each((i, e) => {
    //             if ($(e).find('input[type="radio"]:checked').length === 0) {
    //                 $(e).find('.labelContainer').addClass('radioBtnError');
    //             }
    //         });
    //         event.preventDefault();
    //     }, true);
  }
}
