export const Countries = [
  {
    wording: 'Afghanistan',
    code: '004'
  },
  {
    wording: 'Afrique du Sud',
    code: '710'
  },
  {
    wording: 'Åland(les Îles)',
    code: '248'
  },
  {
    wording: 'Albanie',
    code: '008'
  },
  {
    wording: 'Algérie',
    code: '012'
  },
  {
    wording: 'Allemagne',
    code: '276'
  },
  {
    wording: 'Andorre',
    code: '020'
  },
  {
    wording: 'Angola',
    code: '024'
  },
  {
    wording: 'Anguilla',
    code: '660'
  },
  {
    wording: 'Antarctique',
    code: '010'
  },
  {
    wording: 'Antigua-et-Barbuda',
    code: '028'
  },
  {
    wording: 'Arabie saoudite',
    code: '682'
  },
  {
    wording: 'Argentine',
    code: '032'
  },
  {
    wording: 'Arménie',
    code: '051'
  },
  {
    wording: 'Aruba',
    code: '533'
  },
  {
    wording: 'Australie',
    code: '036'
  },
  {
    wording: 'Autriche',
    code: '040'
  },
  {
    wording: 'Azerbaïdjan',
    code: '031'
  },
  {
    wording: 'Bahamas',
    code: '044'
  },
  {
    wording: 'Bahreïn',
    code: '048'
  },
  {
    wording: 'Bangladesh',
    code: '050'
  },
  {
    wording: 'Barbade',
    code: '052'
  },
  {
    wording: 'Bélarus',
    code: '112'
  },
  {
    wording: 'Belgique',
    code: '056'
  },
  {
    wording: 'Belize',
    code: '084'
  },
  {
    wording: 'Bénin',
    code: '204'
  },
  {
    wording: 'Bermudes',
    code: '060'
  },
  {
    wording: 'Bhoutan',
    code: '064'
  },
  {
    wording: 'Bolivie (État plurinational de)',
    code: '068'
  },
  {
    wording: 'Bonaire, Saint-Eustache et Saba',
    code: '535'
  },
  {
    wording: 'Bosnie-Herzégovine',
    code: '070'
  },
  {
    wording: 'Botswana',
    code: '072'
  },
  {
    wording: "Bouvet (l'Île)",
    code: '074'
  },
  {
    wording: 'Brésil',
    code: '076'
  },
  {
    wording: 'Brunéi Darussalam',
    code: '096'
  },
  {
    wording: 'Bulgarie',
    code: '100'
  },
  {
    wording: 'Burkina Faso',
    code: '854'
  },
  {
    wording: 'Burundi',
    code: '108'
  },
  {
    wording: 'Cabo Verde',
    code: '132'
  },
  {
    wording: 'Caïmans (les Îles)',
    code: '136'
  },
  {
    wording: 'Cambodge',
    code: '116'
  },
  {
    wording: 'Cameroun',
    code: '120'
  },
  {
    wording: 'Canada',
    code: '124'
  },
  {
    wording: 'Chili',
    code: '152'
  },
  {
    wording: 'Chine',
    code: '156'
  },
  {
    wording: "Christmas (l'Île)",
    code: '162'
  },
  {
    wording: 'Chypre',
    code: '196'
  },
  {
    wording: 'Cocos (les Îles)/ Keeling (les Îles)',
    code: '166'
  },
  {
    wording: 'Colombie',
    code: '170'
  },
  {
    wording: 'Comores',
    code: '174'
  },
  {
    wording: 'Congo',
    code: '178'
  },
  {
    wording: 'Congo (la République démocratique du)',
    code: '180'
  },
  {
    wording: 'Cook (les Îles)',
    code: '184'
  },
  {
    wording: 'Corée (la République de)',
    code: '410'
  },
  {
    wording: 'Corée (la République populaire démocratique de)',
    code: '408'
  },
  {
    wording: 'Costa Rica',
    code: '188'
  },
  {
    wording: "Côte d'Ivoire",
    code: '384'
  },
  {
    wording: 'Croatie',
    code: '191'
  },
  {
    wording: 'Cuba',
    code: '192'
  },
  {
    wording: 'Curaçao',
    code: '531'
  },
  {
    wording: 'Danemark',
    code: '208'
  },
  {
    wording: 'Djibouti',
    code: '262'
  },
  {
    wording: 'dominicaine (la République)',
    code: '214'
  },
  {
    wording: 'Dominique',
    code: '212'
  },
  {
    wording: 'Égypte',
    code: '818'
  },
  {
    wording: 'El Salvador',
    code: '222'
  },
  {
    wording: 'Émirats arabes unis',
    code: '784'
  },
  {
    wording: 'Équateur',
    code: '218'
  },
  {
    wording: 'Érythrée',
    code: '232'
  },
  {
    wording: 'Espagne',
    code: '724'
  },
  {
    wording: 'Estonie',
    code: '233'
  },
  {
    wording: 'Eswatini',
    code: '748'
  },
  {
    wording: "États-Unis d'Amérique",
    code: '840'
  },
  {
    wording: 'Éthiopie',
    code: '231'
  },
  {
    wording: 'Falkland (les Îles)/Malouines (les Îles)',
    code: '238'
  },
  {
    wording: 'Féroé (les Îles)',
    code: '234'
  },
  {
    wording: 'Fidji',
    code: '242'
  },
  {
    wording: 'Finlande',
    code: '246'
  },
  {
    wording: 'France',
    code: '250'
  },
  {
    wording: 'Gabon',
    code: '266'
  },
  {
    wording: 'Gambie',
    code: '270'
  },
  {
    wording: 'Géorgie',
    code: '268'
  },
  {
    wording: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    code: '239'
  },
  {
    wording: 'Ghana',
    code: '288'
  },
  {
    wording: 'Gibraltar',
    code: '292'
  },
  {
    wording: 'Grèce',
    code: '300'
  },
  {
    wording: 'Grenade',
    code: '308'
  },
  {
    wording: 'Groenland',
    code: '304'
  },
  {
    wording: 'Guadeloupe',
    code: '312'
  },
  {
    wording: 'Guam',
    code: '316'
  },
  {
    wording: 'Guatemala',
    code: '320'
  },
  {
    wording: 'Guernesey',
    code: '831'
  },
  {
    wording: 'Guinée',
    code: '324'
  },
  {
    wording: 'Guinée équatoriale',
    code: '226'
  },
  {
    wording: 'Guinée-Bissau',
    code: '624'
  },
  {
    wording: 'Guyana',
    code: '328'
  },
  {
    wording: 'Guyane française',
    code: '254'
  },
  {
    wording: 'Haïti',
    code: '332'
  },
  {
    wording: "Heard-et-Îles MacDonald (l'Île)",
    code: '334'
  },
  {
    wording: 'Honduras',
    code: '340'
  },
  {
    wording: 'Hong Kong',
    code: '344'
  },
  {
    wording: 'Hongrie',
    code: '348'
  },
  {
    wording: 'Île de Man',
    code: '833'
  },
  {
    wording: 'Îles mineures éloignées des États-Unis',
    code: '581'
  },
  {
    wording: 'Inde',
    code: '356'
  },
  {
    wording: "Indien (le Territoire britannique de l'océan)",
    code: '086'
  },
  {
    wording: 'Indonésie',
    code: '360'
  },
  {
    wording: "Iran (République Islamique d')",
    code: '364'
  },
  {
    wording: 'Iraq',
    code: '368'
  },
  {
    wording: 'Irlande',
    code: '372'
  },
  {
    wording: 'Islande',
    code: '352'
  },
  {
    wording: 'Israël',
    code: '376'
  },
  {
    wording: 'Italie',
    code: '380'
  },
  {
    wording: 'Jamaïque',
    code: '388'
  },
  {
    wording: 'Japon',
    code: '392'
  },
  {
    wording: 'Jersey',
    code: '832'
  },
  {
    wording: 'Jordanie',
    code: '400'
  },
  {
    wording: 'Kazakhstan',
    code: '398'
  },
  {
    wording: 'Kenya',
    code: '404'
  },
  {
    wording: 'Kirghizistan',
    code: '417'
  },
  {
    wording: 'Kiribati',
    code: '296'
  },
  {
    wording: 'Koweït',
    code: '414'
  },
  {
    wording: 'Lao (la République démocratique populaire)',
    code: '418'
  },
  {
    wording: 'Lesotho',
    code: '426'
  },
  {
    wording: 'Lettonie',
    code: '428'
  },
  {
    wording: 'Liban',
    code: '422'
  },
  {
    wording: 'Libéria',
    code: '430'
  },
  {
    wording: 'Libye',
    code: '434'
  },
  {
    wording: 'Liechtenstein',
    code: '438'
  },
  {
    wording: 'Lituanie',
    code: '440'
  },
  {
    wording: 'Luxembourg',
    code: '442'
  },
  {
    wording: 'Macao',
    code: '446'
  },
  {
    wording: 'Macédoine du Nord',
    code: '807'
  },
  {
    wording: 'Madagascar',
    code: '450'
  },
  {
    wording: 'Malaisie',
    code: '458'
  },
  {
    wording: 'Malawi',
    code: '454'
  },
  {
    wording: 'Maldives',
    code: '462'
  },
  {
    wording: 'Mali',
    code: '466'
  },
  {
    wording: 'Malte',
    code: '470'
  },
  {
    wording: 'Mariannes du Nord (les Îles)',
    code: '580'
  },
  {
    wording: 'Maroc',
    code: '504'
  },
  {
    wording: 'Marshall (les Îles)',
    code: '584'
  },
  {
    wording: 'Martinique',
    code: '474'
  },
  {
    wording: 'Maurice',
    code: '480'
  },
  {
    wording: 'Mauritanie',
    code: '478'
  },
  {
    wording: 'Mayotte',
    code: '175'
  },
  {
    wording: 'Mexique',
    code: '484'
  },
  {
    wording: 'Micronésie (États fédérés de)',
    code: '583'
  },
  {
    wording: 'Moldova (la République de)',
    code: '498'
  },
  {
    wording: 'Monaco',
    code: '492'
  },
  {
    wording: 'Monde',
    code: '001'
  },
  {
    wording: 'Mongolie',
    code: '496'
  },
  {
    wording: 'Monténégro',
    code: '499'
  },
  {
    wording: 'Montserrat',
    code: '500'
  },
  {
    wording: 'Mozambique',
    code: '508'
  },
  {
    wording: 'Myanmar',
    code: '104'
  },
  {
    wording: 'Namibie',
    code: '516'
  },
  {
    wording: 'Nauru',
    code: '520'
  },
  {
    wording: 'Népal',
    code: '524'
  },
  {
    wording: 'Nicaragua',
    code: '558'
  },
  {
    wording: 'Niger',
    code: '562'
  },
  {
    wording: 'Nigéria',
    code: '566'
  },
  {
    wording: 'Niue',
    code: '570'
  },
  {
    wording: "Norfolk (l'Île)",
    code: '574'
  },
  {
    wording: 'Norvège',
    code: '578'
  },
  {
    wording: 'Nouvelle-Calédonie',
    code: '540'
  },
  {
    wording: 'Nouvelle-Zélande',
    code: '554'
  },
  {
    wording: 'Oman',
    code: '512'
  },
  {
    wording: 'Ouganda',
    code: '800'
  },
  {
    wording: 'Ouzbékistan',
    code: '860'
  },
  {
    wording: 'Pakistan',
    code: '586'
  },
  {
    wording: 'Palaos',
    code: '585'
  },
  {
    wording: 'Palestine, État de',
    code: '275'
  },
  {
    wording: 'Panama',
    code: '591'
  },
  {
    wording: 'Papouasie-Nouvelle-Guinée',
    code: '598'
  },
  {
    wording: 'Paraguay',
    code: '600'
  },
  {
    wording: 'Pays-Bas',
    code: '528'
  },
  {
    wording: 'Pérou',
    code: '604'
  },
  {
    wording: 'Philippines',
    code: '608'
  },
  {
    wording: 'Pitcairn',
    code: '612'
  },
  {
    wording: 'Pologne',
    code: '616'
  },
  {
    wording: 'Polynésie française',
    code: '258'
  },
  {
    wording: 'Porto Rico',
    code: '630'
  },
  {
    wording: 'Portugal',
    code: '620'
  },
  {
    wording: 'Qatar',
    code: '634'
  },
  {
    wording: 'République arabe syrienne',
    code: '760'
  },
  {
    wording: 'République centrafricaine',
    code: '140'
  },
  {
    wording: 'Réunion',
    code: '638'
  },
  {
    wording: 'Roumanie',
    code: '642'
  },
  {
    wording: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    code: '826'
  },
  {
    wording: 'Russie (la Fédération de)',
    code: '643'
  },
  {
    wording: 'Rwanda',
    code: '646'
  },
  {
    wording: 'Sahara occidental*',
    code: '732'
  },
  {
    wording: 'Saint-Barthélemy',
    code: '652'
  },
  {
    wording: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    code: '654'
  },
  {
    wording: 'Sainte-Lucie',
    code: '662'
  },
  {
    wording: 'Saint-Kitts-et-Nevis',
    code: '659'
  },
  {
    wording: 'Saint-Marin',
    code: '674'
  },
  {
    wording: 'Saint-Martin (partie française)',
    code: '663'
  },
  {
    wording: 'Saint-Martin (partie néerlandaise)',
    code: '534'
  },
  {
    wording: 'Saint-Pierre-et-Miquelon',
    code: '666'
  },
  {
    wording: 'Saint-Siège',
    code: '336'
  },
  {
    wording: 'Saint-Vincent-et-les Grenadines',
    code: '670'
  },
  {
    wording: 'Salomon (les Îles)',
    code: '090'
  },
  {
    wording: 'Samoa',
    code: '882'
  },
  {
    wording: 'Samoa américaines',
    code: '016'
  },
  {
    wording: 'Sao Tomé-et-Principe',
    code: '678'
  },
  {
    wording: 'Sénégal',
    code: '686'
  },
  {
    wording: 'Serbie',
    code: '688'
  },
  {
    wording: 'Seychelles',
    code: '690'
  },
  {
    wording: 'Sierra Leone',
    code: '694'
  },
  {
    wording: 'Singapour',
    code: '702'
  },
  {
    wording: 'Slovaquie',
    code: '703'
  },
  {
    wording: 'Slovénie',
    code: '705'
  },
  {
    wording: 'Somalie',
    code: '706'
  },
  {
    wording: 'Soudan',
    code: '729'
  },
  {
    wording: 'Soudan du Sud',
    code: '728'
  },
  {
    wording: 'Sri Lanka',
    code: '144'
  },
  {
    wording: 'Suède',
    code: '752'
  },
  {
    wording: 'Suisse',
    code: '756'
  },
  {
    wording: 'Suriname',
    code: '740'
  },
  {
    wording: "Svalbard et l'Île Jan Mayen",
    code: '744'
  },
  {
    wording: 'Tadjikistan',
    code: '762'
  },
  {
    wording: 'Taïwan (Province de Chine)',
    code: '158'
  },
  {
    wording: 'Tanzanie (la République-Unie de)',
    code: '834'
  },
  {
    wording: 'Tchad',
    code: '148'
  },
  {
    wording: 'Tchéquie',
    code: '203'
  },
  {
    wording: 'Terres australes françaises',
    code: '260'
  },
  {
    wording: 'Thaïlande',
    code: '764'
  },
  {
    wording: 'Timor-Leste',
    code: '626'
  },
  {
    wording: 'Togo',
    code: '768'
  },
  {
    wording: 'Tokelau',
    code: '772'
  },
  {
    wording: 'Tonga',
    code: '776'
  },
  {
    wording: 'Trinité-et-Tobago',
    code: '780'
  },
  {
    wording: 'Tunisie',
    code: '788'
  },
  {
    wording: 'Turkménistan',
    code: '795'
  },
  {
    wording: 'Turks-et-Caïcos (les Îles)',
    code: '796'
  },
  {
    wording: 'Turquie',
    code: '792'
  },
  {
    wording: 'Tuvalu',
    code: '798'
  },
  {
    wording: 'Ukraine',
    code: '804'
  },
  {
    wording: 'Uruguay',
    code: '858'
  },
  {
    wording: 'Vanuatu',
    code: '548'
  },
  {
    wording: 'Venezuela (République bolivarienne du)',
    code: '862'
  },
  {
    wording: 'Vierges britanniques (les Îles)',
    code: '092'
  },
  {
    wording: 'Vierges des États-Unis (les Îles)',
    code: '850'
  },
  {
    wording: 'Viet Nam',
    code: '704'
  },
  {
    wording: 'Wallis-et-Futuna',
    code: '876'
  },
  {
    wording: 'Yémen',
    code: '887'
  },
  {
    wording: 'Zambie',
    code: '894'
  },
  {
    wording: 'Zimbabwe',
    code: '716'
  }
];
