import {ActivatedRoute, Router} from '@angular/router';
import GtinUtils from '../../shared/utils/gtin';
import {Gtin} from '../../shared/models/index';
import {NotificationsSvc, CompanySvc, FeatureToggleSvc, UserSvc} from '../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../services/index';
import {DashboardConst} from './dashboard.constants';
import {DateInput} from './dateInput.model';
import {Countries} from '../models/country.constants';
import {Classification} from '../../shared/models/Classification.model';
import {ProductClassificationIndex} from './productClassification.constants';
import {VintageSvc} from '../../shared/services/vintages.service';
import {BaseProductEditionFormModel} from './baseProductEditionForm.model';
import {finalize} from 'rxjs/operators';
import {ProductName} from './ProductName.model';

import * as _ from 'lodash';

const moment = require('moment');

export class BaseEditionUnityFormModel {

  public DECIMAL_PATTERN = '^([0-9]{1,2}(,\\d{1})?|100)$';

  public productForm: any;
  public initialProductForm: any;
  public constants = DashboardConst;
  public initialBrand: string;
  public initialName: string;
  public submitting: boolean = false;
  public dateInput: DateInput = new DateInput('', '', '');
  public gpcCodes: Classification[];
  public countries = Countries;
  public selectedCategorie = new Classification('', '', '');
  public productCategories = ProductClassificationIndex;
  protected attributesToUpdate = {};
  private _id: string;

  public set id(value: string) {
    this._id = value;
  }

  public get id(): string {
    return this._id;
  }

  constructor(public productsSvc: ProductsSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public featureToggle: FeatureToggleSvc,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public notifsSvc: NotificationsSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public userSvc: UserSvc) {
    this.productForm = new BaseProductEditionFormModel(new Gtin(this.companySvc.company.mainPrefix, '', '',
      false, false), '', '', '', '', '', [new ProductName('', 'fr')], [], [], '', '', 0, this.companySvc.company.id, [''], '', false, '', []);

  }

  public onDateChange(date: string): void {
    if (date.match(/[a-z]/g)) {
      [this.dateInput.status, this.dateInput.message] = ['warning', `La date doit être au format JJ/MM/AAAA`];
      return;
    }

    const pattern = /\d{1,2}\/\d{1,2}\/\d{4}/g;
    if (date.match(pattern)) {
      const momentDate = moment(date, 'DD/MM/YYYY');
      if (!momentDate.isValid()) {
        [this.dateInput.status, this.dateInput.message] = ['error', `La date saisie n'est pas valide`];
        return;
      }
      if (momentDate.isAfter(moment())) {
        [this.dateInput.status, this.dateInput.message] = ['error', `La date saisie doit être antérieure à aujourd'hui`];
        return;
      }
      this.dateInput.status = '';
      return;
    }

    [this.dateInput.status, this.dateInput.message] = ['', ''];
  }

  public loadProduct(id: string) {
    this.productsSvc.getProduct(id)
      .subscribe(
        product => {
          _.assign(this.productForm, product);
          this.productForm.gtin = GtinUtils.constructGTINObject(product.gtin, product.prefix);
          // just to display target market label
          // Saving values that should not be changed
          this.initialBrand = product.brand;
          this.initialName = product.name;
          if (_.isUndefined(product.codeGpc) || _.isEmpty(product.codeGpc)) {
            this.productForm.codeGpc = '0';
          }

        },
        err => {
          if (err.status === 404) {
            this._router.navigate(['/dashboard']);
            this.notifsSvc.productNotFound();
          }
        }
      );
  }

  public cancelCreation() {
    this._router.navigate(['/dashboard']);
  }

  public submitForm() {
    if (!this.userSvc.user.arrayTargetMarketSeen) {
      this.userSvc.updateUser({arrayTargetMarketSeen: true});
    }
    if (!this.submitting) {
      this.submitting = true;

      if (this.productForm.codeGpc !== undefined && this.productForm.codeGpc === '0') {
        this.productForm.codeGpc = '';
      }

            this.attributesToUpdate['name'] = this.productForm.name;
            this.attributesToUpdate['brand'] = this.productForm.brand;
            this.attributesToUpdate['mpn'] = this.productForm.mpn;
            this.attributesToUpdate['sku'] = this.productForm.sku;
            this.attributesToUpdate['subBrand'] = this.productForm.subBrand;
            this.attributesToUpdate['description'] = this.productForm.description;
            this.attributesToUpdate['webLink'] = this.productForm.webLink;
            this.attributesToUpdate['completionLevel'] = this.productForm.completionLevel;
            this.attributesToUpdate['isPrivate'] = this.productForm.isPrivate;
            this.attributesToUpdate['netContents'] = this.productForm.netContents;
            this.attributesToUpdate['codeGpc'] = this.productForm.codeGpc;
            this.attributesToUpdate['images'] = this.productForm.images;
            this.attributesToUpdate['imagesAddedByUrl'] = this.constructImagesAddedByUrl();
            this.attributesToUpdate['targetMarket'] = this.productForm.targetMarket;
            this.attributesToUpdate['productNames'] = this.productForm.productNames;
      if (this.productForm.productType === 'BUNDLE'){
        this.attributesToUpdate['productResellerType'] = 'BUNDLE';
      }
      if (this.productForm.productResellerType === 'NOT_NEW'){
        this.attributesToUpdate['originGtin'] = this.productForm.originGtin;
      }
            this.productsSvc.updateProduct(this._id, this.attributesToUpdate, this.productForm.imagesToUpload)
                .pipe(finalize(() => this.submitting = false))
                .subscribe(
                    () => {
                        if (!this.userSvc.user.haveSeenProductNames) {
                            this.userSvc.updateUser({haveSeenProductNames: true});
                        }
                        this._router.navigate(['/dashboard/product/view/' + this._id]);
                    },
                    err => {
                        switch (err.error.type) {
                            case 'IMPORT_IN_PROGRESS':
                                this.notifsSvc.importInProgress();
                                break;
                            case 'PRODUCT_VALIDATION_FAILED' :
                                this.notifsSvc.updateProductValidation();
                                break;

              default:
                console.error(err);
                break;
            }
          }
        );
    }
  }

  public deleteProduct() {
    if (!this.submitting) {
      this.submitting = true;

      this.productsSvc.deleteProduct(this._id)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(
          () => this._router.navigate(['/dashboard'], {queryParams: {actionInProgress: true}}),
          err => {
            if (err.status === 404) {
              return this._router.navigate(['/dashboard']);
            }
            if (err.status === 401) {
              return;
            }

            switch (err.error.type) {
              case 'IMPORT_IN_PROGRESS':
                this.notifsSvc.importInProgress();
                break;

              default:
                this.notifsSvc.serverError();
                break;
            }
          }
        );
    }
  }

  public generateBarcode() {
    this.barcodeGenerationSvc.generateBarcode(this._id);
  }

  public onVisibilityChange(value) {
    this.productForm.isPrivate = !value;
  }

  public getTargetMarketLabel(code: string) {
    const targetMarket: any = _.find(Countries, function(targetMarketItem) {
      return targetMarketItem.code === code;
    }) || {};
    return targetMarket.wording || '';
  }

  public constructImagesAddedByUrl() {
    const toReturn = [];
    this.productForm.imagesAddedByUrl.forEach(imagesAddedByUrl => {
      toReturn.push(imagesAddedByUrl.url);
    });
    return toReturn;
  }


}
