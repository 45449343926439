import {
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    DoCheck,
    forwardRef,
    Component,
    ViewChild,
    Output,
    Renderer2,
    EventEmitter, IterableDiffers, IterableDiffer, IterableChangeRecord, IterableChanges
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl
} from '@angular/forms';

import * as $ from 'jquery';
import 'selectize/dist/js/standalone/selectize.js';
import * as cloneDeep from 'lodash.clonedeep';

export const SELECTIZE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NgSelectizeComponent),
    multi: true
};


@Component({
    selector: 'ng-selectize',
    template: `<select #selectizeInput></select>`,
    providers: [SELECTIZE_VALUE_ACCESSOR]
})
export class NgSelectizeComponent implements OnInit, OnChanges, DoCheck, ControlValueAccessor {

    @Input()
    set options(value: any[]) {
        this._options = value;
        if (!this._optionsDiffer && value) {
            this._optionsDiffer = this._differs.find(value).create();
        }
    }

    get options(): any[] {
        return this._options;
    }

    @Input()
    set optgroups(value: any[]) {
        this._optgroups = value;
        if (!this._optgroupsDiffer && value) {
            this._optgroupsDiffer = this._differs.find(value).create();
        }
    }

    get optgroups(): any[] {
        return this._optgroups;
    }

    @Input() public config: any;
    @Input() public id: string;
    @Input() public placeholder: string;
    @Input() public hasOptionsPlaceholder: string;
    @Input() public noOptionsPlaceholder: string;
    @Input() public enabled = true;
    @Input() public value: string[];
    @Input() public formControl: FormControl;
    @Input() public errorClass: string;
    @Output() public onBlur: EventEmitter<void> = new EventEmitter<void>(false);

    @ViewChild('selectizeInput', {static: true})
    public selectizeInput: any;

    private _options: any[];
    private _optionsDiffer: IterableDiffer<any>;
    private _optgroups: any[];
    private _optgroupsDiffer: IterableDiffer<any>;
    private selectize: any;

    // Control value accessors.
    private onTouchedCallback: () => {};
    private onChangeCallback: (_: any) => {};

    constructor(private _differs: IterableDiffers, private renderer: Renderer2) {
    }

    public ngOnInit(): void {
        if (this.id && this.id.length > 0) {
            this.renderer.setAttribute(this.selectizeInput.nativeElement, 'id', this.id);
        }
        this.reset();
    }

    public reset() {
        // @ts-ignore
      this.selectize = $(this.selectizeInput.nativeElement).selectize(this.config)[0].selectize;
        this.selectize.on('change', this.onSelectizeValueChange.bind(this));
        this.selectize.on('blur', this.onBlurEvent.bind(this));

        this.updatePlaceholder();
        this.onEnabledStatusChange();
    }

    /**
     * Change detection for primitive types.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (this.selectize) {
            if (changes.hasOwnProperty('placeholder') || changes.hasOwnProperty('hasOptionsPlaceholder')
                || changes.hasOwnProperty('noOptionsPlaceholder')) {
                this.updatePlaceholder();
            }
            if (changes.hasOwnProperty('enabled')) {
                this.onEnabledStatusChange();
            }
        }
    }

    /**
     * Implementing deep check for option comparison
     *
     * FIXME -> Implement deep check to only compare against label and value fields.
     */
    public ngDoCheck(): void {
        if (this._optionsDiffer) {
            const changes = this._optionsDiffer.diff(this._options);
            if (changes) {
                this._applyOptionsChanges(changes);
            }
        }
        if (this._optgroupsDiffer) {
            const changes = this._optgroupsDiffer.diff(this._optgroups);
            if (changes) {
                this._applyOptionGroupChanges(changes);
            }
        }
    }

    public onBlurEvent() {
        if (this.formControl) {
            this.formControl.markAsTouched();
        }
        this.onBlur.emit();
        this.evalHasError();
    }

    public onSelectizeOptGroupAdd(optgroup: any): void {
        this.selectize.addOptionGroup(optgroup[this.getOptgroupField()], optgroup);
    }

    public onSelectizeOptGroupRemove(optgroup: any): void {
        this.selectize.removeOptionGroup(optgroup[this.getOptgroupField()]);
    }

    /**
     * Refresh selected values when options change.
     */
    public onSelectizeOptionAdd(option: any): void {
        this.selectize.addOption(cloneDeep(option));
        const valueField = this.getValueField();
        if (this.value) {
            const items = (typeof this.value === 'string' || typeof this.value === 'number') ? [this.value] : this.value;
            if (items && items instanceof Array && items.find(value => value === option[valueField])) {
                this.selectize.addItem(option[valueField], true);
            }
        }
    }

    public onSelectizeOptionRemove(option: any): void {
        this.selectize.removeOption(option[this.getValueField()]);
    }

    public evalHasError() {
        const parent = $(this.selectize.$control).parent();
        if (this.formControl) {
            if (this.formControl.touched && this.formControl.invalid) {
                parent.addClass(this.errorClass || 'has-error');
            } else if (parent.hasClass('has-error')) {
                parent.removeClass(this.errorClass || 'has-error');
            }
        }
    }

    /**
     * Update the current placeholder based on the given input parameter.
     */
    public updatePlaceholder(): void {
        if (this.selectize.items.length === 0 && this.selectize.settings.placeholder !== this.getPlaceholder()) {
            this.selectize.settings.placeholder = this.getPlaceholder();
            this.selectize.updatePlaceholder();
            this.selectize.showInput(); // Without this, when options are cleared placeholder only appears after focus.
        }
    }

    /**
     * Called when a change is detected in the 'enabled' input field.
     * Sets the selectize state based on the new value.
     */
    public onEnabledStatusChange(): void {
        this.enabled ? this.selectize.enable() : this.selectize.disable();
    }

    /**
     * Dispatches change event when a value change is detected.
     * @param $event
     */
    public onSelectizeValueChange($event: any): void {
        // In some cases this gets called before registerOnChange.
        if (this.onChangeCallback) {
            this.onChangeCallback(this.selectize.getValue());
        }
    }

    /**
     * Returns the applicable placeholder.
     */
    public getPlaceholder(): string {
        if (this.hasOptionsPlaceholder) {
            if (this.options && this.options.length > 0) {
                return this.hasOptionsPlaceholder;
            }
        }
        if (this.noOptionsPlaceholder) {
            if (!this.options || this.options.length === 0) {
                return this.noOptionsPlaceholder;
            }
        }
        return this.placeholder;
    }

    /**
     * Implementation from ControlValueAccessor
     *
     * Empty check on 'obj' removed due to restriction on resetting the field.
     * From testing, async should still function appropriately.
     *
     * FIXME This might not be necessary anymore..
     *
     * @param obj
     */
    public writeValue(obj: any): void {
        if (obj !== this.value) {
            this.value = obj;
        }
        this.selectize.setValue(this.value);
    }

    /**
     * Implementation from ControlValueAccessor, callback for (ngModelChange)
     * @param fn
     */
    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    /**
     * Implementation from ControlValueAccessor
     * @param fn
     */
    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public getValueField(): string {
        return this.config['valueField'] ? this.config['valueField'] : 'value';
    }

    public getOptgroupField(): string {
        return this.config['optgroupField'] ? this.config['optgroupField'] : 'optgroup';
    }

    private _applyOptionsChanges(changes: IterableChanges<any>): void {
        changes.forEachAddedItem((record: IterableChangeRecord<any>) => {
            this.onSelectizeOptionAdd(record.item);
        });
        changes.forEachRemovedItem((record: IterableChangeRecord<any>) => {
            this.onSelectizeOptionRemove(record.item);
        });
        this.updatePlaceholder();
        this.evalHasError();
    }

    private _applyOptionGroupChanges(changes: any): void {
        changes.forEachAddedItem((record: IterableChangeRecord<any>) => {
            this.onSelectizeOptGroupAdd(record.item);
        });
        changes.forEachRemovedItem((record: IterableChangeRecord<any>) => {
            this.onSelectizeOptGroupRemove(record.item);
        });
        this.updatePlaceholder();
        this.evalHasError();
    }
}
