import { NgModule, Directive } from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';

import {TopicCard, Topic, HelpCenterComponent, HelpCenterMenu, PopularTopics} from './index';
import {DashboardHeaderSvc} from '../services/dashboard-header.service';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    HelpCenterMenu,
    HelpCenterComponent,
    PopularTopics,
    Topic,
    TopicCard
  ]
})
export class HelpCenterModule {

  constructor(private dashboardHeaderSvc: DashboardHeaderSvc) {}

  public ngOnInit() {
    this.dashboardHeaderSvc.setHeaderData('Centre d\'aide', 'assets/icons/sauvetage.svg');
  }
}
