import {Component, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'topic-card',
  styleUrls: ['./helpCenter-topicCard.less'],
  template: `
    <div class="TopicCard" [ngClass]="GAClassName" (click)="clickCard()">
      <div class="TopicCard-img" [ngClass]="GAClassName">
        <img [src]="imgUrl" alt="">
      </div>
      <div class="TopicCard-link" [ngClass]="GAClassName" [innerHtml]="name"></div>
    </div>
  `
})
export class TopicCard {
  @Input() public imgUrl: string;
  @Input() public name: string;
  @Input() public linkType: string;
  @Input() public routeIndex: number;
  @Input() public downloadLink: string;
  @Input() public GAClassName: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  public clickCard() {
    if (this.linkType === 'route') {
      this.router.navigate(['../topic', this.routeIndex], {relativeTo: this.route});
      return;
    }
    window.open(this.downloadLink);
  }
}
