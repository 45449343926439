
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';

import {Company} from '../models/index';
import {NotificationsSvc} from './notifications.service';



import {LoginConstants} from '../../login/models';
import {AuthGuard} from './auth-guard';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import * as _ from 'lodash';
import {environment} from '../../../environments/environment';
import { Prefix, SortedPrefixes } from '../models/prefix.model';

@Injectable()
export class CompanySvc {
    public company: Company = new Company(-99, '', '', '', [], undefined, undefined, '', '', '', '', [], {
        breadcrumbs: '',
        code: '',
        definition: '',
        parentIds: [],
        text: '',
        type: ''
    }, [{
        breadcrumbs: '',
        code: '',
        definition: '',
        parentIds: [],
        text: '',
        type: '',
        selectedAt: ''
    }], false, false);
    public companyDataSource = new Subject<Company>();

    constructor(private httpClient: HttpClient, private notifsSvc: NotificationsSvc) {

    }

    public initCompany() {
        this.getCompanyData().subscribe(
            () => this.companyDataSource.next(this.company),
            err => this.companyDataSource.error(err)
        );
    }

    public getCompanyData(): Observable<{} | Company> {
        const requestUrl = environment.API_URL + '/company';
        // @ts-ignore
      return this.httpClient.get(requestUrl).pipe(
            map(
                (data: any) => {
                    const serverCompany = data;
                    this.initiateCompany(serverCompany);
                    return this.company;
                }
            ), catchError(err => {
                const returnedError = err as Response;
                if (returnedError.status !== 401) {
                    this.notifsSvc.companyMissing();
                }
                return err;
            }));
    }

    public getCompanyPreferencePrefixes(): Observable<any> {
        const requestUrl = environment.API_URL + '/company/preferencePrefixes';
        return this.httpClient.get(requestUrl);
    }

    public getCompanySortedPrefixes(): Observable<Prefix[]> {
        const requestUrl = environment.API_URL + '/company/sortedPrefixes';
        return this.httpClient.get(requestUrl).pipe(
            map(
                (data: any) => SortedPrefixes.getInstance(data).getPrefixes()
            ));
    }

    public getNbCodesAvailable(): number {
        let result = 0;
        if (this.company) {
            this.company.prefix.map((prefix) => {
                if (!_.isEmpty(_.trim(prefix))) {
                    result += Math.pow(10, 12 - prefix.length);
                }
            });
        }
        return result;
    }

    public getNbrNewGTIN8Available(): number {
        let result = 0;
        if (this.company && this.company.newGtin8) {
            result = _.size(this.company.newGtin8);
        }
        return result;
    }

    public getBookmarkedCategories(): any[] {
        let result: any = [];
        if (this.company) {
            result = this.company.bookmarkedCategories || [];
        }
        return result;
    }

    public getSelectedCategory(): {
        breadcrumbs: string,
        code: string,
        definition: string,
        parentIds: string[],
        text: string,
        type: string
    } {
        let result : any = {
            breadcrumbs: '',
            code: '',
            definition: '',
            parentIds: [],
            text: '',
            type: ''
        };
        if (this.company) {
            result = this.company.selectedCategory || {
                breadcrumbs: '',
                code: '',
                definition: '',
                parentIds: [],
                text: '',
                type: ''
            };
        }
        return result;
    }

    public updateBookmarkedCategories(categories: any): Promise<Company> {
        return this.updateCompany({bookmarkedCategories: categories});
    }

    public updateSelectedCategory(category: { code: string; }, categories: any): Promise<Company> {

        const selectedCategories: any[] = _.get(this.company, 'lastSelectedCategories', []);
        if (category.code !== '99999999') {
            const objectFound = _.find(selectedCategories, (obj) => {
                return obj.code === category.code;
            });
            if (_.isNil(objectFound)) {
                selectedCategories.push({...category, selectedAt: new Date().toISOString()});
                selectedCategories.sort(this.compareDateSelectedCategory);
                if (selectedCategories.length > 3) {
                    selectedCategories.shift();
                }
            }
        }

        return this.updateCompany({
            selectedCategory: category,
            bookmarkedCategories: categories,
            lastSelectedCategories: selectedCategories
        });
    }


    public updateCompany(updatedAttributes: Object): Promise<Company> {
        return new Promise((resolve, reject) => {

            const requestUrl = environment.API_URL + '/company';
            const body = JSON.stringify({
                UpdatedAttributes: JSON.stringify(updatedAttributes)
            });
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            };
            return this.httpClient.put(requestUrl, body, httpOptions)
                .subscribe(
                    (data: any) => {
                        this.initiateCompany(data);
                        resolve(data);
                    },
                    error => reject(error)
                );
        });
    }

    public isVcsMarchandise() {
        const vcsUserPref: any = LoginConstants.disclaimer.preferences.find(item => item.id === 'VCS') || {};
        return (vcsUserPref.id === this.company.preferenceCompany);
    }

    private initiateCompany(serverCompany: any) {
        const cmp = new Company(serverCompany.id, serverCompany.companyName, serverCompany.gln,
            serverCompany.mainPrefix, [], serverCompany.preferenceCompany,
            undefined, serverCompany.street, serverCompany.cp, serverCompany.city,
            serverCompany.urlWebsite, _.get(serverCompany, 'bookmarkedCategories', []), _.get(serverCompany, 'selectedCategory', {}),
            _.get(serverCompany, 'lastSelectedCategories', []), _.get(serverCompany, 'hideSkipChoiceCategory', false), _.get(serverCompany, 'isNonPayer', false),
            _.get(serverCompany, 'newGtin8', []));
        const prefixList = _.filter(serverCompany.prefix, prefix => !_.isEmpty(_.trim(prefix)));
        cmp.prefix = prefixList;
        if (!_.isEmpty(_.trim(serverCompany.mainPrefix))) {
            cmp.prefix = _.uniq([serverCompany.mainPrefix, ...prefixList]);
        }
        this.company = cmp;
        this.company.codesAvailable = this.getNbCodesAvailable();
        this.company.newGtin8Available = this.getNbrNewGTIN8Available();
    }

    private compareDateSelectedCategory(a: any, b: any): number {
        let comparison = 0;
        if (a.selectedAt > b.selectedAt) {
            comparison = 1;
        } else if (a.selectedAt < b.selectedAt) {
            comparison = -1;
        }
        return comparison;
    }

}
