import {Component} from '@angular/core';

import {DashboardHeaderSvc} from '../../services/dashboard-header.service';

@Component({
  selector: 'product-import',
  styleUrls: ['./product-import.less'],
  template: `
<div class='ProductImport'>
  <router-outlet></router-outlet>
</div>`
})
export class ProductImport {

  constructor(private headerSvc: DashboardHeaderSvc) {
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData('Importer vos produits', 'assets/icons/ico_import.svg');
  }

}
