import {Injectable} from '@angular/core';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {NotificationsSvc} from './notifications.service';
import { FileLikeObject } from 'ng2-file-upload/file-upload/file-like-object.class';
import * as _ from 'lodash';

@Injectable()
export class FileUploadSvc {
  public QUEUE_LIMIT: number = 3;
  public uploader: FileUploader;

  constructor(notifSvc: NotificationsSvc) {
    this.uploader = new FileUploader({
      queueLimit: this.QUEUE_LIMIT,
      allowedMimeType: ['image/png', 'image/jpeg'],
      maxFileSize: 10000000,  // 10 Mo
      filters: [{
        name: 'mimeType',
        fn: (item?: FileLikeObject, options?: FileUploaderOptions): boolean => (item?.name.toLowerCase().endsWith('.jpeg')
        || item?.name.toLowerCase().endsWith('.jpg') || item?.name.toLowerCase().endsWith('.png')) ?? false
      }]
    });

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      switch (filter.name) {
        case 'mimeType':
          notifSvc.wrongImageType();
          break;
        case 'queueLimit':
          notifSvc.queueLimitReached();
          break;
        case 'fileSize':
          notifSvc.imageTooBig();
          break;
        default:
          break;
      }
    };
  }

  public setQueueLimit(limit: number): void {
    this.uploader.setOptions({queueLimit: limit});
  }

  public getQueuedFiles(): File[] {
    return _.map(this.uploader.queue, (queue) => queue._file);
  }
}
