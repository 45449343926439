export const SpiritStyles = [{code: '30014830', wording: 'Argente (Couleur d\'alcool)'},
  {code: '30014444', wording: 'Blanc (Couleur d\'alcool)'},
  {code: '30017050', wording: 'Blend (Alcool)'},
  {code: '30014835', wording: 'Brun (Couleur d\'alcool)'},
  {code: '30001135', wording: 'Doré'},
  {code: '30014829', wording: 'Foncé (Couleur d\'alcool)'},
  {code: '30001329', wording: 'Jonge'},
  {code: '30010421', wording: 'London'},
  {code: '30002274', wording: 'Moût aigre'},
  {code: '30002375', wording: 'Moût doux'},
  {code: '30003017', wording: 'Oude'},
  {code: '30012925', wording: 'Single malt'},
  {code: '30000690', wording: 'Transparent'},
  {code: '30000107', wording: 'VS'},
  {code: '30002577', wording: 'VSOP'},
  {code: '30002644', wording: 'XO'},
  {code: '30002515', wording: 'Autre'}];
