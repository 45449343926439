import {
  Component, ViewChild, OnInit, Input
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationModal, ShareModal} from '../../../../shared/components';
import {CacheService, NotificationsSvc, UserSvc} from '../../../../shared/services';
import {BarcodeGenerationSvc, GoogleAnalyticsService, ProductsSvc} from '../../../services';
import {ClassificationSvc} from '../../../../shared/services/classification.service';
import {CompanySvc} from '../../../../shared/services/company.service';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {VintageSvc} from '../../../../shared/services/vintages.service';
import {containerCodes} from '../../../models/container.constants' ;
import {Varietal} from '../../../models/varietals.constants';
import {Aoc} from '../../../models/aoc.constants';
import {GisCodes} from '../../../models/gis.constants' ;
import _ from 'lodash';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {BaseEditionUnityFormModel, DashboardConst, ProductResellerTypes} from '../../../models';
import GtinUtils from '../../../../shared/utils/gtin';
import {NetContent} from '../../../models/NetContent.model';
import {NetContentConst} from '../../../models/netContent.constants';
import {TargetMarketCountries} from '../../../models/targetMarket-country.constants';
import {environment} from '../../../../../environments/environment';
import {QrCodeCreationComponent} from '../../qr-code-creation/qr-code-creation.component';
import {CosmeticsCodeGpcConstants} from '../../../models/cosmeticsCodeGpc.constants';
import {MatDialog} from '@angular/material/dialog';
import {QrExperienceModal} from './qr-experience-modal/qr-experience-modal.component';
import {AppConstants} from '../../../../shared/models';

@Component({
  selector: 'product-view-generic',
  styleUrls: ['./product-view-generic.less'],
  templateUrl: './product-view-generic.tpl.html',
  providers: [{provide: CarouselConfig, useValue: {interval: 5000, noPause: false, showIndicators: false}}]
})
export class ProductViewGenericComponent extends BaseEditionUnityFormModel implements OnInit {

  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;

  @ViewChild('shareModal', {static: true})
  public shareModal: ShareModal;

  @ViewChild('formProduct', {static: true})
  public form;
  public multiSelectOptions: any = Varietal;
  public aocSelectOptions: any = Aoc;
  public containerCodes = containerCodes;
  public gisCodes = GisCodes;
  public shareUrl = '';
  public barcodeUrl = '';
  public constants = DashboardConst;
  public copyGtinToolTip: string = this.constants.copyGtinLabel;
  @Input() public product: any;
  @Input() public isBundle: boolean;
  public measurementUnitsList: Array<Array<{}>> = [];
  public imagesToDisplay: any[];
  public languageProductName: string;
  public NetContentConstants = NetContentConst.all;
  public isNotNew: boolean;
  public readonly environment = environment;
  @ViewChild(QrCodeCreationComponent, {static: true})
  private QrCodeCreationComponent: QrCodeCreationComponent;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public userSvc: UserSvc,
              public notificationsSvc: NotificationsSvc,
              private sanitizer: DomSanitizer,
              public dialog: MatDialog,
              public classificationSvc: ClassificationSvc,
              public googleAnalyticsService: GoogleAnalyticsService
  ) {
    super(productsSvc, _router, route, featureToggleSvc, barcodeGenerationSvc, notificationsSvc, companySvc, vintageSvc, userSvc);
  }

  public ngOnInit() {
    // subscribe to router event
    this.id = this.product.id;
    _.assign(this.productForm, this.product);
    this.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
    this.initNetContents();
    // just to display target market label
    // Saving values that should not be changed
    this.initialBrand = this.product.brand;
    this.initialName = this.product.name;
    this.productForm.productResellerType = this.product.productResellerType;
    if (_.isUndefined(this.product.codeGpc) || _.isEmpty(this.product.codeGpc)) {
      this.productForm.codeGpc = '0';
    }

    this.imagesToDisplay = _.concat(
      this.productForm.images,
      _.map(this.productForm.imagesAddedByUrl, i => ({url: i}))
    );

    this.isNotNew = this.productForm.productResellerType === ProductResellerTypes.NOT_NEW;
    this.languageProductName = _.isEmpty(this.productForm.productNames) ? 'fr' : _.get(_.first(this.productForm.productNames), 'lang', '');
    if (!this.userSvc.user.hasSeenQrExperience) this.openDialog();
  }

  public openDialog(): void {
    const config = {
      height: '590px',
      width: '840px',
      disableClose: true,
      panelClass: 'panel-class'
    };
    const dialogRef = this.dialog.open(QrExperienceModal, config);
    dialogRef.afterClosed().subscribe(result => {
      this.userSvc.updateUser({hasSeenQrExperience: true}).then((r) => {
        console.info('User updated: Has seen cosmetics QR experience');
      });
    });
  }

  public showBarcode() {
    if (this.barcodeUrl.length !== 0) {
      return true;
    }
    if (this.barcodeUrl.length === 0 && this.productForm.gtin.prefix) {
      this.barcodeUrl = `${environment.API_URL}/barcode/${this.productForm.gtin.prefix}${this.productForm.gtin.cip}${this.productForm.gtin.key}`;
      return true;
    }
    return false;
  }

  public findWordingFromCode(constants: Array<{ wording: string, code: any }>, code: any) {
    const c = _.find(constants, element => element.code.toString() === code.toString()) || {
      wording: ''
    };
    return c.wording;
  }

  public extractUrlFromImage(): string {
    if (this.imagesToDisplay && this.imagesToDisplay[0]) {
      return this.imagesToDisplay[0]['url'];
    }
    return '';
  }

  public findListWording(constants: Array<{ wording: string, code: any }>, listCode: [String]) {
    const toReturn: string [] = [];
    // @ts-ignore
    listCode.forEach((code: string) => {
      const wording = this.findWordingFromCode(constants, code);
      if (!_.isEmpty(wording)) {
        toReturn.push(' ' + wording);
      }
    });
    return toReturn;
  }

  public shareCIP() {
    this.shareUrl = `${environment.SHARE_URL_BASE}/gtin/${this.productForm.gtin.prefix}${this.productForm.gtin.cip}${this.productForm.gtin.key}`;
    this.shareModal.openModal();
  }

  public constructHeadersFromCategory(constants: Array<{
    wording: string,
    categorie: string,
    code: string
  }>, category: string): Array<{ wording: string, categorie: string, code: string }> {
    return _.filter(constants, element => element.categorie.toString() === category);
  }

  public cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  public showMpnSubSection(): boolean {
    return this.showSubSection(this.productForm.mpn);
  }

  public showMpnAndSkuSection(): boolean {
    return this.showSubSection(this.productForm.sku);
  }

  public showSubSection(variable: string) {
    variable = !_.isNil(variable) ? variable.toString().trim() : '';
    return !_.isEmpty(variable);
  }

  public showSection(variables: string[]) {
    let toReturn = false;
    for (const variable of variables) {
      if (this.showSubSection(variable)) {
        toReturn = true;
        break;
      }
    }
    return toReturn;
  }

  public onVisibilityChange(state: boolean) {
    this.productsSvc.updateProductVisibility(this.id, state)
      .subscribe(
        updatedProduct => {
          this.productForm.isPrivate = updatedProduct.isPrivate;
        },
        err => {
          this.productForm.isPrivate = !state;
          console.error(err);
        }
      );
  }

  public constructTargetMarketWording(): string {
    const targetMarket = this.productForm.targetMarket || [];
    let wording = '';
    targetMarket.forEach((item, index) => {
      if (index !== 0) {
        wording = wording + ' | ';
      }
      // @ts-ignore
      wording = wording + _.find(TargetMarketCountries, c => item === c.code)['wording-fr'];
    });
    return wording;
  }

  public updateMeasurementUnitList() {
    let hasAtLeastOneValidItem = false;
    const usedMeasurementUnitList = _.map(this.productForm.netContents, 'measurementUnitCode');
    const usedMeasurementCategoriesList = _.reduce(this.NetContentConstants, (categories, item) => {
      if (usedMeasurementUnitList.indexOf(item.code) >= 0) {
        categories[item.category] = true;
      }
      return categories;
    }, {});
    for (let i = 0; i < this.productForm.netContents.length; i++) {
      const selectedNetContent = this.productForm.netContents[i];
      const selectedCategory: any = _.find(this.NetContentConstants, {code: selectedNetContent.measurementUnitCode}) || {
        category: ''
      };
      this.measurementUnitsList[i] = this.NetContentConstants.filter((item: any) => {
        return !usedMeasurementCategoriesList[item.category] || item.category === selectedCategory.category;
      });

      if (!selectedNetContent.isEmpty()) {
        hasAtLeastOneValidItem = true;
      }
    }
  }

  public editProduct() {
    const routeName = 'dashboard/product/update';
    this._router.navigate([routeName, this.id]);
  }

  public constructNetContentWording(): string {
    const netContentObject: any = _.head(this.productForm.netContents);
    if (_.isEmpty(netContentObject)) {
      return '';
    }
    const netContentValue = netContentObject.netContent;
    if (this.isBundle) {
      return `${netContentValue} produit(s)`;
    }
    const measurementUnitValue = this.findWordingFromCode(this.NetContentConstants, netContentObject.measurementUnitCode);
    return `${netContentValue} ${measurementUnitValue}`;
  }

  public extractProductCategory(): string {
    if (!_.isEmpty(this.productForm.codeGpc) && this.productForm.codeGpc !== '99999999') {
      return this.productForm.category;
    }

    return 'Non Spécifié';
  }

  public clickCopyGtin(event, button: HTMLButtonElement) {
    this.copyGtinToolTip = this.constants.copiedGtinLabel;
    setTimeout(() => {
      button.blur();
      button.focus();
    }, 100);
    setTimeout(() => {
      this.copyGtinToolTip = this.constants.copyGtinLabel;
      button.blur();
    }, 1000);
    event.stopPropagation();
  }

  public cannotSeeBrand(productForm): boolean {
    return this.productsSvc.isProductResellerWithoutBrand(productForm.productResellerType);
  }

  public isGtin8() {
    return this.productForm.gtin.isGtin8;
  }

  public openQRcodeModal() {
    this.QrCodeCreationComponent.show();
    this.sendRealTimeEvent();
  }

  public sendRealTimeEvent() {
    const userEmail: string = this.userSvc.user.email;
    const titlePage = 'COL - Créer le QR Code augmenté GS1';
    const locationPage = `/dashboard/product/view/${this.productsSvc.product.id}`;
    const gtin = this.productsSvc.product.gtin;
    this.googleAnalyticsService.sendRealTimeEvent(titlePage, locationPage, userEmail, gtin).subscribe(() => {
      console.info('GA4 - The real-time event has been sent');
    });
  }

  public get notNewConstant() {
    return `${AppConstants.not_new} - `;
  }

  public isCosmeticProduct(): boolean {
    return CosmeticsCodeGpcConstants.indexOf(this.productForm.codeGpc) !== -1;
  }

  private initNetContents() {
    this.productForm.netContents = _.map(this.productForm.netContents, ({netContent, measurementUnitCode}) => {
      const netContentStr = `${netContent}`.replace('.', ',');
      return new NetContent(netContentStr, measurementUnitCode);
    });
    this.measurementUnitsList = new Array(this.productForm.netContents.length).fill(this.NetContentConstants);

    this.updateMeasurementUnitList();
  }
}
