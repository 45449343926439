'use strict';
import {Gtin } from '../models';

const gtinUtils = {

  /**
   * Generate EAN13 control key (same function as backend)
   */
    generateEAN13ControlKey(code: string): string {

    if (typeof code !== 'string' || code.length !== 12) {
      throw new SyntaxError('Malformed code: should be a string of 12 characters instead of ' + code.length);
    }
    // Transforming the string code in array of numbers
    const codeArray = code.split('').map(codeNumber => parseInt(codeNumber, 10));

    // Sum the impair numbers
    let impairsSum = 0;
    for (let i = 1; i < code.length; i += 2) {
      impairsSum += codeArray[i];
    }

    // Multiply the result by 3
    impairsSum *= 3;

    // Sum the pair numbers
    let pairsSum = 0;
    for (let i = 0; i < code.length; i += 2) {
      pairsSum += codeArray[i];
    }

    // Sum the pairs and impairs numbers
    const finalSum = impairsSum + pairsSum;

    // Getting the rest of the modulo10
    const controlKey = 10 - finalSum % 10;

    // If the final result is 10, the key is 0
    const finalResult = (controlKey === 10) ? 0 : controlKey;

    return finalResult.toString();
  },

  /**
   * Transform A GTIN into a GTIN Object
   */
  constructGTINObject(code: string, prefix: string): Gtin {
      if (code.length === 8) {
        return new Gtin(prefix, '', '', true, true);
      }
      if (code.length !== 13) {
        throw new SyntaxError('EAN13 code should be 13 characters');
      }
      const cip = code.substring(prefix.length, code.length - 1);
      const key = code[code.length - 1];
      return new Gtin(prefix, cip, key, true , false);
  }

};

export default gtinUtils;
