export const ProductClassificationIndex = {
  SPI: 'spi',
  VIN: 'vin',
  AUTRE: 'others',
  AUCUN: '',
  SPARKLING_WINE_GPC_CODE: '10000275',
  STILL_WINE_GPC_CODE: '10000276',
  SPIRIT_GPC_CODE: '10000263',
  LIQUEUR_WINE_GPC_CODE: '10000273',
  PREMIXED_ALCOHOLIC_GPC_CODE: '10000144',
  GENERIC_PRODUCT_GPC_CODE: '0',
  BUNDLE: 'bundle',
  NOT_NEW: 'notNew'
};

export const ProductClassifications = Object.freeze({
    GENERIC: 'GENERIC',
    VIN: 'VIN',
    SPI: 'SPI',
    BUNDLE: 'BUNDLE',
    NOT_NEW: 'NOT_NEW'
  }
);
