export const DashboardConst = {
  headers: {
    dashboard: {
      title: 'Ma liste de produits'
    }
  },
  copyGtinLabel: 'Copier le GTIN dans\nle presse-papier',
  copiedGtinLabel: 'GTIN copié',
  settings: {
    prefixes: {
      header: {
        title: 'Paramètres de création de GTIN en nombre.',
        list: {
          first: 'Choisissez le(s) préfixe(s) que vous souhaitez activer pour la génération de GTIN via l\'import de fichier.',
          second: 'Choisissez à partir de GTIN vous souhaitez codifier.',
          third: 'S\'ils sont activés, les préfixes sont consommés prioritairement de haut en bas',
          fourth: 'Ces réglages impactent uniquement la création de GTIN via l\'import de fichier.'
        }
      },
      prefixes: {
        title: 'Préfixes',
        content: 'Ci-dessous, la liste des préfixes associés à votre entreprise'
      },
      activation: {
        title: 'Activation',
        content: 'Vous pouvez activer ou désactiver un ou plusieurs préfixes. Vous devez avoir un préfixe minimum activé.'
      },
      resumeCodification: {
        title: 'Reprendre la codification',
        content: 'Dans le cas où vous souhaiteriez reprendre la codification à un GTIN précis, utilisez cette fonction. L’utilisation de cette fonction n’est pas recommandée si vous avez l’historique de vos GTIN, procédez à un import.'
      }
    }
  },
  settingsErrors: {
    cipType: 'La partie produit ne doit contenir que des chiffres',
    cipUsed: 'Attention ce GTIN est déjà utilisé, la codification reprendra au prochain GTIN disponible.',
    cipSize: 'La partie produit doit faire $$size$$ chiffres'
  },
  inputsInfo: {
    category: {
      title: 'Aide',
      content: 'Le code de la catégorie produit permet d\'identifier le type de produit. Rechercher une catégorie en tapant la désignation générique de l\article (ex : confiture, café, ...)'
    },
    gtin: {
      title: 'Aide',
      content: 'Le Global Trade Item Number (GTIN) ou code article international en français est un code identifiant toute unité commerciale ' +
        '(appelé aussi unité consommateur ou unité standard de regroupement...). \n Le GTIN est aussi appelé code EAN ou GENCOD. ' +
        'Ce code international est défini par GS1 avec une hiérarchisation et une structure unique. Lu partout dans le monde, ' +
        'il ne contient ni l’information du prix, ni le pays de fabrication. '
    },
    dietTypes: {
      COELIAC: {
        title: 'Aide',
        content: 'Indique que le produit peut être consommé en toute sécurité par une personne atteinte de la maladie "cœliaque". La maladie "coeliaque" est causée par une réaction au gladin (une protéine de gluten qui se trouve dans le blé) et des protéines similaires trouvées dans d\'autres cultures.'
      },
      DIABETIC: {
        title: 'Aide',
        content: 'Indique que le produit aide à diminuer le besoin d\'insuline ou d\'agents diabétiques oraux en ajustant l\'apport calorique et glucidique.'
      },
      DIETETIC: {
        title: 'Aide',
        content: 'Désigne un produit spécialement préparé ou transformé pour les personnes suivant un régime.'
      },
      FREE_FROM_GLUTEN: {
        title: 'Aide',
        content: 'Désigne un produit qui ne contient pas de gluten.'
      },
      HALAL: {
        title: 'Aide',
        content: 'Désigne un produit autorisé dans la religion musulmane.'
      },
      KOSHER: {
        title: 'Aide',
        content: 'Désigne un produit autorisé dans la religion juive.'
      },
      VEGAN: {
        title: 'Aide',
        content: 'Désigne un produit ne provenant pas d\'un animal et conforme au régime "vegan" (sans : viande, lait, œufs, ...)'
      },
      VEGETARIAN: {
        title: 'Aide',
        content: 'Désigne un produit ne contenant pas de viande provenant d\'un animal, poisson, …'
      },
      WITHOUT_BEEF: {
        title: 'Aide',
        content: 'Identifie un produit ne contenant pas de viande de bœuf/vache (interdite pour certaine religions de type hindouisme, le bouddhisme et le jaïnisme).'
      },
      WITHOUT_PORK: {
        title: 'Aide',
        content: 'Identifie un produit ne contenant pas de viande de porc.'
      }
    },
    structuredAddress: {
      title: 'Aide',
      content: 'Adresse de l’exploitant du secteur alimentaire qui est l’exploitant sous le nom ou la raison sociale duquel la denrée alimentaire est commercialisée ou, si ledit exploitant n’est pas établi dans l’Union, l’importateur sur le marché de l’Union'
    },
    consumerUsageInstructions: {
      title: 'Aide',
      content: 'Décrivez les conditions d\'utilisation du produit telles qu’indiquées sur l’emballage.'
    },
    ingredientStatement: {
      title: 'Aide',
      content: 'La liste des ingrédients renseignée doit correspondre exactement à la liste présente sur l’embahref =llage du produit.\n' +
        '\n' +
        'Pour répondre à la problématique de mise en évidence des substances ou produits allergènes dans la liste des ingrédients, renseignez en majuscule les substances ou produits allergènes mis en évidence dans la liste des ingrédients présente sur l’emballage de la denrée alimentaire. Cela ne dispense pas de renseigner la présence d\'allergènes dans le champ spécifique. \n' +
        '\n' +
        'La mise en majuscule ne doit être utilisée que pour les substances ou produits allergènes. Les autres informations présentes dans la liste des ingrédients ne peuvent être indiquées qu’en minuscule."\n' +
        'La liste des ingrédients commence toujours par le mot « Ingrédients :» ou « Ingrédient :».'
    },
    nutriment: {
      title: 'Aide',
      content: 'La déclaration nutritionnelle correspond à la quantité de lipides, d\'acides gras saturés, de glucides, de sucres, de protéines et de sel. La règlementation prévoit que les valeurs de la déclaration nutritionnelle sont les valeurs moyennes basées (article 31, §4 règlement 1169/2011) :\n' +
        '- sur une analyse réalisée par l’opérateur,\n' +
        '- sur les valeurs moyennes connues ou réelles des ingrédients utilisés,\n' +
        '- ou des données généralement établies et acceptées.\n' +
        'La DGCCRF travaille sur une formule pour calculer la valeur énergétique, basée sur des valeurs moyennes.' },
    ingredientStatementVcs: {
      title: 'Aide',
      content: 'Les ingrédients devront apparaître dans l’ordre décroissant de leur importance pondérale au moment de leur mise en œuvre pour tous ceux qui représentent plus de 2% du produit fini. ' +
        'Ceux qui constituent moins de 2% du produit fini, peuvent être énumérés dans un ordre différent. ' +
        'La liste complète des composés œnologiques qui peuvent figurer sur la liste des ingrédients est: l’annexe I, partie A, tableau 2, du règlement délégué (UE) 2019/934.' +
        ' Les allergènes doivent être indiqués dans la liste des ingrédients.'
    },
    contactName: {
      title: 'Aide',
      content: 'Nom ou  raison sociale de l’exploitant du secteur alimentaire qui est l’exploitant sous le nom ou la raison sociale duquel la denrée alimentaire est commercialisée ou, si ledit exploitant n’est pas établi dans l’Union, l’importateur sur le marché de l’Union.'
    },
    productVisibility: {
      title: 'Publication de mon produit',
      content: 'En publiant ce produit, vous mettez à disposition ces informations dans les registres GS1, accessibles à nos membres dans le monde.\n' +
        'NB : la publication n’envoie pas automatiquement vos informations produits à vos clients.',
      bundleContent: 'Publier ce GTIN rend visible ses informations clés aux adhérents ayant accès aux registres GS1.'
    },
    productVisibilityView: {
      title: 'Aide',
      content: 'En publiant ce produit vous mettez à disposition de vos partenaires commerciaux vos informations produits.'
    },
    productClassification: {
      title: 'Aide',
      content: 'Dans ce champ, veuillez selectionner le type de produit à codifier. À ce jour, seuls les produits vins et spririteux ont une fiche spécifique. Pour tous les autres produits veuillez selectionner "Tous produits".\n'
        + ' - Spiritueux: Comprend toutes les boissons alcoolisées distillées avec du jus de fruit, des fruits, des céréales ou du sucre fermenté(s). Ces produits ont normalement une forte teneur en alcool, d’environ 35-40 %. Comprend des produits tels que l’eau-de-vie de vin, le schnaps, le gin, le rhum, la tequila, la vodka et le whisky. Exclut des produits tels que les vins fortifiés, les liqueurs, les amers/allongeurs non alcoolisés et le vin. \n'
        + ' - Vin de liqueur:  Comprend toutes les boissons alcoolisées élaborées à partir de la fermentation de jus de raisin, mais dont la fermentation est ensuite interrompue par l’ajout d’alcool. Ces vins peuvent provenir de n’importe quel(le) pays ou région et présenter un style de production particulier.Comprend des vins doux fortifiés tels que le porto, le madère, le vin de Marsala et le vermouth.\n'
        + ' - Vin effervescent: Comprend les boissons alcoolisées élaborées à partir de la fermentation de jus de raisin et contenant du gaz carbonique.Ces produits comprennent tous les types de vin mousseux, le champagne, le vin Cava et le vin pétillant.\n'
        + ' - Vin tranquille : Comprend les boissons alcoolisées élaborées à partir de la fermentation de jus de raisin, ayant généralement une teneur en alcool maximale de 14 %. Ces produits ne sont pas gazeux, peuvent être blancs, rouges ou rosés en couleur, secs, doux, demi-secs ou moelleux en arôme et peuvent être décrits comme « millésimés » ou « non millésimés ». Exclut des produits tels que le champagne et le vin mousseux. Exclut des produits tels que les vins tranquilles et fortifiés.'
    },
    mpn: {
      title: ' Aide',
      content: 'La référence fabricant, Manufacturer Part Number (MPN) en anglais, permet d’identifier un produit à l’aide ' +
        'd’une dénomination propre au fabricant. Le marchand ne peut l\'attribuer. Il vient en complément du code GTIN.'
    },
    originGtin: {
      title: 'Aide',
      content: 'Le GTIN d’origine est le GTIN créé par le propriétaire de la marque commerciale du produit. ' +
        'Ce code est celui utilisé pour identifier et commercialiser le produit neuf lors de sa première mise sur le marché. ' +
        'Vous pouvez le retrouver sur l’emballage d’origine, ou le demander au fabricant ou au propriétaire de la marque commerciale.'
    },
    sku: {
      title: 'Aide',
      content: 'Non réglementé, ni standardisé par GS1, ce code permet de lier votre produit à votre gestion interne (à travers ' +
        'l\'unité de gestion de stocks, également appelée Stock Keeping Unit ou SKU)'
    },

    brand: {
      title: 'Aide',
      content: 'Dans ce champ veuillez renseigner votre marque commerciale. Par exemple Domaine de l\'Origine.'
    },

    subBrand: {
      title: 'Aide',
      content: 'La marque secondaire permet de renseigner les autres marques associées au produit. Cela inclut les marques ombrelles ' +
        '(ex : une marque utilisée simultanément pour un ensemble de produits hétérogènes) ou les sous-marques (ex : une ligne de produits spécifiques à la marque).',
      bundleContent: 'La marque secondaire permet de renseigner les autres marques associées aux produits. Cela inclut les marques ombrelles ' +
        '(ex : une marque utilisée simultanément pour un ensemble de produits hétérogènes) ou les sous-marques (ex : une ligne de produits spécifiques à la marque).'
    },

    alcoholDegree: {
      title: 'Aide',
      content: 'La quantité d’alcool doit respecter le format suivant :\n' +
        ' - S’exprimer avec maximum une décimale après la virgule.\n' +
        ' - Le séparateur de décimale doit être une virgule « , ».'
    },

    alcoholDegreeFood: {
      title: 'Aide',
      content: 'Pourcentage d\'alcool contenu dans l\'unité commerciale déclarée. Cette donnée est utilisée pour indiquer le titre alcoométrique volumique acquis pour les boissons titrant plus de 1,2 % d’alcool en volume, dans le cadre du règlement EU 1169.'
    },

    volume: {
      title: 'Aide',
      content: 'Le volume doit respecter le format suivant :\n' +
        ' - S’exprimer en centilitres avec maximum une décimale après la virgule.\n' +
        ' - Le séparateur de décimale doit être une virgule « , ».'
    },
    allergen: {
      AU: {
        title: 'Aide',
        content: 'Fait référence à la présence de dioxyde de soufre et de sulfites. '
      },
      AE: {
        title: 'Aide',
        content: 'Fait référence à la présence d\'œufs et de leurs dérivés dans le produit.'
      },
      AM: {
        title: 'Aide',
        content: 'Fait référence à la présence de lait et de ses dérivés dans le produit. '
      },
      AW: {
        title: 'Aide',
        content: 'Désigne la présence de céréales contenant du gluten et de leurs dérivés dans le produit. '
      },
      UN: {
        title: 'Aide',
        content: 'Fait référence à la présence de mollusques et de crustacés. '
      },
      AP: {
        title: 'Aide',
        content: 'Fait référence à la présence de cacahuètes et de leurs dérivés dans le produit'
      },
      AN: {
        title: 'Aide',
        content: 'Fait référence à la présence de noix et de leurs dérivés dans le produit, Les noix peuvent inclure des amandes, des noisettes, des noix, des noix de cajou, etc.'
      }

    },
    ageOfSpirit: {
      title: 'Aide',
      content: 'Indique le nombre précis d\'années pendant lesquelles le produit a été vieilli avant d\'être commercialisé. '
    },
    name: {
      title: 'Aide',
      content: 'Il s\'agit du nom marketing du produit connu par vos clients.\n' +
        'Votre emballage peut contenir le nom du produit dans plusieurs langues. Renseignez les dans ce cas. Vous pouvez en saisir 3.\n' +
        'Soyez le plus exhaustif possible, le nom du produit doit permettre de le dissocier d\'un autre. Indiquez les caractéristiques telles que la couleur, marque, taille...'
    },
    nameSpi: {
      title: 'Aide',
      content: 'Renseignez le libellé exhaustif de votre produit. '
    },
    nameVin: {
      title: 'Aide',
      content: 'Renseignez le libellé exhaustif de votre produit. Ex: Domaine de l\'Origine Source de Vignes 2016 Bordeaux Supérieur Rouge. '
    },
    country: {
      title: 'Aide',
      content: 'Indique le pays dans lequel le produit a été fabriqué. '
    },

    images: {
      title: `Aide`,
      content: `Disposez ici l'image qui illustrera votre produit. Veillez aux points suivants :
      - Vous détenez les droits d'utilisation de l'image
      - L'image fait 800 pixels de large au minimum mais son poids n'excède pas 10 Mo
      - La photo du produit (pas de dessin, ni d'illustration) prend au minimum 75% de la taille de l'image sur un fond blanc pur
      - Le produit sur l'image n'est pas coupé et ne contient ni produit annexe ou accessoire non inclus avec le produit, ni texte en superposition, ni logo, ni marquage ou image insérée`
    },

    description: {
      title: 'Aide',
      content: 'La description est à destination des consommateurs. Inclure uniquement les informations pertinentes et ' +
        'liées à votre produit, telles que la taille (H x L x P), la matière, la tranche d\'âge prévue, les caractéristiques ' +
        'spéciales ou d\'autres caractéristiques techniques (consommation, matériaux, précautions, normes, labels, etc). ' +
        'Inclure aussi des détails sur la plupart des attributs visuels de l\'élément, comme la forme, le motif, la texture et ' +
        'la conception. Toutes vos présentations de produits devraient suivre trois principes : \n' +
        '- Soyez honnête et factuel \n ' +
        '- Décrivez clairement et avec précision votre message \n ' +
        '- Incluez des informations pertinentes et complètes sur vos produits avec des attributs'
    },

    webLink: {
      title: 'Aide',
      content: 'Renseigner dans ce champ tout contenu web relatif à votre produit. Il peut s\'agir d\'une page de votre site, ' +
        'd\'une vidéo sur Youtube, de la page Facebook de la marque... Ce lien sera accessible lors du scan de votre code-barres sur l\'application CodeOnline',
      url: 'https://play.google.com/store/apps/details?id=ch.rfidcenter.codeonline&hl=fr'
    },

    level: {
      title: 'Aide',
      content: 'L’indicateur de « Niveau » mesure le degré de complétude de votre fiche produit. Plus vous ajouter d’informations à vos fiches produits, plus il vous sera facile de les retrouver, classer ou trier à l’avenir. Le « Niveau » augmente lorsque vous ajoutez plus de contenu à votre fiche.'
    },

    // Packing specific
    packingName: {
      title: `Aide`,
      content: `On indique ici le nom qu’utilisent habituellement vos clients pour se référer à votre unité logistique.
      Un conseil : soyez le plus exhaustif possible ! Le nom de l’unité logistique doit vous permettre à l'avenir de la dissocier d’une autre.`
    },

    packingGtin: {
      title: `Aide`,
      content: `Le Global Trade Item Number (GTIN) ou code article international en français est un code identifiant toute unité commerciale (unité consommateur et également unité logistique).
      Le GTIN est aussi appelé code EAN ou GENCOD. Ce code international est défini par GS1 avec une hiérarchisation et une structure unique. Lu partout dans le monde, il ne contient ni l’information du prix, ni le pays de fabrication.`
    },
    packingDescription: {
      title: `Aide`,
      content: `La description est à destination de vos clients.
      Inclure uniquement les informations pertinentes et liées à votre unité logistique telles que la taille (H x L x P) et son contenu.`
    },

    targetMarket: {
      title: 'Marché cible',
      content: 'Le pays où le produit est destiné à être vendu.'
    },
    bottlerName: {
      title: 'Nom Embouteilleur',
      content: 'le nom de l\'embouteilleur.'
    },
    sugarContent: {
      title: 'Aide ',
      content: 'Cette mention, obligatoire pour les vins effervescents (mousseux), est facultative mais réglementée pour les autres vins. Selon la teneur en sucre du vin mousseux, peuvent être utilisés les termes suivants : brut nature, dosage zéro, brut, extra-sec, sec et doux. '
    },
    bottlerAddress: {
      title: 'Adresse Embouteilleur',
      content: 'l\'adresse de l\'embouteilleur.'
    },
    aoc: {
      title: 'Aide',
      content: 'Ces indications géographiques sont celles publiées par l\'INAO à la date du 28 mai 2018. En cas de valeur manquante, merci de nous contacter.'
    },
    netContents: {
      title: 'Aide',
      content: `Quantité (poids net, volume net, nombre de pièces, durée, capacité, …) de produit contenue dans l'unité commerciale déclarée (exemples : 250 grammes, 1.5 litres, 10 pièces, …). Cette quantité est celle indiquée sur l'étiquette apposée sur l'unité commerciale, ceci dans le respect des réglementations en vigueur applicables au produit.
      Le poids net correspond au poids du produit sans les conditionnements associés.
      Cette donnée est utilisée pour indiquer la quantité nette de denrée alimentaire dans le cadre du règlement EU 1169. La valeur et l'unité de mesure transmise doivent correspondre au contenu net présent sur l'emballage`
    },
    variantDescription: {
      title: 'Aide',
      content: 'Texte libre utilisé pour identifier la variante du produit. Les variantes sont les caractéristiques (de type parfum, goût, variété, couleur, …) qui distinguent des produits qui ont le même nom de marque, la même marque prénom et le même nom fonctionnel.'
    },
    regulatedProductName: {
      title: 'Aide',
      content: 'La dénomination de vente d\'une denrée alimentaire est celle fixée par la réglementation en vigueur en matière de répression des fraudes ou, à défaut, par d\'autres réglementations ou par les usages commerciaux. En l\'absence de réglementations ou d\'usages, cette dénomination doit consister en une description de la denrée alimentaire et, si nécessaire, de son utilisation. La description doit être suffisamment précise pour permettre à l\'acheteur d\'en connaître la nature réelle et de la distinguer des produits avec lesquels elle pourrait être confondue'
    },
    consumerStorageInstructions: {
      title: 'Aide',
      content: 'Renseignez les conditions particulières de conservation telles qu’indiquées sur l’emballage.'
    },
    preparationInstructions: {
      title: 'Aide',
      content: 'Le mode d’emploi d’une denrée alimentaire est indiqué de façon à permettre un usage approprié de cette denrée.'
    },
    packagingMarkedLabelAccreditationCode: {
      title: 'Aide',
      content: 'Indication des sigles/accréditations/labels (éthiques, environnementaux,...) présents sur le conditionnement de l\'unité commerciale.'
    },
    compulsoryAdditiveLabelInformation: {
      title: 'Aide',
      content: 'Mentions obligatoires complémentaires non incluses dans les mentions précédentes qui doivent être affichées sur les sites de vente à distance.'
    },
    countryOfOrigin: {
      title: 'Aide',
      content: 'Indication du pays dont l\'unité commerciale est réputée issue.\n' +
        'Sont à ce titre, réputées originaires d\'un pays, les marchandises entièrement obtenues dans ce pays ou qui ont subi la dernière transformation substantielle, économiquement justifiée, réalisée dans une entreprise équipée à cet effet et ayant abouti à un produit nouveau ou représentant un stade de fabrication important.\n' +
        'S\'il existe plusieurs pays d\'origine pour l\'unité commerciale, tous les pays d\'origine doivent être indiqués.\n' +
        'Correspond au pays de fabrication / Made in.'
    },
    drainedWeight: {
      title: 'Aide',
      content: 'Poids net égoutté de l\'unité commerciale déclarée. Cela correspond au poids net après égouttage du liquide utilisé pour son conditionnement, ceci dans le respect des réglementations en vigueur applicables au produit.\n' +
        'Cette donnée est utilisée pour indiquer la quantité nette de denrée alimentaire dans le cadre du règlement EU 1169 pour les produits avec liquide de recouvrement. La valeur et l\'unité de mesure transmise doivent correspondre au poids net égoutté présent sur l\'emballage.'
    },
    targetMarketArray: {
      title: ' ',
      content: 'Il s\'agit du pays dans lequel le produit est disponible aux acheteurs. Vous pouvez renseigner jusqu\'à 10 pays.'
    }
  },

  unityTypes: {
    consumer: 'Unité consommateur'
  },

  // Creation/Edition form
  foodCreateForm: {
    placeholders: {
      structuredAddress: {
        streetAddress: 'ex: 21 boulevard Haussmann',
        postalCode: 'ex: 75010',
        countryCode: 'ex: France',
        city: 'ex: Paris'
      },
      contactName: 'ex: Dupont Lebois',
      servingSizeDescription: 'ex: 1 cuillère',
      optionalQuantityContained: ' ex: 1,5',
      quantityContained: ' ex: 1,5',
      dailyValueIntakeReference: ' ex: 1,5',
      variantDescription: '',
      regulatedProductName: '',
      alcoholDegreeFood: 'ex: 0,4',
      contentDescription: 'ex: Produit emballé Packshot'
    }
  },
  createForm: {
    introduction: 'Plus vos fiches seront complètes et plus vous gagnerez du temps dans la gestion de vos produits.',
    helpers: {
      inputTextInfoLength: 'caractères disponibles',
      prefix: 'Préfixe(s) entreprise',
      cip: 'Plage d\'attribution',
      key: 'Clé',
      webLinkInfoText: 'Lien (ex: vers fiche produit, vidéo, page Facebook, etc...)',
      images: {
        valorize: `Valorisez votre produit en ajoutant une à trois images !`,
        bundleExplaination: 'Utilisez une à trois images où tous les produits composant l’assortiment sont visibles.',
        format: `Format attendu: JPG, PNG (10 Mo maximum).`,
        limit: `Limite: 3 images`,
        limitFood: `Limite: une image`,
        input: `Joindre votre fichier depuis votre navigateur`,
        urlText: `ou vous possédez un lien pour accéder à l'image`,
        improveText: 'Améliorez la visibilité de votre produit aux consommateurs en ajoutant jusqu\'à 3 images.'
      },
      textInsideContainerUploadImage: {
        format: `Formats autorisés: JPG, PNG (10 Mo maximum)`,
        limit: '3 images maximum'
      }
    },
    placeholders: {
      foodProduct: {
        brand: 'ex: Bon Papa',
        subBrand: 'ex: Anbros',
        name: 'ex: Confiture Bon Papa Framboises 370g',
        regulatedProductName: 'ex: Confiture de framboises'
      },
      brand: 'ex: Domaine de l\'Origine',
      bundleBrand: 'Domaine de l\'origine / Les miels des apiculteurs / ...',
      subBrand: 'ex: Azur retail',
      nameVin: 'ex: Domaine de l\'Origine - Source de Vignes 2016 -  Bordeaux Superieur Rouge ',
      nameSpi: 'Renseignez le libellé exhaustif de votre produit. ',
      name: 'ex: Pull vert émeraude col en V manche longue - taille M',
      bundleName: 'Ex : Assortiments de cosmétiques',
      webLink: 'http://www.monsite.com/produits/mafiche_produit.html',
      alcoholDegree: 'ex: 12,5',
      volume: 'ex: 75',
      vintageName: 'ex: Source de vignes',
      grapes: 'Ajoutez jusqu\'à 15 cépages',
      netContents: 'ex: 10,50',
      targetMarket: ''
    },
    errors: {
      cipSize: 'La partie produit doit faire $$size$$ chiffres',
      cipType: 'La partie produit ne doit contenir que des chiffres',
      cipUsed: 'Ce code est déjà utilisé pour un produit actif, veuillez le changer',
      noGtinAvailable: `Vous avez utilisé toute la plage de codification de votre préfixe.
      Afin de créer votre GTIN, sélectionnez un autre préfixe ou commandez-en un nouveau sur votre espace client.`,
      alcoholDegreePattern: 'La quantité d’alcool doit respecter le format suivant :' +
        ' - S’exprimer avec maximum une décimale après la virgule.' +
        ' - Le séparateur de décimale doit être une virgule « , ».',
      webLinkInvalid: 'Merci de renseigner une URL valide'
    },
    infoPopIn: {
      title: 'ATTENTION',
      message: `<b></b>
    Vous n'avez pas cliqué sur "Terminer et afficher la fiche produit" pour finaliser la création de votre GTIN.

    Êtes-vous sûr de vouloir quitter ?`
    }
  },

  noProProduct: {
    title: `Bienvenue sur CodeOnline !`,
    subtitle: 'CodeOnline vous permet de gérer et d\'exporter tous vos codes produits \n',
    body: {
      firstText: 'Si vous avez créé des codes produits en dehors de CodeOnline, vous devez les importer avant ' +
        'toute nouvelle création en utilisant le lien <span class="import">"Importer une liste de produits"</span>.' +
        ' <span class="makeBold">Cette étape est impérative</span> afin d\'éviter tout doublon\n' +
        'de codes produit.',
      secondText: 'Sinon cliquez sur "Créer un nouveau code produit"'
    },
    actions: {
      createProduct: `Créer un nouveau code produit`,
      importProducts: `Importer une liste de produits`
    }
  },

  codesLimit: {
    almostFull: `Attention ! Vous arrivez bientôt à la limite de vos codes disponibles.
    Demandez un préfixe supplémentaire au 01 40 22 18 00 (Service Client)`,
    full: `Vous n’avez plus de code GTIN disponible.
    Demandez un préfixe supplémentaire au 01 40 22 18 00 (Service Client)`
  },

  searchEmpty: 'Oups, aucune de vos fiches produit ne correspond à cette recherche.',

  searchLimit: {
    value: 100,
    warningReached: `Oups, vous avez atteint la limite d'affichage d'une recherche !`
  },
  vbgBadge: {
    completed: {
      title_assortment: 'Votre carte d’identité assortiment est complète.',
      title_product: 'Votre carte d’identité produit est complète.',
      body: 'Bravo ! Les adhérents de GS1 dans le monde peuvent accéder aux informations clés de votre produit.'
    },
    notCompleted: {
      title: 'Vous n\'avez pas ajouté d\'image ?',
      body: `Nous vous recommandons d'en ajouter une afin que les adhérents GS1 dans le monde puissent y accéder.`
    }
  },
  sectoralBadge: {
    completed: {
      title: 'Vos informations réglementaires sont complètes.',
      body: 'Bravo ! Il ne vous reste plus qu\'à générer un QR Code à apposer sur votre produit, passez à l\'étape suivante ! <br/><a href="https://www.gs1.fr/identifier-vos-produits-du-vin-spiritueux" target="_blank">En savoir plus.</a>'
    },
    notCompleted: {
      title: 'Obtenez votre QR Code augmenté GS1',
      body: `En renseignant les attributs réglementaires de votre produit, vous pourrez générer un QR Code augmenté GS1 vous permettant d'être en conformité avec la réglementation ! <a href="https://www.gs1.fr/identifier-vos-produits-du-vin-spiritueux" target="_blank">En savoir plus.</a>`
    }
  },
  oldSectoralBadge: {
    completed: {
      title: 'Les informations sectorielles de votre produit sont complètes.',
      body: 'Bravo ! Les applications consommateurs et partenaires commerciaux de votre filière peuvent accéder aux informations clés de votre packaging produit.'
    },
    notCompleted: {
      title: 'Les informations sectorielles de votre produit sont manquantes.',
      body: `Complétez les informations clés de votre packaging produit afin que les applications consommateurs et partenaires commerciaux de votre filière puissent y accéder.`
    }
  },
  privatePanel: {
    title: 'Publiez votre produit !',
    firstText: 'Lorsque votre produit n’est pas publié, vous pouvez utiliser votre code GTIN.',
    secondText: 'Attention, dans le cas où votre partenaire utilise un service GS1 pour contrôler les GTIN, cela pourrait avoir des impacts sur le référencement et la visibilité de vos produits.'
  },
  // Code modification form
  editForm: {
    introduction: `Plus vos fiches seront complètes et plus vous gagnerez du temps dans la gestion de vos unités logistiques.
    Pour compléter votre fiche, 2 champs seulement sont obligatoires. Nous vous encourageons à apporter un maximum de détails pour différencier plus facilement vos unités logistiques à l'avenir`,
    helpers: {
      day: 'JJ',
      month: 'MM',
      year: 'DDDD'
    },
    errors: {
      webLinkInvalid: 'Merci de renseigner une URL valide'
    },
    warnings: {
      brand: {
        text: 'Le changement de la marque principale qui apparaît sur un produit existant doit entrainer la création d’un nouveau GTIN.',
        bundleText: 'Un changement dans la composition de l\'assortiment doit entrainer la création d\'un nouveau GTIN.',
        notNewText : 'Le changement de la marque principale qui apparaît sur un produit non neuf existant doit entrainer la création d’un nouveau GTIN.',
        linkText: 'Plus de détails sur les règles de codification',
        targetLink: 'http://www.gs1.org/1/gtinrules/fr/',
        bundleTargetLink: 'https://www.gs1.org/1/gtinrules/fr/rule/271/pre-defined-assortment'
      },
      netContents: {
        text: 'Le changement du contenu net sur un produit existant doit entrainer la création d\'un nouveau GTIN.',
        notNewText: 'Le changement du contenu net sur un produit non neuf existant doit entrainer la création d\'un nouveau GTIN.',
        bundleText: 'Un changement dans la composition de l\'assortiment doit entrainer la création d\'un nouveau GTIN.',
        linkText: 'Plus de détails sur les règles de codification',
        targetLink: 'https://www.gs1.org/1/gtinrules/fr/rule/266/contenu-net-declare',
        bundleTargetLink: 'https://www.gs1.org/1/gtinrules/fr/rule/271/pre-defined-assortment'
      },
      name: {
        text: 'Un changement majeur du nom du produit doit entrainer la création d\'un nouveau GTIN. Se référer au lien ci-dessous pour les exemples.',
        notNewText: 'Un changement majeur du nom du produit non neuf doit entrainer la création d\'un nouveau GTIN. Se référer au lien ci-dessous pour les exemples.',
        bundleText: 'Un changement dans la composition de l\'assortiment doit entrainer la création d\'un nouveau GTIN.',
        linkText: 'Plus de détais sur les règles de codification',
        targetLink: 'https://www.gs1.org/1/gtinrules/fr/rule/264/lancement-d%C3%82%E2%80%99un-nouveau-produit',
        bundleTargetLink: 'https://www.gs1.org/1/gtinrules/fr/rule/271/pre-defined-assortment'
      },
      packingUnityEditionDisabled: {
        title: 'Vos unités logistiques ne peuvent plus être modifiées',
        content: `Votre application évolue pour vous garantir une qualité de données toujours plus proche des standards
        GS1. Désormais, et afin de simplifier l’utilisation de l’application, nous ne ferons plus la distinction entre
        unités consommateurs et unités logistiques. Vous n’aurez plus qu’un seul format à gérer pour tous vos codes.
        En attendant et à titre temporaire, les unités logistiques ne sont plus modifiables. Cette fonctionnalité
        arrivera bientôt, merci pour votre patience. :)`
      }
    }
  },

  // Code Generation form
  generateForm: {
    categoriesLibelles: {
      img: 'FORMAT IMAGE [PNG]',
      vecto: 'FORMAT VECTORIEL [EPS & SVG]',
      board: 'EN PLANCHE (pdf)'
    },
    wording: {
      description: 'Gérez vous-même vos impressions.',
      help: {
        heading: 'Et si vous visiez 100% de lisibilité ?',
        body: 'Un code mal imprimé risque de ne pas être lu ! ' +
          'Envoyez-nous vos échantillons pour contrôler la qualité de vos codes-barres et éviter ainsi les incidents de lecture.',
        link: 'https://www.gs1.fr/formations-conseil-qualite/gs1-qualicode'
      }
    }
  },

  disclaimer: {
    title: 'À noter',
    content: 'La qualité de votre impression détermine la validité de votre code-barres. GS1 France décline toute ' +
      'responsabilité à ce titre.',
    checkBoxText: 'J\'ai bien compris'
  },

  classificationModal: {
    infoMessage: "Nouveau ! renseignez les informations spécifiques pour vos produits vins & spiritueux. <br/>(<a target='_blanc' href='https://www.gs1.fr/Nos-filieres/Vin-et-spiritueux/Les-actualites/L-initiative-collective-de-cave-digitale-des-vins-et-spiritueux-francais-presentee-aux-Ateliers-du-vin'>+ d'infos</a>)",
    allProducts: 'Tous produits',
    productsVcs: 'Vins et<br/> Spiritueux',
    description: 'Choisir le modèle',
    title: 'Créer un code'

  },

  // Contact Us Popover
  contactUs: {
    title: 'Besoin d\'aide ?\n Une question ?',
    description: 'Notre service client est disponible du lundi au jeudi de 8h30 à 18h00 et le vendredi de 8h30 à 16h30',
    phone: '01 40 22 18 00',
    email: 'serviceclient@gs1fr.org'
  },

  deleteProduct: {
    title: 'Attention',
    message: `<b class="blue">Toute suppression est définitive !</b>
    Ne supprimez une fiche que si elle correspond à un test ou une erreur de saisie.

    Etes-vous sûr de vouloir poursuivre?`
  },

  export: {
    modal: {
      buttons: {
        download: `Télécharger`
      },
      forms: {
        content: {
          radioButtons: {
            all: `Tous mes produits.`,
            selected: `Seulement les produits sélectionnés.`
          },
          title: `Que souhaitez-vous exporter ?`,
          information: "Au vu du nombre important de produits dans votre liste, l'export au format Excel peut nécessiter quelques minutes. Auquel cas il vous sera envoyé par email au lieu d'un téléchargement immédiat."
        },
        format: {
          radioButtons: {
            excel: `Format .xlsx (recommandé).`,
            excelWithoutRecommended: `Format .xlsx.`,
            csvFormatExcel: `Format .csv compatible Excel.`,
            others: `Format .csv compatible autres tableurs (Numbers, LibreOffice, ...).`
          },
          title: `Sous quel format souhaitez-vous exporter ?`
        }
      },
      help: {
        title: `Informations sur les formats`,
        content: `Pour éviter les problèmes liés aux caractères spéciaux, nous vous proposons deux formats .csv différents :

        - un spécifique à Microsoft Excel (encodage en Windows-1252);
        - un universel (encodage UTF-8) qui est reconnu par la majorité des autres tableurs.

        Un fichier CSV (en anglais, Comma Separated Values) est un fichier informatique ouvert, universel, permettant de voir vos données dans une variété d'applications, comme Microsoft Excel, Numbers, le tableur Google, LibreOffice ou autres.`
      },
      subTitle: `Partagez vos informations produits avec vos partenaires commerciaux, sauvegardez votre catalogue...`,
      title: `Exporter vos produits`
    }
  },

  import: {
    newWay: {
      infoPopIn: {
        title: 'Attention',
        message: `<b></b>
    Le fichier analysé n'a pas encore été importé, vous n’êtes pas allez au bout du processus.

    Êtes vous sur de vouloir quitté le parcours d'import ?`
      },
      breadcrumb: {
        firstStep: `Télécharger`,
        secondStep: `Analyser vos`
      },
      file: {
        title: {
          generic: `Téléchargez le modèle`,
          vcs: 'Choisissez le modèle en fonction du type de produit',
          allProducts: 'Choississez le type de produits'
        },
        name: `Modèle_GS1.XLSX`,
        button: {
          download: 'Télécharger le modèle',
          search: 'Rechercher une catégorie',
          create: 'Créer un GTIN'
        }
      },
      updateProducts: {
        firstSection: {
          title: 'Exportez les produits que vous souhaitez modifier'
        },
        secondSection: {
          title: 'Modifiez vos produits',
          firstText: 'Exportez les produits que vous souhaitez modifier et faites vos modifications directement dans le fichier.',
          secondText: 'Si le champ "GTIN" est vide, de nouveaux GTIN seront créés et vos produits ne seront pas mis à jour.',
          thirdText: 'Dans votre fichier d’import si vous laissez un champ vide, l’ancienne valeur correspondante sera effacée.'
        }
      },
      text: {
        title: `Remplissez le modèle.`,
        importMyGtins: {
          firstText: `Veillez à ce que vos GTIN soient au format numérique sans décimale.`,
          secondText: `Veuillez respecter les champs obligatoires.`
        },
        createGtins: {
          genericProduct: {
            firstText: 'Identifiez le code de la catégorie de vos produits',
            firstTextBundle : 'Veuillez sélectionner la catégorie des produits de l’assortiment.\n' +
              'Si les produits appartiennent à différentes catégories, choisissez « Assortiment inter Segments ».',
            firstTextNotNew : 'Veuillez sélectionner la catégorie des produit non neufs',
            secondText: 'Une fois que vous avez trouvé la bonne catégorie, copier le code en cliquant sur "Copier". Ensuite coller le code dans la colonne  B du modèle à télécharger ci-dessous.',
            thirdText: 'Complétez le reste du modèle avec vos informations produits en respectant les instructions annotées dans le fichier.',
            thirdTextForImport: 'Complétez le reste du modèle avec vos informations produits en respectant les instructions annotées dans le fichier. Veillez à renseigner la colonne "GTIN" avec les GTIN que vous souhaitez importer.',
            fourthText: 'Un fois votre fichier complété, cliquez sur "Suivant" en bas de cette page pour analyser et importer le fichier.'
          },
          firstText: `Veillez à laisser la colonne "GTIN" du modèle vide. Nous créons automatiquement vos GTIN pour vous`,
          firstTextForImport: `Veillez à renseigner la colonne "GTIN" avec les GTIN que vous souhaitez importer.`,
          secondText: {
            generic: `Les deux champs obligatoires sont le "Nom produit" et la "Marque principale".`,
            vcs: `Les attributs des modèles Vins et Spiritueux fonctionnent avec des codes. Le fichier est construit de manière à ce que les codes associés à une valeur soit automatiquement générés lorsque vous sélectionnez un élément de la liste.`,
            vcsExample: `Par exemple: dans la colonne "Pays d'origine", si vous choisissez "France", alors vous verrez la la valeur "250" apparaitre dans la colonne "Code Pays d'origine", c'est cette valeur qui sera prise en compte lors de l'import du fichier.`
          },
          thirdText: `Vous avez à disposition toutes les valeurs possibles et les codes correspondants dans les feuilles annexes du fichier.`,
          fourthText: `Les champs obligatoires sont en rouge.`
        }
      }
    },
    template: {
      title: `Téléchargement`,
      breadcrumb: `Télécharger le modèle`,
      file: {
        title: `Le modèle à compléter`,
        title2: `Choisissez un modèle à télécharger`,
        csv: `Format du modèle : .csv (reconnu par Excel, Numbers, OpenOffice).`,
        name: `Modele_GS1.csv`
      },
      files: {
        generic: {
          name: 'Produits génériques'
        },
        wine: {
          name: 'Vins'
        },
        spirit: {
          name: 'Spiritueux'
        }
      }
      ,
      button: `Télécharger`,
      faq: {
        title: `En savoir plus`,
        items: [
          {
            question: `Pourquoi un modèle pour l'import ?`,
            answer: `Un modèle différent de celui proposé ne sera pas reconnu par CodeOnline. Le modèle que nous vous proposons vous permet d’importer vos produits de manière cohérente dans l’application afin de garantir la qualité des données. De ce fait, il est obligatoire de bien respecter son format, l’ordre et le nom des en-têtes de colonnes que nous proposons.`
          },
          {
            question: `Qu'est-ce qu'un fichier .csv ?`,
            answer: `Un fichier CSV (en anglais, Comma Separated Values) est un format de fichier informatique ouvert, universel, permettant de voir vos données dans une variété d'applications, comme Microsoft Excel, Numbers, le tableur Google Sheets, LibreOffice ou autres.`
          },
          {
            question: `Quels logiciels peuvent lire un fichier .csv ?`,
            answer: `La plupart des tableurs du marché peuvent lire un fichier .csv, tous systèmes d’exploitation confondus. Vous pouvez notamment utiliser Microsoft Excel, Numbers, le tableur Google Sheets, LibreOffice, OpenOffice. Vous pouvez aussi ouvrir ou éditer un fichier .csv avec un simple bloc note, les points virgules feront office de colonnes. Ce format est généralement utilisé comme format universel d’export depuis des outils informatiques (ERP, …).`
          }
        ]
      },
      guidance: {
        title: 'Comment remplir un modèle ?',
        items: [
          {
            subtitle: 'Nous créons automatiquement vos GTIN',
            contain: 'Veuillez à laisser la colonne « GTIN » du modèle vide'
          },
          {
            subtitle: 'Respectez les champs obligatoires',
            contain: 'Les deux champs obligatoires sont le "Nom produit" et la Marque principale"'
          },
          {
            subtitle: 'Veuillez à respecter l\'ordre des colonnes du modèle.',
            contain: 'Ne modifiez pas l’ordre des colonnes dans le modèle'
          }
        ]
      }
    },
    fill: {
      title: `Remplir le modèle`,
      breadcrumb: `Remplir le modèle`,
      withGtin: {
        title: `J'ai déjà créé mes GTIN`,
        intro: {
          title: `Dans le cas où vous auriez déjà créé vos GTIN :`,
          points: [
            `Veillez à ce que vos GTIN soient au <b>format numérique sans décimale</b> (voir animation ci-dessous).`,
            `Les deux champs obligatoires sont le <b>"Nom produit"</b> et la <b>"Marque principale"</b>.`,
            `Veillez à respecter <b>l'ordre des colonnes</b> du modèle.`
          ]
        },
        imageWarning: `Pour les utilisateurs d’Excel, les GTIN saisis dans le fichier sont identifiés par défaut en notation scientifique (3,2E+12). Après avoir saisi tous vos GTIN, il suffit de sélectionner la colonne A et de la passer au format « Nombre » puis d’enlever les décimales :`,
        gtin: {
          description: `<b>Remplissez simplement la colonne A</b> avec vos GTIN existants. Veillez à ce que les GTIN soient bien sur la même ligne que les produits correspondants.`,
          tips: {
            title: `Astuces :`,
            values: [
              `Chaque produit a un GTIN unique, veillez à ce que vos GTIN importés ne correspondent pas à un autre produit déjà existant.`,
              `Les préfixes entreprises de vos GTIN doivent vous appartenir.`,
              `La clé de contrôle de vos GTIN doit être valide.`
            ]
          }
        }
      },
      withoutGtin: {
        title: `Je n'ai pas créé mes GTIN`,
        intro: {
          title: `Dans le cas où vous n'auriez pas créé vos GTIN : `,
          points: [
            `Nous créons automatiquement vos GTIN pour vous : <b>veillez à laisser la colonne "GTIN" du modèle vide.</b>`,
            `Les deux champs obligatoires sont le <b>"Nom produit"</b> et la <b>"Marque principale"</b>.`,
            `Veillez à respecter <b>l'ordre des colonnes</b> du modèle.`
          ]
        },
        imageLegend: `Un exemple de modèle correctement rempli :`,
        gtin: {
          description: `<b>Vous ne connaissez pas les GTIN</b> associés à vos produits, <b>ne remplissez pas la colonne A</b> mais veillez à bien garder l’en-tête de cette colonne. Lors de votre import, <b>vos GTIN seront créés automatiquement</b>. Vous pourrez ensuite les visualiser sur l’écran principal de l’application.`,
          tips: {
            title: `Astuce :`,
            values: [
              `Veuillez-vous assurer que vous avez suffisamment de GTIN disponibles (pour votre entreprise) par rapport au nombre de produits à importer. Si ce n’est pas le cas, vous pouvez contacter le Service Client GS1 au 01 40 22 18 00.`
            ]
          }
        }
      },
      updateProducts: {
        title: `Je mets à jour mes produits`,
        intro: {
          title: `Pour mettre à jour vos produits :`,
          points: [
            `Exportez votre base produit.`,
            `Faites vos modifications directement dans le fichier.`,
            `Importez ce fichier, vos produits seront mis à jour.`
          ]
        },
        warnings: [
          `Si le champ "GTIN" est vide, de nouveaux GTIN seront créés et vos produits ne seront pas mis à jour.`,
          `Dans votre fichier d’import si vous laissez un champ vide, l’ancienne valeur correspondante sera effacée.`
        ],
        gtin: {
          description: `<b>Vous connaissez les GTIN</b> associés à vos produits, <b>remplissez simplement la colonne A</b> avec vos GTIN existants. Veillez à ce que les GTIN soient bien sur la même ligne que les produits correspondants.`,
          tips: {
            title: ``,
            values: []
          }
        }
      },
      common: {
        gtin: {
          title: `GTIN [colonne A]`
        },
        productName: {
          title: `Nom produit [colonne B]`,
          description: `<b>Le remplissage du champ « Nom produit » est obligatoire</b> pour tous vos produits.`,
          tips: {
            title: `Astuces :`,
            values: [
              `Ce champ est très important car il permet d’identifier précisément chacun de vos produits, c’est pourquoi le remplissage de ce champ est obligatoire.`,
              `Le champ « Nom produit » doit être inférieur ou égal à 200 caractères.`
            ]
          }
        },
        productBrand: {
          title: `Marque principale [colonne C]`,
          description: `<b>Le remplissage du champ « Marque principale » est obligatoire</b> pour tous vos produits.`,
          tips: {
            title: `Astuces :`,
            values: [
              `Ce champ est très important car il permet d’identifier précisément la marque commerciale de chacun de vos produits, c’est pourquoi le remplissage de ce champ est obligatoire.`,
              `Le champ « Nom produit » doit être inférieur ou égal à 70 caractères.`
            ]
          }
        },
        productSubBrand: {
          title: `Marque secondaire [colonne D]`,
          description: `Le champ « Marque secondaire » doit être inférieur ou égal à 70 caractères.`
        },
        productImageUrl: {
          title: `URL Image 1 / URL Image 2 / URL Image 3 [colonne E/F/G]`,
          description: [
            `Les champs « URL Image » vous permettent de renseigner les urls des images de vos produits. Il suffit de saisir les urls des images qui sont hébergées sur votre site web/ecommerce. Nous acceptons uniquement les formats JPEG et PNG. En conséquence vos urls doivent obligatoirement finir par « .jpeg », « .jpg » ou « .png ».`,
            `Vous devez remplir une seule URL par colonne, et les urls doivent être uniques, la limite étant de trois urls images, une pour chaque colonne E, F et G.`,
            `Les urls doivent être valides (Exemple : http://xxxx.fr/image1.jpeg).`
          ]
        },
        productDescription: {
          title: `Description [colonne H]`,
          description: `Le champ « Description » doit être inférieur ou égal à 2000 caractères.`
        },
        productWebLink: {
          title: `Lien / URL [colonne I]`,
          description: [
            `Le champ « Lien /URL» vous permet de renseigner l’url associée à votre produit sur votre site web/ecommerce.`,
            `Cette url doit être valide (Exemple : http://xxxx.fr/MonProduit).`
          ]
        },
        productMPN: {
          title: `MPN [colonne J]`,
          description: `Le champ « MPN » doit être inférieur ou égal à 70 caractères.`
        },
        productSKU: {
          title: `SKU [colonne K]`,
          description: `Le champ « SKU » doit être inférieur ou égal à 70 caractères.`
        },
        more: `Explications des champs demandés`
      }
    },
    upload: {
      title: `Importer vos produits`,
      breadcrumb: `Importer vos produits`,
      file: {
        title: `Votre fichier`,
        csv: `Format attendu : .csv (comma-separated values). Taille maximum : 30Mo`,
        instruction: `Joindre votre fichier depuis votre navigateur`,
        placeholder: `...`,
        button: `Joindre`
      }
    },
    status: {
      inprogress: {
        title: `Transfert des données`,
        text: `L'import de vos produits est en cours.`,
        textAssortment: `L'import de vos assortiments est en cours.`
      },
      done: {
        title: `Transfert terminé`,
        products: {
          name: ` GTIN`,
          ok: `ont bien été importés dans votre liste.`,
          error: `n'ont pas pu être importés.`
        },
        product: {
          name: ` GTIN`,
          ok: `a bien été importé dans votre liste.`,
          error: `n'a pas pu être importé.`
        },
        errorreport: {
          button: `Télécharger le rapport d'erreur`,
          link: `Comment corriger mes erreurs ?`,
          text: `Téléchargez le rapport d'erreur pour identifier et comprendre les erreurs d'import. Relancez ensuite l'import de votre fichier corrigé pour que vos informations produits soient mis à jour.`
        },
        successReport: {
          button: `Exporter les GTIN`
        }
      },
      error: {
        title: `Transfert en erreur`,
        message: ` Oops.. Une erreur technique est survenue, essayez de relancer votre import. Si le problème persiste contactez-nous au 01 40 22 18 00.`
      }

    },
    button: {
      next: `Suivant`,
      submitAnalysedProduct: `Importer`,
      backToDashboard: `Retourner à la liste`,
      submit: `Démarrer l'import`,
      back: `Retour`,
      cancel: `Annuler`,
      next2: `Suivant`
    }
  },

  secondStepCreation: {
    VCS: {
      title: 'Vins & Spiritueux',
      subTitle: 'Allez plus loin avec CodeOnline Vins & Spiritueux',
      advantagesIntro: 'Valorisez la singularité de vos produits grâce à des fonctionnalités dédiées au secteur des vins & spiritueux :',
      advantages: [
        'Gérez facilement les 15 informations clés de vos étiquettes.',
        'Rendez accessibles vos données aux applications consommateurs.',
        'Gagnez du temps dans les échanges avec vos partenaires commerciaux.'
      ],
      advantagesParagraphs: [
        'Valorisez vos produits en complétant des informations supplémentaires dédiées au secteur des vins et spiritueux.',
        'Rendez ainsi accessibles les 15 informations clés de vos étiquettes.'
      ],
      advantagesParagraphsRowClass: 'col-xl-4 col-lg-4',
      outro: 'Une initiative collective soutenue par déjà plus de 1900 entreprises et les fédérations du secteur des vins & spiritueux :',
      logos: [],
      learnMoreLink: 'https://www.gs1.fr/Notre-offre/CodeOnline-la-cave-digitale-des-Vins-et-Spiritueux',
      backgroundClass: 'VCS-bg'
    },
    FOOD: {
      title: 'Food',
      subTitle: 'Allez plus loin avec CodeOnline Food',
      advantagesIntro: 'Valorisez vos produits grâce à des fonctionnalités dédiées au secteur alimentaire :',
      advantages: [
        'Gérez facilement les 35 informations clés de vos packagings.',
        'Rendez accessibles vos données aux applications consommateurs et à vos partenaires commerciaux.'
      ],
      advantagesParagraphs: [
        'Valorisez vos produits en complétant des informations supplémentaires dédiées au secteur alimentaire.',
        'Rendez accessibles aux applications consommateurs et à vos partenaires commerciaux les 35 attributs clés INCO présents sur vos packagings.',
        'Engagez-vous dans la démarche de transparence vis-à-vis des consommateurs, soutenue par plus de 1600 entreprises et les fédérations du secteur alimentaire :'
      ],
      advantagesParagraphsRowClass: 'col-xl-3 col-lg-5',
      outro: 'Engagez-vous dans la démarche de transparence vis-à-vis de vos consommateurs, soutenue par déjà plus de 1300 entreprises et les fédérations du secteur alimentaire :',
      logos: [
        'logo-ania.png',
        'LOGO-coop-agricole.jpg',
        'logo-FCD.png',
        'logo-groupe-103.png',
        'logo-groupe-105.png',
        'logo-FFAS.png',
        'logo-groupe-95.png'
      ],
      learnMoreLink: 'https://www.gs1.fr/codeonline-loutil-ligne-creer-vos-codes-gtin/codeonline-food',
      backgroundClass: 'FOOD-bg'
    }
  },

  noPayer: {
    warning: {
      title: '<b>IMPORTANT</b>',
      body: `Afin de continuer à utiliser CodeOnline, nous vous invitons à solder toute facture d’abonnement non réglée.
            Sans cela, à compter du 04/03/24, vous ne pourrez plus accéder à CodeOnline pour codifier vos produits.
            Si vous avez déjà fait le nécessaire, merci de ne pas tenir compte de ce message.`
    }
  },
  cache: {
    popIn: {
      title: 'Important',
      body: `Une nouvelle version de CodeOnline est disponible. <br/>Nous vous invitons à cliquer sur le bouton ci-dessous afin d'en bénéficier.<br/>`
    }
  },
  reseller: {
    title: 'Règle de codification GS1',
    OWN_BRAND: `<span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span><br/><br/>
<span>Seul le propriétaire de la marque commerciale ou le détenteur des spécifications techniques* est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span>`,
    THIRD_PARTY_BRAND: `<span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span> </br></br>
<span>Seul le propriétaire de la marque commerciale ou le détenteur des spécifications techniques* est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span></br></br>
<span><b>Par conséquent,  vous n’êtes pas habilité à codifier ce produit. Nous vous invitons à rechercher le GTIN afin de ne pas créer un doublon.</b></span>`,
    WITHOUT_BRAND: `<span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span> </br></br>
<span>Seul le fabricant ou le détenteur des spécifications techniques* est habilité à codifier les produits.<b> Codifier à sa place est contraire aux règles de codification GS1.</b></span> `,
    import: {
      OWN_BRAND: `<span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span><br/><br/>
<span>Seul le propriétaire de la marque commerciale ou le détenteur des spécifications techniques* est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span>`,
      THIRD_PARTY_BRAND: `<span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span> </br></br>
<span>Seul le propriétaire de la marque commerciale ou le détenteur des spécifications techniques* est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span></br></br>
<span><b>Par conséquent,  vous n’êtes pas habilité à codifier ces produits. Nous vous invitons à rechercher les GTIN afin de ne pas créer de doublon.</b></span></span>`,
      WITHOUT_BRAND: `<span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span> </br></br>
<span>Seul le fabricant ou le détenteur des spécifications techniques* est habilité à codifier les produits.<b> Codifier à sa place est contraire aux règles de codification GS1.</b></span> `
    },
    withoutBrand: {
      title: 'Règle de codification GS1',
      firstText: 'Seul le fabricant ou le détenteur des spécifications techniques* du produit est habilité à codifier les produits.\n',
      secondText: '<i>Détenteur des spécification techniques* :</i> il s’agit du propriétaire du cahier des charges du produit, il peut sous-traiter à des tiers la fabrication du produit.'
    },
    body: {
      first: `C’est le propriétaire de la marque commerciale qui doit codifier les produits.`,
      second: `Si le produit est fabriqué selon un cahier des charges par un sous-traitant, ce dernier intègrera lors de la fabrication les codes créés par le propriétaire de la marque.`

    },
    alert: {
      title: 'Important',
      body: 'Par dérogation nous vous permettons de manière exceptionnelle de créer un GTIN.<br>Pour rappel, en adhérant à GS1 France et en acceptant les conditions générales d’adhésion, vous vous êtes engagé à respecter les règles de codification GS1.'
    },
    alertImport: {
      title: 'Important',
      body: 'Par dérogation nous vous permettons de manière exceptionnelle de créer <b>un GTIN unitaire</b></b>.<br>Pour rappel, en adhérant à GS1 France et en acceptant les conditions générales d’adhésion, vous vous êtes engagé à respecter les règles de codification GS1.'
    }
  },
  tags: {
    not_new : 'Non neuf - '
  }

};
