import {Component} from '@angular/core';

@Component({
  selector: 'settings-view',
  styles: [`
.Settings{
    height: 100%;
}`],
  template: `
<div class="Settings">
    <router-outlet></router-outlet>
</div>`
})
export class SettingsViewComponent {
}
