import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {NotificationsSvc} from './notifications.service';
import {catchError, map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {Category} from '../models';

@Injectable()
export class InvoiceSvc {
    constructor(private httpClient: HttpClient, private notifsSvc: NotificationsSvc) {
    }
    public gethasUnpayedInvoice(companyId: string): Observable<any> {
        const requestUrl = environment.API_URL + '/invoice?companyId=' + companyId;
        return this.httpClient.get(requestUrl).pipe(
            map(
                (data: any) => {
                    return data && data.count > 0;
                }
            ), catchError(err => {
                const returnedError = err as Response;
                if (returnedError.status !== 401) {
                    this.notifsSvc.companyMissing();
                }
                return err;
            }));
    }
}

