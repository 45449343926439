import {
  Component, ViewChild, OnInit, Input, ElementRef
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationModal, ShareModal} from '../../../../shared/components';
import {NotificationsSvc, ProductCategorySvc, UserSvc} from '../../../../shared/services';
import {BarcodeGenerationSvc, GoogleAnalyticsService, ProductsSvc} from '../../../services';
import {ClassificationSvc} from '../../../../shared/services/classification.service';
import {CompanySvc} from '../../../../shared/services/company.service';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {Classification} from '../../../../shared/models/Classification.model';
import {VintageSvc} from '../../../../shared/services/vintages.service';
import {containerCodes} from '../../../models/container.constants' ;
import {Varietal} from '../../../models/varietals.constants';
import {Aoc} from '../../../models/aoc.constants';
import {GisCodes} from '../../../models/gis.constants' ;
import * as _ from 'lodash';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {certificationsConstants} from '../../../models/certifications.constants';
import {AllergensConstants} from '../../../models/allergent.constants';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {DashboardConst, VcsEditionUnityForm} from '../../../models';
import GtinUtils from '../../../../shared/utils/gtin';
import {NetContent} from '../../../models/NetContent.model';
import {TargetMarketCountries} from '../../../../dashboard/models/targetMarket-country.constants';
import {environment} from 'src/environments/environment';
import {NutrimentItem} from '../../../models/nutrimentItem.model';
import {Nutriment} from '../../../models/nutriment.model';
import {QrCodeCreationComponent} from '../../qr-code-creation/qr-code-creation.component';
import {NetContentConst} from '../../../models/netContent.constants';
import {RegulatoryModal} from './regulatory-notice/regulatory-modal.component';
import {QrExperienceModalVCS} from './qr-experience-modal/qr-experience-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'product-view-vcs',
  styleUrls: ['./product-view-vcs.less'],
  templateUrl: './product-view-vcs.tpl.html',
  providers: [{provide: CarouselConfig, useValue: {interval: 5000, noPause: false, showIndicators: false}}]
})
export class ProductViewVcsComponent extends VcsEditionUnityForm implements OnInit {

  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;


  @ViewChild('shareModal', {static: true})
  public shareModal: ShareModal;

  @ViewChild('formProduct', {static: true})
  public form;
  public multiSelectOptions: any = Varietal;
  public aocSelectOptions: any = Aoc;
  public containerCodes = containerCodes;
  public gisCodes = GisCodes;
  public shareUrl = '';
  public barcodeUrl = '';
  public constants = DashboardConst;
  public copyGtinToolTip: string = this.constants.copyGtinLabel;
  @Input() public product: any;
  public measurementUnitsList: Array<Array<{}>> = new Array();
  public imagesToDisplay: any[];
  public languageProductName: string;
  public NetContentConstants = NetContentConst.all;
  public readonly environment = environment;
  public gs1AlertContent = 'Générez le QR Code à apposer sur l’étiquette de votre produit. ll permettra à' +
    ' vos consommateurs d’accéder à une page web intégrant les informations réglementaires :' +
    ' ingrédients, allergènes et valeurs nutritionnelles.<br/>';

  @ViewChild(QrCodeCreationComponent, {static: true})
  private QrCodeCreationComponent: QrCodeCreationComponent;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public classificationSvc: ClassificationSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public userSvc: UserSvc,
              private notificationsSvc: NotificationsSvc,
              private sanitizer: DomSanitizer,
              private productCategorySvc: ProductCategorySvc,
              public dialog: MatDialog,
              public googleAnalyticsService: GoogleAnalyticsService
  ) {
    super(productsSvc, _router, route, featureToggleSvc, barcodeGenerationSvc, notificationsSvc, companySvc, vintageSvc, userSvc);
  }

  public ngOnInit() {
    this.initClassification();
    this.initProductForm();
    this.intIngredientStatement();
    this.initNutriment();
    this.imagesToDisplay = _.concat(
      this.productForm.images,
      _.map(this.productForm.imagesAddedByUrl, i => ({url: i}))
    );

    this.languageProductName = _.isEmpty(this.productForm.productNames) ? 'fr' : _.get(_.first(this.productForm.productNames), 'lang', '');
    if (!this.userSvc.user.hasSeenVCSProduct){
      this.openDialog();
    }
  }

  public showBarcode() {
    if (this.isProductVcs()) {
      return false;
    }

    if (this.barcodeUrl.length !== 0) {
      return true;
    }
    if (this.barcodeUrl.length === 0 && this.productForm.gtin.prefix) {
      this.barcodeUrl = `${environment.API_URL}/barcode/${this.productForm.gtin.prefix}${this.productForm.gtin.cip}${this.productForm.gtin.key}`;
      return true;
    }
    return false;
  }

  public showIG() {
    return this.isProductWine() && this.productForm.productCountry === '250';
  }

  public showAOC() {
    return this.showIG() && ['AOC', 'AOP', 'IG', 'IGP'].indexOf(this.productForm.gis) >= 0;
  }

  public isProductVcs() {
    return this.selectedCategorie.categorie === this.productCategories.SPI || this.selectedCategorie.categorie === this.productCategories.VIN;
  }

  public findWordingFromCode(constants: Array<{ wording: string, code: any }> = [], code: any = '') {
    const c = _.find(constants, element => element.code.toString() === code.toString()) || {
      wording: ''
    };
    return c.wording;
  }

  public getCompanyWebsite(): string {
    if (this.companySvc && this.companySvc.company && this.companySvc.company.urlWebsite) {
      const url = this.companySvc.company.urlWebsite;
      return url.startsWith('http') ? url : ('//' + url);
    } else {
      return '';
    }
  }

  public extractUrlFromImage(): string {
    if (this.imagesToDisplay && this.imagesToDisplay[0]) {
      return this.imagesToDisplay[0]['url'];
    }
    return '';
  }

  public findListWording(constants: Array<{ wording: string, code: any }>, listCode: [String]) {
    const toReturn = [];
    listCode.forEach((code: any) => {
      const wording = this.findWordingFromCode(constants, code);
      if (!_.isEmpty(wording)) {
        toReturn.push(' ' + wording);
      }
    });
    return toReturn;
  }

  public constructHeadersFromCategory(constants: Array<{
    wording: string,
    categorie: string,
    code: string
  }>, category: string): Array<{ wording: string, categorie: string, code: string }> {
    return _.filter(constants, element => element.categorie.toString() === category);
  }

  public constructAllergenWording(): string[] {
    const toReturn: string[] = [];
    if (this.productForm.allergen) {
      const keys = _.keys(this.productForm.allergen);
      keys.forEach((key: string) => {
        const allergenHeader = _.find(AllergensConstants, allergen => allergen.code === key) || {
          wording: ''
        };

        if (this.productForm.allergen[key]) {
          toReturn.push(' ' + allergenHeader.wording);
        }
      });
    }
    return toReturn;
  }

  public constructCertifWording() {
    const toReturn = [];
    if (this.productForm.certifications) {
      const certifHeaders = this.constructHeadersFromCategory(certificationsConstants, 'certification');
      certifHeaders.forEach(certif => {
        if (this.productForm.certifications[certif.code] && certif.code !== 'AUTRE_CERTIF') {
          toReturn.push(' ' + certif.wording);
        }
      });
      if (!_.isNil(this.productForm.certifications.AUTRE_CERTIF) && !_.isEmpty(this.productForm.certifications.AUTRE_CERTIF)) {
        toReturn.push(' ' + this.productForm.certifications.AUTRE_CERTIF);
      }
    }
    return toReturn;
  }

  public constructLabelWording(): string[] {
    const toReturn = [];
    if (this.productForm.certifications) {
      const labelHeaders = this.constructHeadersFromCategory(certificationsConstants, 'label');
      labelHeaders.forEach(label => {
        if (this.productForm.certifications[label.code] && label.code !== 'AUTRE_LABEL') {
          toReturn.push(' ' + label.wording);
        }
      });
      if (!_.isNil(this.productForm.certifications.AUTRE_LABEL) && !_.isEmpty(this.productForm.certifications.AUTRE_LABEL)) {
        toReturn.push(' ' + this.productForm.certifications.AUTRE_LABEL);
      }
    }
    return toReturn;
  }

  public cleanURL(oldURL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  public showMpnSubSection(): boolean {
    return (!this.isProductVcs()) && this.showSubSection(this.productForm.mpn);
  }

  public showLabelAndCertifSection(): boolean {
    return this.isProductVcs() && (this.showSection(this.constructCertifWording()) || this.showSection(this.constructLabelWording()));
  }

  public showIngredients(): boolean {
    return this.isProductVcs() && (this.showSection(this.productForm.ingredientStatement));
  }

  public showNutrients(): boolean {
    return this.isProductVcs() && this.productForm.nutriment;
  }

  public showMpnAndSkuSection(): boolean {
    if (!this.isProductVcs()) {
      return this.showSection([this.productForm.mpn, this.productForm.sku]);
    }

    return this.showSubSection(this.productForm.sku);
  }

  public showSubSection(variable: string) {
    variable = !_.isNil(variable) ? variable.toString().trim() : '';
    return !_.isEmpty(variable);
  }

  public showSection(variables: string[]) {
    let toReturn = false;
    for (const variable of variables) {
      if (this.showSubSection(variable)) {
        toReturn = true;
        break;
      }
    }
    return toReturn;
  }

  public editProduct() {
    const routeName = 'dashboard/product/update';
    this._router.navigate([routeName, this.id]);
  }

  public showComplementaryInfoSection() {
    if (this.selectedCategorie.categorie === this.productCategories.AUTRE || this.selectedCategorie.categorie === this.productCategories.AUCUN) {
      return this.showSection([this.productForm.description, this.productForm.webLink, this.extractUrlFromImage(), this.productForm.mpn, this.productForm.sku]);
    }
    return this.showSection([this.productForm.description, this.productForm.webLink, this.extractUrlFromImage(), this.productForm.sku]);

  }

  public covertStringToInt(x: string) {
    return +x;
  }

  public shareCIP() {
    this.shareUrl = `${environment.SHARE_URL_BASE}/gtin/${this.productForm.gtin.prefix}${this.productForm.gtin.cip}${this.productForm.gtin.key}`;
    this.shareModal.openModal();
  }

  public isProductSpi() {
    return this.selectedCategorie.categorie === this.productCategories.SPI;
  }

  public onVisibilityChange(state: boolean) {
    this.productsSvc.updateProductVisibility(this.id, state)
      .subscribe(
        updatedProduct => {
          this.productForm.isPrivate = updatedProduct.isPrivate;
        },
        err => {
          this.productForm.isPrivate = !state;
          console.error(err);
        }
      );
  }

  public constructTargetMarketWording(): string {
    const targetMarket = this.productForm.targetMarket;
    let wording = '';
    targetMarket.forEach((item, index) => {
      if (index !== 0) {
        wording = wording + ' | ';
      }
      wording = wording + _.find(TargetMarketCountries, c => item === c.code)['wording-fr'];
    });
    return wording;
  }

  public clickCopyGtin(event, button: HTMLButtonElement) {
    this.copyGtinToolTip = this.constants.copiedGtinLabel;
    setTimeout(() => {
      button.blur();
      button.focus();
    }, 100);
    setTimeout(() => {
      this.copyGtinToolTip = this.constants.copyGtinLabel;
      button.blur();
    }, 1000);
    event.stopPropagation();
  }

  public cannotSeeBrand(productForm): boolean {
    return this.productsSvc.isProductResellerWithoutBrand(productForm.productResellerType);
  }

  public isGtin8() {
    return this.productForm.gtin.isGtin8;
  }

  public constructPreparationStateWording(): string {
    if (this.productForm.preparationStateCode === 'UNPREPARED') {
      return 'Tel que vendu';
    }

    return 'Préparé';
  }

  public openQRcodeModal() {
    this.QrCodeCreationComponent.show();
    this.sendRealTimeEvent();
  }

  public constructNetContentWording(): string {
    const netContentObject: any = _.head(this.productForm.netContents);
    if (_.isEmpty(netContentObject)) {
      return '';
    }
    const netContentValue = netContentObject.netContent;
    const measurementUnitValue = this.findWordingFromCode(this.NetContentConstants, netContentObject.measurementUnitCode);
    return `${netContentValue} ${measurementUnitValue}`;
  }

  public isAdhereToRegulatoryAgreement(): boolean {
    return this.productForm.adhereToRegulatoryAgreement === true;
  }

  public openDialog(): void {
    const config = {
      height: '590px',
      width: '840px',
      disableClose: true,
      panelClass: 'panel-class'
    };
    const dialogRef = this.dialog.open(QrExperienceModalVCS, config);
    dialogRef.afterClosed().subscribe(result => {
      this.userSvc.updateUser({hasSeenVCSProduct: true}).then((r) => {
        console.info('User updated: Has seen V&S product');
      });
    });
  }

  public sendRealTimeEvent() {
    const userEmail: string = this.userSvc.user.email;
    const titlePage = 'COL - Créer le QR Code augmenté GS1';
    const locationPage = `/dashboard/product/view/${this.productsSvc.product.id}`;
    const gtin = this.productsSvc.product.gtin;
    this.googleAnalyticsService.sendRealTimeEvent(titlePage, locationPage, userEmail, gtin).subscribe(() => {
      console.info('GA4 - The real-time event has been sent');
    });
  }

  private initProductForm() {
    _.assign(this.productForm, this.product);
    this.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
    // just to display target market label
    this.productForm.targetMarketLabel = this.getTargetMarketLabel(this.productForm.targetMarket);

    this.productForm.alcoholDegree = this.productsSvc.getFormatedAlcoholDegree(this.product.alcoholDegree);

    this.initNetContents();
    this.productForm.allergen = this.formatAllergen(this.productForm.allergen);
    // Saving values that should not be changed
    this.initialBrand = this.product.brand;
    this.productForm.productResellerType = this.product.productResellerType;
    this.initialName = this.product.name;
    this.productForm.container = (this.productForm.container === 'BO_GLASS' || this.productForm.container === 'BO_PLASTIC_OTHER') ? 'BO' : this.productForm.container;
    this.id = this.product.id;
    this.getClassification();
    this.initSugarContentList();
  }

  private initClassification() {
    const vcsClassificationObject = this.productCategorySvc.vcsClassification();
    delete vcsClassificationObject.vcsCodes;
    const vcsClassification = Object.keys(vcsClassificationObject).reduce((accumulator, currentValue) => accumulator.concat(vcsClassificationObject[currentValue]), []);
    this.gpcCodes = [new Classification('0', 'others', '')];
    vcsClassification.forEach((obj: any) => {
      this.gpcCodes.push(new Classification(obj.codes[0], obj.category, obj.wording));
    });
  }

  private initNetContents() {
    this.productForm.netContents = _.map(this.productForm.netContents, ({netContent, measurementUnitCode}) => {
      const netContentStr = `${netContent}`.replace('.', ',');
      return new NetContent(netContentStr, measurementUnitCode);
    });
  }

  private intIngredientStatement() {
    const firstPattern = 'Ingrédient :'.toUpperCase();
    const secondPattern = 'Ingrédients :'.toUpperCase();
    const firstIndex = this.productForm.ingredientStatement.toUpperCase().indexOf(firstPattern);
    const secondIndex = this.productForm.ingredientStatement.toUpperCase().indexOf(secondPattern);

    if (firstIndex !== -1) {
      this.productForm.ingredientStatement = this.productForm.ingredientStatement.slice(firstPattern.length, this.productForm.ingredientStatement.length).trim();
    } else if (secondIndex !== -1) {
      this.productForm.ingredientStatement = this.productForm.ingredientStatement.slice(secondPattern.length, this.productForm.ingredientStatement.length).trim();
    }
  }

  private initNutriment() {
    const {servingSize, measurementUnitCode, servingSizeDescription, items} = this.productForm.nutriment;

    const builtItems = _.map(items, (item: NutrimentItem) => {
      return new NutrimentItem(item.nutrientTypeCode, item.measurementPrecisionCode,
        this.productsSvc.replaceDotByCommaInString(item.quantityContained ?? '') ?? '',
        this.productsSvc.replaceDotByCommaInString(item.optionalQuantityContained) ?? '',
        item.measurementUnitCode,
        this.productsSvc.replaceDotByCommaInString(item.dailyValueIntakeReference ?? '') ?? '');
    });

    this.productForm.nutriment = new Nutriment(servingSize, measurementUnitCode, servingSizeDescription, builtItems);
  }
}
