import {
    Component, ElementRef,
    ViewEncapsulation
} from '@angular/core';
import {DashboardConst} from '../../../../../../models/dashboard.constants';
import {PackagingMarkedLabelAccreditationCode} from '../../../../../../models/packagingMarkedLabelAccreditationCode.constants';
import {StepComponent} from './StepComponent';
import {NotificationsSvc} from '../../../../../../../shared/services';

import * as _ from 'lodash';


@Component({
    selector: 'complementary-info-step',
    styleUrls: ['../unity-consumer-creation-food.less'],
    templateUrl: './complementary-info-step.tpl.html',
    encapsulation: ViewEncapsulation.None
})
export class ComplementaryInfoStepComponent extends StepComponent {

    public stepCount: number = 3;
    public activeNutriScore: boolean = false;
    public selectedNutriscore: string = '';
    public constants = DashboardConst;
    public packagingMarkedLabelAccreditationCodeOptions: any = PackagingMarkedLabelAccreditationCode;

    public packagingMarkedLabelAccreditationCodeConfig: any = Object.assign({}, {
        highlight: false,
        create: false,
        persist: true,
        plugins: ['remove_button'],
        dropdownDirection: 'down',
        wrapperClass: 'gs1selectize-control',
        inputClass: 'gs1selectize-input',
        dropdownClass: 'gs1selectize-dropdown',
        dropdownContentClass: 'gs1selectize-dropdown-content'
    }, {
        labelField: 'wording',
        valueField: 'code',
        searchField: ['wording'],
        maxItems: 50
    });

    constructor(public notificationsSvc: NotificationsSvc, private elRef: ElementRef) {
        super();
    }

    public ngOnInit() {
        if (this.productForm.nutriScore && this.productForm.nutriScore.length) {
            this.activeNutriScore = true;
            this.selectNutriscore(this.productForm.nutriScore);
        }
    }

    public hasNutriScore() {
        this.activeNutriScore = !this.activeNutriScore;
    }

    public selectNutriscore(nsValue: string) {
        if (this.activeNutriScore) {
            this.selectedNutriscore = nsValue;
        }
    }

    public checkNutriscore() {
        if (this.activeNutriScore && this.selectedNutriscore.length === 0) {
            this.notificationsSvc.wrongNutriscore();
            return false;
        }
        return true;
    }

    // @ts-ignore
  public submitForm(submitting: boolean = true) {
        if (this.productForm.preparationStateCode === 'PREPARED' && _.isEmpty(_.trim(this.productForm.preparationInstructions))) {
            return false;
        }
        if (this.checkNutriscore()) {
            this.productForm.nutriScore = this.activeNutriScore ? this.selectedNutriscore : '';
            // @ts-ignore
          super.submitForm(submitting);
        }
        return false;
    }

    public checkRequiredTextArea(form: HTMLFormElement, event: Event) {
        if (this.productForm.preparationStateCode === 'PREPARED' && _.isEmpty(_.trim(this.productForm.preparationInstructions))) {
            const preparationInstructionsElement: HTMLTextAreaElement = this.elRef.nativeElement.querySelector('#preparationInstructions');
            if (_.isEmpty(_.trim(this.productForm.preparationInstructions))) {
                preparationInstructionsElement.className = 'infosArea defineScrollbar selectError';
            }
        }

        const scrollToElement = this.elRef.nativeElement.querySelector('.selectError');
        if (scrollToElement) {
            scrollToElement.scrollIntoView({
                block: 'center'
            });
            return;
        }

    }
}
