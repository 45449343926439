import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NotificationsSvc} from '../shared/services/notifications.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {AuthGuard} from '../shared/services/auth-guard';
import {GtinSvc} from '../shared/services';
import {HttpErrorResponse} from '@angular/common/http';
import * as _ from 'lodash';
import {ImportTypes} from '../shared/models';
import {environment} from '../../environments/environment';

@Component({
  selector: 'search',
  styleUrls: ['./search.less'],
  templateUrl: './search.tpl.html'
})
export class SearchComponent implements OnInit {
  public searchedGtin: string;
  public gtinExist: boolean;
  public rateLimitExceeded: boolean;
  public url: string;
  public productName: string;
  public productImg: string;
  public companyName: string;
  public isValid: boolean = true;
  public error: boolean;
  public isPrivate: boolean = false;
  public searchInProgress: boolean = false;
  public searchRequested: boolean = false;
  public brandName: string;
  public errorCode: string;
  public license: any;
  public isSearchByKey: boolean = false;
  public isGtin8Valid: boolean = true;
  public isGtin8: boolean = false;

  constructor(private router: Router, private oidcSecurityService: OidcSecurityService, private authGuard: AuthGuard,
              private gtinSvc: GtinSvc, public notifSvc: NotificationsSvc, private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    document.body.classList.add('searchBody');
    this.route.params.subscribe((params: Params) => {
      switch (params['type']) {
        case 'by-key':
          this.isSearchByKey = true;
          break;
        default:
          this.isSearchByKey = false;
          break;
      }
    });
  }

  public ssoAuthentication() {
    window.open('/', '_blank');
    return;
  }

  public isUserConnected() {
    if (this.authGuard.jwt) {
      return true;
    }
    return false;
  }

  public searchGtin() {
    this.searchRequested = true;
    this.productName = null;
    this.isGtin8Valid = this.isValid = true;
    this.isGtin8 = false;
    if (!this.searchedGtin) {
      return;
    }
    this.searchedGtin = this.searchedGtin.replace(/\s/g, '');

    const gtinInt = _.parseInt(this.searchedGtin);
    const cleanedGtin = _.isNaN(gtinInt) ? this.searchedGtin : `${gtinInt}`;
    if (cleanedGtin.length === 8) {
      this.isGtin8 = true;
    }
    if (isNaN(Number(this.searchedGtin)) || this.searchedGtin.length > 14 || (this.searchedGtin.length < 13 && this.searchedGtin.length !== 8)) {
      this.isValid = false;
      return;
    }
    this.searchInProgress = true;
    this.gtinSvc.searchGtin(this.searchedGtin).subscribe(data => {
      this.rateLimitExceeded = false;
      this.error = false;
      this.errorCode = null;
      if (data.exist || data.gtin) {
        this.searchInProgress = false;
        this.gtinExist = true;
        const isPrivate = data.isPrivate;

        if (isPrivate) {
          this.isPrivate = isPrivate;
          this.license = data.license;
          return;
        }
        this.constructProduct(data);
        return;
      }
      this.gtinExist = false;
    }, err => {
      this.searchInProgress = false;
      if (err.status === 0 || (err instanceof HttpErrorResponse && err.status === 429)) {
        this.rateLimitExceeded = true;
        return;
      }
      if (err.status === 0 || (err instanceof HttpErrorResponse && err.status === 404)) {
        this.rateLimitExceeded = false;
        this.gtinExist = false;
        this.license = err.error.license;
        if (!this.license) {
          if (this.isGtin8) {
            this.isGtin8Valid = _.inRange(Number(this.searchedGtin), 30000001, 30199996);
          } else {
            this.error = true;
          }
        }
        return;
      }

      if (err.status === 0 || (err instanceof HttpErrorResponse && err.status === 409)) {
        this.errorCode = `MOT-${err.error.code}`;
      }
      this.error = true;
    });
  }

  public cleanView(e) {
    e = e || window.event;
    this.gtinExist = null;
    this.rateLimitExceeded = null;
    this.error = null;
    this.isPrivate = false;
    const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
    // TODO CHECK THIS CODE
    // if (charCode !== 13) {
    //     this.isValid = false;
    // }
  }

  private constructProduct(data) {
    const url = `${environment.SHARE_URL_BASE}/gtin/${this.searchedGtin}`;
    this.url = url;
    this.productName = _.isArray(data.tradeItemDescription) ? _.get(_.first(data.tradeItemDescription), 'value', '') : data.tradeItemDescription;
    const imageUrl = _.get(data, 'tradeItemImageUrls[0]', '/assets/img/nopicture.png');
    this.productImg = imageUrl;
    this.companyName = data.company.companyName;
    this.brandName = data.brandName;
  }
}
