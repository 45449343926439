import {Gtin} from '../../shared/models/gtin.model';
import {FormInputError} from './formInputError';
import {Countries} from './country.constants';
import {BaseProductEditionFormModel} from './baseProductEditionForm.model';
import {DietTypes} from './dietTypes.model';
import {Nutriment} from './nutriment.model';
import {NetContent} from './NetContent.model';
import {AllergenFoodModel} from './allergenFood.model';
import {DrainedWeight} from './DrainedWeight.model';
import {StructuredAddress} from './StructuredAddress.model';
import {ImageUrl} from './ImageUrl.model';
import {ProductName} from './ProductName.model';

import * as _ from 'lodash';

export class FoodProductEditionFormModel extends BaseProductEditionFormModel {
    constructor(
        public gtin: Gtin,
        public cipError: FormInputError,
        public mpn: string,
        public sku: string,
        public brand: string,
        public subBrand: string,
        public name: string,
        public productNames: ProductName[],
        public imagesToUpload: Object[],
        public images: Object[],
        public description: string,
        public webLink: string,
        public completionLevel: number,
        public companyId?: number,
        public targetMarket: string[] = ['250'],
        public codeGpc: string = '',
        public isPrivate: boolean = false,
        public adhereToTheSectoralAgreement?: boolean,
        public structuredAddress: StructuredAddress = new StructuredAddress('', '', '', ''),
        public dietTypes: DietTypes = {},
        public contactName: string = '',
        public nutriment: Nutriment = {},
        public category: string = '',
        public netContents: NetContent[] = [],
        public regulatedProductName: string = '',
        public allergensFood: AllergenFoodModel[] = [],
        public alcoholDegree: string = '',
        public preparationStateCode: string = '',
        public consumerUsageInstructions: string = '',
        public consumerStorageInstructions: string = '',
        public ingredientStatement: string = '',
        public preparationInstructions: string = '',
        public compulsoryAdditiveLabelInformation: string = '',
        public packagingMarkedLabelAccreditationCode: string[] = [],
        public countryOfOrigin: string[] = [],
        public drainedWeight: DrainedWeight = {},
        public contentDescription: string = '',
        public nutriScore: string = '',
        public dailyValueIntakeReference: string = 'Apports de référence pour un adulte-type (8400 kj/2000 kcal)',
        public fullAddress: string = '',
        public imagesAddedByUrl: ImageUrl[] = [],
        public productResellerType? : string
    ) {
        super(gtin, mpn, sku, brand, subBrand, name, productNames, imagesToUpload, images, description, webLink, completionLevel,
            companyId,
            targetMarket,
            codeGpc,
            isPrivate,
            category,
            netContents,
            adhereToTheSectoralAgreement,
            imagesAddedByUrl,
            productResellerType
        );
    }

}
