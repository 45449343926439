import {NutrimentItem} from './nutrimentItem.model';

export class Nutriment {
  constructor(
    public servingSize?: string,
    public measurementUnitCode?: string,
    public servingSizeDescription?: string,
    public items?: NutrimentItem[],
    public dailyValueIntakeReference?: string
  ) {
  }
}






