export const vcsCategoriesConstants = [
  {
    code: '10000273',
    text: 'Vin - Fortifié',
    definition: "Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées élaborées à partir de la fermentation de jus de raisin, mais dont la fermentation est ensuite interrompue par l'ajout d'alcool. Ces vins peuvent provenir de n'importe quel(le) pays ou région et présenter un style de production particulier.Comprend des vins doux fortifiés tels que le porto, le madère, le vin de Marsala et le vermouth. Exclut des produits tels que le Pineau, le Pommeau et les vins et spiritueux non fortifiés.",
    formatedDefinition: "Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées élaborées à partir de la fermentation de jus de raisin, mais dont la fermentation est ensuite interrompue par l'ajout d'alcool. Ces vins peuvent provenir de n'importe quel(le) pays ou région et présenter un style de production particulier.Comprend des vins doux fortifiés tels que le porto, le madère, le vin de Marsala et le vermouth. ",
    type: 'brick',
    level: 4,
    parentIds: [
      '50202200',
      '50200000',
      '50000000'
    ],
    breadcrumbs: 'Nourriture/Boisson/Tabac / Boissons / Boissons Alcoolisées / Vin - Fortifié'
  },
  {
    code: '10000275',
    text: 'Vin - Mousseux',
    definition: 'Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées élaborées à partir de la fermentation de jus de raisin et contenant du gaz carbonique.Ces produits comprennent tous les types de vin mousseux, le champagne, le vin Cava et le vin pétillant. Exclut des produits tels que les vins tranquilles et fortifiés.',
    formatedDefinition: 'Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées élaborées à partir de la fermentation de jus de raisin et contenant du gaz carbonique.Ces produits comprennent tous les types de vin mousseux, le champagne, le vin Cava et le vin pétillant. ',
    type: 'brick',
    level: 4,
    parentIds: [
      '50202200',
      '50200000',
      '50000000'
    ],
    breadcrumbs: 'Nourriture/Boisson/Tabac / Boissons / Boissons Alcoolisées / Vin - Mousseux'
  },
  {
    code: '10000276',
    text: 'Vin - Tranquille',
    definition: 'Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées élaborées à partir de la fermentation de jus de raisin, ayant généralement une teneur en alcool maximale de 14 %. Ces produits ne sont pas gazeux, peuvent être blancs, rouges ou rosés en couleur, secs, doux, demi-secs ou moelleux en arôme et peuvent être décrits comme « millésimés » ou « non millésimés ». Exclut des produits tels que le champagne et le vin mousseux.',
    formatedDefinition: 'Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées élaborées à partir de la fermentation de jus de raisin, ayant généralement une teneur en alcool maximale de 14 %. Ces produits ne sont pas gazeux, peuvent être blancs, rouges ou rosés en couleur, secs, doux, demi-secs ou moelleux en arôme et peuvent être décrits comme « millésimés » ou « non millésimés ». ',
    type: 'brick',
    level: 4,
    parentIds: [
      '50202200',
      '50200000',
      '50000000'
    ],
    breadcrumbs: 'Nourriture/Boisson/Tabac / Boissons / Boissons Alcoolisées / Vin - Tranquille'
  },
  {
    code: '10000263',
    text: 'Spiritueux',
    definition: "Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées distillées avec du jus de fruit, des fruits, des céréales ou du sucre fermenté(s). Ces produits ont normalement une forte teneur en alcool, d'environ 35-40 %.Comprend des produits tels que l'eau-de-vie de vin, le schnaps, le gin, le rhum, la tequila, la vodka et le whisky. Exclut des produits tels que les vins fortifiés, les liqueurs, les amers/allongeurs non alcoolisés et le vin.",
    formatedDefinition: "Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées distillées avec du jus de fruit, des fruits, des céréales ou du sucre fermenté(s). Ces produits ont normalement une forte teneur en alcool, d'environ 35-40 %.Comprend des produits tels que l'eau-de-vie de vin, le schnaps, le gin, le rhum, la tequila, la vodka et le whisky. ",
    type: 'brick',
    level: 4,
    parentIds: [
      '50202200',
      '50200000',
      '50000000'
    ],
    breadcrumbs: 'Nourriture/Boisson/Tabac / Boissons / Boissons Alcoolisées / Spiritueux'
  },
  {
    code: '10000144',
    text: 'Boissons Alcoolisées Pré-mélangées',
    definition: 'Comprend tous les produits qui peuvent être décrits/observés comme étant des boissons alcoolisées aromatisées qui sont pré-mélangées avec d\'autres boissons, généralement non-alcoolisées. Comprend aussi les boissons gazeuses ou lactées auxquelles des spiritueux sont ajoutés durant le processus de production. Comprend les cocktails pré-mélangés, tels que la Vodka-Martini, et les boissons mélangées à du vin, telles que Spritzers et Bucks Fizz.',
    type: 'brick',
    level: 4,
    parentIds: [
      '50202200',
      '50200000',
      '50000000'
    ],
    breadcrumbs: 'Nourriture/Boisson/Tabac / Boissons / Boissons Alcoolisées (y compris les variantes désalcoolisées) / Boissons Alcoolisées Pré-mélangées'
  }
];
