export const nutrimentItemsConst = [
  {
    code: 'ENER-',
    wording: 'Valeur énergétique',
    measurementUnitCode: 'KJO',
    isRequired: true
  },
  {
    code: 'ENER-',
    wording: '',
    measurementUnitCode: 'E14',
    isRequired: true
  },
  {
    code: 'FAT',
    wording: 'Matières grasses',
    measurementUnitCode: 'GRM',
    isRequired: true
  },
  {
    code: 'PRO-',
    wording: 'Protéines',
    measurementUnitCode: 'GRM',
    isRequired: true
  },
  {
    code: 'SALTEQ',
    wording: 'Sel',
    measurementUnitCode: 'GRM',
    isRequired: true
  },
  {
    code: 'CHOAVL',
    wording: 'Glucides',
    measurementUnitCode: 'GRM',
    isRequired: true
  },
  {
    code: 'SUGAR-',
    wording: 'Sucres',
    measurementUnitCode: 'GRM',
    isRequired: true
  },
  {
    code: 'FASAT',
    wording: 'Acides gras saturés',
    measurementUnitCode: 'GRM',
    isRequired: true
  }
];
