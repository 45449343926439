export const Aoc = [{code: 30016800, wording: 'Agenais'},
  {code: 30016540, wording: 'AJACCIO'},
  {code: 30016444, wording: 'ALOXE-CORTON'},
  {code: 30016711, wording: 'ALPES-DE-HAUTE-PROVENCE'},
  {code: 30016712, wording: 'ALPES-MARITIMES'},
  {code: 30016713, wording: 'ALPILLES'},
  {code: 30016378, wording: 'ALSACE GRAND CRU'},
  {code: 30016379, wording: 'ALSACE OU VIN D\'ALSACE'},
  {code: 30016653, wording: 'ANJOU'},
  {code: 30016654, wording: 'ANJOU VILLAGES'},
  {code: 30016655, wording: 'ANJOU VILLAGES BRISSAC'},
  {code: 30016656, wording: 'ANJOU-COTEAUX DE LA LOIRE'},
  {code: 30016554, wording: 'ARBOIS'},
  {code: 30016732, wording: 'ARDECHE'},
  {code: 30016733, wording: 'ARDECHE COTEAUX DE L\'ARDECHE'},
  {code: 30016801, wording: 'ARIEGE'},
  {code: 30016802, wording: 'ARIEGE COTEAUX DE LA LEZE'},
  {code: 30016803, wording: 'ARIEGE COTEAUX DU PLANTAUREL'},
  {code: 30016804, wording: 'ATLANTIQUE'},
  {code: 30016560, wording: 'AUDE'},
  {code: 30016561, wording: 'AUDE COTEAUX DE LA CABRERISSE'},
  {code: 30016562, wording: 'AUDE COTEAUX DE MIRAMONT'},
  {code: 30016563, wording: 'AUDE COTES DE LASTOURS'},
  {code: 30016564, wording: 'AUDE COTES DE PROUILHE'},
  {code: 30016565, wording: 'AUDE HAUTERIVE'},
  {code: 30016566, wording: 'AUDE LA COTE REVEE'},
  {code: 30016567, wording: 'AUDE PAYS DE CUCUGNAN'},
  {code: 30016568, wording: 'AUDE VAL DE CESSE'},
  {code: 30016569, wording: 'AUDE VAL DE DAGNE'},
  {code: 30016445, wording: 'AUXEY-DURESSES'},
  {code: 30016805, wording: 'AVEYRON'},
  {code: 30016723, wording: 'BANDOL'},
  {code: 30016778, wording: 'BANYULS'},
  {code: 30016779, wording: 'BANYULS GRAND CRU'},
  {code: 30016394, wording: 'BARSAC'},
  {code: 30016446, wording: 'BATARD-MONTRACHET'},
  {code: 30016835, wording: 'BEARN'},
  {code: 30016381, wording: 'BEAUJOLAIS'},
  {code: 30016745, wording: 'BEAUMES DE VENISE'},
  {code: 30016447, wording: 'BEAUNE'},
  {code: 30016724, wording: 'BELLET OU VIN DE BELLET'},
  {code: 30016395, wording: 'BERGERAC'},
  {code: 30016448, wording: 'BIENVENUES-BATARD-MONTRACHET'},
  {code: 30016449, wording: 'BLAGNY'},
  {code: 30016396, wording: 'BLAYE'},
  {code: 30016450, wording: 'BONNES-MARES'},
  {code: 30016657, wording: 'BONNEZEAUX'},
  {code: 30016397, wording: 'BORDEAUX'},
  {code: 30016398, wording: 'BORDEAUX SUPERIEUR'},
  {code: 30016714, wording: 'BOUCHES-DU-RHONE'},
  {code: 30016715, wording: 'BOUCHES-DU-RHONE TERRE DE CAMARGUE'},
  {code: 30016451, wording: 'BOURGOGNE'},
  {code: 30016452, wording: 'BOURGOGNE ALIGOTE'},
  {code: 30016453, wording: 'BOURGOGNE MOUSSEUX'},
  {code: 30016454, wording: 'BOURGOGNE PASSE-TOUT-GRAINS'},
  {code: 30016658, wording: 'BOURGUEIL'},
  {code: 30016455, wording: 'BOUZERON'},
  {code: 30016382, wording: 'BROUILLY'},
  {code: 30016836, wording: 'BRULHOIS'},
  {code: 30016795, wording: 'Bugey'},
  // {code: 674, wording: 'Bugey'},
  {code: 30016837, wording: 'BUZET'},
  {code: 30016611, wording: 'CABARDES'},
  {code: 30016659, wording: 'CABERNET D\'ANJOU'},
  {code: 30016660, wording: 'CABERNET DE SAUMUR'},
  {code: 30016399, wording: 'CADILLAC'},
  {code: 30016838, wording: 'CAHORS'},
  {code: 819, wording: 'Cairanne'},
  {code: 30016628, wording: 'CALVADOS'},
  {code: 30016629, wording: 'CALVADOS GRISY'},
  {code: 30016400, wording: 'CANON FRONSAC'},
  {code: 30016725, wording: 'CASSIS'},
  {code: 30016401, wording: 'CERONS'},
  {code: 30016570, wording: 'CEVENNES'},
  {code: 30016456, wording: 'CHABLIS'},
  {code: 30016457, wording: 'CHABLIS GRAND CRU'},
  {code: 30016458, wording: 'CHAMBERTIN'},
  {code: 30016459, wording: 'CHAMBERTIN-CLOS DE BEZE'},
  {code: 30016460, wording: 'CHAMBOLLE-MUSIGNY'},
  {code: 30016528, wording: 'CHAMPAGNE'},
  {code: 30016461, wording: 'CHAPELLE-CHAMBERTIN'},
  {code: 30016531, wording: 'CHARENTAIS'},
  {code: 30016462, wording: 'CHARLEMAGNE'},
  {code: 30016463, wording: 'CHARMES-CHAMBERTIN'},
  {code: 30016464, wording: 'CHASSAGNE-MONTRACHET'},
  {code: 30016555, wording: 'CHATEAU-CHALON'},
  {code: 30016746, wording: 'CHATEAU-GRILLET'},
  {code: 30016661, wording: 'CHATEAUMEILLANT'},
  {code: 30016747, wording: 'CHATEAUNEUF-DU-PAPE'},
  {code: 30016748, wording: 'CHATILLON-EN-DIOIS'},
  {code: 30016383, wording: 'CHENAS'},
  {code: 30016465, wording: 'CHEVALIER-MONTRACHET'},
  {code: 30016662, wording: 'CHEVERNY'},
  {code: 30016663, wording: 'CHINON'},
  {code: 30016384, wording: 'CHIROUBLES'},
  {code: 30016466, wording: 'CHOREY-LES-BEAUNE'},
  {code: 30016571, wording: 'CITE DE CARCASSONNE'},
  {code: 30016749, wording: 'CLAIRETTE DE BELLEGARDE'},
  {code: 30016750, wording: 'CLAIRETTE DE PIE'},
  {code: 30016612, wording: 'CLAIRETTE DU LANGUEDOC'},
  {code: 30016751, wording: 'CLOS DE LA ROCHE'},
  {code: 30016467, wording: 'CLOS DE TART'},
  {code: 30016468, wording: 'CLOS DE VOUGEOT OU CLOS VOUGEOT'},
  {code: 30016469, wording: 'CLOS DES LAMBRAYS'},
  {code: 30016470, wording: 'CLOS SAINT-DENIS'},
  {code: 30016734, wording: 'COLLINES RHODANIENNES'},
  {code: 30016780, wording: 'COLLIOURE'},
  {code: 30016738, wording: 'COMTE DE GRIGNANDROME'},
  {code: 30016740, wording: 'COMTE DE GRIGNANMEDITERRANEE'},
  {code: 30016806, wording: 'COMTE TOLOSAN'},
  {code: 30016807, wording: 'COMTE TOLOSAN BIGORRE'},
  {code: 30016808, wording: 'COMTE TOLOSAN CANTAL'},
  {code: 30016809, wording: 'COMTE TOLOSAN COTEAUX ET TERRASSES'},
  {code: 30016810, wording: 'COMTE TOLOSAN HAUTE-GARONNE MONTAUBAN'},
  {code: 30016811, wording: 'COMTE TOLOSAN PYRENEES ATLANTIQUE'},
  {code: 30016812, wording: 'COMTE TOLOSAN TARN ET GARONNE'},
  {code: 30016735, wording: 'COMTES RHODANIENS'},
  {code: 30016752, wording: 'CONDRIEU'},
  {code: 30016613, wording: 'CORBIERES'},
  {code: 30016614, wording: 'CORBIERES-BOUTENAC'},
  {code: 30016385, wording: 'CORNAS'},
  {code: 1168, wording: 'Corrèze'},
  {code: 30016471, wording: 'CORTON'},
  {code: 30016472, wording: 'CORTON-CHARLEMAGNE'},
  {code: 30016753, wording: 'COSTIERES DE NIMES'},
  {code: 30016473, wording: 'COTE DE BEAUNE'},
  {code: 30016474, wording: 'COTE DE BEAUNE-VILLAGES'},
  {code: 30016386, wording: 'COTE DE BROUILLY'},
  {code: 30016475, wording: 'COTE DE NUITS-VILLAGES'},
  {code: 30016664, wording: 'COTE ROANNAISE'},
  {code: 30016754, wording: 'COTE ROTIE'},
  {code: 30016775, wording: 'COTE VERMEILLE'},
  {code: 30016476, wording: 'COTEAUX BOURGUIGNONS OU BOURGOGNE GRAND ORDINAIRE OU BOURGOGNE ORDINAIRE'},
  {code: 30016529, wording: 'COTEAUX CHAMPENOIS'},
  {code: 30016726, wording: 'COTEAUX D\'AIX-EN-PROVENCE'},
  {code: 30016665, wording: 'COTEAUX D\'ANCENIS'},
  {code: 996, wording: 'Coteaux de Béziers'},
  {code: 30016526, wording: 'COTEAUX DE COIFFY'},
  {code: 273, wording: 'Coteaux de Die'},
  {code: 939, wording: 'Coteaux de Glanes'},
  {code: 30016787, wording: 'COTEAUX DE L\'AIN'},
  {code: 30016789, wording: 'COTEAUX DE L\'AIN VAL DE SAONE'},
  {code: 30016790, wording: 'COTEAUX DE L\'AIN VALROMEY'},
  {code: 30016666, wording: 'COTEAUX DE L\'AUVANCE'},
  {code: 30016440, wording: 'COTEAUX DE L\'AUXOIS'},
  {code: 30016741, wording: 'COTEAUX DE MONTELIMAR MEDITERRANEE'},
  {code: 30016573, wording: 'COTEAUX DE NARBONNE'},
  {code: 30016574, wording: 'COTEAUX DE PEYRIAC'},
  {code: 882, wording: 'Coteaux de Peyriac'},
  {code: 30016575, wording: 'COTEAUX DE PEYRIAC HAUTDE BADENS'},
  {code: 30016755, wording: 'COTEAUX DE PIE'},
  {code: 87, wording: 'Coteaux de Saumur'},
  {code: 30016630, wording: 'COTEAUX DE TANNAY'},
  {code: 30016813, wording: 'COTEAUX DEGLANES'},
  {code: 30016814, wording: 'COTEAUX DEL\'AIN PAYS DE GEX'},
  {code: 30016572, wording: 'COTEAUX D\'ENSERUNE'},
  {code: 30016736, wording: 'COTEAUX DES BARONNIES'},
  {code: 30016631, wording: 'COTEAUX DU CHER ET DE L\'ARNON'},
  {code: 30016668, wording: 'COTEAUX DU GIENNOIS'},
  {code: 30016669, wording: 'COTEAUX DU LAYON'},
  {code: 30016576, wording: 'COTEAUX DU LIBRON'},
  {code: 30016577, wording: 'COTEAUX DU LIBRON LES COTEAUX DE BEZIERS'},
  {code: 30016670, wording: 'COTEAUX DU LOIR'},
  {code: 30016387, wording: 'COTEAUX DU LYONNAIS'},
  {code: 30016578, wording: 'COTEAUX DU PONT DU GARD'},
  {code: 688, wording: 'Coteaux du Quercy'},
  {code: 30016671, wording: 'COTEAUX DU VENDOMOIS'},
  {code: 30016839, wording: 'COTEAUX DUQUERCY'},
  {code: 30016727, wording: 'COTEAUX VAROIS EN PROVENCE'},
  {code: 30016788, wording: 'COTEAUXDE L AIN REVERMONT'},
  {code: 30016776, wording: 'COTES CATALANES'},
  {code: 30016777, wording: 'COTES CATALANES PYRENEES'},
  {code: 30016672, wording: 'COTES D\'AUVERGNE'},
  {code: 30016402, wording: 'COTES DE BERGERAC'},
  {code: 30016403, wording: 'COTES DE BLAYE'},
  {code: 30016404, wording: 'COTES DE BORDEAUX'},
  {code: 30016405, wording: 'COTES DE BORDEAUX-SAINT-MACAIRE'},
  {code: 30016406, wording: 'COTES DE BOURG, BOURG ET BOURGEAIS'},
  {code: 30016840, wording: 'COTES DE DURAS'},
  {code: 30016815, wording: 'COTES DE GASCOGNE'},
  {code: 30016816, wording: 'COTES DE GASCOGNE CONDOMOIS'},
  {code: 30016632, wording: 'COTES DE LA CHARITE'},
  {code: 30016708, wording: 'COTES DE MEUSE'},
  {code: 30016841, wording: 'COTES DE MILLAU'},
  {code: 30016842, wording: 'COTES DE MONTRAVEL'},
  {code: 30016728, wording: 'COTES DE PROVENCE'},
  {code: 30016579, wording: 'COTES DE THAU'},
  {code: 30016580, wording: 'COTES DE THAU CAP D\'AGDE'},
  {code: 30016581, wording: 'COTES DE THONGUE'},
  {code: 30016709, wording: 'COTES DE TOUL'},
  {code: 30016673, wording: 'COTES DU FOREZ'},
  {code: 30016556, wording: 'COTES DU JURA'},
  {code: 30016817, wording: 'COTES DU LOT'},
  {code: 30016818, wording: 'COTES DU LOT ROCAMADOUR'},
  {code: 30016843, wording: 'COTES DU MARMANDAIS'},
  {code: 30016756, wording: 'COTES DU RHONE'},
  {code: 30016757, wording: 'COTES DU RHONE VILLAGES'},
  {code: 30016781, wording: 'COTES DU ROUSSILLON'},
  {code: 30016782, wording: 'COTES DU ROUSSILLON VILL'},
  {code: 30016819, wording: 'COTES DU TARN'},
  {code: 30016820, wording: 'COTES DU TARN CABANES'},
  {code: 30016821, wording: 'COTES DU TARN CUNAC'},
  {code: 30016760, wording: 'COTES DU VIVARAIS'},
  {code: 1164, wording: 'Coulée de Serrant'},
  {code: 30016674, wording: 'COUR-CHEVERNY'},
  {code: 30016380, wording: 'CREMANT D’ALSACE'},
  {code: 30016407, wording: 'CREMANT DE BORDEAUX'},
  {code: 30016477, wording: 'CREMANT DE BOURGOGNE'},
  {code: 279, wording: 'Crémant de Die'},
  {code: 30016615, wording: 'CREMANT DE LIMOUX'},
  {code: 30016675, wording: 'CREMANT DE LOIRE'},
  {code: 30016758, wording: 'CREMANT DE PIE'},
  {code: 30016557, wording: 'CREMANT DU JURA'},
  {code: 30016478, wording: 'CRIOTS-BATARD-MONTRACHET'},
  {code: 30016759, wording: 'CROZES-HERMITAGE OU CROZES-ERMITAGE'},
  {code: 30016737, wording: 'DROME'},
  {code: 30016739, wording: 'DROME COTEAUX DE MONTELIMAR'},
  {code: 666, wording: 'Duché d\'Uzès'},
  {code: 30016479, wording: 'ECHEZEAUX'},
  {code: 1016, wording: 'Entraygues - Le Fel'},
  {code: 30016844, wording: 'ENTRAYUES– LE FEL'},
  {code: 30016408, wording: 'ENTRE-DEUX-MERS'},
  {code: 30016845, wording: 'ESTAING'},
  {code: 30016616, wording: 'FAUGERES'},
  {code: 30016676, wording: 'FIEFS VENDEENS'},
  {code: 30016846, wording: 'FITOU'},
  {code: 30016480, wording: 'FIXIN'},
  {code: 30016388, wording: 'FLEURIE'},
  {code: 30016847, wording: 'FLOC DE GASCOGNE'},
  {code: 30016544, wording: 'FRANCHE-COMTE'},
  {code: 30016545, wording: 'FRANCHE-COMTE BUFFARD'},
  {code: 30016546, wording: 'FRANCHE-COMTE COTEAUX DE CHAMPLITTE'},
  {code: 30016547, wording: 'FRANCHE-COMTE DOUBS'},
  {code: 30016548, wording: 'FRANCHE-COMTE GY'},
  {code: 30016549, wording: 'FRANCHE-COMTE HAUTE-SAONE'},
  {code: 30016550, wording: 'FRANCHE-COMTE HUGIER'},
  {code: 30016551, wording: 'FRANCHE-COMTE MOTEY-BESUCHE'},
  {code: 30016552, wording: 'FRANCHE-COMTE OFFLANGES'},
  {code: 30017874, wording: 'FRANCHE-COMTE VUILLAFANS'},
  {code: 30016409, wording: 'FRONSAC'},
  {code: 30016848, wording: 'FRONTON'},
  {code: 30016849, wording: 'GAILLAC'},
  {code: 30016410, wording: 'GAILLAC PREMIERES COTES'},
  {code: 30016582, wording: 'GARD'},
  {code: 30016822, wording: 'GERS'},
  {code: 30016481, wording: 'GEVREY-CHAMBERTIN'},
  {code: 30016761, wording: 'GIGONDAS'},
  {code: 30016482, wording: 'GIVRY'},
  {code: 30016783, wording: 'GRAND ROUSSILLON'},
  {code: 30016483, wording: 'GRANDS-ECHEZEAUX'},
  {code: 30016411, wording: 'GRAVES'},
  {code: 30016412, wording: 'GRAVES DE VAYRES'},
  {code: 30016413, wording: 'GRAVES SUPERIEURES'},
  {code: 30016762, wording: 'GRIGNAN-LES-ADHEMAR'},
  {code: 30016484, wording: 'GRIOTTE-CHAMBERTIN'},
  {code: 843, wording: 'Gros Plant du Pays Nantais'},
  {code: 30016677, wording: 'GROS PLANT DUPAYS NANTAIS'},
  {code: 30016823, wording: 'HAUTE VALLEE DE l\'AUDE'},
  {code: 30016583, wording: 'HAUTE VALLEE DE L\'ORB'},
  {code: 30016527, wording: 'HAUTE-MARNE'},
  {code: 30016716, wording: 'HAUTES-ALPES'},
  {code: 30016633, wording: 'HAUTE-VIENNE'},
  {code: 30016414, wording: 'HAUT-MEDOC'},
  {code: 30016850, wording: 'HAUT-MONTRAVEL'},
  {code: 30016678, wording: 'HAUT-POITOU'},
  {code: 30016763, wording: 'HERMITAGE OU ERMITAGE OU L\'HERMITAGE OU'},
  {code: 30016539, wording: 'ILE DE BEAUTE'},
  {code: 30016485, wording: 'IRANCY'},
  {code: 30016851, wording: 'IROULEGUY'},
  {code: 30016791, wording: 'ISERE'},
  {code: 30016792, wording: 'ISERE BALMES DAUPHINOISES'},
  {code: 30016793, wording: 'ISERECOTEAUX DU GRESIVAUDAN'},
  {code: 30016679, wording: 'JASNIERES'},
  {code: 30016389, wording: 'JULIENAS'},
  {code: 30016852, wording: 'JURANCON'},
  {code: 831, wording: 'La Clape'},
  {code: 30016486, wording: 'LA GRANDE RUE'},
  {code: 30016487, wording: 'LA ROMANEE'},
  {code: 30016488, wording: 'LA TACHE'},
  {code: 30016489, wording: 'LADOIX'},
  {code: 30016415, wording: 'LALANDE-DE-POMEROL'},
  {code: 30016824, wording: 'LANDES'},
  {code: 30016825, wording: 'LANDES COTEAUX DE CHALOSSE'},
  {code: 30016826, wording: 'LANDES COTES DE L\'ADOUR'},
  {code: 30016827, wording: 'LANDES SABLES DE L\'OCEAN'},
  {code: 30016828, wording: 'LANDES SABLES FAUVES'},
  {code: 30016617, wording: 'LANGUEDOC'},
  {code: 30016490, wording: 'LATRICIERES-CHAMBERTIN'},
  {code: 30016829, wording: 'LAVILLEDIEU'},
  {code: 30016584, wording: 'LE PAYS CATHARE'},
  {code: 30016729, wording: 'LES BAUX DE PROVENCE'},
  {code: 30016558, wording: 'L\'ETOILE'},
  {code: 30016618, wording: 'LIMOUX'},
  {code: 283, wording: 'Lirac'},
  {code: 30016764, wording: 'LIRAC MITAGE'},
  {code: 30016416, wording: 'LISTRAC-MEDOC'},
  {code: 30016417, wording: 'LOUPIAC'},
  {code: 30016765, wording: 'LUBERON'},
  {code: 30016418, wording: 'LUSSAC-SAINT-EMILION'},
  {code: 30016491, wording: 'MACON'},
  {code: 30016559, wording: 'MACVIN DU JURA'},
  {code: 30016853, wording: 'MADIRAN'},
  {code: 30016619, wording: 'MALEPERE'},
  {code: 30016492, wording: 'MARANGES'},
  {code: 30016419, wording: 'MARCILLAC'},
  {code: 30016420, wording: 'MARGAUX'},
  {code: 30016493, wording: 'MARSANNAY'},
  {code: 30016717, wording: 'MAURES'},
  {code: 30016784, wording: 'MAURY'},
  {code: 30016494, wording: 'MAZIS-CHAMBERTIN'},
  {code: 30016495, wording: 'MAZOYERES-CHAMBERTIN'},
  {code: 863, wording: 'Méditerranée'},
  {code: 30016421, wording: 'MEDOC'},
  {code: 30016680, wording: 'MENETOU-SALON'},
  {code: 30016496, wording: 'MERCUREY'},
  {code: 30016497, wording: 'MEURSAULT'},
  {code: 30016620, wording: 'MINERVOIS'},
  {code: 30016621, wording: 'MINERVOIS-LA LIVINIERE'},
  {code: 30016422, wording: 'MONBAZILLAC'},
  {code: 30016718, wording: 'MONT CAUME'},
  {code: 30016423, wording: 'MONTAGNE-SAINT-EMILION'},
  {code: 30016498, wording: 'MONTAGNY'},
  {code: 30016499, wording: 'MONTHELIE'},
  {code: 30016681, wording: 'MONTLOUIS-SUR-LOIRE'},
  {code: 30016500, wording: 'MONTRACHET'},
  {code: 30016854, wording: 'MONTRAVEL'},
  {code: 30016501, wording: 'MOREY-SAINT-DENIS'},
  {code: 30016390, wording: 'MORGON'},
  {code: 30016710, wording: 'MOSELLE'},
  {code: 30016391, wording: 'MOULIN-A-VENT'},
  {code: 30016424, wording: 'MOULIS OU MOULIS-EN-MEDOC'},
  {code: 30016682, wording: 'MUSCADET'},
  {code: 30016683, wording: 'MUSCADET COTEAUX DE LA LOIRE'},
  {code: 30016684, wording: 'MUSCADET COTES DE GRANDLIEU'},
  {code: 30016685, wording: 'MUSCADET SEVRE ET MAINE'},
  {code: 30016766, wording: 'MUSCAT DE BEAUMES DE-VENISE'},
  {code: 30016622, wording: 'MUSCAT DE FRONTIGNAN ORFRONTIGNAN OR VIN DE FRONTIGNAN'},
  {code: 30016623, wording: 'MUSCAT DE LUNEL'},
  {code: 30016624, wording: 'MUSCAT DE MIREVAL'},
  {code: 30016785, wording: 'MUSCAT DE RIVESALTES'},
  {code: 30016625, wording: 'MUSCAT DE SAINT-JEAN-DE MINERVOIS'},
  {code: 312, wording: 'Muscat du Cap Corse'},
  {code: 30016541, wording: 'MUSCAT DU CAP CORSE CHARENTAIS'},
  {code: 30016502, wording: 'MUSIGNY'},
  {code: 30006350, wording: 'NON CLASSE'},
  {code: 30016503, wording: 'NUITS-SAINT-GEORGES'},
  {code: 30016686, wording: 'ORLEANS'},
  {code: 30016687, wording: 'ORLEANS-CLERY'},
  {code: 30016855, wording: 'PACHERENCDU VIC-BILH'},
  {code: 30016730, wording: 'PALETTE'},
  {code: 30016542, wording: 'PATRIMONIO'},
  {code: 30016425, wording: 'PAUILLAC'},
  {code: 1169, wording: 'Pays de Brive'},
  {code: 885, wording: 'Pays des Bouches-du-Rhône'},
  {code: 30016585, wording: 'PAYS D\'HERAULT'},
  {code: 30016589, wording: 'PAYS D\'HERAULT BENOVIE'},
  {code: 30016590, wording: 'PAYS D\'HERAULT BERANGE'},
  {code: 30016591, wording: 'PAYS D\'HERAULT CASSAN'},
  {code: 30016592, wording: 'PAYS D\'HERAULT CESSENON'},
  {code: 30016593, wording: 'PAYS D\'HERAULT COLLINESDE LA MOURE'},
  {code: 30016594, wording: 'PAYS D\'HERAULT COTEAUXDE BESSILLES'},
  {code: 30016595, wording: 'PAYS D\'HERAULT COTEAUXDE FONTCAUDE'},
  {code: 30016596, wording: 'PAYS D\'HERAULT COTEAUXDE LAURENS'},
  {code: 30016597, wording: 'PAYS D\'HERAULT COTEAUXDE MURVIEL'},
  {code: 30016586, wording: 'PAYS D\'HERAULT COTEAUXDU SALAGOU'},
  {code: 30016587, wording: 'PAYS D\'HERAULT COTES DUBRIAN'},
  {code: 30016598, wording: 'PAYS D\'HERAULT COTES DUCERESSOU'},
  {code: 30016599, wording: 'PAYS D\'HERAULT MONT BAUDILE'},
  {code: 30016600, wording: 'PAYS D\'HERAULT MONTS DELA GRAGE'},
  {code: 30016601, wording: 'PAYS D\'HERAULT PAYS DEBESSAN'},
  {code: 30016602, wording: 'PAYS D\'HERAULT PAYS DECAUX'},
  {code: 30016588, wording: 'PAYS D\'OC'},
  {code: 30016426, wording: 'PECHARMANT'},
  {code: 30016830, wording: 'PERIGORD'},
  {code: 30016831, wording: 'PERIGORD DORDOGNE'},
  {code: 30017877, wording: 'PERIGORD VIN DE DOMME'},
  {code: 30016504, wording: 'PERNAND-VERGELESSES'},
  {code: 30016427, wording: 'PESSAC-LEOGNAN'},
  {code: 30016505, wording: 'PETIT CHABLIS'},
  {code: 833, wording: 'Pic Saint-Loup'},
  {code: 733, wording: 'Picpoul de Pinet'},
  {code: 30016731, wording: 'PIERREVERT'},
  {code: 30016538, wording: 'PINEAU DES'},
  {code: 329, wording: 'Pineau des Charentes ou Pineau Charentais'},
  {code: 30016428, wording: 'POMEROL'},
  {code: 30016506, wording: 'POMMARD'},
  {code: 30016688, wording: 'POUILLY-FUISSE'},
  {code: 30016507, wording: 'POUILLY-FUME OU BLANC FUME DE POUILLY'},
  {code: 30016508, wording: 'POUILLY-LOCHE'},
  {code: 30016689, wording: 'POUILLY-SUR-LOIRE'},
  {code: 30016509, wording: 'POUILLY-VINZELLES'},
  {code: 30016429, wording: 'PREMIERES COTES DE BORDEAUX'},
  {code: 30016430, wording: 'PUISSEGUIN-SAINT-EMILION'},
  {code: 30016510, wording: 'PULIGNY-MONTRACHET'},
  {code: 30016634, wording: 'PUY-DE-DOME'},
  {code: 30016690, wording: 'QUARTS DE CHAUME'},
  {code: 30016691, wording: 'QUINCY'},
  {code: 30016767, wording: 'RASTEAU'},
  {code: 576 , wording: 'RATAFIA DE CHAMPAGNE'},
  {code: 30016392, wording: 'REGNIE'},
  {code: 30016692, wording: 'REUILLY'},
  {code: 30016511, wording: 'RICHEBOURG'},
  {code: 30016786, wording: 'RIVESALTES'},
  {code: 30016512, wording: 'ROMANEE-CONTI'},
  {code: 30016513, wording: 'ROMANEE-SAINT-VIVANT'},
  {code: 30016693, wording: 'ROSE D\'ANJOU'},
  {code: 30016694, wording: 'ROSE DE LOIRE'},
  {code: 30016530, wording: 'ROSE DES RICEYS'},
  {code: 30016856, wording: 'ROSETTE'},
  {code: 30016797, wording: 'ROUSSETTE DE SAVOIE'},
  {code: 30016796, wording: 'ROUSSETTE DU BUGEY'},
  {code: 30016514, wording: 'RUCHOTTES-CHAMBERTIN'},
  {code: 30016515, wording: 'RULLY'},
  {code: 30016603, wording: 'SABLE DE CAMARGUE'},
  {code: 30016393, wording: 'SAINT-AMOUR'},
  {code: 30016516, wording: 'SAINT-AUBIN'},
  {code: 30016517, wording: 'SAINT-BRIS'},
  {code: 30016626, wording: 'SAINT-CHINIAN'},
  {code: 30016431, wording: 'SAINTE-CROIX-DU-MONT'},
  {code: 30016432, wording: 'SAINTE-FOY-BORDEAUX'},
  {code: 30016441, wording: 'SAINTE-MARIE-LA-BLANCHE'},
  {code: 30016433, wording: 'SAINT-EMILION'},
  {code: 30016434, wording: 'SAINT-EMILION GRAND CRU'},
  {code: 30016435, wording: 'SAINT-ESTEPHE'},
  {code: 30016437, wording: 'SAINT-GEORGES-SAINT-EMILION'},
  {code: 30016604, wording: 'SAINT-GUILHEM-LE-DESERT'},
  {code: 30016605, wording: 'SAINT-GUILHEM-LE-DESERTCITE D\'ANIANE'},
  {code: 30016606, wording: 'SAINT-GUILHEM-LE-DESERTVAL DE MONTFERRAND'},
  {code: 30016768, wording: 'SAINT-JOSEPH'},
  {code: 30016438, wording: 'SAINT-JULIEN'},
  {code: 30016857, wording: 'SAINT-MONT'},
  {code: 30016695, wording: 'SAINT-NICOLAS-DE-BOURGUEIL'},
  {code: 30016769, wording: 'SAINT-PERAY'},
  {code: 30016696, wording: 'SAINT-POURCAIN'},
  {code: 30016518, wording: 'SAINT-ROMAIN'},
  {code: 30016858, wording: 'SAINT-SARDOS'},
  {code: 30016519, wording: 'SAINT-VERAN'},
  {code: 30016697, wording: 'SANCERRE'},
  {code: 30016520, wording: 'SANTENAY'},
  {code: 30016442, wording: 'SAONE-ET-LOIRE'},
  {code: 30016698, wording: 'SAUMUR'},
  {code: 30016699, wording: 'SAUMUR-CHAMPIGNY'},
  {code: 30016859, wording: 'SAUSSIGNAC'},
  {code: 30016439, wording: 'SAUTERNES'},
  {code: 30016701, wording: 'SAVENNIERES'},
  {code: 30016702, wording: 'SAVENNIERES COULEE DE SERRANT'},
  {code: 30016703, wording: 'SAVENNIERES ROCHE AUX MOINES'},
  {code: 30016521, wording: 'SAVIGNY-LES-BEAUNE'},
  {code: 30016798, wording: 'SEYSSEL'},
  {code: 30016770, wording: 'TAVEL'},
  {code: 30016627, wording: 'TERRASSES DU LARZAC'},
  {code: 1182, wording: 'TERRES DU MIDI'},
  {code: 30016833, wording: 'THEZAC-PERRICARD'},
  {code: 30016704, wording: 'TOURAINE'},
  {code: 30016705, wording: 'TOURAINE NOBLE JOUE'},
  {code: 30016860, wording: 'TURSAN'},
  {code: 30016635, wording: 'URFE'},
  {code: 30016636, wording: 'URFE AMBIERLEROSE'},
  {code: 30016637, wording: 'URFE TRELINS'},
  {code: 30016771, wording: 'VACQUEYRAS'},
  {code: 30016638, wording: 'VAL DE LOIRE'},
  {code: 30016639, wording: 'VAL DE LOIRE ALLIER'},
  {code: 30016640, wording: 'VAL DE LOIRE CHER'},
  {code: 30016641, wording: 'VAL DE LOIRE INDRE'},
  {code: 30016642, wording: 'VAL DE LOIRE INDRE-ET-LOIRE'},
  {code: 30016644, wording: 'VAL DE LOIRE LOIRE-ATLANTIQUE'},
  {code: 30016645, wording: 'VAL DE LOIRE LOIRET'},
  {code: 30016643, wording: 'VAL DE LOIRE LOIR-ET-CHER'},
  {code: 30016646, wording: 'VAL DE LOIRE MAINE-ET-LOIRE'},
  {code: 30016647, wording: 'VAL DE LOIRE MARCHES DE BRETAGNE'},
  {code: 30016648, wording: 'VAL DE LOIRE NIEVRE'},
  {code: 30016649, wording: 'VAL DE LOIRE PAYS DE RETZ'},
  {code: 30016650, wording: 'VAL DE LOIRE SARTHE'},
  {code: 30016651, wording: 'VAL DE LOIRE VENDEE'},
  {code: 30016652, wording: 'VAL DE LOIRE VIENNE'},
  {code: 30016706, wording: 'VALENCAY'},
  {code: 30016607, wording: 'VALLEE DU PARADIS'},
  {code: 30016608, wording: 'VALLEE DU TORGAN'},
  {code: 30016719, wording: 'VAR'},
  {code: 30016720, wording: 'VAR ARGENS'},
  {code: 30016721, wording: 'VAR COTEAUX DU VERDON'},
  {code: 30016722, wording: 'VAR SAINTE BAUME'},
  {code: 30016742, wording: 'VAUCLUSE'},
  {code: 30016743, wording: 'VAUCLUSE AIGUES'},
  {code: 30016744, wording: 'VAUCLUSE PRINCIPAUTE D\'ORANGE'},
  {code: 30016772, wording: 'VENTOUX'},
  {code: 1170, wording: 'Vézelay'},
  {code: 30016609, wording: 'VICOMTE D\'AUMELAS'},
  {code: 30016610, wording: 'VICOMTE D\'AUMELAS VALLEE DOREE'},
  {code: 30016543, wording: 'VIN DE CORSE OU CORSE'},
  {code: 30016799, wording: 'VIN DE SAVOIE'},
  {code: 30016794, wording: 'VIN DES ALLOBROGES'},
  {code: 30016834, wording: 'VINS DE LACORREZE'},
  {code: 30016774, wording: 'VINS DE PROVENCEET DE CORSE – MEDITERRA'},
  {code: 30016773, wording: 'VINSOBRES'},
  {code: 30016522, wording: 'VIRE-CLESSE'},
  {code: 30016523, wording: 'VOLNAY'},
  {code: 30016524, wording: 'VOSNE-ROMANEE'},
  {code: 30016525, wording: 'VOUGEOT'},
  {code: 30016707, wording: 'VOUVRAY'},
  {code: 30016443, wording: 'YONNE'},
  {code: 30018494, wording: 'COTES DE BORDEAUX CASTILLON'}];
