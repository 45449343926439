export const nutrimentMeasurementPrecisionConst = [
  {
    code: 'APPROXIMATELY',
    wording: 'Approximativement'
  },
  {
    code: 'LESS_THAN',
    wording: 'Inférieur à'
  },
  {
    code: 'EQUAL',
    wording: 'Égale'
  }
];
