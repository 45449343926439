import {Component, ViewChild, OnInit} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {DashboardConst} from '../../models/dashboard.constants';
import {Product} from '../../models/product.model';
import {ProductsSvc} from '../../services/products.service';
import {ClassificationSvc} from '../../../shared/services/classification.service';
import {Classification} from '../../../shared/models/Classification.model';
import {NotificationsSvc} from '../../../shared/services/index';
import {Router, ActivatedRoute} from '@angular/router';

import * as _ from 'lodash';

@Component({
  selector: `classification-modal`,
  styleUrls: [`./classification-modal.less`],
  template: `
      <div>
          <bs-modal class="classificationModal" [keyboard]="false" [backdrop]="'static'" [size]="'md'"
                 #productsExportModal>

              <bs-modal-header [showDismiss]="true">
                  <div class="modal-title">{{constants.classificationModal.title}}</div>
              </bs-modal-header>
              <bs-modal-body>
                  <div class="row">
                      <div class="col-xs-12">
                          <span class="classification-description">{{constants.classificationModal.description}}</span>
                      </div>
                      <div class="col-xs-6 col-xs-offset-6 info-message"
                           [innerHTML]="constants.classificationModal.infoMessage"></div>
                      <div class="col-xs-12 classification-menu-container">
                          <div class="col-xs-6">
                              <button type="button" class="classification-menu-item" (click)="goToCreationVCSPage('0')">
                                  {{constants.classificationModal.allProducts}}
                              </button>
                          </div>
                          <div class="col-xs-6">
                              <button type="button" class="classification-menu-item" (click)="selectVcsMenu()"
                                      [hidden]="menuVcsSelected"
                                      [innerHTML]="constants.classificationModal.productsVcs"></button>
                              <ul class="classification-menu-sub-container" [hidden]="!menuVcsSelected">
                                  <li *ngFor="let classification of classifications "
                                      [ngClass]="{'fade-in': menuVcsSelected}">
                                      <button type="button" class="classification-menu-sub-item"
                                              (click)="goToCreationVCSPage(classification.code)">
                                          {{classification.wording}}
                                      </button>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </bs-modal-body>
          </bs-modal>
      </div>
  `
})
export class ClassificationModal implements OnInit {

  public constants = DashboardConst;

  @ViewChild(BsModalComponent, {static: true})
  public modal: BsModalComponent;
  public menuVcsSelected = false;
  public classifications: Classification[];

  constructor(private router: Router, private route: ActivatedRoute, private classificationSvc: ClassificationSvc) {
  }

  public ngOnInit(): void {
    this.classificationSvc.getClassifications().subscribe(
      (classifications: Classification[]) => {
        this.classifications = _.filter(classifications, (item) => item.code !== '0');

      });
  }

  public selectVcsMenu() {
    this.menuVcsSelected = true;
  }

  public show() {
    this.menuVcsSelected = false;
    this.modal.open();
  }

  public goToCreationVCSPage(codeGpc) {
    this.modal.dismiss();
    this.router.navigate(['dashboard/creationvcs/consumer', codeGpc], {relativeTo: this.route.parent});
  }
}
