export const LoginConstants = {
  disclaimer: {
    title: 'La gestion et l\'impression de vos codes-barres en toute simplicité',
    titleVcs: `Pour vous, CodeOnline évolue afin de répondre spécifiquement à vos attentes: CodeOnline spécialement pensé pour les produits vins et spiritueux.`,
    filiere: 'Choisissez votre filière :',
    checkBoxText: 'J’ai lu et j’accepte les conditions générales d’utilisation.\n' ,
    preferences: [
      {
        id: 'OTHERS',
        text: 'tous types de Marchandises'
      },
      {
        id: 'VCS',
        text: 'Vins Champagnes et spiritueux'
      }
    ]
  },
  tutorialSlides: [
    {
      img: 'entrepot.png',
      title: 'Créez simplement vos codes-barres',
      text: `Gérez la codification de vos produits et imprimez vos codes-barres <b>en 3 clics</b>`,
      link: `Passez l'introduction`
    },
    {
      img: 'chaussures.png',
      title: 'Retrouvez tout facilement',
      text: `Retrouvez l'ensemble de vos codes-barres sur un site unique, <b>accessible partout, tout le temps</b>.
Bénéficiez d’une vision précise de l’utilisation et de la disponibilité de vos codes`,
      link: `Passez l'introduction`
    },
    {
      img: 'zen.png',
      title: 'Soyez serein !',
      text: `Ayez la certitude que vos codes seront <b>conformes aux attentes</b> de vos partenaires commerciaux`,
      link: `Passez l'introduction`
    },
    {
      img: 'chaise.png',
      title: 'Augmentez votre visibilité',
      text: `Ajoutez du contenu dans les fiches produits rattachées à vos codes et soyez visible dans la <b>base mondiale de référence des produits</b>`,
      link: `Passez l'introduction`
    }
  ]
};
