import {Component} from '@angular/core';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {Router} from '@angular/router';
import {CompanySvc, NotificationsSvc} from '../../../shared/services/index';
import {DashboardConst} from '../../models/dashboard.constants';
import {Gtin} from '../../../shared/models';
import {Observable} from 'rxjs';
import gtinUtils from '../../../shared/utils/gtin';
import {ProductsSvc} from '../../../dashboard/services';
import * as _ from 'lodash';

@Component({
  selector: 'settings-prefixes-view',
  styleUrls: ['./settings-prefixes.less'],
  templateUrl: './settings-prefixes-view.tpl.html'
})
export class SettingsPrefixesComponent {
  public constants = DashboardConst;
  public prefixes = [];

  constructor(public router: Router, public headerSvc: DashboardHeaderSvc, public companySvc: CompanySvc, public notificationSvc: NotificationsSvc, private productsSvc: ProductsSvc) {
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData('Paramètres de création de GTIN en nombre', 'assets/icons/editCode.svg');

    this.companySvc.getCompanyPreferencePrefixes().subscribe((data) => {
      this.prefixes = _.map(data.prefixes, (prefixInfo) => {
        let prefix = '';
        let cip = '';
        let key = '';
        let available = false;
        if (prefixInfo.startGtin !== -1 && prefixInfo.startGtin !== '-1') {
          prefix = prefixInfo.prefix;
          cip = this.getCipFromGtin(prefixInfo.startGtin, prefix);
          key = this.getKeyFromGtin(prefixInfo.startGtin);
          available = true;
        }
        prefixInfo.gtin = new Gtin(prefix, cip, key, available);
        prefixInfo.error = {
          message: '',
          box: false
        };
        if (typeof prefixInfo.resumeCodification === 'undefined') {
          prefixInfo.resumeCodification = false;
        }
        return prefixInfo;
      });
    });
  }

  public gotoDashboard() {
    this.router.navigate(['dashboard']);
  }

  public updatePrefixes() {
    if (this.getNbrActivePrefixes() === 0) {
      this.notificationSvc.selectPrefixMin();
      return;
    }

    if (this.checkErrors()) {
      return;
    }

    const prefixes = _.map(this.prefixes, (prefix) => {
      const updatedPrefix = {...prefix};
      if (updatedPrefix.resumeCodification && updatedPrefix.startGtin !== -1) {
        updatedPrefix.startGtin = updatedPrefix.gtin.toString();
      }

      delete updatedPrefix.gtin;
      delete updatedPrefix.error;

      if (updatedPrefix.resumeCodification && updatedPrefix.startGtin === -1) {
        updatedPrefix.resumeCodification = false;
      }

      return updatedPrefix;
    });
    this.companySvc.updateCompany({preferencePrefixes: prefixes})
      .then(() => {
        this.router.navigate(['/dashboard']);
      })
      .catch((err) => {
        this.notificationSvc.preferencesError();
      });
  }

  public onValueChange(prefix, index) {
    if (!prefix.active) {
      prefix.resumeCodification = false;
      if (this.getNbrActivePrefixes() === 0) {
        this.prefixes[index] = {
          ...prefix,
          active: true
        };
        this.notificationSvc.selectPrefixMin();
      }
    }
  }

  public getCipFromGtin(gtin = '', prefix) {
    return gtin.substring(prefix.length, gtin.length - 1);
  }

  public getKeyFromGtin(gtin = '') {
    return gtin.substring(gtin.length - 1, gtin.length);
  }

  public getValidationPattern(cipSize) {
    return '[0-9]{' + cipSize + '}';
  }

  public checkCipAvailability(gtin: Gtin): Observable<boolean> {
    // Generating the control key
    gtin.key = gtinUtils.generateEAN13ControlKey(gtin.prefix + gtin.cip);
    // Getting the products with that GTIN
    const newGtin = gtin.prefix + gtin.cip + gtin.key;
    return this.productsSvc.checkGtinAvailability(newGtin);
  }

  public showHeader(): boolean {
    for (const prefix of this.prefixes) {
      if (prefix.resumeCodification) {
        return true;
      }
    }
    return false;
  }

  public onCIPChange(prefix) {
    prefix.error.box = false;
    // Test if cip only contains numbers
    if (!/^([0-9]*)$/.test(prefix.gtin.cip)) {
      prefix.gtin.available = false;
      prefix.error.message = this.constants.settingsErrors.cipType;
      return;
    } else {
      prefix.gtin.available = true;
      prefix.error.message = '';
    }

    // Do nothing if the cip length is not correct
    if (prefix.gtin.cip.length < prefix.gtin.cipSize) {
      prefix.gtin.available = false;
      prefix.error.message = this.constants.settingsErrors.cipSize.replace('$$size$$', prefix.gtin.cipSize);
      return;
    }

    return this.checkCipAvailability(prefix.gtin)
      .subscribe(
        cipAvailable => {
          prefix.gtin.available = cipAvailable;
          if (!cipAvailable) {
            prefix.error.message = this.constants.settingsErrors.cipUsed;
          }
        },
        err => console.error(err)
      );
  }


  private getNbrActivePrefixes() {
    return _.filter(this.prefixes, {active: true}).length;
  }

  private checkErrors() {
    return _.filter(this.prefixes, (prefix) => {
      if (!prefix.active) {
        return false;
      }
      if (prefix.error.message !== '' && prefix.error.message !== this.constants.settingsErrors.cipUsed) {
        prefix.error.box = true;
        return true;
      }
      return false;
    }).length !== 0;
  }
}
