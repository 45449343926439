import {Component, Input, ElementRef} from '@angular/core';

import * as _ from 'lodash';

@Component({
    selector: 'attributes-checker',
    styleUrls: ['attributes-checker.less'],
    template: `
        <div class="check-atts-component">
            <div class="check-atts-component-title" [attr.data-qa]="getBadgeTitle()">
                {{getTitleWording1ForCompletion()}}
                <img class="check-atts-badge-preview" *ngIf="getCompletionStatus() != 100"
                     src="assets/icons/vbg-checked.svg"/>
                {{getTitleWording2ForCompletion()}}
            </div>
            <div class="check-atts-component-subTitle"
                 data-qa="badge-subtitle">{{getSubtitleWordingForCompletion()}}</div>
            <div class="check-atts-component-completion" qata-qa="banner-completion">
                <div class="barContainer">
                    <div [style.width.%]="getCompletionStatus()" class="check-atts-completion-bar"></div>
                </div>
                {{getCompletionStatus()}}%
            </div>
            <div class="check-atts-component-attributes">
                <div class="check-atts-component-attribute" *ngFor="let attribute of getVBGAttributes();">
                    <img [attr.data-qa]="attribute+'-img'"
                         *ngIf="getAttributeCompletion(attribute) && attribute !== 'imagesAddedByUrl'"
                         src="assets/icons/check-att.png"/>
                    <div [attr.data-qa]="attribute+'-value'" *ngIf="attribute !== 'imagesAddedByUrl'"
                         class="attribute-label" [ngClass]="{'checked': getAttributeCompletion(attribute)}"
                         (click)="focusAttribute(attribute)">
                        {{getWordingForVBGAttributes(attribute)}}
                    </div>
                </div>
            </div>
            <div class="check-atts-learnMore-container" data-qa="link">
                <a href="https://www.gs1.fr/Notre-offre/Verified-by-GS1-la-carte-d-identite-digitale-de-vos-produits"
                   target="_blank">En savoir plus</a>
            </div>
        </div>
    `
})

export class AttributesCheckerComponent {
    @Input() public productForm;
    @Input() public isBundle;

    constructor(private elRef: ElementRef) {
    }

    public getCompletionStatus() {
        let counter = 0;
        this.getVBGAttributes().forEach(a => {
            counter = counter + (this.getAttributeCompletion(a) ? (100 / this.getVBGAttributes().length) : 0);
        });
        return Number.parseFloat(counter.toFixed(2));
    }

    public getBadgeTitle(): string {
        if (this.getCompletionStatus() !== 100) {
            return 'badge-title-not-complete';
        }
        return 'badge-title-complete';
    }

    public getTitleWording1ForCompletion() {
        return this.getCompletionStatus() === 100 ? 'Bravo, votre carte d\'identité GS1 est complète !' : 'Obtenez le badge';
    }

    public getTitleWording2ForCompletion() {
        return this.getCompletionStatus() === 100 ? '' : '"Carte d\'identité GS1"';
    }

    public getSubtitleWordingForCompletion() {
        return this.getCompletionStatus() === 100 ? '' : 'Gagnez en visibilité une fois votre carte d\'identité complète et publiée !';
    }

    public getVBGAttributes() {
        return ['codeGpc', 'brand', 'targetMarket', 'productNames',  'netContents', 'imagesToUpload'];
    }

    public getAttributeCompletion(attribute) {
        if (attribute === 'codeGpc') {
            return this.productForm.codeGpc.length && this.productForm.codeGpc !== '99999999';
        }

        if (attribute === 'imagesToUpload') {
            const notEmptyImageUrls = _.filter(this.productForm.images, i => !i.isEmpty() && !i.hasError());
            const notEmptyImagesAddedByUrlUrls = _.filter(this.productForm.imagesAddedByUrl, i => !i.isEmpty() && !i.hasError());
            return (this.productForm[attribute].length + notEmptyImageUrls.length + notEmptyImagesAddedByUrlUrls.length) > 0;
        }

        if (attribute === 'netContents') {
            const NET_CONTENT_PATTERN = '^([0-9]{1,}(,\\d{1,2})?)$';
            const decimalRegex = new RegExp(NET_CONTENT_PATTERN);
            return this.productForm.netContents[0].netContent.length && decimalRegex.test(this.productForm.netContents[0].netContent);
        }

        if (attribute === 'productNames') {
            const productNames = _.filter(this.productForm.productNames, (element) => !element.isEmpty());
            return !_.isEmpty(productNames);
        }


        return this.productForm[attribute].length;

    }

    public getWordingForVBGAttributes(vbgAttribute): [string] {
        const wordings = {
            codeGpc: `Catégorie ${this.isBundle ? 'de l\'assortiment' : 'produit'}`,
            brand: 'Marque principale',
            targetMarket: 'Pays de vente',
            productNames: `Nom ${this.isBundle ? 'de l\'assortiment' : ' du produit' }`,
            netContents: 'Contenu net',
            imagesToUpload: 'Image'
        };
        return wordings[vbgAttribute] || '';
    }

    public focusAttribute(attribute) {
        const parentNode = this.elRef.nativeElement.parentElement.parentElement;
        const htmlIds = {
            codeGpc: 'codeGpc',
            brand: 'brand',
            productNames: 'product-name-item-value-0',
            netContents: ['volume', 'net-content-item-input-0'],
            imagesToUpload: 'image'
        };

        if (attribute === 'netContents') {
            let netContentElement = parentNode.querySelector(`#${htmlIds[attribute][0]}`);
            if (netContentElement) {
                netContentElement.focus();
            } else {
                netContentElement = parentNode.querySelector(`#${htmlIds[attribute][1]}`);
                if (netContentElement) {
                    netContentElement.focus();
                }
            }

            return;
        }
        const scrollToElement = parentNode.querySelector(`#${htmlIds[attribute]}`);
        if (scrollToElement) {
            if (attribute === 'imagesToUpload' || attribute === 'codeGpc') {
                scrollToElement.scrollIntoView({
                    block: 'center'
                });
            } else {
                // focus the shit
                scrollToElement.focus();
            }
        }

    }
}
