import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'regulatory-modal',
  templateUrl: 'regulatory-modal.component.html',
  styleUrls: ['./regulatory-modal.component.less']
})
export class RegulatoryModal {
  @Input() public product: any;

  constructor(
    public _router: Router
  ) {

  }

  public editProduct() {
    const routeName = 'dashboard/product/update';
    this._router.navigate([routeName, this.product.id]).then(res => {
      setTimeout(() => {
        const elementScroll = document.getElementById('defineScrollbar');
        const element = document.getElementById('regulatory-agreement');
        if (elementScroll) elementScroll.scroll({
          left: 0,
          top:  element.offsetTop - 78
        });
      }, 10);

    });
  }
}
