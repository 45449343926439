import {Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';

@Component({
  selector: `product-import-modal`,
  styleUrls: [`./product-import-modal.less`],
  template: `
      <div>
          <bs-modal class="productsImportModal" [keyboard]="false" [backdrop]="'static'" [size]="'md'"
                 #productsImportModal>
                <bs-modal-header [showDismiss]="true">
                    <div class="productsImportModal-title">
                        Import de fichier CSV
                    </div>
                </bs-modal-header>
                <bs-modal-body>
                    <div class="import-container">
                        <div class="import-generic">
                            <div class="import-generic-description">Modèle générique</div>
                            <p><i>Nom produit, Marques, Images, Description, Lien url, MPN, Code interne/SKU</i></p>
                            <button class="import-generic-button" (click)="importProducts()">Importer
                            </button>
                        </div>
                        <div class="import-vcs">
                            <div class="import-vcs-description">Modèle <br/> Vin, Champagne, Spiritueux.</div>
                            <div class="import-vcs-info">Import non disponible à ce jour merci de passer par la création manuelle</div>
                        </div>
                    </div>
                </bs-modal-body>
          </bs-modal>
      </div>
  `
})
export class ProductImportModal {

  @ViewChild(BsModalComponent, {static: true})
  public modal: BsModalComponent;

  @Output() public onImportProducts = new EventEmitter();

  constructor() {
  }

  public show() {
    this.modal.open();
  }

  public importProducts() {
    this.modal.close();
    this.onImportProducts.emit(null);
  }
}
