import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';

import {DashboardConst, FormInputError, ProductFormModel} from './index';
import {ProductsSvc} from '../services/index';
import {CompanySvc, NotificationsSvc, UserSvc} from '../../shared/services/index';
import {Company, Gtin} from '../../shared/models/index';
import GtinUtils from '../../shared/utils/gtin';
import {Countries} from './country.constants';
import {FeatureToggleSvc} from '../../shared/services/feature-toggle.service';
import {NetContent} from './NetContent.model';
import {ImageUrl} from './ImageUrl.model';
import {finalize} from 'rxjs/operators';
import {ProductName} from './ProductName.model';

import * as _ from 'lodash';
import { Prefix } from 'src/app/shared/models/prefix.model';
import gtinUtils from '../../shared/utils/gtin';

export class BaseCreationUnityForm {

  public DECIMAL_PATTERN = '^([0-9]{1,2}(,\\d{1})?|100)$';
  public VOLUME_PATTERN = '^([0-9]{1,}(,\\d{1})?)$';
  public productForm: any;
  public cipPattern: string;
  public constants = DashboardConst;
  public submitting: boolean = false;
  public cipLoaded: boolean = false;
  public countries = Countries;
  public imgIsLoaded: boolean = false;
  public lastVisitedStep: number = 1;
  public sortedPrefixes: Prefix[];
  public isNoGtinAvailable: boolean = false;
  public isCipErrorNotifAllowed: boolean = false;

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notifsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc, public userSvc: UserSvc) {
  }

  public resetFormProduct() {
    this.productForm = new ProductFormModel(new Gtin(_.head(this.companySvc.company.prefix), '', '', false, false), new FormInputError('', ''), '', '', '', '', '', [new ProductName('', 'fr')], [], '', '', 0, this.companySvc.company.id, ['250'], this.companySvc.company.selectedCategory.code, false, this.companySvc.company.selectedCategory.text, [
      new NetContent('', 'H87')
    ], true, 0, [new ImageUrl('')]);
  }

  public resetFormAsBaseProduct() {
    this.productForm = new ProductFormModel(this.productForm.gtin, this.productForm.cipError, this.productForm.mpn, this.productForm.sku, this.productForm.brand, this.productForm.subBrand, this.productForm.name, this.productForm.productNames, this.productForm.imagesToUpload, this.productForm.description, this.productForm.webLink, this.productForm.completionLevel, this.productForm.companyId, this.productForm.targetMarket, this.productForm.codeGpc, this.productForm.isPrivate, this.productForm.category, this.productForm.netContents,
      this.productForm.adhereToTheSectoralAgreement, this.productForm.vbGs1CompletionLevel, this.productForm.imagesAddedByUrl, this.productForm.productResellerType, this.productForm.originGtin);
  }

  public updateFormWithCompanyFields(company: Company) {
    this.productForm.gtin = new Gtin(company.mainPrefix, '', '', false, false);
    this.productForm.companyId = company.id;
  }

  public updateCipError(cipAvailable: boolean, noGtinAvailable: boolean = false) {
    if (!_.isNil(cipAvailable)) {
      this.productForm.cipError = cipAvailable === true ? new FormInputError('valid', '') : new FormInputError('warning', this.constants.createForm.errors.cipUsed);
    }
    if (noGtinAvailable) {
      this.productForm.cipError = new FormInputError('warning', this.constants.createForm.errors.noGtinAvailable);
    }
  }

  public getFirstGtinAvailable(prefix: string) {
    this.cipLoaded = false;
    return this.productsSvc.getFirstGtinsAvailable(prefix)
      .subscribe(
        result => {
          this.setNextGtin(result);
          this.cipLoaded = true;
          return null;
        },
        err => {
          if (err.name === 'ReferenceError' || err.status === 404) {
            this.setNoGtinError();
          } else if (err.status !== 401) {
            this.notifsSvc.serverError();
          }
          this.productForm.gtin.cip = '';
          this.cipPattern = '[0-9]{' + this.productForm.gtin.cipSize + '}';
          this.cipLoaded = true;
        });
  }

  public setNextGtin(gtin: Gtin) {
    this.productForm.gtin = gtin;
    this.cipPattern = '[0-9]{' + this.productForm.gtin.cipSize + '}';
    this.updateCipError(gtin.available);
  }

  public setNoGtinError(){
    this.isNoGtinAvailable = true;
    if (this.isCipErrorNotifAllowed) this.notifsSvc.noGtinAvailable();
    this.updateCipError(null, true);
  }

  public setSortedPrefixes(): Subscription {
    this.cipLoaded = false;
    return this.companySvc.getCompanySortedPrefixes().subscribe(sortedPrefixes => {
      this.sortedPrefixes = sortedPrefixes;
      const selectedPrefix = this.sortedPrefixes[0].code;
      this.productForm.gtin.prefix = selectedPrefix;
      this.processSelectedPrefix(selectedPrefix);
      this.cipLoaded = true;
    });
  }

  public processSelectedPrefix(prefix: string): void {
    const prefixData: Prefix = this.getPrefixData(prefix);
    if (prefixData.isPrefixInError()) {
      this.getFirstGtinAvailable(prefixData.code);
    } else if (prefixData.isPrefixFull()) {
      this.setNoGtinError();
    } else {
      const nextGtin = gtinUtils.constructGTINObject(prefixData.nextGtin, prefixData.code);
      this.setNextGtin(nextGtin);
    }
  }

  public getPrefixData(prefix: string): Prefix {
    return this.sortedPrefixes.find(p => p.code === prefix);
  }

  public onPrefixChange(prefix: string) {
    this.productForm.gtin.cip = '';
    this.getFirstGtinAvailable(prefix);
  }

  public checkCipAvailability(cip: string): Observable<boolean> {
    // Generating the control key
    this.productForm.gtin.key = GtinUtils.generateEAN13ControlKey(this.productForm.gtin.prefix + cip);
    // Getting the products with that GTIN
    const gtin = this.productForm.gtin.prefix + cip + this.productForm.gtin.key;
    return this.productsSvc.checkGtinAvailability(gtin);
  }

  public onCIPChange(cip: string) {
    // Test if cip only contains numbers
    if (!/^([0-9]*)$/.test(cip)) {
      this.productForm.cipError = new FormInputError('warning', this.constants.createForm.errors.cipType);
      return;
    } else {
      this.productForm.cipError.status = '';
    }

    // Do nothing if the cip length is not correct
    if (cip.length < this.productForm.gtin.cipSize) {
      return;
    }
    return this.checkCipAvailability(cip)
      .subscribe(
        cipAvailable => {
          this.updateCipError(cipAvailable);
          this.productForm.gtin.available = cipAvailable;
        },
        err => console.error(err)
      );
  }

  public onFileChange(files: Object[]): void {
    this.productForm.imagesToUpload = files;
  }

  public submitForm() {
    if (!this.submitting && this.productForm.gtin.available) {
      this.submitting = true;
      this.productsSvc.createProduct(this.productForm)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(
          product => {
            if (!this.userSvc.user.haveSeenProductNames) {
              this.userSvc.updateUser({haveSeenProductNames: true});
            }
            return this._router.navigate(['/dashboard/product/view', product.id]);
          },
          err => {
            if (err.status === 409) {
              switch (err.error.type) {
                case 'IMPORT_IN_PROGRESS' :
                  this.notifsSvc.importInProgress();
                  break;
                case 'PRODUCT_VALIDATION_FAILED' :
                  this.notifsSvc.createProductValidation();
                  if (!this.productForm.gtin.isGtin8) {
                    this.getFirstGtinAvailable(this.productForm.gtin.prefix);
                  }
                  break;

                default:
                  console.error(err);
                  break;
              }
            } else {
              console.error('error to create form ', err);
            }
          }
        );
    }
  }

  public cancelCreation() {
    this.resetFormProduct();
    this._router.navigate(['/dashboard']);
  }

  public onVisibilityChange(value) {
    this.productForm.isPrivate = !value;
  }
}
