
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Classification} from '../models/Classification.model';
import {Observable} from 'rxjs';
import {AuthGuard} from './auth-guard';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class ClassificationSvc {

    constructor(private httpClient: HttpClient, private authGuard: AuthGuard) {}

    public getClassifications(): Observable<Classification[]> {
    const requestUrl = environment.API_URL + '/productClassification';
    // @ts-ignore
      return this.httpClient.get(requestUrl).pipe(
      map((data: any) => data.classification),
      catchError(err => {
       return err;
      }));
  }
}
