// export enum ProductResellerTypes {
//   OWN_BRAND,
//   THIRD_PARTY_BRAND,
//   WITHOUT_BRAND_AND_IS_OWN_BRAND,
//   WITHOUT_BRAND_AND_ISNOT_OWN_BRAND
// }

export const ProductResellerTypes = {
  OWN_BRAND : 'OWN_BRAND',
  THIRD_PARTY_BRAND: 'THIRD_PARTY_BRAND',
  WITHOUT_BRAND_AND_IS_MANUFACTURER: 'WITHOUT_BRAND_AND_IS_MANUFACTURER',
  WITHOUT_BRAND_AND_ISNOT_MANUFACTURER: 'WITHOUT_BRAND_AND_ISNOT_MANUFACTURER',
  BUNDLE : 'BUNDLE',
  NOT_NEW: 'NOT_NEW',
  WITHOUT_BRAND: 'WITHOUT_BRAND'
};
