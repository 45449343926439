import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanySvc, NotificationsSvc, ProductCategorySvc, UserSvc} from '../../../shared/services';
import {oldToNewGpcMapping} from '../../models/oldToNewGpcmapping';
import {ProductsSvc} from '../../services';

@Component({
  selector: 'app-update-category',
  templateUrl: './update-category.component.html',
  styleUrls: ['./update-category.component.less']
})
export class UpdateCategoryComponent implements OnInit {

  public categoryDetails: any = {};
  public isPickedCategory = false;
  public categories: any[] = [];
  public oldCategory: string;
  public oldCodeGpc: string;
  public selectedCategory: any = {};
  public bookmarkedCategories: any[] = [];
  public productId: string;
  constructor(private route: ActivatedRoute,
              private userSvc: UserSvc,
              private productCategorySvc: ProductCategorySvc,
              private companySvc: CompanySvc,
              private productsSvc: ProductsSvc,
              public notificationsSvc: NotificationsSvc,
              public _router: Router) {
  }

  public ngOnInit(): void {
    // Access the resolved data from the route
    this.productId = this.route.snapshot.params['id'];
    this.productsSvc.getProduct(this.productId)
      .subscribe(
        (product: any) => {
          this.oldCodeGpc = product.oldCodeGpc;
          this.oldCategory = product.oldCategory;
          this.getNewCategories();
        },
        err => console.error(err)
      );
  }


  public navigateToClassification() {
    this._router.navigate(['/dashboard/product/classifications']);
  }

  public showDetails = (category: any) => {
    this.categoryDetails = category;
  }

  public updateProductCategory = (category: any) => {
    this.productsSvc.updateProduct(this.productId, {codeGpc: category.code, category: category.text}).subscribe(() => {
      this.notificationsSvc.categoryUpdated();
      this._router.navigate(['/dashboard'], {queryParams: {actionInProgress: true}});
    });
  }
  private getNewCategories = async () => {
    const gpcList = oldToNewGpcMapping[this.oldCodeGpc];
    this.categories = await this.productCategorySvc.getCategoriesWithGpc(gpcList);
  }
}
