import {Component, Input, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {UserSvc} from '../../../shared/services/index';
import {LoginConstants} from '../../models/index';
import {AuthGuard} from '../../../shared/services/auth-guard';
import {Router} from '@angular/router';
import 'jquery';
import 'bootstrap';

@Component({
  selector: 'account-modal',
  styleUrls: ['./account-modal.less'],
  templateUrl: './account-modal.html'
})
export class AccountModal implements OnInit {
  @Input() public accounts;
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;
  public constants = LoginConstants;
  public guid: string;

  @Output() public onValidate = new EventEmitter<any>();

  constructor(private userSvc: UserSvc, private router: Router) {
  }

  public openModal() {
    this.modal.open('lg');
    this.modal.cssClass = 'modal-displayed';
  }

  public ngOnInit(): void {
    this.openModal();
    this.modal.onDismiss.subscribe(() => {
      this.userSvc.disconnectUser(true);
    });
  }

  public validateGuid() {
    if (this.guid) {
      this.modal.close();
      this.onValidate.emit({guid: this.guid});
    }
  }
}
