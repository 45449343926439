import {AfterContentInit, Component, EventEmitter, Output, ViewEncapsulation} from '@angular/core';

import {DashboardConst} from '../../../../models/dashboard.constants';
import {CompanySvc, FeatureToggleSvc, NotificationsSvc, UserSvc} from '../../../../../shared/services';
import {Router} from '@angular/router';
import {ProductTypes} from '../../../../models';
import {ProductsSvc} from '../../../../services';
import {InitiatorStepComponent} from '../InitiatorStepComponent';

import * as _ from 'lodash';

@Component({
    selector: 'second-step',
    styleUrls: ['./second-step.less'],
    templateUrl: './second-step.tpl.html',
    encapsulation: ViewEncapsulation.None
})
export class SecondStepComponent extends InitiatorStepComponent implements AfterContentInit {

    public stepCount: number = 6;
    public constants = DashboardConst;
    public cipPattern: string;
    public barcodeUrl: string = '';
    public canShowChoicePrefix: boolean = false;
    public imgIsLoaded: boolean = false;
    public adhereToTheSectoralAgreement: boolean = true;
    public isGeneric: boolean;
    public isVcs: boolean;
    public isFood: boolean;
    public layoutData: any;
    @Output() public onForceToGoToLastStep = new EventEmitter<any>();

    constructor(public productsSvc: ProductsSvc,
                public companySvc: CompanySvc,
                public _router: Router,
                public notificationsSvc: NotificationsSvc,
                public featureToggleSvc: FeatureToggleSvc,
                public userSvc: UserSvc) {
        super(productsSvc, companySvc, _router, notificationsSvc, featureToggleSvc, userSvc);
    }

    public ngAfterContentInit() {
        this.isGeneric = this.productType === ProductTypes.GENERIC;
        this.isVcs = this.productType === ProductTypes.VCS;
        this.isFood = this.productType === ProductTypes.FOOD;
        this.layoutData = this.getLayoutDataForProductType();
    }


    public getTitlePart(): string {
        return _.get(this.layoutData, 'title', '');
    }

    public getSubtitlePart(): string {
        return _.get(this.layoutData, 'subTitle', '');
    }

    public getAdvantagesIntro(): string {
        return _.get(this.layoutData, 'advantagesIntro', '');
    }

    public getAdvantages(): [string] {
        return _.get(this.layoutData, 'advantages', []);
    }

    public getOutro(): string {
        return _.get(this.layoutData, 'outro', '');
    }

    public getLogos(): [string] {
        return _.get(this.layoutData, 'logos', []);
    }

    public getLearnMoreLink(): string {
        return _.get(this.layoutData, 'learnMoreLink', '');
    }

    public getBackgroundClassForType(): string {
        return _.get(this.layoutData, 'backgroundClass', '');
    }

    public getAdvantagesParagraphs(): string {
        return _.get(this.layoutData, 'advantagesParagraphs', []);
    }

    public getAdvantagesParagraphsRowClass(): string {
        return _.get(this.layoutData, 'advantagesParagraphsRowClass', '');
    }

    private getLayoutDataForProductType() {
        return this.isFood ? DashboardConst.secondStepCreation.FOOD : DashboardConst.secondStepCreation.VCS;
    }

}
