export default {
  read(key: string): any {
    const data = localStorage.getItem(key);
    if (data != null) {
      return JSON.parse(data);
    }
    return;
  },
  write(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove(key: string): void {
    localStorage.removeItem(key);
  },
  clearAll(): void {
    localStorage.clear();
  },
  clearAllAndKeepSpecificKeys(keys: string[]): void {
    const map = new Map<string, string>();
    keys.forEach((key, index) => {
      const value = this.read(key);
      if (value)
        map.set(key, value);
    });
    this.clearAll();
    map.forEach((value, key) => {
      this.write(key, value);
    });
  }
};
