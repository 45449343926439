import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {version} from '../../../../package.json';
import {map} from 'rxjs/operators';

@Injectable()
export class CacheService {
    public showCachePopIn: boolean = false;

    constructor(private http: HttpClient) {
    }


    public getAppVersion() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Pragma': 'no-cache',
                'Expires': '-1',
                'Cache-Control': 'no-cache'
            })
        };
        return new Promise((resolve, reject) => {
            this.http.get('/package.json', httpOptions)
                .subscribe((data: any) => {
                    const appVersion = data.version;
                    return resolve(appVersion);
                }, err => {
                    return reject(err);
                });
        });
    }

    public async checkNewVersionAvailable(localVersion: string) {
        const remoteVersion = await this.getAppVersion();
        console.info('remoteVersion: ' + remoteVersion);
        console.info('localVersion: ' + localVersion);
        if (localVersion !== remoteVersion) {
            this.showCachePopIn = true;
            return;
        }
        this.showCachePopIn = false;
    }

    public forceReload(){
       window.location.href = window.location.href;
    }
}
