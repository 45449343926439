export class AgeOfSpiritModel {
  public nbYears : string[] = [];
  constructor() {
    let i = 1;
    while (i < 101) {
      this.nbYears.push(i.toString());
      i++;
    }
  }
}
