import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';
import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[appForbiddenUrls]',
  providers: [{provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true}]
})
export class ForbiddenValidatorDirective implements Validator {
  @Input('appForbiddenUrls') public forbiddenUrls = [''];

  public validate(control: AbstractControl): ValidationErrors | null {
    let toReturn = null;
    if (this.forbiddenUrls) {
      for (const u of this.forbiddenUrls) {
        toReturn = forbiddenUrlValidator(u)(control);
      }
    }
    return toReturn;
  }
}

export function forbiddenUrlValidator(url: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = control.value?.includes(url);
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  };
}
