import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {UserSvc} from '../../../shared/services';
import {LoginConstants} from '../../../login/models';
import { environment } from '../../../../environments/environment';
import {ModalDirective} from '@gs1france/gs1-components-library';

@Component({
  selector: 'application-disclaimer-modal',
  styleUrls: ['./application-disclaimer-modal.less'],
  template: `
      <div gs1Modal
           width="615"
           title="Bienvenue sur CodeOnline GTIN !"
           [customHtml]= customHtml
           confirm="Accepter"
           [confirmDisabled]="true"
           (closed)="validateDisclaimer($event)"
           [closeButton]="false"
           [disableClose]="true"
           #directiveRef="gs1Modal"
      >
      </div>
  `
})
export class ApplicationDisclaimerModal implements OnInit, AfterViewInit {
  @ViewChild('directiveRef', { static: false }) public applicationDisclaimerModal: ModalDirective;
  public constants = LoginConstants;
  public linkCguPdf: string = environment.CGU_URL;
  public input = '<input type="checkbox" name="disclaimerAccepted" class="input-accept-cgu"' +
    ' onclick="let btn = document.getElementsByClassName(`mat-dialog-actions`)[0].getElementsByTagName(`button`)[0];' +
    ' btn.classList.toggle(`disabled`);btn.disabled= !btn.disabled">' ;
  public customHtml = "<div class='cgu-modal-body'>Avant d’utiliser notre outil GS1 France, vous devez accepter nos " +
    "<a class href='" + this.linkCguPdf + "' target='_blank'>conditions générales d\'utilisation </a>en cochant la case ci-dessous.</div>" +
    '<div class="cgu-disclaimer">' + this.input + ' ' + this.constants.disclaimer.checkBoxText + '</div>';
  // public sanitizedHtml = this._sanitizer.bypassSecurityTrustHtml(this.customHtml) as string;
  constructor( private userSvc: UserSvc) {
  }

  public openModal() {
    this.applicationDisclaimerModal.openDialog();
  }

  public ngOnInit(): void {}
  public ngAfterViewInit() {
    this.openModal();
  }
  public validateDisclaimer(disclaimerAccepted: boolean) {
    if (disclaimerAccepted) {
      this.validateUser()
        .catch((err) => console.error(err));
    }
  }

  public validateUser() {
    return this.userSvc.updateUser({applicationDisclaimerAccepted: true});
  }

  // public rejectDisclaimer() {
  //   this.userSvc.disconnectUser(false);
  // }

}
