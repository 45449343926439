import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {User} from '../models/user.model';
import {environment} from '../../../environments/environment';


@Injectable()
export class GtinSvc {
  constructor(private httpClient: HttpClient) {
  }

  public searchGtin(gtin: string): Observable<any> {
    const requestUrl = `${environment.GTIN_URL}gtin/${gtin}/search`;
    const httpHeader = new HttpHeaders();
    httpHeader.append('Access-Control-Allow-Origin', '*');
    return this.httpClient.get(requestUrl, {headers: httpHeader});
  }
}
