import {Injectable} from '@angular/core';
import {Router, CanActivate, CanDeactivate} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import localStorage from './../utils/localStorage';
import {Observable} from 'rxjs';
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(private router: Router, private oidcSecurityService: OidcSecurityService) {
  }

  public canActivate() {
    if (this.jwt != null) {
      // logged in so return true
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
  public canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
  get jwt(): string {
    return localStorage.read('jwtToken');
  }

  set jwt(token: string) {
    localStorage.write('jwtToken', token);
  }

  public removeJwt(): void {
    localStorage.remove('jwtToken');
    return;
  }

  public isSSoAuth(): boolean {
    return localStorage.read('_isAuthorized');
  }
}
