import {Component, OnInit, ViewChild} from '@angular/core';
import {DashboardConst} from '../../../models/dashboard.constants';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import * as $ from 'jquery';
import {environment} from '../../../../../environments/environment';
@Component({
  selector: 'import-my-gtin',
  styleUrls: ['./import-my-gtin.less'],
  templateUrl: './import-my-gtin.tpl.html'
})
export class ImportMyGtinComponent implements OnInit {
  public constants = DashboardConst;
  public isMoreSectionOpened: boolean = false;

  constructor(private headerSvc: DashboardHeaderSvc, private featureToggleSvc: FeatureToggleSvc) {
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData('Importer mes GTIN', 'assets/icons/ico_import.svg');
  }

  public toggleMoreSection() {
    $('#importPanel').animate({
      scrollTop: document.getElementById('moreSectionLink').offsetTop + 450
    }, 800);
    this.isMoreSectionOpened = !this.isMoreSectionOpened;
  }


  public downloadFileTemplate() {
    window.open(environment.IMPORT_TEMPLATE_GENERIC_URL);
  }

  public isVcs(): boolean {
    return this.featureToggleSvc.canSeeVcs();
  }

  public downloadGenericTemplate() {
    window.open(environment.IMPORT_TEMPLATE_GENERIC_URL);
  }

  public downloadWineTemplate() {
    window.open(environment.IMPORT_TEMPLATE_WINE_URL);
  }

  public downloadSpiritTemplate() {
    window.open(environment.IMPORT_TEMPLATE_SPIRIT_URL);
  }

}
