export const NewFeatureOnBoardingConstants = {
  titleVcs: 'Redécouvrez CodeOnline et testez ses nouvelles fonctionnalités',
  titleGeneric: 'Redécouvrez CodeOnline et testez la fonctionnalité de partage',
  sections: [
    {
      titleVcs: 'Identifiez votre produit',
      titleGeneric: 'Identifiez',
      firstText: `Générez en un clic vos codes produits GS1 et vos codes-barres pour faciliter la vente de vos produits dans le monde entier`,
      secondText: 'Créez vos codes GTIN uniques',
      thirdText: 'Créez et téléchargez vos code-barres'
    },
    {
      titleVcs: 'Digitalisez votre étiquette',
      titleGeneric: 'Digitalisez',
      firstText: `Renseignez et maitrisez les informations clés de vos produits dans un language commun pour valoriser leurs spécificités`,
      secondText: `Renseignez les informations de votre produit`,
      thirdText: ` Importez vos produits via fichier Excel ou CSV`
    },
    {
      title: 'Partagez',
      firstText: `Mettez à disposition de vos partenaires commerciaux vos informations produits`,
      secondText: `Publiez et activez vos produits dans la base mondiale GS1`,
      thirdText: `Partagez le lien de la carte d'identité de votre produit`
    }
  ]
};
