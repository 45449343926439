import * as _ from 'lodash';
// const IMAGE_URL_PATTERN = '(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|png)';
const validUrlPattern = /([a-zA-Z0-9\s_\\.\-\(\):])+(.png|.jpg|.jpeg)$/i;

export class ImageUrl {
    constructor(
        public url: string
    ) { }

    public isEmpty() {
        return _.isEmpty(this.url);
    }

    public hasError() {
        if (this.url) {
            return(this.url.match(validUrlPattern) === null);
        }
        return false;
    }
}
