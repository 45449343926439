import {
    Component,
    ViewEncapsulation,
    ElementRef,
    AfterViewChecked
} from '@angular/core';

import {DashboardConst} from '../../../../../../models/dashboard.constants';
import {FormInputError} from '../../../../../../../dashboard/models';
import URL from '../../../../../../../shared/utils/url';
import {Countries} from '../../../../../../../dashboard/models/country.constants';
import {StepComponent} from './StepComponent';
import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
    selector: 'origin-step',
    styleUrls: ['../unity-consumer-creation-food.less'],
    templateUrl: './origin-step.tpl.html',
    encapsulation: ViewEncapsulation.None
})
export class OriginStepComponent extends StepComponent implements AfterViewChecked {

    public stepCount: number = 4;
    public constants = DashboardConst;
    public webLinkError: FormInputError;
    public countryOfOriginOptions: any = Countries;
    public countryOfOriginConfig: any = Object.assign({}, {
        highlight: false,
        create: false,
        persist: true,
        plugins: ['remove_button'],
        dropdownDirection: 'down',
        wrapperClass: 'gs1selectize-control',
        inputClass: 'gs1selectize-input countryOfOrigin-input',
        dropdownClass: 'gs1selectize-dropdown',
        dropdownContentClass: 'gs1selectize-dropdown-content'
    }, {
        labelField: 'wording',
        valueField: 'code',
        searchField: ['wording'],
        maxItems: 15
    });
    public countryCodeConfig: any = Object.assign({}, {
        openOnFocus: false,
        highlight: false,
        create: false,
        persist: true,
        plugins: ['remove_button'],
        dropdownDirection: 'down',
        wrapperClass: 'gs1selectize-control',
        inputClass: 'gs1selectize-input countryCode-input',
        dropdownClass: 'gs1selectize-dropdown',
        dropdownContentClass: 'gs1selectize-dropdown-content'
    }, {
        labelField: 'wording',
        valueField: 'code',
        searchField: ['wording'],
        maxItems: 1
    });

    constructor(public elRef: ElementRef) {
        super();
        this.resetWebLinkError();
    }

    public ngAfterViewChecked() {
        this.makeCountryOfOriginElementRequired();
        this.makeCountryCodeElementRequired();
    }

    public isRequiredStructuredAddress(): boolean {
        const keys = Object.keys(this.productForm.structuredAddress);
        for (const key of keys) {
            if (!_.isEmpty(this.productForm.structuredAddress[key])) {
                return true;
            }
        }
        return false;
    }

    public trackByIndex(index: number, obj: any): any {
        return index;
    }

    public validateURL() {
        this.resetWebLinkError();
        this.productForm.webLink = this.productForm.webLink.toLowerCase();
        if (this.productForm.webLink) {
            if (URL.isNotPrefixedWithProtocol(this.productForm.webLink)) {
                this.productForm.webLink = 'http://' + this.productForm.webLink;
            }
            if (URL.isValidURL(this.productForm.webLink)) {
                this.webLinkError = new FormInputError('valid', '');
            } else {
                this.webLinkError = new FormInputError('warning', DashboardConst.createForm.errors.webLinkInvalid);
            }
        }
    }

    public checkRequiredInputs() {
        const elements: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('[required]');
        for (const el of elements) {
            if (_.isEmpty(el.value) && !el.className.includes('selectError')) {
                el.className = el.className + ' selectError';
                if (el.id === 'countryCode-selectized') {
                    const countryCodeInput: HTMLElement = this.elRef.nativeElement.querySelector('.gs1selectize-input.countryCode-input');
                    countryCodeInput.className = countryCodeInput.className + ' selectError';
                }
                if (el.id === 'countryOfOrigin-selectized') {
                    const countryCodeInput: HTMLElement = this.elRef.nativeElement.querySelector('.gs1selectize-input.countryOfOrigin-input');
                    countryCodeInput.className = countryCodeInput.className + ' selectError';
                }
            }
            if (!_.isEmpty(el.value) && el.className.includes('selectError')) {
                el.className = el.className.replace('selectError', '');
                if (el.id === 'countryCode-selectized') {
                    const countryCodeInput: HTMLElement = this.elRef.nativeElement.querySelector('.gs1selectize-input.countryCode-input');
                    countryCodeInput.className = countryCodeInput.className.replace('selectError', '');
                }
                if (el.id === 'countryOfOrigin-selectized') {
                    const countryCodeInput: HTMLElement = this.elRef.nativeElement.querySelector('.gs1selectize-input.countryOfOrigin-input');
                    countryCodeInput.className = countryCodeInput.className.replace('selectError', '');
                }
            }
        }
    }

    public selectizeChange(){
        if ($('.countryOfOrigin-input').hasClass('selectError')){
            $('.countryOfOrigin-input').removeClass('selectError');
        }
    }

    private makeCountryOfOriginElementRequired() {
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.countryOfOrigin-input');
        if (divElement) {
            // @ts-ignore
          const inputElement: HTMLInputElement = divElement.querySelector('input');
            if (!this.productForm.countryOfOrigin || this.productForm.countryOfOrigin.length === 0)
                inputElement.required = true;
            else
                inputElement.required = false;
        }
    }

    private makeCountryCodeElementRequired() {
        const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.countryCode-input');
        if (divElement) {
            // @ts-ignore
          const inputElement: HTMLInputElement = divElement.querySelector('input');
            if (_.isEmpty(this.productForm.structuredAddress.countryCode) && !_.isEmpty(this.productForm.structuredAddress.streetAddress)) {
                inputElement.required = true;
                return;
            }

            if (_.isEmpty(this.productForm.structuredAddress.countryCode) && !_.isEmpty(this.productForm.structuredAddress.postalCode)) {
                inputElement.required = true;
                return;
            }

            if (_.isEmpty(this.productForm.structuredAddress.countryCode) && !_.isEmpty(this.productForm.structuredAddress.city)) {
                inputElement.required = true;
                return;
            }
            inputElement.required = false;
        }
    }

    private resetWebLinkError() {
        this.webLinkError = new FormInputError('', '');
    }
}
