import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  Input, OnInit, ElementRef
} from '@angular/core';
import {DashboardConst} from '../../models/dashboard.constants';
import {ProductResellerTypes} from '../../models/productResellerTypes.model';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {BannerService} from '../../../shared/services/banner.service';
import {UserSvc} from '../../../shared/services';

@Component({
  selector: 'product-reseller',
  styleUrls: ['./product-reseller.less'],
  templateUrl: './product-reseller.tpl.html',
  encapsulation: ViewEncapsulation.None
})
export class ProductResellerComponent implements OnInit {
  public DashboardConst = DashboardConst;
  public brandTypes = ProductResellerTypes;

  public questionValue: string = null;
  public tutoTextValue: string = null;
  public isManufacturer: boolean = null;
  @Input() public isImport: boolean;

  public choicesUnitary: any[] = [
    {
      key: this.brandTypes.OWN_BRAND,
      value: 'Un produit neuf de votre propre marque',
      tip_title: 'Propre marque',
      tip: 'Vous êtes propriétaire de la marque commerciale du produit que vous souhaitez identifier.'
    },
    {
      key: this.brandTypes.THIRD_PARTY_BRAND,
      value: 'Un produit neuf d\'une marque tierce',
      tip_title: 'Marque tierce',
      tip: 'Vous n’êtes pas propriétaire de la marque commerciale du produit que vous souhaitez identifier.'
    },
    {
      key: this.brandTypes.WITHOUT_BRAND,
      value: 'Un produit neuf sans marque',
      tip_title: 'Sans marque',
      tip: 'Vous souhaitez identifier un produit qui n’a pas de marque commerciale.'
    },
    {
      key: this.brandTypes.BUNDLE,
      value: 'Un assortiment de produits neufs',
      tip_title: 'Un assortiment de produits',
      tip: 'Vous souhaitez identifier un assortiment de produits physiquement reliés.'
    },
    {
      key: this.brandTypes.NOT_NEW,
      value: 'Un produit non neuf',
      tip_title: 'Un produit non neuf',
      tip: 'Vous souhaitez identifier un produit reconditionné, remis à neuf ou d\'occasion.'
    }
  ];

  public choicesMass: any[] = [
    {
      key: this.brandTypes.OWN_BRAND,
      value: 'Des produits neufs de vos propres marques',
      tip_title: 'Propres marques',
      tip: 'Vous êtes propriétaire des marques commerciales des produits que vous souhaitez identifier.'
    },
    {
      key: this.brandTypes.THIRD_PARTY_BRAND,
      value: 'Des produits neufs de marque(s) tierce(s)',
      tip_title: 'Marque tierce',
      tip: 'Vous n’êtes pas propriétaire des marques commerciales des produits que vous souhaitez identifier.'
    },
    {
      key: this.brandTypes.WITHOUT_BRAND,
      value: 'Des produits neufs sans marque',
      tip_title: 'Sans marque',
      tip: 'Vous souhaitez identifier des produits qui n’ont pas de marque commerciale.'
    },
    {
      key: this.brandTypes.BUNDLE,
      value: 'Des assortiments de produits neufs',
      tip_title: 'Des assortiments de produits',
      tip: 'Vous souhaitez identifier des assortiments de produits physiquement reliés.'
    },
    {
      key: this.brandTypes.NOT_NEW,
      value: 'Des produits non neufs',
      tip_title: 'Un produit non neuf',
      tip: 'Vous souhaitez identifier un produit reconditionné, remis à neuf ou d\'occasion.'
    }
  ];

  public steps: any[] = [
    {
      value: 'Regarder le code-barres présent sur le produit ou son emballage, le GTIN correspond à la suite de chiffres sous le code-barres.'
    },
    {
      value: 'Demander au fabricant ou au propriétaire de la marque commerciale du produit de vous communiquer le GTIN.'
    }
  ];
  @Output() public onRedirectToChoiceCategory = new EventEmitter<any>();
  @Output() public onRedirectToMassCreation = new EventEmitter<any>();
  public certifications: any[] = [
    {
      checked: false,
      value: 'Je n’ai pas trouvé le GTIN sur le produit.'
    }, {
      checked: false,
      value: 'Le propriétaire de la marque commerciale ou le fabricant ne m’a pas communiqué le GTIN.'
    }];
  public massCertifications: any[] = [
    {
      checked: false,
      value: 'Je n’ai pas trouvé les GTIN sur les produits.'
    }, {
      checked: false,
      value: 'Le propriétaire de la marque commerciale ou le fabricant ne m’a pas communiqué les GTIN.'
    }];
  public showChoiceBox: boolean = true;

  public popoverConfig = {
    title: 'Détenteur des spécifications techniques',
    description: 'Il s’agit du propriétaire du cahier des charges du produit, qui peut sous-traiter à des tiers la fabrication du produit.'
  };

  constructor(public _router: Router, public bannerSvc: BannerService, private headerSvc: DashboardHeaderSvc, private elementRef: ElementRef,  private userSvc: UserSvc) {
  }

  public ngOnInit() {
    this.tutoTextValue = 'Vous n’êtes pas le fabricant du produit que vous souhaitez vendre. Par conséquent, dans le cadre des règles de codification GS1 vous n’êtes pas habilité à codifier ce produit. Nous vous invitons à rechercher le GTIN afin de ne pas créer un doublon.';
    if (this.isImport) {
      this.headerSvc.setHeaderData('Créer mes GTIN en nombre', 'assets/icons/ico_import.svg');

      this.tutoTextValue = 'Vous n’êtes pas le fabricant des produits que vous souhaitez vendre. Par conséquent, dans le cadre des règles de codification GS1 vous n’êtes pas habilité à codifier ces produits. Nous vous invitons à rechercher les GTIN afin de ne pas créer de doublon.';

    }
  }

  public allCertificationChecked(): boolean {
    const values = _.filter(this.certifications, (el) => el.checked === true);
    return _.size(values) === _.size(this.certifications);

  }

  public massCertificationChecked(): boolean {
    const values = _.filter(this.massCertifications, (el) => el.checked === true);
    return _.size(values) === _.size(this.massCertifications);

  }

  public returnTitle(): string {
    if (this.questionValue === 'WITHOUT_BRAND') {
      return DashboardConst.reseller.withoutBrand.title;
    }

    return DashboardConst.reseller.title;
  }

  public returnFirstText(): string {
    if (this.questionValue === 'WITHOUT_BRAND') {
      return DashboardConst.reseller.withoutBrand.firstText;
    }

    return DashboardConst.reseller.body.first;
  }

  public returnText(productResellerType: string): string {
    if (this.isImport) {
      return DashboardConst.reseller.import[productResellerType];
    }
    return DashboardConst.reseller[productResellerType];
  }

  public returnSecondText(): string {
    if (this.questionValue === 'WITHOUT_BRAND') {
      return DashboardConst.reseller.withoutBrand.secondText;
    }
    return DashboardConst.reseller.body.second;
  }

  public scrollToElement(div: HTMLElement) {
    const container = this.elementRef.nativeElement.querySelector('#body-container');
    const pos = div.offsetTop;

    container.scrollTop = pos;
  }


  public goBack() {
    if (this.showChoiceBox === false) {
      this.showChoiceBox = true;
      this.questionValue = null;
      _.map(this.certifications, (el) => el.checked = null);
      _.map(this.massCertifications, (el) => el.checked = null);
      return;
    }
    this._router.navigate(['/dashboard/list']);
  }

  public goToDashboard() {
    this._router.navigate(['/dashboard/list']);
  }

  public resetFields() {
    if (this.isManufacturer !== undefined) this.isManufacturer = undefined;
  }

  public changeChoice(choice) {
    this.resetFields();
    setTimeout(() => {
      switch (choice.key) {
        case 'OWN_BRAND':
          this.scrollToElement(this.elementRef.nativeElement.querySelector('#warningBox'));
          break;
        case 'WITHOUT_BRAND':
          this.scrollToElement(this.elementRef.nativeElement.querySelector('#warningBox'));
          break;
        case 'THIRD_PARTY_BRAND':
          this.scrollToElement(this.elementRef.nativeElement.querySelector('#warningBox'));
          break;
        default:
      }
    }, 0);

  }

  public goToCreateGtin() {
    let productResellerType = this.questionValue;
    if (this.questionValue === this.brandTypes.WITHOUT_BRAND) {
      productResellerType = this.isManufacturer ? this.brandTypes.WITHOUT_BRAND_AND_IS_MANUFACTURER : this.brandTypes.WITHOUT_BRAND_AND_ISNOT_MANUFACTURER;
    }

    if (!this.isImport) {
      this.onRedirectToChoiceCategory.emit({showCategory: true, productResellerType});
    } else {
      this.onRedirectToMassCreation.emit({productResellerType});
    }
  }

  public dontShowChoiceBox(event) {
    this.showChoiceBox = false;
  }

  public scrollToTuto() {
    setTimeout(() => {
      this.scrollToElement(this.elementRef.nativeElement.querySelector('#tuto'));
    }, 0);
  }

  public scrollToAlertwhenAllchecked() {
    setTimeout(() => {
      if (this.allCertificationChecked() || this.massCertificationChecked()) {
        this.scrollToElement(this.elementRef.nativeElement.querySelector('#alert'));
      }
    }, 0);
  }

  public displayTag(index: number) {
    const isLastItem: boolean = this.choicesUnitary.length === index + 1;
    const disappearDate: boolean = this.userSvc.user.authorizations['disappearDateTagNew'];
    return isLastItem && !disappearDate;
  }
}
