import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {DashboardConst, ProductTypes, ProductResellerTypes} from '../../models/index';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CompanySvc, NotificationsSvc, UserSvc} from '../../../shared/services';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import {ProductCategorySvc} from '../../../shared/services/product-category.service';
import {
  ClassificationOnBoardingModal
} from '../classification-onboarding-modal/first-Modal/classification-onboarding-modal.component';
import {
  ClassificationSecondOnBoardingModal
} from '../classification-onboarding-modal/second-modal/classification-second-onboarding-modal.component';
import {ProductCreationComponent} from './product-creation/product-creation.component';

import * as _ from 'lodash';

@Component({
  selector: 'product-creation-choice-category',
  styleUrls: ['./product-creation-choice-category.less'],
  templateUrl: './product-creation-choice-category.tpl.html'
})
export class ProductCreationChoiceCategoryComponent implements OnInit, AfterViewInit {
  public constants = DashboardConst;
  public showCategory: boolean | undefined;
  public showProductReseller: boolean;
  public selectedCategory: {
    breadcrumbs: string,
    code: string,
    definition: string,
    parentIds: string[],
    text: string,
    type: string
  } = {
    breadcrumbs: '',
    code: '',
    definition: '',
    parentIds: [],
    text: '',
    type: ''
  };
  public productType: ProductTypes;
  public isSpi: boolean;
  public isWine: boolean;
  public showClassification: boolean = false;
  @ViewChild('classificationOnBoardingModal', {static: true})
  public classificationOnBoardingModal: ClassificationOnBoardingModal;
  @ViewChild('classificationSecondOnBoardingModal', {static: true})
  public classificationSecondOnBoardingModal: ClassificationSecondOnBoardingModal;

  @ViewChild('productCreationNewWay', {static: false})
  public productCreationComponent: ProductCreationComponent;
  public showSecondModal: boolean = false;
  public productResellerType: string;
  public brandTypes = ProductResellerTypes;
  public isBundle: boolean = false;
  public isNotNew : boolean = false;
  constructor(private headerSvc: DashboardHeaderSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notificationsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public userSvc: UserSvc,
              private productCategorySvc: ProductCategorySvc,
              private route: ActivatedRoute
  ) {
  }


  public canDeactivate() {
    if (this.productCreationComponent) {
      return this.productCreationComponent.canDeactivate();
    }
    return true;
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData('Nouveau Code', 'assets/icons/newCodeOrange.svg');
    this.route.params.subscribe((params: Params) => {
      const productResellerType = params['productResellerType'];
      this.isBundle = productResellerType === this.brandTypes.BUNDLE;
      this.isNotNew = productResellerType === this.brandTypes.NOT_NEW;
      if (productResellerType === this.brandTypes.WITHOUT_BRAND_AND_ISNOT_MANUFACTURER || productResellerType === this.brandTypes.THIRD_PARTY_BRAND) {
        this.productResellerType = productResellerType;
        this.showCategory = true;
        this.showProductReseller = false;
        return;
      }

      this.showCategory = false;
      this.showProductReseller = true;
    });
  }

  public ngAfterViewInit() {
    if (!this.companySvc.company.companyName) {
      this._router.navigate(['/dashboard']);
    }
  }

  public openSecondModal() {
    this.showSecondModal = true;
    if (document.getElementById('classificationTree')) {
      // @ts-ignore
      const classificationTree: HTMLElement = document.getElementById('classificationTree');
      classificationTree.className = 'findCategoryLink onTopOf-backdrop glow';
    }
    if (document.getElementById('classificationTree2')) {
      // @ts-ignore
      const classificationTree2: HTMLElement = document.getElementById('classificationTree2');
      classificationTree2.className = 'onTopOf-backdrop';
    }
  }

  public onCloseSecondModal(noShow) {
    if (noShow) {
      this.userSvc.updateUser({showProductOnBoarding: false});
    }
  }

  public categorySelected(category: {
    breadcrumbs: string,
    code: string,
    definition: string,
    parentIds: string[],
    text: string,
    type: string
  }) {
    this.selectedCategory = this.companySvc.getSelectedCategory();
    this.showCategory = false;
    this.showClassification = false;
    if (this.isVcsCategory(category.code) && !this.isBundle && !this.isNotNew) {
      this.productType = ProductTypes.VCS;
      return;
    }

    if (this.isFoodCategory(category.code) && !this.isBundle && !this.isNotNew) {
      this.productType = ProductTypes.FOOD;
      return;
    }
    this.productType = ProductTypes.GENERIC;
  }

  public changeShowCategory(showCategory: boolean, productResellerType: string = '') {
    this.showCategory = showCategory;
    this.showProductReseller = false;
    if (productResellerType !== null) {
      this.productResellerType = productResellerType;
      this.isBundle = productResellerType === this.brandTypes.BUNDLE;
      this.isNotNew = productResellerType === this.brandTypes.NOT_NEW;
    }

  }

  public navigateToClassification() {
    this.productType = ProductTypes.GENERIC;
    this.showCategory = false;
    this.showClassification = true;
  }

  private isFoodCategory(codeGpc: string): boolean {
    const foodObject: any = _.find(this.productCategorySvc.categories, c => c.type === 'FOOD') || {};
    const codes = foodObject.codes || [];
    return codes.indexOf(codeGpc) !== -1;
  }

  private isVcsCategory(codeGpc: string): boolean {
    const check = this.productCategorySvc.isVcsCategory(codeGpc);

    this.isSpi = check.isSpi;
    this.isWine = check.isWine;
    return check.isVcsCategory;
  }
}
