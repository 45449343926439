export const AppConstants = {
  not_new: 'Non neuf',
  notifications: {
    serverError: {
      title: `Erreur inattendue`,
      content: `L'opération n'a pu aboutir, veuillez nous en excuser`
    },
    productNotFound: {
      title: `Produit inexistant`,
      content: `Le produit a été récemment supprimé`
    },
    preferencesError: {
      title: '',
      content: 'Impossible de sauvegarder vos préférences.'
    },
    selectPrefixMin: {
      title: '',
      content: 'Vous devez avoir au moins un préfixe activé'
    },
    gtinAlreadyExists: {
      title: `Conflit code GTIN`,
      content: `Le code GTIN vient d'être utilisé, le code suivant a donc été automatiquement sélectionné
      Veuillez re-valider le formulaire`
    },
    createProductValidation: {
      title: `La création a échoué`,
      content: `Vérifiez vos données puis re-validez le formulaire.
      Si le problème persiste veuillez nous contacter au 01 40 22 18 00 ou par mail serviceclient@gs1fr.org.`
    },
    updateProductValidation: {
      title: `La modification a échoué`,
      content: `Vérifiez vos données puis re-validez le formulaire.
      Si le problème persiste veuillez nous contacter au 01 40 22 18 00 ou par mail serviceclient@gs1fr.org.`
    },
    categoryUpdated: {
      title: ``,
      content: `La catégorie de votre produit a bien été mise à jour !`
    },
    companyMissing: {
      title: `Données manquantes`,
      content: `Vos données entreprise n’ont probablement pas encore été intégrées dans l’application suite à votre adhésion.
   Merci de contacter le Service Client de GS1 France au 01 40 22 18 00 ou par mail serviceclient@gs1fr.org.`
    },
    wrongFoodAllergen: {
      title: `Données invalides`,
      content: `Il y a une incohérence dans la déclaration d'allergène des fruits à coque.`
    },
    wrongFoodNutriment: {
      title: `Données invalides`,
      content: `La quantité des Acides gras saturés ne doit pas être supérieure à la quantité des Matières grasses.`
    },
    wrongNutriscore: {
      title: `Données manquantes`,
      content: `Veuillez reseigner la valeur du nutri-score ou décocher la case "Je dispose d'un Nutri-score pour ce produit".`
    },
    sugarSupToCarbohydrates: {
      title: `Données invalides`,
      content: `La quantité de Sucres ne doit pas être supérieure à la quantité de Glucides.`
    },
    userUnauthorizedToConnect: {
      title: `Donnée utilisateur non valide`,
      content: `Vous n'êtes pas authorisé à vous connecter.
      Merci de contacter le Service Client de GS1 France au 01 40 22 18 00 ou par mail serviceclient@gs1fr.org.`
    },
    uncompletedInscriptionProcess: {
      title: `Adhésion incomplète`,
      content: `Vous n'avez pas complété le process d'adhésion.
      Merci de contacter le Service Client de GS1 France au 01 40 22 18 00 ou par mail serviceclient@gs1fr.org.`
    },
    noGtinAvailable: {
      title: `Vous avez utilisé toute la plage de codification de votre préfixe`,
      content: `Afin de créer votre GTIN, sélectionnez un autre préfixe ou commandez-en un nouveau sur votre espace client.`
    },
    noClassificationChosen: {
      title: `Vous n'avez pas sélectionné de classification`,
      content: `Veuillez choisir le type de produit que vous voulez créer`
    },
    imagesUpload: {
      wrongType: {
        title: `Format non supporté`,
        content: `Oups, vous avez mis un format non autorisé !
        Seules les images au format PNG ou JPG sont acceptées`
      },
      wrongSize: {
        title: `Image trop volumineuse`,
        content: `Oups, votre image fait plus de 10Mo !
        Merci de compresser votre image avant de créer ou mettre à jour votre fiche produit`
      },
      queueLimit: {
        title: `Limite atteinte`,
        content: `Vous ne pouvez pas ajouter d’autres images pour ce produit.`
      }
    },
    invalidImageUrl: {
      title: 'URL de l\'image',
      content: 'url non valide'
    },
    packingUnityEditionDisabled: {
      title: 'Attention',
      content: 'Vos unités logistiques ne peuvent plus être modifiées'
    },
    userDisconnected: {
      title: 'Session expirée',
      content: 'Désolé, votre session a expiré, veuillez vous connecter'
    },
    wrongTime: {
      title: 'Date ou heure incorrecte',
      content: `Désolé, vous ne pouvez pas utiliser codeOnline. Vous devez régler votre horloge.`
    },
    productImport: {
      wrongType: {
        title: `Format non supporté`,
        content: `Oups, vous avez chargé un ficher avec un format non autorisé !
Seuls les fichiers aux formats CSV, XLS et XLSX sont acceptés.`
      },
      wrongSize: {
        title: `Fichier CSV trop volumineux`,
        content: `Votre fichier fait plus de 30Mo. Merci de réduire sa taille en le découpant en plusieurs fichiers.`
      },
      validationError: {
        title: `Fichier invalide`,
        content: `Merci de vous assurer que vous avez bien téléchargé le dernier modèle fourni à l'étape précédente et que votre fichier ne contient pas de ligne vide entre chaque produits.`,
        bundleContent: `Merci de vous assurer que vous avez bien téléchargé le modèle de fichier dédié aux assortiments de produits.`
},
      unsupportedEncoding: {
        title: `Attention !`,
        content: `L'encodage de votre fichier CSV n'est pas UTF-8. Modifiez votre document et relancez l'import.`
      },
      emptyFile: {
        title: `Votre fichier est vide`,
        content: `Vérifiez que votre fichier contient au moins un produit à importer.`
      },
      importInProgress: {
        title: `Import en cours`,
        content: `Un import est en cours au sein de votre entreprise. Veuillez patentier jusqu'à ce que ce dernier soit terminé.`
      },
      drainedWeight: {
        title: `Poids net égoutté`,
        content: `Vérifiez l'attribut  Poids net égoutté puis réessayez .`
      }

    },
    shareUrlCopied: {
      title: '<p></p>',
      content: 'Lien copié !'
    },
    nutrimentsDecimalsError: {
      title: 'Valeur de nutriment erronée',
      content: 'Veuillez corriger la quantité des nutriments pour avoir au maximum trois chiffres après la virgule.'
    },
    mandatoryProductName: {
      title: `Nom du produit`,
      bundleTitle: 'Nom de l\'assortiment',
      bundleContent: 'Veuillez renseigner le nom de l\'assortiment avant d\'en ajouter un nouveau.',
      content: `Veuillez renseigner le nom du produit avant d'en ajouter un nouveau.`
    },
    exportInProgress: {
      title: 'Export en cours',
      content: `Votre export est en cours de traitement, vous recevrez un mail vous permettant d'y accéder dans quelques minutes.`
    }
  },

  restrictionIE: {
    title: `Oups, votre navigateur n'est pas compatible`,
    content: `Le navigateur Internet Explorer n'est pas compatible avec le service CodeOnline.
    Merci d'utiliser un navigateur <a href="https://www.google.fr/chrome" target="_blank">Chrome</a>, <a href="https://www.mozilla.org/fr/firefox/new/" target="_blank">Firefox</a>, <a href="https://www.apple.com/fr/safari" target="_blank">Safari</a> ou <a href="http://www.microsoft.com/fr-fr/windows/microsoft-edge" target="_blank">Edge</a>.`
  }
};
