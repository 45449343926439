import {Injectable} from '@angular/core';
import LocalStorage from '../utils/localStorage';
import {DomSanitizer} from '@angular/platform-browser';
import {FeatureToggleSvc} from './feature-toggle.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class BannerService {

    public data: any = null;
    public canShowBanner: boolean = false;

    constructor(private http: HttpClient, private featureToggleSvc: FeatureToggleSvc) {
    }


    public getData() {
        return new Promise((resolve, reject) => {
            if (this.data) {
                return resolve(this.data);
            }
            this.http.get(environment.BANNER_FILE_URL)
                .subscribe((data) => {
                    this.data = data;
                    return resolve(this.data);
                }, err => {
                    return reject(err);
                });
        });
    }

    public getBannerInformation() {
        return this.getData()
            .then((data: any) => {
                let banner: any = data.banner || {};
                const GLNs = data.bannerGLN;
                const canClose: boolean = banner.canClose;
                const forceDisappear: boolean = banner.forceDisappear;
                if (this.featureToggleSvc.canSeeVcs() && data.bannerVcs) {
                    banner = this.data.bannerVcs;
                }
                return {
                    forceDisappear, canClose, content: banner.content, disappearanceDate: banner.disappearanceDate, GLNs
                };
            });
    }

    public closeBanner() {
        LocalStorage.write('bannerClosed', true);
    }

    public showBanner(content: string) {
        if (`${content}`.trim().length === 0) {
            this.canShowBanner = false;
            return false;
        }
        const bannerContent = `${LocalStorage.read('bannerContent')}`;
        if (bannerContent.toString() !== `${content}`){
            this.canShowBanner = false;
            return true;
        }
        if (LocalStorage.read('bannerClosed') === true){
            this.canShowBanner = false;
            return false;
        }

        this.canShowBanner = true;
        return true;
    }
}
