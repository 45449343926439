import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {FeatureToggleSvc} from '../../shared/services/feature-toggle.service';
import {AuthGuard} from '../../shared/services/auth-guard';
import {NotificationsSvc} from '../../shared/services/notifications.service';
import {DateSvc} from '../../shared/services/date.service';

const UAParser = require('ua-parser-js');

@Component({
  selector: 'login',
  styleUrls: ['./login.less'],
  template: `<div class="center" gs1Spinner [big]="true" [size]="'80px'" [mainLoader]="true"></div>`
})
export class Login implements OnInit{
  constructor(private router: Router, private oidcSecurityService: OidcSecurityService,
              private featureToggleSvc: FeatureToggleSvc, private authGuard: AuthGuard,
              private notifSvc: NotificationsSvc, private dateSvc: DateSvc) {
    this.dateSvc.isCorrectDate().subscribe(isSameDate => {
      if (!isSameDate) {
        this.notifSvc.wrongTime();
      }
    });
    const browser = new UAParser().getBrowser();
    if (browser.name.includes('IE')) {
      this.router.navigate([('/restrict')]);
    }
  }

  public ngOnInit() {
    if (this.authGuard.jwt) {
        this.router.navigate([('/dashboard')]);
        return;
    }
  }
  public ssoAuthentication() {
    if (this.authGuard.jwt) {
      this.router.navigate([('/dashboard')]);
      return;
    }
    this.oidcSecurityService.authorize();
  }
}
