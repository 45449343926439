import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'maintenance',
  styles: [`
    html, head, body {
      font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'sans-serif';
      font-weight: 300;
      font-size: 14px;
      width: 100%;
      height: 100%;
    }
    .Maintenance{
      width: 700px;
      margin: 100px auto;
      text-align: center;
    }
    .Maintenance-image{
      display: flex;
      justify-content: center;
      height: 150px;
    }
    .Maintenance-title{
      font-size: 1.2rem;
      font-weight: 500;
      padding-bottom: 10px;
    }
`],
  template: `
    <div class="Maintenance">
      <div class="Maintenance-image" style="">
        <img src="assets/icons/logoGs1.svg" alt="">
      </div>
      <div class="Maintenance-title">Maintenance en cours</div>
      <div id="maintenanceContent">Loading...</div>
    </div>
`
})
export class Maintenance implements OnInit{
  constructor() {}

  public ngOnInit(): void {
    let message = "Le site n'est pas disponible actuellement, merci de re-essayer dans quelques minutes.";
    $(document).ready(function() {
      const url = '/alerts.json';
      const maintenanceContent = $('#maintenanceContent');
      $.ajax({
        url,
        dataType: 'json'
      }).done(function(result) {
        const maintenanceObj = result.maintenance || {};
        if (maintenanceObj.content) {
          message = maintenanceObj.content;
        }
      }).always(function(){
        maintenanceContent.html(message);
      });
    });
  }
}
