export const nutrimentMeasurementShortUnitConst = [
    {
        code: 'GRM',
        wording: 'g'
    },
    {
        code: 'MGM',
        wording: 'mg'
    },
    {
        code: 'KJO',
        wording: 'kJ'
    },
    {
        code: 'E14',
        wording: 'kcal'
    },
    {
        code: 'MC',
        wording: 'µg'
    },
    {
        code: 'MLT',
        wording: 'ml'
    }
];
