import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthGuard} from './auth-guard';
import {EventSourcePolyfill} from 'ng-event-source';
import {environment} from '../../../environments/environment';

@Injectable()
export class EventSourceSvc {
    constructor(public authGuard: AuthGuard) {
    }

    public createEventSource(url: string): Observable<any> {
        const requestUrl = environment.API_URL + url;
        const token = this.authGuard.jwt;
        const options = {headers: {Authorization: 'Bearer ' + token}};

        return new Observable(observer => {
            const eventSource = new EventSourcePolyfill(requestUrl, options);

            eventSource.onmessage = message => observer.next(JSON.parse(message.data));
            eventSource.onerror = error => observer.error(error);

            return () => eventSource.close();
        });
    }

}
