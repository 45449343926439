import {Gtin} from '../../shared/models/gtin.model';
import {FormInputError} from './formInputError';
import {NetContent} from './NetContent.model';
import { ImageUrl } from './ImageUrl.model';
import {ProductName} from './ProductName.model';

export class ProductFormModel {
    constructor(
        public gtin: Gtin,
        public cipError: FormInputError,
        public mpn: string,
        public sku: string,
        public brand: string,
        public subBrand: string,
        public name: string,
        public productNames: ProductName[],
        public imagesToUpload: Object[],
        public description: string,
        public webLink: string,
        public completionLevel: number,
        public companyId?: number,
        public targetMarket: string[] = ['250'],
        public codeGpc: string = '',
        public isPrivate: boolean = false,
        public category: string = '',
        public netContents: NetContent[] = [new NetContent('', '')],
        public adhereToTheSectoralAgreement?: boolean,
        public vbGs1CompletionLevel?: number,
        public imagesAddedByUrl?: ImageUrl[],
        public productResellerType?: string,
        public originGtin?: string
    ) {
    }
}
