export const TargetMarketCountries = [
    {
        'wording-en': 'Afghanistan',
        'wording-fr': 'Afghanistan',
        'Code alpha-2': 'AF',
        'code': '004'
    },
    {
        'wording-en': 'Africa',
        'wording-fr': 'Afrique',
        'code': '002'
    },
    {
        'wording-en': 'Middle Africa',
        'wording-fr': 'Afrique Centrale',
        'code': '017'
    },
    {
        'wording-en': 'Eastern Africa',
        'wording-fr': "Afrique de l'Est",
        'code': '014'
    },
    {
        'wording-en': 'Western Africa',
        'wording-fr': "Afrique de l'Ouest",
        'code': '011'
    },
    {
        'wording-en': 'Northern Africa',
        'wording-fr': 'Afrique du Nord',
        'code': '015'
    },
    {
        'wording-en': 'South Africa',
        'wording-fr': 'Afrique du Sud',
        'Code alpha-2': 'ZA',
        'code': '710'
    },
    {
        'wording-en': 'Southern Africa',
        'wording-fr': 'Afrique du Sud',
        'code': '018'
    },
    {
        'wording-en': 'Sub-Saharian Africa',
        'wording-fr': 'Afrique sub saharienne',
        'code': '202'
    },
    {
        'wording-en': 'Åland Islands',
        'wording-fr': 'Åland(les Îles)',
        'Code alpha-2': 'AX',
        'code': '248'
    },
    {
        'wording-en': 'Albania',
        'wording-fr': 'Albanie',
        'Code alpha-2': 'AL',
        'code': '008'
    },
    {
        'wording-en': 'Algeria',
        'wording-fr': 'Algérie',
        'Code alpha-2': 'DZ',
        'code': '012'
    },
    {
        'wording-en': 'Germany',
        'wording-fr': 'Allemagne',
        'Code alpha-2': 'DE',
        'code': '276'
    },
    {
        'wording-en': 'Americas',
        'wording-fr': 'Amérique',
        'code': '019'
    },
    {
        'wording-en': 'Central America',
        'wording-fr': 'Amérique Centrale',
        'code': '013'
    },
    {
        'wording-en': 'Northern America',
        'wording-fr': 'Amérique du Nord',
        'code': '021'
    },
    {
        'wording-en': 'South America',
        'wording-fr': 'Amérique du Sud',
        'code': '005'
    },
    {
        'wording-en': 'Latin America and the Caribbean',
        'wording-fr': 'Amérique Latine et Caraïbes',
        'code': '419'
    },
    {
        'wording-en': 'Andorra',
        'wording-fr': 'Andorre',
        'Code alpha-2': 'AD',
        'code': '020'
    },
    {
        'wording-en': 'Angola',
        'wording-fr': 'Angola',
        'Code alpha-2': 'AO',
        'code': '024'
    },
    {
        'wording-en': 'Anguilla',
        'wording-fr': 'Anguilla',
        'Code alpha-2': 'AI',
        'code': '660'
    },
    {
        'wording-en': 'Antarctica',
        'wording-fr': 'Antarctique',
        'Code alpha-2': 'AQ',
        'code': '010'
    },
    {
        'wording-en': 'Antigua and Barbuda',
        'wording-fr': 'Antigua-et-Barbuda',
        'Code alpha-2': 'AG',
        'code': '028'
    },
    {
        'wording-en': 'Saudi Arabia',
        'wording-fr': 'Arabie saoudite',
        'Code alpha-2': 'SA',
        'code': '682'
    },
    {
        'wording-en': 'Argentina',
        'wording-fr': 'Argentine',
        'Code alpha-2': 'AR',
        'code': '032'
    },
    {
        'wording-en': 'Armenia',
        'wording-fr': 'Arménie',
        'Code alpha-2': 'AM',
        'code': '051'
    },
    {
        'wording-en': 'Aruba',
        'wording-fr': 'Aruba',
        'Code alpha-2': 'AW',
        'code': '533'
    },
    {
        'wording-en': 'Asia',
        'wording-fr': 'Asie',
        'code': '142'
    },
    {
        'wording-en': 'Central Asia',
        'wording-fr': 'Asie Centrale',
        'code': '143'
    },
    {
        'wording-en': 'Eastern Asia',
        'wording-fr': "Asie de l'Est",
        'code': '030'
    },
    {
        'wording-en': 'Western Asia',
        'wording-fr': "Asie de l'Ouest",
        'code': '145'
    },
    {
        'wording-en': 'Southern Asia',
        'wording-fr': 'Asie du Sud',
        'code': '034'
    },
    {
        'wording-en': 'South-eastern Asia',
        'wording-fr': 'Asie du Sud-Est',
        'code': '035'
    },
    {
        'wording-en': 'Australia',
        'wording-fr': 'Australie',
        'Code alpha-2': 'AU',
        'code': '036'
    },
    {
        'wording-en': 'Australia and New Zealand',
        'wording-fr': 'Australie et Nouvelle-Zélande',
        'code': '053'
    },
    {
        'wording-en': 'Austria',
        'wording-fr': 'Autriche',
        'Code alpha-2': 'AT',
        'code': '040'
    },
    {
        'wording-en': 'Azerbaijan',
        'wording-fr': 'Azerbaïdjan',
        'Code alpha-2': 'AZ',
        'code': '031'
    },
    {
        'wording-en': 'Bahamas (the)',
        'wording-fr': 'Bahamas',
        'Code alpha-2': 'BS',
        'code': '044'
    },
    {
        'wording-en': 'Bahrain',
        'wording-fr': 'Bahreïn',
        'Code alpha-2': 'BH',
        'code': '048'
    },
    {
        'wording-en': 'Bangladesh',
        'wording-fr': 'Bangladesh',
        'Code alpha-2': 'BD',
        'code': '050'
    },
    {
        'wording-en': 'Barbados',
        'wording-fr': 'Barbade',
        'Code alpha-2': 'BB',
        'code': '052'
    },
    {
        'wording-en': 'Belarus',
        'wording-fr': 'Bélarus',
        'Code alpha-2': 'BY',
        'code': '112'
    },
    {
        'wording-en': 'Belgium',
        'wording-fr': 'Belgique',
        'Code alpha-2': 'BE',
        'code': '056'
    },
    {
        'wording-en': 'Belize',
        'wording-fr': 'Belize',
        'Code alpha-2': 'BZ',
        'code': '084'
    },
    {
        'wording-en': 'Benin',
        'wording-fr': 'Bénin',
        'Code alpha-2': 'BJ',
        'code': '204'
    },
    {
        'wording-en': 'Bermuda',
        'wording-fr': 'Bermudes',
        'Code alpha-2': 'BM',
        'code': '060'
    },
    {
        'wording-en': 'Bhutan',
        'wording-fr': 'Bhoutan',
        'Code alpha-2': 'BT',
        'code': '064'
    },
    {
        'wording-en': 'Bolivia (Plurinational State of)',
        'wording-fr': 'Bolivie (État plurinational de)',
        'Code alpha-2': 'BO',
        'code': '068'
    },
    {
        'wording-en': 'Bonaire, Sint Eustatius and Saba',
        'wording-fr': 'Bonaire, Saint-Eustache et Saba',
        'Code alpha-2': 'BQ',
        'code': '535'
    },
    {
        'wording-en': 'Bosnia and Herzegovina',
        'wording-fr': 'Bosnie-Herzégovine',
        'Code alpha-2': 'BA',
        'code': '070'
    },
    {
        'wording-en': 'Botswana',
        'wording-fr': 'Botswana',
        'Code alpha-2': 'BW',
        'code': '072'
    },
    {
        'wording-en': 'Bouvet Island',
        'wording-fr': "Bouvet (l'Île)",
        'Code alpha-2': 'BV',
        'code': '074'
    },
    {
        'wording-en': 'Brazil',
        'wording-fr': 'Brésil',
        'Code alpha-2': 'BR',
        'code': '076'
    },
    {
        'wording-en': 'Brunei Darussalam',
        'wording-fr': 'Brunéi Darussalam',
        'Code alpha-2': 'BN',
        'code': '096'
    },
    {
        'wording-en': 'Bulgaria',
        'wording-fr': 'Bulgarie',
        'Code alpha-2': 'BG',
        'code': '100'
    },
    {
        'wording-en': 'Burkina Faso',
        'wording-fr': 'Burkina Faso',
        'Code alpha-2': 'BF',
        'code': '854'
    },
    {
        'wording-en': 'Burundi',
        'wording-fr': 'Burundi',
        'Code alpha-2': 'BI',
        'code': '108'
    },
    {
        'wording-en': 'Cabo Verde',
        'wording-fr': 'Cabo Verde',
        'Code alpha-2': 'CV',
        'code': '132'
    },
    {
        'wording-en': 'Cayman Islands (the)',
        'wording-fr': 'Caïmans (les Îles)',
        'Code alpha-2': 'KY',
        'code': '136'
    },
    {
        'wording-en': 'Cambodia',
        'wording-fr': 'Cambodge',
        'Code alpha-2': 'KH',
        'code': '116'
    },
    {
        'wording-en': 'Cameroon',
        'wording-fr': 'Cameroun',
        'Code alpha-2': 'CM',
        'code': '120'
    },
    {
        'wording-en': 'Canada',
        'wording-fr': 'Canada',
        'Code alpha-2': 'CA',
        'code': '124'
    },
    {
        'wording-en': 'Caribbean',
        'wording-fr': 'Caraïbes',
        'code': '029'
    },
    {
        'wording-en': 'Chile',
        'wording-fr': 'Chili',
        'Code alpha-2': 'CL',
        'code': '152'
    },
    {
        'wording-en': 'China',
        'wording-fr': 'Chine',
        'Code alpha-2': 'CN',
        'code': '156'
    },
    {
        'wording-en': 'Christmas Island',
        'wording-fr': "Christmas (l'Île)",
        'Code alpha-2': 'CX',
        'code': '162'
    },
    {
        'wording-en': 'Cyprus',
        'wording-fr': 'Chypre',
        'Code alpha-2': 'CY',
        'code': '196'
    },
    {
        'wording-en': 'Cocos (Keeling) Islands (the)',
        'wording-fr': 'Cocos (les Îles)/ Keeling (les Îles)',
        'Code alpha-2': 'CC',
        'code': '166'
    },
    {
        'wording-en': 'Colombia',
        'wording-fr': 'Colombie',
        'Code alpha-2': 'CO',
        'code': '170'
    },
    {
        'wording-en': 'Comoros (the)',
        'wording-fr': 'Comores',
        'Code alpha-2': 'KM',
        'code': '174'
    },
    {
        'wording-en': 'Congo (the)',
        'wording-fr': 'Congo',
        'Code alpha-2': 'CG',
        'code': '178'
    },
    {
        'wording-en': 'Congo (the Democratic Republic of the)',
        'wording-fr': 'Congo (la République démocratique du)',
        'Code alpha-2': 'CD',
        'code': '180'
    },
    {
        'wording-en': 'Cook Islands (the)',
        'wording-fr': 'Cook (les Îles)',
        'Code alpha-2': 'CK',
        'code': '184'
    },
    {
        'wording-en': 'Korea (the Republic of)',
        'wording-fr': 'Corée (la République de)',
        'Code alpha-2': 'KR',
        'code': '410'
    },
    {
        'wording-en': "Korea (the Democratic People's Republic of)",
        'wording-fr': 'Corée (la République populaire démocratique de)',
        'Code alpha-2': 'KP',
        'code': '408'
    },
    {
        'wording-en': 'Costa Rica',
        'wording-fr': 'Costa Rica',
        'Code alpha-2': 'CR',
        'code': '188'
    },
    {
        'wording-en': "Côte d'Ivoire",
        'wording-fr': "Côte d'Ivoire",
        'Code alpha-2': 'CI',
        'code': '384'
    },
    {
        'wording-en': 'Croatia',
        'wording-fr': 'Croatie',
        'Code alpha-2': 'HR',
        'code': '191'
    },
    {
        'wording-en': 'Cuba',
        'wording-fr': 'Cuba',
        'Code alpha-2': 'CU',
        'code': '192'
    },
    {
        'wording-en': 'Curaçao',
        'wording-fr': 'Curaçao',
        'Code alpha-2': 'CW',
        'code': '531'
    },
    {
        'wording-en': 'Denmark',
        'wording-fr': 'Danemark',
        'Code alpha-2': 'DK',
        'code': '208'
    },
    {
        'wording-en': 'Djibouti',
        'wording-fr': 'Djibouti',
        'Code alpha-2': 'DJ',
        'code': '262'
    },
    {
        'wording-en': 'Dominican Republic (the)',
        'wording-fr': 'dominicaine (la République)',
        'Code alpha-2': 'DO',
        'code': '214'
    },
    {
        'wording-en': 'Dominica',
        'wording-fr': 'Dominique',
        'Code alpha-2': 'DM',
        'code': '212'
    },
    {
        'wording-en': 'Egypt',
        'wording-fr': 'Égypte',
        'Code alpha-2': 'EG',
        'code': '818'
    },
    {
        'wording-en': 'El Salvador',
        'wording-fr': 'El Salvador',
        'Code alpha-2': 'SV',
        'code': '222'
    },
    {
        'wording-en': 'United Arab Emirates (the)',
        'wording-fr': 'Émirats arabes unis',
        'Code alpha-2': 'AE',
        'code': '784'
    },
    {
        'wording-en': 'Ecuador',
        'wording-fr': 'Équateur',
        'Code alpha-2': 'EC',
        'code': '218'
    },
    {
        'wording-en': 'Eritrea',
        'wording-fr': 'Érythrée',
        'Code alpha-2': 'ER',
        'code': '232'
    },
    {
        'wording-en': 'Spain',
        'wording-fr': 'Espagne',
        'Code alpha-2': 'ES',
        'code': '724'
    },
    {
        'wording-en': 'Estonia',
        'wording-fr': 'Estonie',
        'Code alpha-2': 'EE',
        'code': '233'
    },
    {
        'wording-en': 'Eswatini',
        'wording-fr': 'Eswatini',
        'Code alpha-2': 'SZ',
        'code': '748'
    },
    {
        'wording-en': 'United States of America (the)',
        'wording-fr': "États-Unis d'Amérique",
        'Code alpha-2': 'US',
        'code': '840'
    },
    {
        'wording-en': 'Ethiopia',
        'wording-fr': 'Éthiopie',
        'Code alpha-2': 'ET',
        'code': '231'
    },
    {
        'wording-en': 'Europe',
        'wording-fr': 'Europe',
        'code': '150'
    },
    {
        'wording-en': 'Eastern Europe',
        'wording-fr': "Europe de l'Est",
        'code': '151'
    },
    {
        'wording-en': 'Western Europe',
        'wording-fr': "Europe de l'Ouest",
        'code': '155'
    },
    {
        'wording-en': 'Northern Europe',
        'wording-fr': 'Europe du Nord',
        'code': '154'
    },
    {
        'wording-en': 'Southern Europe',
        'wording-fr': 'Europe du Sud',
        'code': '039'
    },
    {
        'wording-en': 'Falkland Islands (the) [Malvinas]',
        'wording-fr': 'Falkland (les Îles)/Malouines (les Îles)',
        'Code alpha-2': 'FK',
        'code': '238'
    },
    {
        'wording-en': 'Faroe Islands (the)',
        'wording-fr': 'Féroé (les Îles)',
        'Code alpha-2': 'FO',
        'code': '234'
    },
    {
        'wording-en': 'Fiji',
        'wording-fr': 'Fidji',
        'Code alpha-2': 'FJ',
        'code': '242'
    },
    {
        'wording-en': 'Finland',
        'wording-fr': 'Finlande',
        'Code alpha-2': 'FI',
        'code': '246'
    },
    {
        'wording-en': 'France',
        'wording-fr': 'France',
        'Code alpha-2': 'FR',
        'code': '250'
    },
    {
        'wording-en': 'Gabon',
        'wording-fr': 'Gabon',
        'Code alpha-2': 'GA',
        'code': '266'
    },
    {
        'wording-en': 'Gambia (the)',
        'wording-fr': 'Gambie',
        'Code alpha-2': 'GM',
        'code': '270'
    },
    {
        'wording-en': 'Georgia',
        'wording-fr': 'Géorgie',
        'Code alpha-2': 'GE',
        'code': '268'
    },
    {
        'wording-en': 'South Georgia and the South Sandwich Islands',
        'wording-fr': 'Géorgie du Sud-et-les Îles Sandwich du Sud',
        'Code alpha-2': 'GS',
        'code': '239'
    },
    {
        'wording-en': 'Ghana',
        'wording-fr': 'Ghana',
        'Code alpha-2': 'GH',
        'code': '288'
    },
    {
        'wording-en': 'Gibraltar',
        'wording-fr': 'Gibraltar',
        'Code alpha-2': 'GI',
        'code': '292'
    },
    {
        'wording-en': 'Greece',
        'wording-fr': 'Grèce',
        'Code alpha-2': 'GR',
        'code': '300'
    },
    {
        'wording-en': 'Grenada',
        'wording-fr': 'Grenade',
        'Code alpha-2': 'GD',
        'code': '308'
    },
    {
        'wording-en': 'Greenland',
        'wording-fr': 'Groenland',
        'Code alpha-2': 'GL',
        'code': '304'
    },
    {
        'wording-en': 'Guadeloupe',
        'wording-fr': 'Guadeloupe',
        'Code alpha-2': 'GP',
        'code': '312'
    },
    {
        'wording-en': 'Guam',
        'wording-fr': 'Guam',
        'Code alpha-2': 'GU',
        'code': '316'
    },
    {
        'wording-en': 'Guatemala',
        'wording-fr': 'Guatemala',
        'Code alpha-2': 'GT',
        'code': '320'
    },
    {
        'wording-en': 'Guernsey',
        'wording-fr': 'Guernesey',
        'Code alpha-2': 'GG',
        'code': '831'
    },
    {
        'wording-en': 'Guinea',
        'wording-fr': 'Guinée',
        'Code alpha-2': 'GN',
        'code': '324'
    },
    {
        'wording-en': 'Equatorial Guinea',
        'wording-fr': 'Guinée équatoriale',
        'Code alpha-2': 'GQ',
        'code': '226'
    },
    {
        'wording-en': 'Guinea-Bissau',
        'wording-fr': 'Guinée-Bissau',
        'Code alpha-2': 'GW',
        'code': '624'
    },
    {
        'wording-en': 'Guyana',
        'wording-fr': 'Guyana',
        'Code alpha-2': 'GY',
        'code': '328'
    },
    {
        'wording-en': 'French Guiana',
        'wording-fr': 'Guyane française',
        'Code alpha-2': 'GF',
        'code': '254'
    },
    {
        'wording-en': 'Haiti',
        'wording-fr': 'Haïti',
        'Code alpha-2': 'HT',
        'code': '332'
    },
    {
        'wording-en': 'Heard Island and McDonald Islands',
        'wording-fr': "Heard-et-Îles MacDonald (l'Île)",
        'Code alpha-2': 'HM',
        'code': '334'
    },
    {
        'wording-en': 'Honduras',
        'wording-fr': 'Honduras',
        'Code alpha-2': 'HN',
        'code': '340'
    },
    {
        'wording-en': 'Hong Kong',
        'wording-fr': 'Hong Kong',
        'Code alpha-2': 'HK',
        'code': '344'
    },
    {
        'wording-en': 'Hungary',
        'wording-fr': 'Hongrie',
        'Code alpha-2': 'HU',
        'code': '348'
    },
    {
        'wording-en': 'Isle of Man',
        'wording-fr': 'Île de Man',
        'Code alpha-2': 'IM',
        'code': '833'
    },
    {
        'wording-en': 'Channel Islands',
        'wording-fr': 'Îles anglo-normandes',
        'code': '830'
    },
    {
        'wording-en': 'United States Minor Outlying Islands (the)',
        'wording-fr': 'Îles mineures éloignées des États-Unis',
        'Code alpha-2': 'UM',
        'code': '581'
    },
    {
        'wording-en': 'India',
        'wording-fr': 'Inde',
        'Code alpha-2': 'IN',
        'code': '356'
    },
    {
        'wording-en': 'British Indian Ocean Territory (the)',
        'wording-fr': "Indien (le Territoire britannique de l'océan)",
        'Code alpha-2': 'IO',
        'code': '086'
    },
    {
        'wording-en': 'Indonesia',
        'wording-fr': 'Indonésie',
        'Code alpha-2': 'ID',
        'code': '360'
    },
    {
        'wording-en': 'Iran (Islamic Republic of)',
        'wording-fr': "Iran (République Islamique d')",
        'Code alpha-2': 'IR',
        'code': '364'
    },
    {
        'wording-en': 'Iraq',
        'wording-fr': 'Iraq',
        'Code alpha-2': 'IQ',
        'code': '368'
    },
    {
        'wording-en': 'Ireland',
        'wording-fr': 'Irlande',
        'Code alpha-2': 'IE',
        'code': '372'
    },
    {
        'wording-en': 'Iceland',
        'wording-fr': 'Islande',
        'Code alpha-2': 'IS',
        'code': '352'
    },
    {
        'wording-en': 'Israel',
        'wording-fr': 'Israël',
        'Code alpha-2': 'IL',
        'code': '376'
    },
    {
        'wording-en': 'Italy',
        'wording-fr': 'Italie',
        'Code alpha-2': 'IT',
        'code': '380'
    },
    {
        'wording-en': 'Jamaica',
        'wording-fr': 'Jamaïque',
        'Code alpha-2': 'JM',
        'code': '388'
    },
    {
        'wording-en': 'Japan',
        'wording-fr': 'Japon',
        'Code alpha-2': 'JP',
        'code': '392'
    },
    {
        'wording-en': 'Jersey',
        'wording-fr': 'Jersey',
        'Code alpha-2': 'JE',
        'code': '832'
    },
    {
        'wording-en': 'Jordan',
        'wording-fr': 'Jordanie',
        'Code alpha-2': 'JO',
        'code': '400'
    },
    {
        'wording-en': 'Kazakhstan',
        'wording-fr': 'Kazakhstan',
        'Code alpha-2': 'KZ',
        'code': '398'
    },
    {
        'wording-en': 'Kenya',
        'wording-fr': 'Kenya',
        'Code alpha-2': 'KE',
        'code': '404'
    },
    {
        'wording-en': 'Kyrgyzstan',
        'wording-fr': 'Kirghizistan',
        'Code alpha-2': 'KG',
        'code': '417'
    },
    {
        'wording-en': 'Kiribati',
        'wording-fr': 'Kiribati',
        'Code alpha-2': 'KI',
        'code': '296'
    },
    {
        'wording-en': 'Kuwait',
        'wording-fr': 'Koweït',
        'Code alpha-2': 'KW',
        'code': '414'
    },
    {
        'wording-en': "Lao People's Democratic Republic (the)",
        'wording-fr': 'Lao (la République démocratique populaire)',
        'Code alpha-2': 'LA',
        'code': '418'
    },
    {
        'wording-en': 'Lesotho',
        'wording-fr': 'Lesotho',
        'Code alpha-2': 'LS',
        'code': '426'
    },
    {
        'wording-en': 'Latvia',
        'wording-fr': 'Lettonie',
        'Code alpha-2': 'LV',
        'code': '428'
    },
    {
        'wording-en': 'Lebanon',
        'wording-fr': 'Liban',
        'Code alpha-2': 'LB',
        'code': '422'
    },
    {
        'wording-en': 'Liberia',
        'wording-fr': 'Libéria',
        'Code alpha-2': 'LR',
        'code': '430'
    },
    {
        'wording-en': 'Libya',
        'wording-fr': 'Libye',
        'Code alpha-2': 'LY',
        'code': '434'
    },
    {
        'wording-en': 'Liechtenstein',
        'wording-fr': 'Liechtenstein',
        'Code alpha-2': 'LI',
        'code': '438'
    },
    {
        'wording-en': 'Lithuania',
        'wording-fr': 'Lituanie',
        'Code alpha-2': 'LT',
        'code': '440'
    },
    {
        'wording-en': 'Luxembourg',
        'wording-fr': 'Luxembourg',
        'Code alpha-2': 'LU',
        'code': '442'
    },
    {
        'wording-en': 'Macao',
        'wording-fr': 'Macao',
        'Code alpha-2': 'MO',
        'code': '446'
    },
    {
        'wording-en': 'North Macedonia',
        'wording-fr': 'Macédoine du Nord',
        'Code alpha-2': 'MK',
        'code': '807'
    },
    {
        'wording-en': 'Madagascar',
        'wording-fr': 'Madagascar',
        'Code alpha-2': 'MG',
        'code': '450'
    },
    {
        'wording-en': 'Malaysia',
        'wording-fr': 'Malaisie',
        'Code alpha-2': 'MY',
        'code': '458'
    },
    {
        'wording-en': 'Malawi',
        'wording-fr': 'Malawi',
        'Code alpha-2': 'MW',
        'code': '454'
    },
    {
        'wording-en': 'Maldives',
        'wording-fr': 'Maldives',
        'Code alpha-2': 'MV',
        'code': '462'
    },
    {
        'wording-en': 'Mali',
        'wording-fr': 'Mali',
        'Code alpha-2': 'ML',
        'code': '466'
    },
    {
        'wording-en': 'Malta',
        'wording-fr': 'Malte',
        'Code alpha-2': 'MT',
        'code': '470'
    },
    {
        'wording-en': 'Northern Mariana Islands (the)',
        'wording-fr': 'Mariannes du Nord (les Îles)',
        'Code alpha-2': 'MP',
        'code': '580'
    },
    {
        'wording-en': 'Morocco',
        'wording-fr': 'Maroc',
        'Code alpha-2': 'MA',
        'code': '504'
    },
    {
        'wording-en': 'Marshall Islands (the)',
        'wording-fr': 'Marshall (les Îles)',
        'Code alpha-2': 'MH',
        'code': '584'
    },
    {
        'wording-en': 'Martinique',
        'wording-fr': 'Martinique',
        'Code alpha-2': 'MQ',
        'code': '474'
    },
    {
        'wording-en': 'Mauritius',
        'wording-fr': 'Maurice',
        'Code alpha-2': 'MU',
        'code': '480'
    },
    {
        'wording-en': 'Mauritania',
        'wording-fr': 'Mauritanie',
        'Code alpha-2': 'MR',
        'code': '478'
    },
    {
        'wording-en': 'Mayotte',
        'wording-fr': 'Mayotte',
        'Code alpha-2': 'YT',
        'code': '175'
    },
    {
        'wording-en': 'Melanesia',
        'wording-fr': 'Mélanésie',
        'code': '054'
    },
    {
        'wording-en': 'Mexico',
        'wording-fr': 'Mexique',
        'Code alpha-2': 'MX',
        'code': '484'
    },
    {
        'wording-en': 'Micronesia (Federated States of)',
        'wording-fr': 'Micronésie (États fédérés de)',
        'Code alpha-2': 'FM',
        'code': '583'
    },
    {
        'wording-en': 'Moldova (the Republic of)',
        'wording-fr': 'Moldova (la République de)',
        'Code alpha-2': 'MD',
        'code': '498'
    },
    {
        'wording-en': 'Monaco',
        'wording-fr': 'Monaco',
        'Code alpha-2': 'MC',
        'code': '492'
    },
    {
        'wording-en': 'Whole Word',
        'wording-fr': 'Monde',
        'code': '001'
    },
    {
        'wording-en': 'Mongolia',
        'wording-fr': 'Mongolie',
        'Code alpha-2': 'MN',
        'code': '496'
    },
    {
        'wording-en': 'Montenegro',
        'wording-fr': 'Monténégro',
        'Code alpha-2': 'ME',
        'code': '499'
    },
    {
        'wording-en': 'Montserrat',
        'wording-fr': 'Montserrat',
        'Code alpha-2': 'MS',
        'code': '500'
    },
    {
        'wording-en': 'Mozambique',
        'wording-fr': 'Mozambique',
        'Code alpha-2': 'MZ',
        'code': '508'
    },
    {
        'wording-en': 'Myanmar',
        'wording-fr': 'Myanmar',
        'Code alpha-2': 'MM',
        'code': '104'
    },
    {
        'wording-en': 'Namibia',
        'wording-fr': 'Namibie',
        'Code alpha-2': 'NA',
        'code': '516'
    },
    {
        'wording-en': 'Nauru',
        'wording-fr': 'Nauru',
        'Code alpha-2': 'NR',
        'code': '520'
    },
    {
        'wording-en': 'Nepal',
        'wording-fr': 'Népal',
        'Code alpha-2': 'NP',
        'code': '524'
    },
    {
        'wording-en': 'Nicaragua',
        'wording-fr': 'Nicaragua',
        'Code alpha-2': 'NI',
        'code': '558'
    },
    {
        'wording-en': 'Niger (the)',
        'wording-fr': 'Niger',
        'Code alpha-2': 'NE',
        'code': '562'
    },
    {
        'wording-en': 'Nigeria',
        'wording-fr': 'Nigéria',
        'Code alpha-2': 'NG',
        'code': '566'
    },
    {
        'wording-en': 'Niue',
        'wording-fr': 'Niue',
        'Code alpha-2': 'NU',
        'code': '570'
    },
    {
        'wording-en': 'Norfolk Island',
        'wording-fr': "Norfolk (l'Île)",
        'Code alpha-2': 'NF',
        'code': '574'
    },
    {
        'wording-en': 'Norway',
        'wording-fr': 'Norvège',
        'Code alpha-2': 'NO',
        'code': '578'
    },
    {
        'wording-en': 'New Caledonia',
        'wording-fr': 'Nouvelle-Calédonie',
        'Code alpha-2': 'NC',
        'code': '540'
    },
    {
        'wording-en': 'New Zealand',
        'wording-fr': 'Nouvelle-Zélande',
        'Code alpha-2': 'NZ',
        'code': '554'
    },
    {
        'wording-en': 'Oceania',
        'wording-fr': 'Océanie',
        'code': '009'
    },
    {
        'wording-en': 'Oman',
        'wording-fr': 'Oman',
        'Code alpha-2': 'OM',
        'code': '512'
    },
    {
        'wording-en': 'Uganda',
        'wording-fr': 'Ouganda',
        'Code alpha-2': 'UG',
        'code': '800'
    },
    {
        'wording-en': 'Uzbekistan',
        'wording-fr': 'Ouzbékistan',
        'Code alpha-2': 'UZ',
        'code': '860'
    },
    {
        'wording-en': 'Pakistan',
        'wording-fr': 'Pakistan',
        'Code alpha-2': 'PK',
        'code': '586'
    },
    {
        'wording-en': 'Palau',
        'wording-fr': 'Palaos',
        'Code alpha-2': 'PW',
        'code': '585'
    },
    {
        'wording-en': 'Palestine, State of',
        'wording-fr': 'Palestine, État de',
        'Code alpha-2': 'PS',
        'code': '275'
    },
    {
        'wording-en': 'Panama',
        'wording-fr': 'Panama',
        'Code alpha-2': 'PA',
        'code': '591'
    },
    {
        'wording-en': 'Papua New Guinea',
        'wording-fr': 'Papouasie-Nouvelle-Guinée',
        'Code alpha-2': 'PG',
        'code': '598'
    },
    {
        'wording-en': 'Paraguay',
        'wording-fr': 'Paraguay',
        'Code alpha-2': 'PY',
        'code': '600'
    },
    {
        'wording-en': 'Netherlands (the)',
        'wording-fr': 'Pays-Bas',
        'Code alpha-2': 'NL',
        'code': '528'
    },
    {
        'wording-en': 'Peru',
        'wording-fr': 'Pérou',
        'Code alpha-2': 'PE',
        'code': '604'
    },
    {
        'wording-en': 'Philippines (the)',
        'wording-fr': 'Philippines',
        'Code alpha-2': 'PH',
        'code': '608'
    },
    {
        'wording-en': 'Pitcairn',
        'wording-fr': 'Pitcairn',
        'Code alpha-2': 'PN',
        'code': '612'
    },
    {
        'wording-en': 'Poland',
        'wording-fr': 'Pologne',
        'Code alpha-2': 'PL',
        'code': '616'
    },
    {
        'wording-en': 'Polynesia',
        'wording-fr': 'Polynésie',
        'code': '061'
    },
    {
        'wording-en': 'French Polynesia',
        'wording-fr': 'Polynésie française',
        'Code alpha-2': 'PF',
        'code': '258'
    },
    {
        'wording-en': 'Puerto Rico',
        'wording-fr': 'Porto Rico',
        'Code alpha-2': 'PR',
        'code': '630'
    },
    {
        'wording-en': 'Portugal',
        'wording-fr': 'Portugal',
        'Code alpha-2': 'PT',
        'code': '620'
    },
    {
        'wording-en': 'Qatar',
        'wording-fr': 'Qatar',
        'Code alpha-2': 'QA',
        'code': '634'
    },
    {
        'wording-en': 'Syrian Arab Republic (the)',
        'wording-fr': 'République arabe syrienne',
        'Code alpha-2': 'SY',
        'code': '760'
    },
    {
        'wording-en': 'Central African Republic (the)',
        'wording-fr': 'République centrafricaine',
        'Code alpha-2': 'CF',
        'code': '140'
    },
    {
        'wording-en': 'Réunion',
        'wording-fr': 'Réunion',
        'Code alpha-2': 'RE',
        'code': '638'
    },
    {
        'wording-en': 'Romania',
        'wording-fr': 'Roumanie',
        'Code alpha-2': 'RO',
        'code': '642'
    },
    {
        'wording-en': 'United Kingdom of Great Britain and Northern Ireland (the)',
        'wording-fr': "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
        'Code alpha-2': 'GB',
        'code': '826'
    },
    {
        'wording-en': 'Russian Federation (the)',
        'wording-fr': 'Russie (la Fédération de)',
        'Code alpha-2': 'RU',
        'code': '643'
    },
    {
        'wording-en': 'Rwanda',
        'wording-fr': 'Rwanda',
        'Code alpha-2': 'RW',
        'code': '646'
    },
    {
        'wording-en': 'Western Sahara*',
        'wording-fr': 'Sahara occidental*',
        'Code alpha-2': 'EH',
        'code': '732'
    },
    {
        'wording-en': 'Saint Barthélemy',
        'wording-fr': 'Saint-Barthélemy',
        'Code alpha-2': 'BL',
        'code': '652'
    },
    {
        'wording-en': 'Saint Helena, Ascension and Tristan da Cunha',
        'wording-fr': 'Sainte-Hélène, Ascension et Tristan da Cunha',
        'Code alpha-2': 'SH',
        'code': '654'
    },
    {
        'wording-en': 'Saint Lucia',
        'wording-fr': 'Sainte-Lucie',
        'Code alpha-2': 'LC',
        'code': '662'
    },
    {
        'wording-en': 'Saint Kitts and Nevis',
        'wording-fr': 'Saint-Kitts-et-Nevis',
        'Code alpha-2': 'KN',
        'code': '659'
    },
    {
        'wording-en': 'San Marino',
        'wording-fr': 'Saint-Marin',
        'Code alpha-2': 'SM',
        'code': '674'
    },
    {
        'wording-en': 'Saint Martin (French part)',
        'wording-fr': 'Saint-Martin (partie française)',
        'Code alpha-2': 'MF',
        'code': '663'
    },
    {
        'wording-en': 'Sint Maarten (Dutch part)',
        'wording-fr': 'Saint-Martin (partie néerlandaise)',
        'Code alpha-2': 'SX',
        'code': '534'
    },
    {
        'wording-en': 'Saint Pierre and Miquelon',
        'wording-fr': 'Saint-Pierre-et-Miquelon',
        'Code alpha-2': 'PM',
        'code': '666'
    },
    {
        'wording-en': 'Holy See (the)',
        'wording-fr': 'Saint-Siège',
        'Code alpha-2': 'VA',
        'code': '336'
    },
    {
        'wording-en': 'Saint Vincent and the Grenadines',
        'wording-fr': 'Saint-Vincent-et-les Grenadines',
        'Code alpha-2': 'VC',
        'code': '670'
    },
    {
        'wording-en': 'Solomon Islands',
        'wording-fr': 'Salomon (les Îles)',
        'Code alpha-2': 'SB',
        'code': '090'
    },
    {
        'wording-en': 'Samoa',
        'wording-fr': 'Samoa',
        'Code alpha-2': 'WS',
        'code': '882'
    },
    {
        'wording-en': 'American Samoa',
        'wording-fr': 'Samoa américaines',
        'Code alpha-2': 'AS',
        'code': '016'
    },
    {
        'wording-en': 'Sao Tome and Principe',
        'wording-fr': 'Sao Tomé-et-Principe',
        'Code alpha-2': 'ST',
        'code': '678'
    },
    {
        'wording-en': 'Senegal',
        'wording-fr': 'Sénégal',
        'Code alpha-2': 'SN',
        'code': '686'
    },
    {
        'wording-en': 'Serbia',
        'wording-fr': 'Serbie',
        'Code alpha-2': 'RS',
        'code': '688'
    },
    {
        'wording-en': 'Seychelles',
        'wording-fr': 'Seychelles',
        'Code alpha-2': 'SC',
        'code': '690'
    },
    {
        'wording-en': 'Sierra Leone',
        'wording-fr': 'Sierra Leone',
        'Code alpha-2': 'SL',
        'code': '694'
    },
    {
        'wording-en': 'Singapore',
        'wording-fr': 'Singapour',
        'Code alpha-2': 'SG',
        'code': '702'
    },
    {
        'wording-en': 'Slovakia',
        'wording-fr': 'Slovaquie',
        'Code alpha-2': 'SK',
        'code': '703'
    },
    {
        'wording-en': 'Slovenia',
        'wording-fr': 'Slovénie',
        'Code alpha-2': 'SI',
        'code': '705'
    },
    {
        'wording-en': 'Somalia',
        'wording-fr': 'Somalie',
        'Code alpha-2': 'SO',
        'code': '706'
    },
    {
        'wording-en': 'Sudan (the)',
        'wording-fr': 'Soudan',
        'Code alpha-2': 'SD',
        'code': '729'
    },
    {
        'wording-en': 'South Sudan',
        'wording-fr': 'Soudan du Sud',
        'Code alpha-2': 'SS',
        'code': '728'
    },
    {
        'wording-en': 'Sri Lanka',
        'wording-fr': 'Sri Lanka',
        'Code alpha-2': 'LK',
        'code': '144'
    },
    {
        'wording-en': 'Sweden',
        'wording-fr': 'Suède',
        'Code alpha-2': 'SE',
        'code': '752'
    },
    {
        'wording-en': 'Switzerland',
        'wording-fr': 'Suisse',
        'Code alpha-2': 'CH',
        'code': '756'
    },
    {
        'wording-en': 'Suriname',
        'wording-fr': 'Suriname',
        'Code alpha-2': 'SR',
        'code': '740'
    },
    {
        'wording-en': 'Svalbard and Jan Mayen',
        'wording-fr': "Svalbard et l'Île Jan Mayen",
        'Code alpha-2': 'SJ',
        'code': '744'
    },
    {
        'wording-en': 'Tajikistan',
        'wording-fr': 'Tadjikistan',
        'Code alpha-2': 'TJ',
        'code': '762'
    },
    {
        'wording-en': 'Taiwan (Province of China)',
        'wording-fr': 'Taïwan (Province de Chine)',
        'Code alpha-2': 'TW',
        'code': '158'
    },
    {
        'wording-en': 'Tanzania, the United Republic of',
        'wording-fr': 'Tanzanie (la République-Unie de)',
        'Code alpha-2': 'TZ',
        'code': '834'
    },
    {
        'wording-en': 'Chad',
        'wording-fr': 'Tchad',
        'Code alpha-2': 'TD',
        'code': '148'
    },
    {
        'wording-en': 'Czechia',
        'wording-fr': 'Tchéquie',
        'Code alpha-2': 'CZ',
        'code': '203'
    },
    {
        'wording-en': 'French Southern Territories (the)',
        'wording-fr': 'Terres australes françaises',
        'Code alpha-2': 'TF',
        'code': '260'
    },
    {
        'wording-en': 'Thailand',
        'wording-fr': 'Thaïlande',
        'Code alpha-2': 'TH',
        'code': '764'
    },
    {
        'wording-en': 'Timor-Leste',
        'wording-fr': 'Timor-Leste',
        'Code alpha-2': 'TL',
        'code': '626'
    },
    {
        'wording-en': 'Togo',
        'wording-fr': 'Togo',
        'Code alpha-2': 'TG',
        'code': '768'
    },
    {
        'wording-en': 'Tokelau',
        'wording-fr': 'Tokelau',
        'Code alpha-2': 'TK',
        'code': '772'
    },
    {
        'wording-en': 'Tonga',
        'wording-fr': 'Tonga',
        'Code alpha-2': 'TO',
        'code': '776'
    },
    {
        'wording-en': 'Trinidad and Tobago',
        'wording-fr': 'Trinité-et-Tobago',
        'Code alpha-2': 'TT',
        'code': '780'
    },
    {
        'wording-en': 'Tunisia',
        'wording-fr': 'Tunisie',
        'Code alpha-2': 'TN',
        'code': '788'
    },
    {
        'wording-en': 'Turkmenistan',
        'wording-fr': 'Turkménistan',
        'Code alpha-2': 'TM',
        'code': '795'
    },
    {
        'wording-en': 'Turks and Caicos Islands (the)',
        'wording-fr': 'Turks-et-Caïcos (les Îles)',
        'Code alpha-2': 'TC',
        'code': '796'
    },
    {
        'wording-en': 'Turkey',
        'wording-fr': 'Turquie',
        'Code alpha-2': 'TR',
        'code': '792'
    },
    {
        'wording-en': 'Tuvalu',
        'wording-fr': 'Tuvalu',
        'Code alpha-2': 'TV',
        'code': '798'
    },
    {
        'wording-en': 'Ukraine',
        'wording-fr': 'Ukraine',
        'Code alpha-2': 'UA',
        'code': '804'
    },
    {
        'wording-en': 'European Union',
        'wording-fr': 'Union Européenne',
        'Code alpha-2': 'EU',
        'code': '097'
    },
    {
        'wording-en': 'Uruguay',
        'wording-fr': 'Uruguay',
        'Code alpha-2': 'UY',
        'code': '858'
    },
    {
        'wording-en': 'Vanuatu',
        'wording-fr': 'Vanuatu',
        'Code alpha-2': 'VU',
        'code': '548'
    },
    {
        'wording-en': 'Venezuela (Bolivarian Republic of)',
        'wording-fr': 'Venezuela (République bolivarienne du)',
        'Code alpha-2': 'VE',
        'code': '862'
    },
    {
        'wording-en': 'Virgin Islands (British)',
        'wording-fr': 'Vierges britanniques (les Îles)',
        'Code alpha-2': 'VG',
        'code': '092'
    },
    {
        'wording-en': 'Virgin Islands (U.S.)',
        'wording-fr': 'Vierges des États-Unis (les Îles)',
        'Code alpha-2': 'VI',
        'code': '850'
    },
    {
        'wording-en': 'Viet Nam',
        'wording-fr': 'Viet Nam',
        'Code alpha-2': 'VN',
        'code': '704'
    },
    {
        'wording-en': 'Wallis and Futuna',
        'wording-fr': 'Wallis-et-Futuna',
        'Code alpha-2': 'WF',
        'code': '876'
    },
    {
        'wording-en': 'Yemen',
        'wording-fr': 'Yémen',
        'Code alpha-2': 'YE',
        'code': '887'
    },
    {
        'wording-en': 'Zambia',
        'wording-fr': 'Zambie',
        'Code alpha-2': 'ZM',
        'code': '894'
    },
    {
        'wording-en': 'Zimbabwe',
        'wording-fr': 'Zimbabwe',
        'Code alpha-2': 'ZW',
        'code': '716'
    }
];
