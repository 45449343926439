'use strict';

const URL = {
    isNotPrefixedWithProtocol(url: string): boolean {
        return !/^([a-z]+):\/\//.test(url);
    },

    isValidURL(url: string): boolean {
        return /^(?:[a-z]+)?:\/\/(?:www\.|(?!www))(?:(?:[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])|(?:[a-zA-Z0-9]))\.[^\s]{2,}/.test(url);
    }
};

export default URL;
