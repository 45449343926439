import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';

@Component({
  selector: 'app-validation-edition-product',
  templateUrl: './validation-edition-product.component.html',
  styleUrls: ['./validation-edition-product.component.less']
})
export class ValidationEditionProductComponent implements OnInit {
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;

  @Output() public confirmEvent = new EventEmitter<boolean>();
  constructor() { }

  public ngOnInit(): void {
  }

  public show() {
    this.modal.open();
  }

  public close() {
    this.confirmEvent.emit(false);
    this.modal.close();
  }

  public confirm() {
    this.confirmEvent.emit(true);
  }

}
