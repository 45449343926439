import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';
import {NotificationsService, SimpleNotificationsModule} from 'angular2-notifications';
import {AppComponent} from './app.component';
import {CompanySvc, EventSourceSvc, NotificationsSvc, UserSvc} from './shared/services/index';
import {IERestrict} from './ie-restriction/ie-restriction.component';
import {SearchComponent} from './search/search.component';
import {OpenDataComponent} from './open-data/open-data.component';
import {Login} from './login/index';
import {NotFound} from './not-found/not-found.component';
import {
  UserPreferenceModal,
  LoadingComponent,
  AccountModal
} from './login/components/index';
import {Authentication} from './login/services/index';
import {SharedModule} from './shared/shared.module';
import {TutorialModal} from './login/components/tutorial-modal/tutorial-modal.component';
import { BsModalModule } from 'ng2-bs3-modal';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {HttpClientModule} from '@angular/common/http';
import {DashboardModule} from './dashboard/dashboard.module';
import {FeatureToggleSvc} from './shared/services/feature-toggle.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AuthConfigModule } from './auth-config.module';
import {VintageSvc} from './shared/services/vintages.service';
import {CommonModule} from '@angular/common';
// // Import here styles outside of the application component
// require('!style-loader!raw-loader!less-loader!../assets/styles/main.less');
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './shared/interceptor/token.interceptor';
import {FileUploadModule} from 'ng2-file-upload';
import {DateSvc} from './shared/services/date.service';
import {BannerService} from './shared/services/banner.service';
import {GtinSvc} from './shared/services/gtin.service';
import {OpenDataService} from './open-data/services/open-data.service';
import {ProductCategorySvc} from './shared/services/product-category.service';
import {ClassificationComponent} from './dashboard/components/classification';
import { HttpHeaders } from '@angular/common/http';
import {CacheService} from './shared/services/cache.service';

import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {environment} from '../environments/environment';
import { Gs1ComponentsLibraryModule, GS1LayoutModule } from '@gs1france/gs1-components-library';
import { NgSelectModule } from '@ng-select/ng-select';
import {Maintenance} from './maintenance/maintenance.component';
import {InvoiceSvc} from './shared/services/invoice.service';
const appRroutes: Routes = [
  {path: 'login', component: Login},
  {path: 'loading', component: LoadingComponent},
  {path: 'restrict', component: IERestrict},
  {path: 'search', component: SearchComponent},
  {path: 'search/:type', component: SearchComponent},
  {path: 'classifications', component: ClassificationComponent},
  {path: 'maintenance', component: Maintenance},
  {path: '', redirectTo: '/', pathMatch: 'prefix'},
  {path: '**', component: NotFound}
];

/*
 * App Module
 * Top Level Module
 */
@NgModule({
  bootstrap: [AppComponent],
  imports: [
    RouterModule.forRoot(appRroutes, { useHash: false, preloadingStrategy: PreloadAllModules, enableTracing: false, relativeLinkResolution: 'legacy' }),
    DashboardModule,
    SharedModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    BsModalModule,
    CarouselModule.forRoot(),
    FileUploadModule,
    AuthConfigModule,
    CommonModule,
    Gs1ComponentsLibraryModule.forRoot(), GS1LayoutModule,
    NgSelectModule
  ],
  providers: [
    UserSvc,
    CompanySvc,
    InvoiceSvc,
    EventSourceSvc,
    NotificationsService,
    NotificationsSvc,
    Authentication,
    FeatureToggleSvc,
    BannerService,
    VintageSvc,
    DateSvc,
    GtinSvc,
    ProductCategorySvc,
    CacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri:  `${environment.APP_SYNC_URL}`,
            headers: new HttpHeaders({
              'x-api-key':  `${environment.APP_SYNC_API_KEY}`
            })
          })
        };
      },
      deps: [HttpLink]
    }
  ],
  declarations: [
    AppComponent,
    Login,
    SearchComponent,
    IERestrict,
    NotFound,
    Maintenance,
    LoadingComponent,
    UserPreferenceModal,
    AccountModal,
    TutorialModal
  ]
})
export class AppModule {
  constructor() {
  }
}
