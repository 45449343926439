import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Authentication} from '../../services/index';
import {UserSvc, FeatureToggleSvc} from '../../../shared/services';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Account} from '../../models/index';
import {NotificationsSvc} from '../../../shared/services/notifications.service';
import {AuthGuard} from '../../../shared/services/auth-guard';

@Component({
  selector: 'loading',
  styleUrls: ['./loading.less'],
  templateUrl: './loading.tpl.html'
})
export class LoadingComponent implements OnInit {
  public accounts: Account[];

  constructor(private authSvc: Authentication,
              private router: Router, private userSvc: UserSvc, public oidcSecurityService: OidcSecurityService,
              private featureToggleSvc: FeatureToggleSvc, private notifSvc: NotificationsSvc, private authGuard: AuthGuard) {
  }

  public async ngOnInit() {
    // @TODO: review this  code
    try {
      if (this.authGuard.jwt) {
        this.router.navigate([('/dashboard')]);
        return;
      }
      const idToken = this.oidcSecurityService.getIdToken();
      const token = this.oidcSecurityService.getAccessToken();
      if (idToken && token) {
        const user = this.oidcSecurityService.getUserData();
        this.userSvc.user.email = user.email;
        const companyGuid = user.company_id;
        const blockedList: [String] = await this.userSvc.getBlockedList();
        if (blockedList.indexOf(companyGuid) !== -1) {
          this.notifSvc.userUnauthorizedToConnect();
          this.userSvc.disconnectUser(true);
          return null;
        }
        await this.connectUser(companyGuid, idToken, token);
        return ;
      }
    } catch (e) {
      this.router.navigate(['/login']);
      return null;
    }

    this.router.navigate(['/login']);
  }

  public getUserByGuid(guid, idToken, token) {
    return this.userSvc.getUserByGuid(this.userSvc.user.email, guid, idToken, token);
  }

  public connectUser(guid: string, idToken: string, token: string): void {
    this.getUserByGuid(guid, idToken, token).then(() => {
      this.router.navigate(['/dashboard']);
      return null;
    }).catch(err => {
      if (err.status === 403) {
        this.notifSvc.userUnauthorizedToConnect();
        this.userSvc.disconnectUser(true);
      }
      return null;
    });
  }

  public onValidateApplicationDisclaimer() {
    this.router.navigate(['/dashboard']);
  }
}
