import {
  Component,
  ViewEncapsulation,
  ElementRef,
  OnInit
} from '@angular/core';

import {NotificationsSvc} from '../../../../../../../shared/services';
import {DashboardConst} from '../../../../../../models/dashboard.constants';

import {NetContent} from '../../../../../../../dashboard/models/NetContent.model';
import {NetContentConst} from '../../../../../../../dashboard/models/netContent.constants';
import {
  nutrimentItemsConst,
  nutrimentMeasurementUnitConst,
  nutrimentServingSizeConst,
  nutrimentMeasurementPrecisionConst,
  optionalNutrimentItemsConst
} from '../../../../../../../dashboard/models';
import {
  drainedWeightMeasurementUnits
} from '../../../../../../../dashboard/models/drainedWeightMeasurementUnit.constants';
import {StepComponent} from './StepComponent';
import {NutrimentItem} from '../../../../../../models/nutrimentItem.model';
import * as $ from 'jquery';
import * as _ from 'lodash';

@Component({
  selector: 'composition-step',
  styleUrls: ['../unity-consumer-creation-food.less'],
  templateUrl: './composition-step.tpl.html',
  encapsulation: ViewEncapsulation.None
})
export class CompositionStepComponent extends StepComponent implements OnInit {

  public stepCount: number = 1;
  public requiredNutrimentItems: any = nutrimentItemsConst;
  public measurementUnit = nutrimentMeasurementUnitConst;
  public nutrimentServingSize = nutrimentServingSizeConst;
  public measurementPrecision = nutrimentMeasurementPrecisionConst;
  public optionalNutrimentItems = optionalNutrimentItemsConst;
  public drainedWeightMeasurementUnitConst = drainedWeightMeasurementUnits;
  // used in createBase class : to move or refactor
  public DECIMAL_PATTERN = '^([0-9]{1,2}(,\\d{1})?|100)$';
  public constants = DashboardConst;
  public canAddNetContent: boolean = true;
  public measurementUnitsList: Array<Array<{}>> = new Array();
  public canSeeOptionalNutriment: boolean = false;
  public canChangeDryWeight: boolean = false;
  public canChangeAlcoholPerVolume: boolean = false;
  public dailyValueIntakeReferenceEditable: boolean = false;
  public showAddNutriment: boolean = true;
  public optionalColumnHeight: number;
  public selectedNutriment: any;
  public ingredientPlaceholder: string = '';
  public NetContentConstants = NetContentConst.all;
  public preparationStateCodeClicked: boolean;
  private selectedNutrimentList: Array<Array<{}>> = new Array();

  constructor(public notificationsSvc: NotificationsSvc, private elRef: ElementRef) {
    super();
  }

  public ngOnInit() {
    if (this.productForm.nutriment.servingSizeDescription && this.productForm.nutriment.servingSizeDescription.length !== 0) {
      this.canSeeOptionalNutriment = true;
    }

    this.canChangeDryWeight = this.hasDrainedWeight;
    this.canChangeAlcoholPerVolume = this.hasAlcohol;

    $('input[name="preparationStateCode"]').on('click', () => {
      $('.radio-inline-text').removeClass('radioBtnError');
    });
    if (this.productForm && this.productForm.ingredientStatement && this.productForm.ingredientStatement.length) {
      this.ingredientPlaceholder = this.productForm.ingredientStatement;
    }
  }

  public submitForm(submitting: boolean = true, form: HTMLFormElement) {
    if (!this.checkErrorsInQuantities()) {
      this.notificationsSvc.wrongNumberOfDecimalsDigits();
      return false;
    }
    if (form && form.checkValidity() === false) {
      return;
    }
    if (!this.checkSugar()) {
      return false;
    }
    if (!this.isValidFatNutriments()) {
      this.notificationsSvc.wrongFoodNutriment();
      return false;
    }


    if (this.isValidAlcoholDegree() && this.checkDrainedWeight() && this.checkNetContents()) {
      const data: any = _.filter(this.productForm.netContents, (netContent: NetContent) => netContent.netContent && netContent.measurementUnitCode);
      this.productForm.netContents = data;
      super.submitForm(submitting, form);
    }
    return false;
  }

  public checkSugar() {
    const glucidesItem = this.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'CHOAVL');
    const sugarItem = this.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'SUGAR-');
    const keysToCheck = ['quantityContained'];         // , ['optionalQuantityContained', 'dailyValueIntakeReference']
    let checker = false;

    for (const key of keysToCheck) {
      // const key = keysToCheck[i];
      const glucidesValue = parseFloat(glucidesItem[key].replace(',', '.'));
      const sugarValue = parseFloat(sugarItem[key].replace(',', '.'));
      checker = checker || (sugarValue > glucidesValue);
      if (sugarValue > glucidesValue) {
        const sugarElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_SUGAR-`);
        const glucidesElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_CHOAVL`);
        sugarElement.className += ' inputError';
        glucidesElement.className += ' inputError';
        sugarElement.scrollIntoView({
          block: 'center'
        });
      }
    }

    if (checker) {
      this.notificationsSvc.sugarSupToCarbohydrates();
      return false;
    }
    return true;
  }

  public editDailyValueIntakeReference() {
    this.dailyValueIntakeReferenceEditable = true;
  }

  public hasErrorDrainedWeight(): boolean {
    const DRAINED_WEIGHT_PATTERN = '^([0-9]{1,}(,\\d{1,2})?)$';
    if (this.productForm.drainedWeight.quantityContained) {
      const decimalRegex = new RegExp(DRAINED_WEIGHT_PATTERN);
      if (!decimalRegex.test(this.productForm.drainedWeight.quantityContained)) {
        return true;
      }
    }
    return false;
  }

  public addNetContent() {
    this.productForm.netContents.push(new NetContent());
    this.measurementUnitsList.push(this.NetContentConstants);
    this.updateMeasurementUnitList();
  }

  public updateMeasurementUnitList() {
    let hasAtLeastOneValidItem = false;
    const usedMeasurementUnitList = _.map(this.productForm.netContents, 'measurementUnitCode');
    const usedMeasurementCategoriesList = _.reduce(this.NetContentConstants, (categories: any, item) => {
      if (usedMeasurementUnitList.indexOf(item.code) >= 0) {
        categories[item.category] = true;
      }
      return categories;
    }, {});
    for (let i = 0; i < this.productForm.netContents.length; i++) {
      const selectedNetContent = this.productForm.netContents[i];
      const selectedCategory = _.find(this.NetContentConstants, {code: selectedNetContent.measurementUnitCode}) || {category: ''};
      this.measurementUnitsList[i] = this.NetContentConstants.filter((item: any) => {
        return !usedMeasurementCategoriesList[item.category] || item.category === selectedCategory.category;
      });

      if (!selectedNetContent.isEmpty()) {
        hasAtLeastOneValidItem = true;
      }
    }
    this.makeNetContentRequired(!hasAtLeastOneValidItem);
    this.canAddNetContent = this.productForm.netContents.length < 5;
  }

  public makeNetContentRequired(state: boolean) {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#net-content-item-0');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      const selectElement: HTMLSelectElement = divElement.querySelector('select');
      inputElement.required = state;
      selectElement.required = state;
    }
  }

  public removeNetContent(netContent: any, index: number) {
    this.productForm.netContents = this.productForm.netContents.filter((item: any) => {
      return netContent !== item;
    });

    this.measurementUnitsList = this.measurementUnitsList.filter((item: any, i) => {
      return index !== i;
    });

    this.updateMeasurementUnitList();
  }

  public isValidAlcoholDegree() {
    if (this.productForm.alcoholDegree !== '') {
      const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
      return decimalRegex.test(this.productForm.alcoholDegree);
    }
    return true;
  }

  public onChangeMeasurementUnit(self: any) {
    if ($(self).hasClass('selectError')) {
      $(self).removeClass('selectError');
    }
    this.updateMeasurementUnitList();
  }

  public showDailyValueIntakeReferenceMessage(): boolean {
    if (_.isEmpty(this.productForm.nutriment.servingSizeDescription)) {
      this.resetDailyValueIntakeReferenceAndOptionalQuantityContained();
    }
    return !_.isEmpty(this.productForm.nutriment.servingSizeDescription);

  }

  public findNutrimentWording(code: string, measurementUnitCode: string): string {
    const nutriment: any = _.find(this.requiredNutrimentItems, c => c.code === code && c.measurementUnitCode === measurementUnitCode) || {};
    return nutriment.wording;
  }

  public findMeasurementUnitWording(code: string): string {
    const measurementUnit: any = _.find(this.measurementUnit, c => c.code === code) || {};
    return measurementUnit.wording;
  }

  public findServingSizeWording(code: string): string {
    const returnedObject: any = _.find(this.nutrimentServingSize, c => c.code === code) || {};
    return `${returnedObject.wording}`;
  }

  public findMeasurementPrecisionWording(code: string): string {
    const measurementPrecision: any = _.find(this.measurementPrecision, c => c.code === code) || {};
    return measurementPrecision.wording;
  }

  public findDrainedWeightMesurementWording(code: string): string {
    const returnedObject: any = _.find(this.drainedWeightMeasurementUnitConst, c => c.code === code) || {};
    return `${returnedObject.wording}`;
  }

  public isRequiredDailyValueIntakeReference(): boolean {
    let isRequired = false;
    if (!_.isEmpty(this.productForm.nutriment.servingSizeDescription)) {
      const dailyValueIntakeReferences = _.map(this.productForm.nutriment.items, item => item.dailyValueIntakeReference) || [];
      for (const dailyValueIntakeReference of dailyValueIntakeReferences) {
        if (!_.isEmpty(dailyValueIntakeReference)) {
          isRequired = true;
          break;
        }
      }
    }
    return isRequired;
  }

  public checkValue(event: any) {
    return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 44;
  }

  public checkEnergeticValue(item: any, key: any) {
    const energyKeys = ['KJO', 'E14'];
    const indexKey = energyKeys.indexOf(item.measurementUnitCode);
    if (indexKey >= 0) {
      this.manageEnergeticValues(item, energyKeys[1 - indexKey], key);
    }
  }

  public manageEnergeticValues(item: { [x: string]: any; }, type: string, key: string | number) {
    if (item[key]) {
      let value = item[key];
      let otherValue = this.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key];

      // value: , => .
      value = value.replace(',', '.');

      otherValue = '' + (
        type === 'E14' ?
          (parseFloat(value) * 0.239006) :
          (parseFloat(value) / 0.239006)
      ).toFixed(1);

      // otherValue: . => ,
      otherValue = otherValue.replace('.', ',');

      this.productForm.nutriment.items.find((f : any) => f.measurementUnitCode === type)[key] = otherValue;
    } else {
      this.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = '';
    }
  }

  public calculateDailyValues(item: NutrimentItem) {
    const dailyReferences: any = {
      'KJO': 8400,
      'E14': 2000,
      'FAT': 70,
      'PRO-': 50,
      'SALTEQ': 6,
      'CHOAVL': 260,
      'SUGAR-': 90,
      'FASAT': 20
    };

    // the first two nutriments (kilojoule and kilocalories) have the same nutriment type code, so I used their measurement unit code in the dailyReferences object
    const key = item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode;

    if (_.isEmpty(item.optionalQuantityContained) || _.isNil(dailyReferences[key])) {
      item.dailyValueIntakeReference = null;
      return;
    }
    // the value to set
    let valueToSet = '';
    if (item.optionalQuantityContained && item.optionalQuantityContained.length) {
      valueToSet = '' + ((parseFloat(item.optionalQuantityContained) / dailyReferences[key]) * 100).toFixed(1);
      valueToSet = valueToSet.replace('.', ',');
    }
    // in the array for the brought references, and for the energetic values, we only use the first item (the one with KJO unit)
    if (key !== 'E14') {
      item.dailyValueIntakeReference = valueToSet;
    } else {
      this.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === 'KJO').dailyValueIntakeReference = valueToSet;
    }
  }

  public calculateAllDailyValues() {
    this.productForm.nutriment.items.forEach((item: NutrimentItem) => {
      this.calculateDailyValues(item);
    });
  }

  public hideOptionalNutriment() {
    this.canSeeOptionalNutriment = false;
  }

  public showOptionalNutriment() {
    this.canSeeOptionalNutriment = true;
  }

  public showDryWeight() {
    this.canChangeDryWeight === true ? this.canChangeDryWeight = false : this.canChangeDryWeight = true;
    this.hasDrainedWeight = this.canChangeDryWeight;
    this.onHasDrainedWeightChanged.emit(this.hasDrainedWeight);
  }

  public showAlcoholPerVolume() {
    this.canChangeAlcoholPerVolume === true ? this.canChangeAlcoholPerVolume = false : this.canChangeAlcoholPerVolume = true;
    this.hasAlcohol = this.canChangeAlcoholPerVolume;
    this.onHasAlcoholChanged.emit(this.hasAlcohol);
  }

  public reValidateOptionalQuantityContained() {
    if (!this.isRequiredOptionalQuantityContained()) {
      const optionalQuantityContainedInputs: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('.optionalQuantityContained');
      optionalQuantityContainedInputs.forEach((element: HTMLInputElement) => {
        element.className = `optionalQuantityContained specificWidth`;
      });
    }
  }

  public checkRequiredInputs(form: HTMLFormElement, event: Event) {
    if (form.checkValidity() === false) {
      this.addClassToNetContents();
      this.addClassToPreperationStatement();
      this.addClassToRequiredNutriments();

      let scrollToElement: HTMLElement = this.elRef.nativeElement.querySelector('.inputError');
      if (scrollToElement) {
        scrollToElement.scrollIntoView({
          block: scrollToElement.className.indexOf('dailyValueIntakeReference') === -1 ? 'center' : 'end'
        });
        return;
      }

      scrollToElement = this.elRef.nativeElement.querySelector('.error');
      if (scrollToElement) {
        scrollToElement.scrollIntoView({
          block: 'nearest'
        });
        return;
      }

      scrollToElement = this.elRef.nativeElement.querySelector('.selectError');
      if (scrollToElement) {
        scrollToElement.scrollIntoView({
          block: 'center'
        });
        return;
      }
      scrollToElement = this.elRef.nativeElement.querySelector('.radioBtnError');
      if (scrollToElement) {
        scrollToElement.scrollIntoView({
          block: 'center'
        });
        return;
      }
    }
  }

  public isRequiredOptionalQuantityContained(): boolean {
    if (this.productForm.nutriment.servingSizeDescription === null) {
      return false;
    }

    if (this.productForm.nutriment.servingSizeDescription.length === 0) {
      return false;
    }

    return true;
  }

  public addNutriment() {
    this.showAddNutriment = false;
  }

  public removeNutriment(nutriment: NutrimentItem, index: number) {
    const itemRemoved: any = _.filter(this.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === nutriment.nutrientTypeCode)[0] || {};
    this.requiredNutrimentItems = _.filter(this.requiredNutrimentItems, (nutrimentItem: NutrimentItem) => nutrimentItem.nutrientTypeCode !== nutriment.nutrientTypeCode);
    this.optionalNutrimentItems.push(itemRemoved);
    this.productForm.nutriment.items = this.productForm.nutriment.items.filter((item: any) => {
      return nutriment !== item;
    });
    this.selectedNutriment = null;
    this.optionalColumnHeight -= 60;
  }

  public selectNutriment(element: HTMLDivElement) {
    this.showAddNutriment = true;
    this.requiredNutrimentItems.push(this.selectedNutriment);
    this.optionalNutrimentItems = _.filter(this.optionalNutrimentItems, (nutrimentItem) => nutrimentItem.code !== this.selectedNutriment.code);
    const nutriment = new NutrimentItem(this.selectedNutriment.code, 'APPROXIMATELY', null, null, this.selectedNutriment.measurementUnitCode, null, false);
    this.productForm.nutriment.items.push(nutriment);
    this.optionalColumnHeight = element.offsetHeight + 60;
  }

  public resetNutrimentClass(item: NutrimentItem, type: string) {
    const selector = item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode;
    const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${type}_${selector}`);
    element.className = element.className.replace('inputError', '');
  }

  public updateTextArea(editableContent: HTMLDivElement) {
    this.productForm.ingredientStatement = editableContent.textContent;
  }

  private addClassToRequiredNutriments() {
    const quantityContainedInputs: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('.item_quantityContained');
    quantityContainedInputs.forEach((element: HTMLInputElement) => {
      if (_.isEmpty(element.value) && !element.className.includes('inputError')) {
        element.className = `${element.className} inputError`;
      }
    });
    const optionalQuantityContainedInputs: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('.optionalQuantityContained');
    optionalQuantityContainedInputs.forEach((element: HTMLInputElement) => {
      if (_.isEmpty(element.value) && element.required && !element.className.includes('inputError')) {
        element.className = `${element.className} inputError`;
      }
    });

    const dailyValueIntakeReferenceInputs: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('.dailyValueIntakeReference');
    dailyValueIntakeReferenceInputs.forEach((element: HTMLInputElement) => {
      if (_.isEmpty(element.value) && element.required && !element.className.includes('inputError')) {
        element.className = `${element.className} inputError`;
      }
    });
  }

  private addClassToPreperationStatement() {
    if (_.isNil(this.productForm.preparationStateCode)) {
      const preparationStateCodeElements: HTMLSpanElement[] = this.elRef.nativeElement.querySelectorAll('.radio-inline-text');
      preparationStateCodeElements.forEach((element: HTMLSpanElement) => {
        element.className = `${element.className} radioBtnError`;
      });
    }
  }

  private addClassToNetContents() {
    const netContentInputs: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('.net-content-item-value');
    netContentInputs.forEach((element: HTMLInputElement) => {
      if (_.isEmpty(element.value) && !element.className.includes('inputError')) {
        element.className = `${element.className} inputError`;
      }
    });
    const netContentSelects: HTMLSelectElement[] = this.elRef.nativeElement.querySelectorAll('.net-content-item-unit');
    netContentSelects.forEach((element: HTMLSelectElement) => {
      if (_.isEmpty(element.value)) {
        element.className = `${element.className} selectError`;
      }
    });
  }

  private resetDailyValueIntakeReferenceAndOptionalQuantityContained() {
    this.productForm.nutriment.items.forEach((item: any) => {
      item.dailyValueIntakeReference = null;
      item.optionalQuantityContained = null;
    });
  }

  private checkDrainedWeight(): boolean {
    if (this.hasErrorDrainedWeight()) {
      this.notificationsSvc.drainedWeightError();
      return false;
    }

    if (this.productForm.drainedWeight.quantityContained && _.isEmpty(this.productForm.drainedWeight.measurementUnitCode)) {
      this.notificationsSvc.drainedWeightError();
      return false;
    }

    if (this.productForm.drainedWeight.measurementUnitCode && _.isEmpty(this.productForm.drainedWeight.quantityContained)) {
      this.notificationsSvc.drainedWeightError();
      return false;
    }
    return true;
  }

  private isContainIngredientWord(): boolean {
    const ingredient: string = this.productForm.ingredientStatement;
    if (ingredient.toUpperCase().indexOf('Ingrédients :'.toUpperCase()) !== -1) {
      return true;
    }
    return false;
  }

  private checkNetContents() {
    for (const currentNetContent of this.productForm.netContents) {
      if (currentNetContent.hasError()) {
        return false;
      }
    }
    return true;
  }

  private isValidFatNutriments(): boolean {

    const FASATNutriments = _.filter(this.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FASAT');
    const FATNutriments = _.filter(this.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FAT');
    const FASATNutriment: NutrimentItem = FASATNutriments[0];
    const FATNutriment: NutrimentItem = FATNutriments[0];
    const FASATQuantityContained = FASATNutriment.quantityContained ? FASATNutriment.quantityContained : '';
    const FATQuantityContained = FATNutriment.quantityContained ? FATNutriment.quantityContained : '';
    const toReturn = parseFloat(FASATQuantityContained.replace(/,/g, '.')) <= parseFloat(FATQuantityContained.replace(/,/g, '.'));
    const fasatElement: HTMLInputElement = this.elRef.nativeElement.querySelector('#quantityContained_FASAT');
    const fatElement: HTMLInputElement = this.elRef.nativeElement.querySelector('#quantityContained_FAT');
    // fasatElement.className = 'specificWidth';
    // fatElement.className = 'specificWidth';
    if (!toReturn) {
      fasatElement.className += ' inputError';
      fatElement.className += ' inputError';
      fasatElement.scrollIntoView({
        block: 'center'
      });
    }
    return toReturn;
  }

  private checkErrorsInQuantities(): boolean {
    let toReturn = true;
    this.productForm.nutriment.items.forEach((i: any) => {
      toReturn = toReturn && (!i.hasErrorQuantityContained() && !i.hasErrorOptionalQuantityContained() && !i.hasErrorDailyValueIntakeReference());
      if (i.hasErrorQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#quantityContained_${selector}`);
        if (!element.className.includes('inputError')) {
          element.className += ' inputError';
        }
      }
      if (i.hasErrorOptionalQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#optionalQuantityContained_${selector}`);
        if (!element.className.includes('inputError')) {
          element.className += ' inputError';
        }
      }
    });
    return toReturn;
  }
}
