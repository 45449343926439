import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {DashboardConst, FormInputError, ProductTypes} from '../../models/index';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CompanySvc, NotificationsSvc} from '../../../shared/services';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import {ProductCategorySvc} from '../../../shared/services/product-category.service';
import {BarcodeGenerationSvc, ProductsSvc} from '../../services';
import {VintageSvc} from '../../../shared/services/vintages.service';
import {ConfirmationModal} from '../../../shared/components';
import {UnityFormResolver} from '../../services/unityFormResolver.service';
import {Subject} from 'rxjs';
import {PublishingDescriptionModal} from '../publishing-description/publishing-description.component';
import {ValidationEditionProductComponent} from './validation-edition-product/validation-edition-product.component';

import * as _ from 'lodash';
import {UnityConsumerEditionVcsComponent} from './product-edition-vcs/unity-consumer-edition-vcs.component';

@Component({
  selector: 'product-edition-choice-category',
  styleUrls: ['./product-edition-choice-category.less'],
  templateUrl: './product-edition-choice-category.tpl.html'
})
export class ProductEditionChoiceCategoryComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild('formProduct', {static: true})
  public form;
  public constants = DashboardConst;
  public showCategory: boolean;
  public isFood: boolean;
  public product: any;
  public isGeneric: boolean;
  public isBundle: boolean;
  public isVcs: boolean;
  public selectedCategory: {
    breadcrumbs: string,
    code: string,
    definition: string,
    parentIds: string[],
    text: string,
    type: string
  } = {
    breadcrumbs: '',
    code: '',
    definition: '',
    parentIds: [],
    text: '',
    type: ''
  };
  public showClassification: boolean = false;
  public webLinkError: FormInputError;
  @Output() public onModifyCategory = new EventEmitter<any>();
  public measurementUnitsList: Array<Array<{}>> = [];
  public canAddNetContent: boolean = true;
  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;
  public unityForm: any;
  public productType: ProductTypes;
  public isSpi: boolean;
  public isWine: boolean;
  public submitSubject: Subject<void> = new Subject<void>();
  @ViewChild('PublishingDescriptionModal', {static: true})
  public publishingDescriptionModal: PublishingDescriptionModal;
  public showAdhereSectoralBtn: boolean;

  @ViewChild('unityConsumerEditionVcsComponent')
  public unityConsumerEditionVcsComponent: UnityConsumerEditionVcsComponent;

  @ViewChild(ValidationEditionProductComponent, {static: true})
  public ValidationEditionProductComponent: ValidationEditionProductComponent;

  private validationEditionKeys = [
    'brand',
    'productNames',
    'netContents'
  ];

  constructor(private headerSvc: DashboardHeaderSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public notificationsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              private productCategorySvc: ProductCategorySvc,
              public productsSvc: ProductsSvc,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public vintageSvc: VintageSvc,
              private elRef: ElementRef,
              private unityFormResolver: UnityFormResolver) {
    this.resetWebLinkError();
  }

  public ngOnInit() {
    // subscribe to router event
    this.headerSvc.setHeaderData('Modification fiche produit', 'assets/icons/editCode.svg');
    this.showCategory = false;
    this.route.params.subscribe(
      (param: Params) => {
        const id = param['id'];
        if (id !== _.get(this.productsSvc.product, 'id', null)) {
          this._router.navigate(['/dashboard']);
          return;
        }
        this.product = this.productsSvc.product;
        const codeGpc = this.product.codeGpc;
        if (this.isVcsCategory(codeGpc) && this.product.productType !== 'BUNDLE' && this.product.productResellerType !== 'NOT_NEW') {
          this.isVcs = true;
          this.productType = ProductTypes.VCS;
        } else {
          this.isGeneric = true;
          this.productType = ProductTypes.GENERIC;
        }
        this.unityForm = this.unityFormResolver.resolveEditionModel(this.productType);
        _.assign(this.unityForm.productForm, this.product);
        this.changeAdhereSectoralValueBasedOnMandatoryAtt();
        this.setShowAdhereSectoralBtn();
      });
  }

  public ngAfterViewInit() {
    if (!this.companySvc.company.companyName) {
      this._router.navigate(['/dashboard']);
    }

  }

  public ngAfterContentChecked(): void {
    if (this.form) {
      this.form.control.valueChanges
        .subscribe(() => {
          this.unityForm.productForm.completionLevel = this.productsSvc.getLevelCompletion(this.unityForm.productForm);
        });
    }
  }

  public categorySelected(category: {
    breadcrumbs: string,
    code: string,
    definition: string,
    parentIds: string[],
    text: string,
    type: string
  }) {
    this.selectedCategory = this.companySvc.getSelectedCategory();
    this.showCategory = false;
    this.showClassification = false;
    this.product.codeGpc = category.code;
    this.product.category = category.text;
    if (this.isFoodCategory(category.code)) {
      this.isFood = true;
      this.isGeneric = false;
      this.isVcs = false;
      this.productType = ProductTypes.FOOD;
      this.product.adhereToTheSectoralAgreement = false;
    } else if (this.isVcsCategory(category.code)) {
      this.isVcs = true;
      this.isFood = false;
      this.isGeneric = false;
      this.productType = ProductTypes.VCS;
      this.product.adhereToTheSectoralAgreement = false;
    } else {
      this.productType = ProductTypes.GENERIC;
      this.isGeneric = true;
      this.isVcs = false;
      this.isFood = false;
      this.product.adhereToTheSectoralAgreement = true;
    }
    this.unityForm = this.unityFormResolver.resolveEditionModel(this.productType);
    _.assign(this.unityForm.productForm, this.product);
    this.setShowAdhereSectoralBtn();
  }

  public navigateToClassification() {
    this.isFood = false;
    this.isGeneric = false;
    this.isVcs = false;
    this.showCategory = false;
    this.showClassification = true;
  }

  public modifyCategory() {
    this.showCategory = true;
    this.unityForm.productForm.adhereToTheSectoralAgreement = this.productType === ProductTypes.GENERIC;
    this.showAdhereSectoralBtn = !this.unityForm.productForm.adhereToTheSectoralAgreement;
  }

  public lastSubmitStep() {
    if (this.isGeneric) {
      this.unityForm.submitForm();
    }else {
      this.submitSubject.next();
    }
  }

  public validEdition(event: boolean) {
    event ? this.lastSubmitStep() : this._router.navigate([`/dashboard/product/view/${this.product.id}`]);
  }

  public submitForm() {
    const urlsWithErrors = _.filter(this.unityForm.productForm.imagesAddedByUrl, i => !i.isEmpty() && i.hasError());
    if (!_.isEmpty(urlsWithErrors)) {
      // Clean urls needed
      this.notificationsSvc.invalidImageUrl();
      return;
    }
    this.unityForm.productForm.imagesAddedByUrl = _.filter(this.unityForm.productForm.imagesAddedByUrl, i => !i.isEmpty() && !i.hasError());
    if (this.modificationDetector()) {
      this.ValidationEditionProductComponent.show();
    }else {
      this.lastSubmitStep();
    }
  }

  public showPublishingDescriptionModal() {
    this.publishingDescriptionModal.openModal();
  }

  public btnCompletionWording(): string {
    return this.unityForm.productForm.adhereToTheSectoralAgreement ? 'Je ne souhaite plus compléter mes informations sectorielles' : 'Compléter les informations sectorielles';
  }

  public showBtn(): boolean {
    const requiredElements: [HTMLElement] = this.elRef.nativeElement.querySelectorAll('[required]');
    let isInvalidForm = false;
    for (const el of requiredElements) {
      if (el.className.indexOf('ng-invalid') !== -1) {
        isInvalidForm = true;
      }
    }
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) {
      if (!this.hasSetAllRegulatoryAttribute()) {
        return false;
      }
      if (this.unityForm.productForm.adhereToTheSectoralAgreement === true && !isInvalidForm) {
        return true;
      }
      if (this.unityForm.productForm.adhereToTheSectoralAgreement === true && isInvalidForm) {
        return false;
      }

      return true;
    }

    if (!this.unityForm.productForm.adhereToRegulatoryAgreement) {
      if (this.unityForm.productForm.adhereToTheSectoralAgreement === true && isInvalidForm) {
        return false;
      }
    }

    return true;
  }

  public adhereToTheSectoralAgreement() {
    this.unityForm.productForm.adhereToTheSectoralAgreement = !this.unityForm.productForm.adhereToTheSectoralAgreement;
    const scrollToElement = this.elRef.nativeElement.querySelector('.to-scroll-to');
    if (scrollToElement && this.unityForm.productForm.adhereToTheSectoralAgreement) {
      setTimeout(() => {
        scrollToElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }, 50);
    }
  }

  public isGtin8() {
    return this.unityForm.productForm.gtin.isGtin8;
  }

  public hasSetAllRegulatoryAttribute() {
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) {
      return !_.isEmpty(this.unityForm.productForm.ingredientStatement) && this.hasSetAllNutrients();
    }
    return false;
  }

  public hasSetAllNutrients(): boolean {
    let toReturn = true;
    if (!this.isVcs && _.isEmpty(this.unityForm.productForm.preparationStateCode)) {
      return false;
    }

    for (const item of this.unityForm.productForm.nutriment?.items) {
      if (_.isEmpty(item.quantityContained)) {
        toReturn = false;
      }
    }

    return toReturn;
  }

  private isFoodCategory(codeGpc: string): boolean {
    const foodObject: any = _.find(this.productCategorySvc.categories, c => c.type === 'FOOD') || {};
    const codes = foodObject.codes || [];
    return codes.indexOf(codeGpc) !== -1;
  }

  private isVcsCategory(codeGpc: string): boolean {
    const check = this.productCategorySvc.isVcsCategory(codeGpc);

    this.isSpi = check.isSpi;
    this.isWine = check.isWine;
    return check.isVcsCategory;
  }

  private resetWebLinkError() {
    this.webLinkError = new FormInputError('', '');
  }

  /**
   * we use this method to update the value of adhereToTheSectoralAgreement attribute
   * to avoid changing the value of adhereToTheSectoralAgreement when
   * the user creates a product with all food attributes and ofter he makes an import food
   * Nowadays, when the user makes an import food the adhereToTheSectoralAgreement attribute is set to false
   */
  private changeAdhereSectoralValueBasedOnMandatoryAtt() {
    if (!this.unityForm.productForm.adhereToTheSectoralAgreement && this.isFood) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = _.isEmpty(this.unityForm.productForm.regulatedProductName) ? this.unityForm.productForm.adhereToTheSectoralAgreement : true;
    }
  }

  private setShowAdhereSectoralBtn() {
    if (this.unityForm.productForm.codeGpc === '99999999') {
      this.showAdhereSectoralBtn = false;
      return;
    }

    if (this.productType === ProductTypes.GENERIC && !this.unityForm.productForm.adhereToTheSectoralAgreement) {
      this.showAdhereSectoralBtn = false;
      return;
    }
    this.showAdhereSectoralBtn = _.isNil(this.unityForm.productForm.adhereToTheSectoralAgreement) ? false : !this.unityForm.productForm.adhereToTheSectoralAgreement;
  }

  private modificationDetector() : boolean {
    let hasBeenModify = false;
    let index = 0;
    const convertNumbersToStrings = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'number') {
          obj[key] = String(obj[key]);
        }else if (typeof obj === 'object' && obj[key] !== null) {
          convertNumbersToStrings(obj[key]);
        }
      });
    };
    convertNumbersToStrings(this.unityForm.initialProductForm);
    while (!hasBeenModify && index <= this.validationEditionKeys.length) {
      const key = this.validationEditionKeys[index];
      hasBeenModify = JSON.stringify(this.unityForm.initialProductForm[key]) !== JSON.stringify(this.unityForm.productForm[key]);
      index++;
    }
    return hasBeenModify;
  }
}
