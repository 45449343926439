import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ElementRef,
  OnChanges,
  OnDestroy,
  AfterViewChecked
} from '@angular/core';
import {FileUploadSvc} from '../../services/index';
import {ImagePreviewModel} from '../../models/index';
import {DashboardConst} from '../../../dashboard/models/dashboard.constants';

import * as _ from 'lodash';

//  FILE_UPLOAD_DIRECTIVES,
//  NgClass,
//  NgStyle,
//  ImagePreview,
//  FileSize,
@Component({
  selector: '²file-upload',
  providers: [
    FileUploadSvc
  ],
  styleUrls: ['./file-upload.less'],
  template: `
    <div class="FileUpload">
      <div ng2FileDrop
           [ngClass]="{'disabled': disabled}"
           [ngClass]="{'nv-file-over': hasBaseDropZoneOver, 'hasItems': (fileUploadSvc.uploader.queue.length > 0) || S3_URLS.length>0}"
           class="dropZone"
           (fileOver)="fileOverBase($event)"
           (onFileDrop)="onFileDrop()"
           [uploader]="fileUploadSvc.uploader">
        <div class="textContainer" [hidden]="(this.imagePlaceholder === false)">
          <div class="dropZone-infoText" [ngClass]="{'more-picture': (fileUploadSvc.uploader.queue.length > 0) || S3_URLS.length>0}" id="import-label-file"><label for="importFile">Importez depuis votre
            ordinateur</label> ou glissez-déposez votre fichier
            <div class="descriptionText">{{constants.createForm.helpers.textInsideContainerUploadImage.format}} / {{constants.createForm.helpers.textInsideContainerUploadImage.limit}}</div>
          </div>
          <input #file class="inputFile" type="file" id="importFile" name="importFile"
                 value="Importez depuis votre ordinateur" ng2FileSelect
                 [uploader]="fileUploadSvc.uploader" (onFileSelected)="fileOverBase($event)" [multiple]="true"
                 [accept]="'image/png, image/jpeg'"/>
        </div>
        <div class="files">
          <div *ngFor="let image of productImages; let i=index" [hidden]="image.isEmpty() || image.hasError()">
            <image-preview [id]="'product-image-'+i" [image]="image"
                           (onRemoveImage)="removeExistingImage(i)"></image-preview>
          </div>
          <div *ngFor="let image of S3_URLS; let i=index" [hidden]="!image.url.length">
            <image-preview [id]="'s3-image-'+i" [image]="image"
                           (onRemoveImage)="removeS3Image(i)"></image-preview>
          </div>
          <div class="file" *ngFor="let file of fileUploadSvc.uploader.queue; let j=index">
            <img id="{{j}}" class="file-preview" src="">
            <div class="file-content">
              <div class="file-description">
                <div class="file-description-name">{{file.file.name}}</div>
                <div class="file-description-size">
                  ({{file.imageWidth}} x {{file.imageHeight}} - {{file.file.size | fileSize}})
                </div>
              </div>
              <button type="button" class="file-removeButton" (click)="removeQueuedFile(file)">X</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <input id="files" type="file" name="files" ng2FileSelect [uploader]="fileUploadSvc.uploader" multiple (change)="onChange()"/> -->
    </div>`
})
export class FileUpload implements OnInit, AfterViewChecked, OnDestroy {

  @Input() public productImages: Object[] = [];
  @Output() public productImagesChange = new EventEmitter<Object[]>();
  @Input() public S3_URLS: Object[] = [];
  @Input() public nbrProductImages: number;
  @Output() public nbrProductImagesChange = new EventEmitter<number>();
  @Output() public productImagesRemoved = new EventEmitter<number>();
  @Output() public s3ImagesRemoved = new EventEmitter<number>();
  @Input() public files: Object[];
  @Input() public mode: string;
  @Output() public filesChange = new EventEmitter<Object[]>();
  @Output() public nbrFilesChanged = new EventEmitter<any>();

  @Input() public disabled: boolean = false;
  @Input() public limit: number = 3;

  public imagePlaceholder: boolean = false;
  public hasBaseDropZoneOver: boolean = false;
  public oldNbrProductImages: number = 0;
  public constants = DashboardConst;

  constructor(public fileUploadSvc: FileUploadSvc, private elRef: ElementRef) {
    window.addEventListener('dragover', function(e) {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', function(e) {
      e.preventDefault();
    }, false);
  }

  public ngOnInit() {
    this.fileUploadSvc.uploader.addToQueue(this.files as File[]);
    this.updateUploaderPreviews();
    // this.imagePlaceholder = this.productImages.length === 0;
    this.imagePlaceholder = _.filter(this.productImages, (i) => this.validateImageUrl(i)).length === 0;
    const keptImages = _.filter(this.productImages, (i) => this.validateImageUrl(i));
    this.fileUploadSvc.setQueueLimit(this.limit - (keptImages.length + this.files.length + this.S3_URLS.length));
    Object.assign(this.oldNbrProductImages, this.nbrProductImages);
  }

  public ngAfterViewChecked() {
    if (this.oldNbrProductImages !== this.nbrProductImages) {
      this.setNewQueueLimit();
      Object.assign(this.oldNbrProductImages, this.nbrProductImages);
    }
  }

  public ngOnDestroy() {
    this.fileUploadSvc.uploader = null as any;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    this.updateUploaderPreviews();
    this.filesChange.emit(this.fileUploadSvc.getQueuedFiles());
  }

  public onFileDrop() {
    this.nbrFilesChanged.emit(this.fileUploadSvc.getQueuedFiles().length);
  }

  public onChange(): void {
    setTimeout(() => {                                                                // Hack because (change) event is fired before uploader's queue update
      this.updateUploaderPreviews();                                                  // Need to wait for a proper event in ng2-file-upload
      this.filesChange.emit(this.fileUploadSvc.getQueuedFiles());
    }, 200);
  }

  public removeQueuedFile(file): void {
    this.fileUploadSvc.uploader.removeFromQueue(file);
    // (document.getElementById('files') as HTMLInputElement).value = '';                                      // Hack for queue limit
    this.filesChange.emit(this.fileUploadSvc.getQueuedFiles());
    const textContainer = this.elRef.nativeElement.querySelector('.textContainer');
    if (textContainer.length !== 0) {
      textContainer.removeAttribute('hidden');
    }
    this.nbrFilesChanged.emit(this.fileUploadSvc.getQueuedFiles().length);
  }

  public removeExistingImage(index: number): void {
    // img.keep = false;
    // @ts-ignore
    this.fileUploadSvc.setQueueLimit(this.fileUploadSvc.uploader.options.queueLimit + 1);
    this.productImagesRemoved.emit(index);
    const textContainer = this.elRef.nativeElement.querySelector('.textContainer');
    if (textContainer.length !== 0) {
      textContainer.removeAttribute('hidden');
    }
  }

  public removeS3Image(index: number): void {
    // @ts-ignore
    this.fileUploadSvc.setQueueLimit(this.fileUploadSvc.uploader.options.queueLimit + 1);
    this.s3ImagesRemoved.emit(index);
  }

  public updateUploaderPreviews(): void {
    _.forEach(this.fileUploadSvc.uploader.queue, (file: any, index: string) => {
      const reader = new FileReader();

      reader.addEventListener('load', function() {
        const image = new Image();
        image.src = reader.result as string;
        // TODO maybe cancel if dimensions are too large
        image.addEventListener('load', () => {
          // Setting the image dimensions
          file.imageWidth = image.width;
          file.imageHeight = image.height;
        });
        const preview = document.getElementById(index) as HTMLInputElement;
        preview.src = reader.result as string;
      }, false);

      if (file) {
        reader.readAsDataURL(file._file);
      }
    });
  }

  public setNewQueueLimit() {
    const keptImages = _.filter(this.productImages, (i) => this.validateImageUrl(i));
    const newLimit = 3 - (keptImages.length + this.S3_URLS.length);
    this.fileUploadSvc.setQueueLimit(newLimit);
  }

  private validateImageUrl(image) {
    return image.url.length > 0;
  }
}
