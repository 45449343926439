import { Component, ViewChild, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { UserSvc } from '../../../shared/services';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'confirmation-modal',
  styleUrls: ['./confirmation-modal.less'],
  template: `
<div class="confirmationModal">
    <bs-modal [ngClass]="{'confirmationModal' : true, 'deleteDialog': isDeleteDialog, 'nonpayer': NPOption}"  [animation]="true" (onClose)="onClose($event)" [keyboard]="false"
           [backdrop]="'static'" [size]="'sm'" #confirmationmodal>
        <bs-modal-body>
            <div class="ConfirmationModal-body">
                <div class="ConfirmationModal-body-title" [innerHtml]="title"></div>
                <div class="ConfirmationModal-body-content" [innerHtml]="body"></div>
            </div>
        </bs-modal-body>
        <bs-modal-footer class="ConfirmationModal-footer" [showDefaultButtons]="false">
            <div class="ConfirmationModal-footer-buttons" role="group" *ngIf="!NPOption">
                <button type="button" class="cancelButton" (click)="modal.dismiss()">{{cancelBtnLabel}}</button>
                <button type="submit" [ngClass]="{'submitButton' : true, 'confirmDeleteDialogButton': isDeleteDialog}" (click)="validate()">{{submitBtnLabel}}</button>
            </div>
            <div class="ConfirmationModal-footer-buttons" role="group" *ngIf="NPOption">
                <div class="np-biggerBtns">
                    <button type="button" class="cancelButton np-btn" (click)="close()">Fermer</button>
                    <button type="submit" class="np-btn" [ngClass]="{'submitButton' : true, 'confirmDeleteDialogButton': isDeleteDialog}" (click)="goToInvoice()">Accéder à mes factures</button>
                </div>
                <div class="np-checkbox">
                    <input type="checkbox" [ngModel]="noShow" (click)="toggleNoShow()"/>
                    <span class="checkmark"></span>
                    <label class="toggleNutriScore" style="padding-left: 4px">
                        Ne plus afficher
                    </label>
                </div>
            </div>
        </bs-modal-footer>
    </bs-modal>
</div>

  `
})
export class ConfirmationModal implements AfterViewInit{

  @ViewChild('confirmationmodal', { static: true })
  public modal: BsModalComponent;

  @Input() public title: string;
  @Input() public body: string;
  @Input() public cancelBtnLabel: string;
  @Input() public submitBtnLabel: string;
  @Output() public onConfirm = new EventEmitter<Event>();
  @Input() public isDeleteDialog: boolean = false;
  @Input() public NPOption: boolean = false;
  public noShow: boolean = false;
  @Output() public onCloseModal = new EventEmitter<any>();
  @Output() public onGoToInvoice = new EventEmitter<any>();

  constructor(public userSvc: UserSvc) {
  }

  public ngAfterViewInit() {
    if (this.NPOption && !this.userSvc.user.noShowNPAlerte) {
      this.openModal();
    }
  }

  public openModal() {
    if (this.NPOption) {
      this.userSvc.updateUser({hasSeenAlertNonPayer: true});
    }
    this.modal.open();
  }

  public validate() {
    this.modal.close();
  }

  public onClose(event) {
    this.onConfirm.emit(event);
  }

  public toggleNoShow() {
    this.noShow = !this.noShow;
  }

  public goToInvoice() {
    this.onGoToInvoice.emit();
    this.modal.close();
  }

  public close() {
    if (this.NPOption) {
      this.onCloseModal.emit({noShow: this.noShow});
    }
    this.modal.dismiss();
  }
}
