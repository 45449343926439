import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  AfterViewChecked,
  Input, Output, EventEmitter
} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';

import {VcsCreationUnityOldForm, ProductFormModel} from '../../../../../models/index';
import {CompanySvc, NotificationsSvc, ProductCategorySvc} from '../../../../../../shared/services/index';
import {ClassificationSvc} from '../../../../../../shared/services/classification.service';
import {ProductsSvc} from '../../../../../services/index';
import {FormInputError} from '../../../../../models/formInputError';
import {DashboardConst} from '../../../../../models/dashboard.constants';
import URL from '../../../../../../shared/utils/url';
import {FeatureToggleSvc} from '../../../../../../shared/services/feature-toggle.service';
import {VintageSvc} from '../../../../../../shared/services/vintages.service';
import {Classification} from '../../../../../../shared/models/Classification.model';
import {AgeOfSpiritModel} from '../../../../../models/ageOfSpirit.model';
import {Varietal} from '../../../../../models/varietals.constants';
import {Aoc} from '../../../../../models/aoc.constants';

import * as _ from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'unity-consumer-creation-vcs-old',
  styleUrls: ['./unity-creation-vcs-old.less'],
  templateUrl: './unity-consumer-creation-vcs-old.tpl.html'
})
export class UnityConsumerCreationVcsOldComponent implements OnInit, AfterViewInit {
  @ViewChild('formProduct', {static: true}) public form;
  public currentStep: number = 1;
  public showCategory: boolean;
  public webLinkError: FormInputError;
  @Input() public isSpi: boolean;
  @Input() public isWine: boolean;
  @Output() public onNavigateToFinalStep = new EventEmitter<any>();
  @Output() public onReturnToPreviousStep = new EventEmitter<any>();
  public multiSelectConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 15
  });
  public multiSelectOptions: any = Varietal;
  @Input() public unityForm: VcsCreationUnityOldForm;

  public aocSelectConfig: any = Object.assign({}, {
    openOnFocus: false,
    highlight: false,
    create: false,
    persist: true,
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input aoc-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: 'wording',
    maxItems: 1
  });
  public aocSelectOptions: any = Aoc;
  public companySvcSubscription: Subscription;
  public nbYears = new AgeOfSpiritModel().nbYears;


  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public _router: Router,
              public route: ActivatedRoute,
              public notificationsSvc: NotificationsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public vintageSvc: VintageSvc,
              private elRef: ElementRef) {
  }

  @Input()
  public setGpcCodes(codes: Classification[]) {
    this.unityForm.gpcCodes = codes;
  }

  public ngOnInit(): void {
    this.unityForm.onGpcChange();
    this.unityForm.productForm.productCountry = '250';
  }

  public ngAfterViewInit() {
  }

  public next(submitting: boolean = true, form: NgForm) {
    this.unityForm.submitting = submitting;
    this.onNavigateToFinalStep.emit({submitting, form});
  }

  public returnToPreviousStep(submitting: boolean = true) {
    this.onReturnToPreviousStep.emit({submitting});
  }


  public showIG() {
    const toReturn = this.isWine && this.unityForm.productForm.productCountry === '250';
    if (!toReturn) {
      this.unityForm.productForm.gis = '';
    }
    return this.isWine && this.unityForm.productForm.productCountry === '250';
  }

  public showAOC() {
    const toReturn = this.showIG() && ['AOC', 'AOP', 'IG', 'IGP'].indexOf(this.unityForm.productForm.gis) >= 0;
    if (!toReturn) {
      this.unityForm.productForm.aoc = '';
    } else {
      this.makeAocElementRequired();
    }
    return toReturn;
  }

  // private resetWebLinkError() {
  //     this.webLinkError = new FormInputError('', '');
  // }

  private makeAocElementRequired() {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.aoc-input');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      if (!this.unityForm.productForm.aoc || this.unityForm.productForm.aoc.length === 0)
        inputElement.required = true;
      else
        inputElement.required = false;
    }
  }
}
