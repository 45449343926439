import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {UiSwitchModule} from 'ngx-ui-switch';
import {BsModalModule} from 'ng2-bs3-modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ClipboardModule} from 'ngx-clipboard';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {
  Loader,
  ConfirmationModal,
  ShareModal,
  Gs1Modal,
  ImagePreview,
  FileUpload,
  DangerAlertComponent,
  InfoAlertComponent
} from './components';
import {FileUploadModule} from 'ng2-file-upload';
import {Pagination} from './components/pagination/pagination.component';
import {FileSize} from './pipes/file-size.pipe';
import {CommaReplacesDot} from './pipes/comma-separator.pipe';
import {SpaceDivider} from './pipes/space-divider.pipe';
import {BannerComponent} from './components/banner/banner.component';
import {NgSelectizeComponent} from './components/ng-selectize/ng-selectize.component';
import {ClassificationComponent} from '../dashboard/components/classification';
import { ClassificationCategoriesModal } from '../dashboard/components/classification-categories-modal/classification-categories-modal.component';
import { AttributesCheckerComponent } from './components/attributes-checker/attributes-checker.component';
import { PublishingDescriptionModal } from '../dashboard/components/publishing-description/publishing-description.component';
import { SearchEngineComponent } from './components/search-engine/search-engine.component';
import {ForbiddenValidatorDirective} from './directives/forbidden-url.directive';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BsModalModule,
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        FileUploadModule,
        ClipboardModule,
        UiSwitchModule
    ],
    providers: [],
    declarations: [
        Loader,
        FileUpload,
        FileSize,
        ImagePreview,
        BannerComponent,
        ConfirmationModal,
        Gs1Modal,
        ShareModal,
        CommaReplacesDot,
        SpaceDivider,
        ClassificationComponent,
        ClassificationCategoriesModal,
        AttributesCheckerComponent,
        PublishingDescriptionModal,
        NgSelectizeComponent,
        SearchEngineComponent,
        DangerAlertComponent,
        InfoAlertComponent,
        Pagination,
        ForbiddenValidatorDirective
    ],
  exports: [
    CommonModule,
    BrowserModule,
    Loader,
    FileUpload,
    FileSize,
    ImagePreview,
    BannerComponent,
    ConfirmationModal,
    Gs1Modal,
    ShareModal,
    CommaReplacesDot,
    SpaceDivider,
    ClassificationComponent,
    AttributesCheckerComponent,
    PublishingDescriptionModal,
    NgSelectizeComponent,
    SearchEngineComponent,
    DangerAlertComponent,
    Pagination,
    InfoAlertComponent,
    ForbiddenValidatorDirective
  ]
})
export class SharedModule {

}
