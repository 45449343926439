import {ActivatedRoute, Params} from '@angular/router';
import {Component} from '@angular/core';
import {DashboardConst} from '../../models/dashboard.constants';
import {ProductImport} from '../../models';
import {ProductsSvc} from '../../services/index';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ImportSteps} from '../product-import-download-template/product-import-download-template.component';
import {ImportTypes} from '../../../shared/models';

@Component({
  selector: 'product-import-status',
  styleUrls: ['./product-import.less', './product-import-status.less'],
  templateUrl: './product-import-status.tpl.html'
})
export class ProductImportStatus {

  public productImport: ProductImport;
  public importType: ImportTypes;

  private constants = DashboardConst;
  private subscription: Subscription;
  private productImportId: string;
  private fileName: string;
  private isBundle: boolean;

  private importCompletionPercentage: number;
  private lineCount: number;
  private supportedExtension = ['.xlsx', '.xlsm', '.xlsb', '.xls', '.xla', '.biff8', '.biff5', '.biff2', '.xlml', '.ods',
    '.fods', '.csv', '.txt', '.sylk', '.html', '.dif', '.rtf', '.prn', '.eth'];

  constructor(private productsSvc: ProductsSvc, private route: ActivatedRoute) {
    this.productImport = new ProductImport();
    this.importCompletionPercentage = 0;
    this.lineCount = 0;
    this.isBundle = false;
  }

  public ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.productImportId = params['id'];
      this.subscription = this.productsSvc.streamProductImport(this.productImportId)
        .subscribe(
          (productImport: ProductImport) => {
            this.productImport = productImport;
            this.importCompletionPercentage = this.getImportCompletionPercentage();
          },
          (error) => {
            // TODO handle error (US-489)
            console.error('Erreur sur le stream', error);
            this.importCompletionPercentage = this.getImportCompletionPercentage();
          },
          () => {
            console.info('Import ' + this.productImportId + ' completed');
            this.importCompletionPercentage = this.getImportCompletionPercentage();
          });
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.fileName = params['fileName'];
      this.importType = Number(params['importType']);
      this.lineCount = params['lineCount'];
      this.isBundle = params['isBundle'] === 'true';
    });
  }

  public getImportCompletionPercentage() {
    if (this.productImport.completed || this.productImport.failed) {
      return 100;
    }
    if (this.lineCount === 0) {
      return 0;
    }

    const completed = this.productImport.ok + this.productImport.errors;
    return Math.round(100 * completed / this.lineCount);
  }

  public downloadErrorReport() {
    let extension = this.extractFileExtension(this.fileName);
    const baseName = this.extractFileBaseName(this.fileName);
    if (this.supportedExtension.indexOf(extension) === -1) {
      extension = '.xls';
    }
    window.open(`${environment.TEMPORARY_URL_PREFIX}/${this.productImportId}/${baseName}_Erreurs${extension}`);
  }

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  public extractFileExtension(fileName: string): string {
    const index = fileName.lastIndexOf('.');
    const extension = fileName.substr(index, fileName.length);
    return extension;
  }

  public extractFileBaseName(fileName: string): string {
    const extension = this.extractFileExtension(fileName);
    const index = fileName.lastIndexOf(extension);
    const baseName = fileName.substr(0, index);
    return baseName;
  }

  public downloadSuccessReport() {
    let extension = this.extractFileExtension(this.fileName);
    // const baseName = this.extractFileBaseName(this.fileName);
    if (this.supportedExtension.indexOf(extension) === -1) {
      extension = '.xls';
    }
    const date = new Date();
    const UTCString = [
      date.getUTCDate().toString().padStart(2, '0'),
      (date.getUTCMonth() + 1).toString().padStart(2, '0'),
      date.getFullYear()
    ].join('_');
    window.open(`${environment.TEMPORARY_URL_PREFIX}/${this.productImportId}/Mes-derniers-produits-${UTCString}${extension}`);
  }

  public showBtnExport(): boolean {
    return  this.importType === ImportTypes.CREATE_GTINS;
  }

  public getTextInProgress(): string {
    return this.isBundle ? this.constants.import.status.inprogress.textAssortment : this.constants.import.status.inprogress.text;
  }

  public importResultText(error: boolean = false): string {
    if (error){
      return this.productImport.errors > 1 ? this.constants.import.status.done.products.error : this.constants.import.status.done.product.error;
    }
    return this.productImport.ok > 1 ? this.constants.import.status.done.products.ok : this.constants.import.status.done.product.ok;
  }
}
