import {Component} from '@angular/core';
import {HelpCenterConstants} from '../../models';

@Component({
  selector: 'popular-topics',
  styles: [`
    .PopularTopics-title {
      font-size: 1.4rem;
      padding-left: 15px;
    }`],
  template: `
    <div class="PopularTopics">
      <h1 class="PopularTopics-title">Sujets les plus populaires</h1>

      <div class="PopularTopics-content container-fluid">
        <div class="row">
          <!-- Download the guide -->
          <topic-card class="col-md-4 col-xs-6"
                      [imgUrl]="constants.guideLink.imgUrl"
                      [name]="constants.guideLink.name"
                      [linkType]="'download'"
                      [GAClassName]="'guideLink'"
                      [downloadLink]="constants.guideLink.downloadLink">
          </topic-card>
          <topic-card class="col-md-4 col-xs-6"
                      [imgUrl]="constants.focusLink.imgUrl"
                      [name]="constants.focusLink.name"
                      [linkType]="'download'"
                      [GAClassName]="'focusLink'"
                      [downloadLink]="constants.focusLink.downloadLink">
          </topic-card>
          <topic-card class="col-md-4 col-xs-6"
                      [imgUrl]="constants.sevenStepLink.imgUrl"
                      [name]="constants.sevenStepLink.name"
                      [linkType]="'download'"
                      [GAClassName]="'sevenStepLink'"
                      [downloadLink]="constants.sevenStepLink.downloadLink">
          </topic-card>
          <topic-card class="col-md-4 col-xs-6"
                      [imgUrl]="constants.topics[6].imgUrl"
                      [name]="constants.topics[6].title"
                      [linkType]="'route'"
                      [routeIndex]="6"
          >
          </topic-card>
          <!-- Link to a topic -->
          <topic-card class="col-md-4 col-xs-6"
                      [imgUrl]="constants.topics[0].imgUrl"
                      [name]="constants.topics[0].title"
                      [linkType]="'route'"
                      [routeIndex]="0"

          >
          </topic-card>
          <!-- Download the CGU -->
          <topic-card class="col-md-4 col-xs-6"
                      [imgUrl]="constants.cguLink.imgUrl"
                      [name]="constants.cguLink.name"
                      [linkType]="'download'"
                      [GAClassName]="'cguLink'"
                      [downloadLink]="constants.cguLink.downloadLink">
          </topic-card>
        </div>
      </div>
    </div>`
})
export class PopularTopics {

  public constants = HelpCenterConstants;

  constructor() {
  }

}
