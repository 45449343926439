import {Injectable} from '@angular/core';
import {DateSvc} from './date.service';

@Injectable()
export class VintageSvc {
  public vintages: any[] = [{}];
  private START_YEAR: number = 1900;
  private NO_VINTAGE: string = 'Non Millésimé';

  constructor(private dateSvc: DateSvc) {
    this.dateSvc.getCurrentYear().subscribe(currentYear => {
      const years = Array.from(Array(currentYear - this.START_YEAR + 1).keys())
        .map((v, i) => (this.START_YEAR + i).toString());
      years.forEach((year) => {
        this.vintages.push({
          wording: year,
          code: parseFloat(year)
        });
      });
      this.vintages.push({
        wording: this.NO_VINTAGE,
        code: -1
      });
      this.vintages = this.vintages.splice(1);
      this.vintages.reverse();
    });

  }
}
