export class DietTypes {
  constructor(
    public COELIAC?: boolean,
    public DIABETIC?: boolean,
    public DIETETIC?: boolean,
    public FREE_FROM_GLUTEN?: boolean,
    public HALAL?: boolean,
    public KOSHER?: boolean,
    public VEGAN?: boolean,
    public VEGETARIAN?: boolean,
    public WITHOUT_BEEF?: boolean,
    public WITHOUT_PORK?: boolean
  ) {}
}






