export const optionalNutrimentItemsConst = [
    {
       code: 'AAE-',
       wording: 'Acides aminés',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'BIOT',
       wording: 'Biotine',
       measurementUnitCode: 'MC'
    },
    {
       code: 'CA',
       wording: 'Calcium',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'CASN',
       wording: 'Caséines',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'CHOLN',
       wording: 'Choline',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'CLD',
       wording: 'Chlorure',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'CR',
       wording: 'Chrome',
       measurementUnitCode: 'MC'
    },
    {
       code: 'CU',
       wording: 'Cuivre',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'F18D2CN6',
       wording: 'Acide linoléique = LA',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'F18D3N3',
       wording: 'Acide alpha-linolénique = ALA',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'F20D4',
       wording: 'Acide arachidonique',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'F20D5N3',
       wording: 'Acide eicosapentaénoïque',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'F22D6N3',
       wording: 'Acide docosahexaénoïque',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'FAMSCIS',
       wording: 'Acides gras mono-insaturés',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'FAPUCIS',
       wording: 'Acides gras polyinsaturés',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'FAPULC',
       wording: 'Acides gras polyinsaturés à longue chaîne (LCP)',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'FD',
       wording: 'Fluorure',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'FE',
       wording: 'Fer',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'FIBSOL',
       wording: 'Fibres solubles',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'FIBTG',
       wording: 'Fibres alimentaires',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'FOL',
       wording: 'Folate',
       measurementUnitCode: 'MC'
    },
    {
       code: 'FOLDFE',
       wording: 'Acide folique',
       measurementUnitCode: 'MC'
    },
    {
       code: 'FRUFB',
       wording: 'FOS (fructo-oligosaccharides)',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'GALFB',
       wording: 'GOS (galacto-oligosaccharides)',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'GLUS',
       wording: 'Glucose',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'ID',
       wording: 'Iode',
       measurementUnitCode: 'MC'
    },
    {
       code: 'INOTL',
       wording: 'Inositol',
       measurementUnitCode: 'MC'
    },
    {
       code: 'K',
       wording: 'Potassium',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'L_CARNITINE',
       wording: 'L-Carnitine',
       measurementUnitCode: ''
    },
    {
       code: 'LACS',
       wording: 'Lactose',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'MALTDEX',
       wording: 'Maltodextrine',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'MG',
       wording: 'Magnésium',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'MN',
       wording: 'Manganèse',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'MO',
       wording: 'Molybdène',
       measurementUnitCode: 'MC'
    },
    {
       code: 'NA',
       wording: 'Sodium',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'NIA',
       wording: 'Niacine',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'NUCLEOTIDE',
       wording: 'Nucléotides',
       measurementUnitCode: ''
    },
    {
       code: 'OLSAC',
       wording: 'Fos Gos',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'P',
       wording: 'Phosphore',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'PANTAC',
       wording: 'Acide Pantothénique',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'PHOLIP',
       wording: 'Phospholipides',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'POLYL',
       wording: 'Polyols',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'RIBF',
       wording: 'Riboflavine',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'SE',
       wording: 'Sélénium',
       measurementUnitCode: 'MC'
    },
    {
       code: 'STARCH',
       wording: 'Amidon',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'SUCS',
       wording: 'Saccharose',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'SUGAR-',
       wording: 'Sucres',
       measurementUnitCode: 'GRM'
    },
    {
       code: 'TAU',
       wording: 'Taurine',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'THIA',
       wording: 'Thiamine',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'VITA-',
       wording: 'Vitamine A',
       measurementUnitCode: 'MC'
    },
    {
       code: 'VITB12',
       wording: 'Vitamine B12',
       measurementUnitCode: 'MC'
    },
    {
       code: 'VITB6-',
       wording: 'Vitamine B6',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'VITC-',
       wording: 'Vitamine C',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'VITD-',
       wording: 'Vitamine D',
       measurementUnitCode: 'MC'
    },
    {
       code: 'VITE-',
       wording: 'Vitamine E',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'VITK',
       wording: 'Vitamine K',
       measurementUnitCode: 'MC'
    },
    {
       code: 'WHEY',
       wording: 'protéines sériques',
       measurementUnitCode: 'MGM'
    },
    {
       code: 'ZN',
       wording: 'Zinc',
       measurementUnitCode: 'MGM'
    }
];
