export const PackagingMarkedLabelAccreditationCode = [
    {
        wording: 'Agri Confiance',
        code: 'AGRI_CONFIANCE'
    },
    {
        wording: 'Agriculture Biologique (AB)',
        code: 'AGRICULTURE_BIOLOGIQUE'
    },
    {
        wording: 'Agriculture raisonnée (HVE)',
        code: 'HAUTE_VALEUR_ENVIRONNEMENTALE'
    },
    {
        wording: "Appellation d'origine contrôlée (AOC)",
        code: 'APPELLATION_ORIGINE_CONTROLEE'
    },
    {
        wording: "Appellation d'origine protégée (AOP)",
        code: 'PROTECTED_DESIGNATION_OF_ORIGIN'
    },
    {
        wording: 'Aquaculture Stewardship Council (ASC)',
        code: 'AQUACULTURE_STEWARDSHIP_COUNCIL'
    },
    {
        wording: 'Best Aquaculture Practices (BAP)',
        code: 'BEST_AQUACULTURE_PRACTICES'
    },
    {
        wording: 'Bio Européen',
        code: 'EU_ORGANIC_FARMING'
    },
    {
        wording: 'Bleu-Blanc-Cœur (BBC)',
        code: 'BLEU_BLANC_COEUR'
    },
    {
        wording: 'Certification de conformité produit (CCP)',
        code: 'ADCCPA'
    },
    {
        wording: 'Chasseurs de France',
        code: 'CHASSEURS_DE_FRANCE'
    },
    {
        wording: 'Dolphin Safe (WTO)',
        code: 'DOLPHIN_SAFE'
    },
    {
        wording: 'Fairtrade (FLO)',
        code: 'FAIR_TRADE_MARK'
    },
    {
        wording: 'Max Havelaar',
        code: 'MAX_HAVELAAR'
    },
    {
        wording: 'Friend of the Sea (FOS)',
        code: 'FRIEND_OF_THE_SEA'
    },
    {
        wording: 'Global Gap',
        code: 'GLOBAL_GAP'
    },
    {
        wording: 'Roundtable on Sustainable Palm Oil (RSPO)',
        code: 'SUSTAINABLE_PALM_OIL_RSPO'
    },
    {
        wording: 'Indication géographique protégée (IGP)',
        code: 'PROTECTED_GEOGRAPHICAL_INDICATION'
    },
    {
        wording: 'Label Rouge',
        code: 'LABEL_ROUGE'
    },
    {
        wording: 'Marine Stewardship Council (MSC)',
        code: 'MARINE_STEWARDSHIP_COUNCIL_LABEL'
    },
    {
        wording: 'Nordic Swan',
        code: 'SVANEN'
    },
    {
        wording: 'Pavillon France',
        code: 'PAVILLON_FRANCE'
    },
    {
        wording: 'Produit en Bretagne',
        code: 'PRODUIT_EN_BRETAGNE'
    },
    {
        wording: 'Programme Européen des Forêts Certifiées (PEFC)',
        code: 'PEFC'
    },
    {
        wording: 'Rainforest Alliance',
        code: 'RAINFOREST_ALLIANCE'
    },
    {
        wording: 'The Alaska Seafood Marketing Institute (ASMI)',
        code: 'ASMI'
    },
    {
        wording: 'Spécialité traditionnelle garantie',
        code: 'TRADITIONAL_SPECIALTY_GUARANTEED'
    },
    {
        wording: 'Sustainable Forestry Initiative (SFI)',
        code: 'SUSTAINABLE_FORESTRY_INITIATIVE'
    },
    {
        wording: 'UTZ Certified',
        code: 'UTZ_CERTIFIED'
    },
    {
        wording: 'Vegaplan',
        code: 'VEGAPLAN'
    },
    {
        wording: "Viande d'agneau français",
        code: 'VIANDE_AGNEAU_FRANCAIS'
    },
    {
        wording: 'Viande de chevreau française',
        code: 'VIANDE_DE_CHEVREAU_FRANCAISE'
    },
    {
        wording: 'Viande de chèvre française',
        code: 'VIANDE_DE_CHEVRE_FRANCAISE'
    },
    {
        wording: 'Viande de veau française',
        code: 'VIANDE_DE_VEAU_FRANCAISE'
    },
    {
        wording: 'Viande chevaline française',
        code: 'VIANDE_CHEVALINE_FRANCAISE'
    },
    {
        wording: 'Viande ovine française',
        code: 'VIANDE_OVINE_FRANCAISE'
    },
    {
        wording: 'Viande Bovine Française',
        code: 'VIANDE_BOVINE_FRANCAISE'
    },
    {
        wording: 'Le porc français',
        code: 'LE_PORC_FRANCAIS'
    },
    {
        wording: 'Volaille française',
        code: 'VOLAILLE_FRANCAISE'
    },
    {
        wording: 'Lapin de France',
        code: 'LAPIN_DE_FRANCE'
    },
    {
        wording: 'Origine France Garantie',
        code: 'ORIGINE_FRANCE_GARANTIE'
    },
    {
        wording: 'Entreprise du Patrimoine Vivant ',
        code: 'ENTREPRISE_DU_PATRIMOINE_VIVANT'
    },
    {
        wording: 'AFDIAG',
        code: 'CROSSED_GRAIN_SYMBOL'
    },
    {
        wording: 'Fleurs de France',
        code: 'FLEURS_DE_FRANCE'
    },
    {
        wording: 'Fruits et légumes de France',
        code: 'FRUITS_ET_LEGUMES_DE_FRANCE'
    },
    {
        wording: 'Pommes de terre de France',
        code: 'POMMES_DE_TERRES_DE_FRANCE'
    },
    {
        wording: 'Œufs de France',
        code: 'ŒUFS_DE_FRANCE'
    },
    {
        wording: 'Viandes de France',
        code: 'VIANDES_DE_FRANCE'
    },
    {
        wording: 'Cosmos Natural',
        code: 'ECOCERT_COSMOS_NATURAL'
    },
    {
        wording: 'Cosmos Organic',
        code: 'ECOCERT_COSMOS_ORGANIC'
    },
    {
      wording: 'Demeter',
      code: 'DEMETER_LABEL'
    }
];
