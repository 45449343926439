import {Component} from '@angular/core';

@Component({
    selector: 'qr-experience-dialog',
    templateUrl: 'qr-experience-modal.component.html',
    styleUrls: ['./qr-experience-modal.component.less']
})
export class QrExperienceModal {
    public step = 1;
    constructor(
    ) {}

    // onNoClick(): void {
    //     this.dialogRef.close();
    // }
    public goNextStep(){
        this.step++;
    }
    public goPrevStep(){
        this.step--;
    }
}
