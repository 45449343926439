import {Component, ViewChild, Input, Output, OnInit} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import {UserSvc} from '../../../shared/services/user.service';
import {NewFeatureOnBoardingConstants} from '../../models/newFeartureOnboarding.constants';

@Component({
  selector: 'new-feature-information-modal',
  styleUrls: ['./new-feature-information-modal.less'],
  templateUrl: './new-feature-information-modal.tpl.html'
})
export class NewFeatureInformationModalComponent implements OnInit {
  public newFeatureOnBoardingConstants = NewFeatureOnBoardingConstants;
  @ViewChild(BsModalComponent, {static: true})
  private modal: BsModalComponent;

  constructor(public featureToggleSvc: FeatureToggleSvc, public userSvc: UserSvc) {
  }

  public show() {
    this.modal.open();
  }

  public back() {
    this.modal.dismiss();
  }

  public isVcsUser(): boolean {
    return this.featureToggleSvc.canSeeVcs();
  }

  public ngOnInit(): void {
    this.show();
    this.modal.onDismiss.subscribe(() => {
      this.userSvc.updateUser({showNewFeatureOnBoarding: true});
    });
  }

  public titleModal(): string {
    if (this.featureToggleSvc.canSeeVcs()) {
      return this.newFeatureOnBoardingConstants.titleVcs;
    }

    return this.newFeatureOnBoardingConstants.titleGeneric;

  }

  public titleFirstSection(): string {
    if (this.featureToggleSvc.canSeeVcs()) {
      return this.newFeatureOnBoardingConstants.sections[0].titleVcs;
    }

    return this.newFeatureOnBoardingConstants.sections[0].titleGeneric;

  }

  public titleSecondSection(): string {
    if (this.featureToggleSvc.canSeeVcs()) {
      return this.newFeatureOnBoardingConstants.sections[1].titleVcs;
    }

    return this.newFeatureOnBoardingConstants.sections[1].titleGeneric;

  }
}
