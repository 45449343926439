import {debounceTime} from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterContentInit,
  Input
} from '@angular/core';
import {Subject} from 'rxjs';
import {ProductCategorySvc, CompanySvc, UserSvc} from '../../../shared/services/index';
import diacritics from '../../../shared/utils/diacritic';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {DashboardConst} from '../../models/dashboard.constants';
import {vcsCategoriesConstants} from '../../models/vcsCategories.constants';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {oldToNewGpcMapping} from '../../models/oldToNewGpcmapping';
import {ProductClassifications} from '../../models/productClassification.constants';

@Component({
  selector: 'product-category',
  styleUrls: ['./product-category.less'],
  templateUrl: './product-category.tpl.html',
  encapsulation: ViewEncapsulation.None
})
export class ProductCategoryComponent implements AfterContentInit {

  @Output() public onValidateCategory = new EventEmitter<any>();
  @Output() public onSelectCategory = new EventEmitter<any>();
  @Output() public onNavigateToClassification = new EventEmitter<any>();

  @Input() public showForCreation: boolean = true;

  @ViewChild('gpcSearchInput', {static: true})
  public gpcSearchInput: ElementRef;

  public gpcQuery = new Subject();
  public formatedCategories: any[] = [];
  public categoryDetails: any = {};
  public isPickedCategory = false;
  public bookmarkedCategories: any[] = [];
  public lastSelectedCategories: any[] = [];
  public selectedCategory: any = {};
  public constants = DashboardConst;
  public oldToNewGpcMappingConstants = oldToNewGpcMapping;
  public hideSkipChoiceCategory: boolean = false;
  public vcsCategories = vcsCategoriesConstants;
  public noCategoryFound: boolean = false;
  @Input() public isBundle: boolean;
  @Input() public isNotNew: boolean;
  constructor(private headerSvc: DashboardHeaderSvc, private productCategorySvc: ProductCategorySvc,
              private companySvc: CompanySvc, private userSvc: UserSvc, public _router: Router) {
    this.gpcQuery.pipe(
      debounceTime(200))
      .subscribe((query) => {
        this.searchQuery(query);
      });
  }

  public ngAfterContentInit(): void {
    this.gpcSearchInput.nativeElement.focus();
    this.lastSelectedCategories = _.filter(_.get(this.companySvc.company, 'lastSelectedCategories', []), (sc: {
      code: string
    }) => _.isEmpty(this.oldToNewGpcMappingConstants[sc.code]));
    if (this.isNotNew) {
      const vcsCodes = this.vcsCategories.map((element) => element.code);
      this.lastSelectedCategories = this.lastSelectedCategories.filter((category) => vcsCodes.indexOf(category.code) === -1);
    }
    this.hideSkipChoiceCategory = _.get(this.companySvc.company, 'hideSkipChoiceCategory', false);
  }

  public onKeyupGpcSearchInput = (query: string) => {
    this.formatedCategories = [];
    this.isPickedCategory = false;
    this.noCategoryFound = false;
    this.hideDetails();
    if (query.length > 2) {
      this.gpcQuery.next(this.escapeRegExp(query));
    }
  }

  public enterPressed() {
    this.onValidateCategory.emit({showCategory: false});
    this.hideDetails();
  }

  public showDetails = (category: any) => {
    this.categoryDetails = category;
  }

  public hideDetails = () => {
    this.categoryDetails = {};
  }

  public isVcsUser(): boolean {
    return this.userSvc.user.sectorCode === '860330004';
  }

  public pickCategory = (category: { text: any; }) => {
    this.categoryDetails = category;
    this.formatedCategories = [];
    this.isPickedCategory = true;
    this.noCategoryFound = false;
    this.gpcSearchInput.nativeElement.value = category.text;
  }

  public bookmarkCategory = (category: any) => {
    const hasCategory = this.hasCategory(category);
    if (!hasCategory) {
      this.companySvc.updateBookmarkedCategories(this.bookmarkedCategories.concat(category))
        .then((company) => {
          this.bookmarkedCategories = company.bookmarkedCategories || [];
        });
    } else {
      this.deleteCategory(category);
    }
  }

  public isSelectedCategory = (category: any): boolean => {
    return this.selectedCategory.code === category.code;
  }

  public saveSelectedCategory = (category: any) => {
    const selectedCategory = {
      breadcrumbs: category.breadcrumbs,
      code: category.code,
      definition: category.definition,
      parentIds: category.parentIds,
      text: category.text,
      type: category.type
    };
    if (!this.isSelectedCategory(category)) {
      const hasCategory = this.hasCategory(category);
      let bookmarkedCategories = this.bookmarkedCategories;
      if (!hasCategory) {
        bookmarkedCategories = this.bookmarkedCategories.concat(category);
      }
      this.companySvc.updateSelectedCategory(selectedCategory, bookmarkedCategories)
        .then((company) => {
          this.selectedCategory = company.selectedCategory || {};
          this.onSelectCategory.emit({
              category: selectedCategory
            }
          );
        });
    } else {
      this.onSelectCategory.emit({
        category: selectedCategory
      });
    }

  }

  public deleteCategory = (category: any) => {
    this.companySvc.updateBookmarkedCategories(this.bookmarkedCategories.filter(item => item.code !== category.code))
      .then((company) => {
        this.bookmarkedCategories = company.bookmarkedCategories || [];
      });
  }

  public hasCategory = (catgeory: any) => {
    const hasCategory = this.bookmarkedCategories.find((item) => {
      return item.code === catgeory.code;
    });
    return hasCategory !== undefined;
  }

  public gotoDashborad() {
    this._router.navigate(['/dashboard/list']);
  }

  public forward() {
    const category = {
      code: '99999999',
      text: 'Non spécifié',
      definition: "Inclut tous les produits qui peuvent être décrits et observé comme un produit qui ne peuvent pas être classées dans le schéma de classification des produits de GS1. Cette brique sert de lieu de détention temporaire pour les produits, qui ne peuvent pas être classées dans les segments actuels du schéma en raison de son évolution actuelle.L'industrie est encouragée à migrer dès que le classement nécessaire est disponible. Exclut tous les produits qui peuvent être classées dans la publication GS1 Global Product Classification Schema.",
      formatedDefinition: "Inclut tous les produits qui peuvent être décrits et observé comme un produit qui ne peuvent pas être classées dans le schéma de classification des produits de GS1. Cette brique sert de lieu de détention temporaire pour les produits, qui ne peuvent pas être classées dans les segments actuels du schéma en raison de son évolution actuelle.L'industrie est encouragée à migrer dès que le classement nécessaire est disponible. ",
      type: 'brick',
      level: 4,
      parentIds: [
        '58010100',
        '58010000',
        '58000000'
      ],
      breadcrumbs: 'Inter-Segment / Inter-Segment / Inter-Segment / Non spécifié'
    };
    this.saveSelectedCategory(category);
  }

  public navigateToClassification() {
    if (this.showForCreation) {
      this.onNavigateToClassification.emit();
      return;
    }
    this._router.navigate(['/dashboard/product/classifications']);
  }

  private searchQuery = (query: any) => {
    this.noCategoryFound = false;
    const classificationFilter = [];
    if (this.isNotNew) {
      classificationFilter.push(ProductClassifications.NOT_NEW);
    }
    this.productCategorySvc.search(query, classificationFilter)
      .then((categories) => {
        this.formatedCategories = this.formatProductCategories(categories, query);
        if (this.formatedCategories.length === 0) {
          this.noCategoryFound = true;
        }
      })
      .catch(() => {
        this.formatedCategories = [];
        this.noCategoryFound = true;
      });
  }

  private formatProductCategories(categories: any[], query: string) {
    const formatedCategories = [];
    const formatedQuery = query.split(' ').map((word) => {
      return diacritics(word);
    }).join('|');

    const pattern = new RegExp('(' + formatedQuery + ')', 'gi');
    for (const category of categories) {
      const formatedCategory = category.text.replace(pattern, '<span class="highlight">$1</span>');
      const highlightDefinition = category.definition.replace(pattern, '<span class="highlight">$1</span>');

      formatedCategories.push({
        ...category,
        formatedCategory,
        highlightDefinition
      });
    }
    return formatedCategories;
  }

  private escapeRegExp(query: string) {
    return query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

}
