import {Gtin} from '../../shared/models/gtin.model';
import {FormInputError} from './formInputError';
import {Allergen} from './Allergen.model';
import {Certifications} from './certifications.model';
import {BaseProductEditionFormModel} from './baseProductEditionForm.model';
import { NetContent } from './NetContent.model';
import { ImageUrl } from './ImageUrl.model';
import {ProductName} from './ProductName.model';

export class VcsProductEditionOldFormModel extends BaseProductEditionFormModel{
  constructor(
    public gtin: Gtin,
    public alcoholDegreeError: FormInputError,
    public volumeError: FormInputError,
    public name: string,
    public productNames: ProductName[],
    public brand: string,
    public mpn: string,
    public sku: string,
    public subBrand: string,
    public imagesAddedByUrl: ImageUrl[] = [],
    public imagesToUpload: Object[],
    public description: string,
    public webLink: string,
    public completionLevel: number,
    public targetMarket: string[],
    public codeGpc: string,
    public targetMarketLabel: string,
    public adhereToTheSectoralAgreement: boolean,
    public companyId: number,
    public alcoholDegree: string = '',
    public productCountry: string = '',
    public spiritCodeGpc: string = '',
    public wineColor: string = '',
    public vintage: number = -1,
    public allergen: Allergen = {},
    public ageOfSpirit: string= '',
    public vintageName: string = '',
    public container: string = '',
    public certifications: Certifications= {},
    public varietals: string[] = [],
    public sugarContent: string= '',
    public aoc: string = '',
    public spiritStyle: string= '',
    public gis: string = '',
    public isPrivate: boolean = false,
    public category: string = '',
    public netContents: NetContent[] = [],
    public images: Object[],
    public productResellerType? : string,
    public productType? : string
  ) {
    super(gtin, mpn, sku, brand, subBrand, name, productNames, imagesToUpload, images, description, webLink, completionLevel,
      companyId,
      targetMarket,
      codeGpc,
      isPrivate, category, netContents, adhereToTheSectoralAgreement, imagesAddedByUrl, productResellerType, productType);
  }
}
