'use strict';

export class Company {
    constructor(
        public id: number,
        public companyName: string,
        public gln: string,
        public mainPrefix: string,
        public prefix: string[],
        public preferenceCompany?: string,
        public codesAvailable?: number,
        public street ?: string,
        public cp ?: string,
        public city?: string,
        public urlWebsite?: string,
        public bookmarkedCategories?: any[],
        public selectedCategory?: {
            breadcrumbs: string,
            code: string,
            definition: string,
            parentIds: string[],
            text: string,
            type: string
        },
        public lastSelectedCategories?: [{
            breadcrumbs: string,
            code: string,
            definition: string,
            parentIds: string[],
            text: string,
            type: string,
            selectedAt: string
        }],
        public hideSkipChoiceCategory?: boolean,
        public isNonPayer?: boolean,
        public newGtin8?: string[],
        public newGtin8Available?: number
    ) {
    }
}
