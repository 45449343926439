import { RouterModule, Routes } from '@angular/router';
import {Dashboard} from './dashboard.component';
import {AuthGuard} from '../shared/services/auth-guard';
import {DashboardProducts} from './components/dashboard-products/dashboard-products.component';
import {ProductCreationChoiceCategoryComponent} from './components/product-creation-choice-category';
import {Resolver} from '../shared/services/resolver';
import {ClassificationComponent} from './components/classification';
import {ProductCategoryImportComponent} from './components/product-category-import/product-category-import.component';
import {ProductEditionChoiceCategoryComponent} from './components/product-edition-choice-category/product-edition-choice-category.component';
import {ProductViewChoiceCategoryComponent} from './components/product-view-choice-category';
import {ProductImport} from './components/product-import/product-import.component';
import {ProductImportUpload} from './components/product-import/product-import-upload.component';
import {ProductImportDownloadTemplate} from './components/product-import-download-template/product-import-download-template.component';
import {ProductImportStatus} from './components/product-import/product-import-status.component';
import {SettingsViewComponent} from './components/settings';
import {SettingsPrefixesComponent} from './components/settings-prefixes';
import {HelpCenterComponent, PopularTopics, Topic} from './helpCenter';
import {UpdateCategoryComponent} from './components/update-category/update-category.component';

const routes: Routes = [
    {
        path: 'dashboard', component: Dashboard, canActivate: [AuthGuard], children: [
            {path: '', redirectTo: 'list', pathMatch: 'full'},
            {path: 'list', component: DashboardProducts},
            // {
            //   path: 'create', component: ProductCreationChoiceCategoryComponent, children: [
            //     {path: '', redirectTo: 'product', pathMatch: 'full'},
            //     {path: 'product', component: UnityConsumerChoiceCategoryComponent}
            //   ]
            // },
            {path: 'product/create', component: ProductCreationChoiceCategoryComponent, resolve: { categories: Resolver }, canDeactivate: [AuthGuard]},
            {path: 'product/create/:productResellerType', component: ProductCreationChoiceCategoryComponent, resolve: { categories: Resolver }, canDeactivate: [AuthGuard]},
            {path: 'product/classifications', component: ClassificationComponent},
            {path: 'product/categories', component: ProductCategoryImportComponent},
            {path: 'product/categories/:type', component: ProductCategoryImportComponent},
            {path: 'product/update/:id', component: ProductEditionChoiceCategoryComponent, resolve: { categories: Resolver }},
            {path: 'product/view/:id', component: ProductViewChoiceCategoryComponent, resolve: { categories: Resolver }},
            {path: 'product/update-category/:id', component: UpdateCategoryComponent},
            {
                path: 'import', component: ProductImport, children: [
                    {path: 'upload', component: ProductImportUpload},
                    {path: 'template/:type', component: ProductImportDownloadTemplate, canDeactivate: [AuthGuard]},
                    {path: 'status/:id', component: ProductImportStatus}
                ]
            },
            {
                path: 'settings', component: SettingsViewComponent, children: [
                    {path: 'prefixes', component: SettingsPrefixesComponent}
                ]
            },
            {
                path: 'helpcenter', component: HelpCenterComponent, children: [
                    {path: '', redirectTo: 'populartopics', pathMatch: 'full'},
                    {path: 'populartopics', component: PopularTopics},
                    {path: 'topic/:id', component: Topic}
                ]
            }]
    }
];

export const DashboardRoutingModule = RouterModule.forRoot(routes);
