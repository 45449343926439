import {Component, Input, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {onBoardingConstants} from '../../models/onboarding.constants';
import {CarouselComponent} from 'ngx-bootstrap/carousel';

@Component({
  selector: 'onboarding-modal',
  styleUrls: ['./onboarding-modal.less'],
  template: `
      <div class="OnBoardingModal">
          <bs-modal class="onBoardingModal" [animation]="true" [keyboard]="false"
                 [backdrop]="'static'"
                 [size]="'lg'" #modal>
              <bs-modal-header class="" [showDismiss]="true">Nouveauté CodeOnline</bs-modal-header>
              <bs-modal-body>
                  <div class="OnBoardingModal-carousel">
                      <carousel [noWrap]="true" #carousel>
                          <slide *ngFor="let slide of slides; let index=index" [active]="slide.active">
                              <div class="col-md-12 col-xs-12">
                                   <div *ngIf="index != 0" class="OnBoardingModal-carousel-image">
                                       <img [src]="'assets/img/' + slide.img">
                                   </div>
                                  <div class="OnBoardingModal-carousel-body">
                                      <div class="OnBoardingModal-carousel-body-text" [innerHtml]="slide.text"></div>
                                      <div class="OnBoardingModal-carousel-body-subtext" [innerHtml]="slide.subtext"></div>
                                      <div class="OnBoardingModal-carousel-body-detail" [innerHtml]="slide.detail"></div>
                                  </div>
                              </div>
                          </slide>
                      </carousel>
                  </div>
              </bs-modal-body>
              <bs-modal-footer>
                  <button class="OnBoardingModal-carousel-footer-dismiss" (click)="dismiss()">Passer</button>
                  <button *ngIf="this.carousel.getCurrentSlideIndex() != MAX_SLIDES-1" class="OnBoardingModal-carousel-footer-next" (click)="next()">Continuer</button>
                  <button *ngIf="this.carousel.getCurrentSlideIndex() == MAX_SLIDES-1" class="OnBoardingModal-carousel-footer-next" (click)="dismiss()">Mettre à jour</button>
              </bs-modal-footer>
          </bs-modal>
      </div>
  `
})
export class OnBoardingModal implements OnInit {
    @ViewChild('modal', {static: true})
    public modal: BsModalComponent;
    @ViewChild('carousel', {static: true})
    public carousel: CarouselComponent;
    @Output()
    public onCreate = new EventEmitter<any>();
    public constants = onBoardingConstants;
    public slides: any[] = [];
    public MAX_SLIDES = 4 ;
    public state = 0;
    constructor() {
        this.slides = this.constants.onBoardingSlides;
    }

    public ngOnInit() {
    }

    public openModal() {
      this.modal.open();
    }
    public next() {
      this.state = this.carousel.activeSlide + 1;
      this.carousel.nextSlide();
    }
    public dismiss() {
        this.modal.dismiss();
    }
    public createProduct() {
        this.dismiss();
        this.onCreate.emit(null);
    }
}
