export class ProductImportForm {
  constructor(
    public importFile: Blob,
    public totalProducts?: number,
    public failedProducts?: number,
    public succeededProducts?: number,
    public reportUrl?: string,
    public filename?: string,
    public lastFilledRowIndex?: number
   ) {}

   public getProgressValue() {
     if (this.totalProducts === 0) {
       return 0;
     }
    return  ((this.succeededProducts + this.failedProducts) / this.totalProducts) * 100;
   }

   public getPercentageFailedProducts() {
     if ((this.succeededProducts + this.failedProducts) === 0) {
       return 0;
     }
    return  Math.ceil(((this.failedProducts) / (this.succeededProducts + this.failedProducts)) * 100);
   }

   public getPercentageSucceededProducts() {
    if ((this.succeededProducts + this.failedProducts) === 0) {
      return 0;
    }
    return  100 - this.getPercentageFailedProducts();
   }
}
