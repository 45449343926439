import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationModal} from '../../../../shared/components/index';
import {NotificationsSvc, CompanySvc, ProductCategorySvc, UserSvc} from '../../../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../../../services/index';
import {FormInputError} from '../../../models/formInputError';
import {DashboardConst} from '../../../models/dashboard.constants';
import URL from '../../../../shared/utils/url';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {VintageSvc} from '../../../../shared/services/vintages.service';
import {BaseEditionUnityFormModel} from '../../../models/baseEditionUnityForm.model';
import GtinUtils from '../../../../shared/utils/gtin';
import {NetContent} from '../../../../dashboard/models/NetContent.model';
import {NetContentConst} from '../../../../dashboard/models/netContent.constants';
import {ImageUrl} from '../../../../dashboard/models/ImageUrl.model';
import {Category} from '../../../../shared/models';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';
import {TargetMarketCountries} from '../../../../dashboard/models/targetMarket-country.constants';
import {LanguageConstants} from '../../../../dashboard/models/language.constants';
import {ProductName} from '../../../../dashboard/models/ProductName.model';

import * as _ from 'lodash';
import {NetContentGenericProduct} from '../../../models/NetContentGenericProduct.model';
import { ProductResellerTypes } from 'src/app/dashboard/models';


@Component({
  selector: 'unity-consumer-edition-generic',
  styleUrls: ['./unity-consumer-edition-generic.less'],
  templateUrl: './unity-consumer-edition-generic.tpl.html'
})
export class UnityConsumerEditionGenericComponent implements OnInit, OnDestroy {
  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;
  @ViewChild('formProduct', {static: true})
  public form: any;
  public webLinkError: FormInputError;
  @Input() public product: any;
  @Output() public onModifyCategory = new EventEmitter<any>();
  public measurementUnitsList: Array<Array<{}>> = new Array();
  public canAddNetContent: boolean = true;
  @Input() public unityForm: BaseEditionUnityFormModel;
  @Input() public isVcs: boolean;
  public isNotNew : boolean;
  public isBundle: boolean;
  public nbrFiles: number;
  public nbrProductImages: number;
  public uploadLimit: number = 3;
  public constants = DashboardConst;
  public netContentPattern: string[];
  public multiSelectConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input targetMarket-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording-fr',
    valueField: 'code',
    searchField: ['wording-fr'],
    maxItems: 10
  });
  public multiSelectOptions = TargetMarketCountries;
  public firstTimeTargetMarketCSS = false;
  public languageList: Array<Array<{}>> = new Array();
  public showCodificationRule = false;
  public productNamesHasChanged = false;
  public initialName = '';
  public NetContentConstants = NetContentConst.all;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public featureToggle: FeatureToggleSvc,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              private notificationsSvc: NotificationsSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public productCategorySvc: ProductCategorySvc,
              private elRef: ElementRef,
              private headerSvc: DashboardHeaderSvc,
              public userSvc: UserSvc) {
    this.resetWebLinkError();
    this.netContentPattern = [];
  }

  public ngOnDestroy() {
  }

  public ngAfterViewChecked() {
    this.makeTargetMarketElementRequired();
  }


  public ngOnInit() {
    this.isBundle = this.unityForm.productForm.productType === 'BUNDLE';
    this.isNotNew = this.unityForm.productForm.productResellerType === ProductResellerTypes.NOT_NEW;
    this.headerSvc.setHeaderData('Modification fiche produit', 'assets/icons/editCode.svg');

    this.unityForm.id = this.product.id;
    this.unityForm.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
    if (this.isVcs) {
      this.NetContentConstants = NetContentConst.vcs;
      for (let i = 0; i < this.unityForm.productForm.netContents; i++){
        this.netContentPattern.push('^([0-9]{1,}(,\\d{1,2})?)$');
      }
    }
    this.initNetContents();
    this.initImages();

    this.nbrFiles = this.uploadLimit - this.unityForm.productForm.images.length;
    if (!this.userSvc.user.arrayTargetMarketSeen) {
      this.firstTimeTargetMarketCSS = true;
    }

    if (this.unityForm.productForm.productNames && this.unityForm.productForm.productNames.length > 0) {
      this.updateListLang();
    }

    if (!this.isVcs){
      this.changeBehaviorNetContent();
    }
    this.unityForm.initialProductForm = JSON.parse(JSON.stringify(this.unityForm.productForm));
  }

  public validateURL() {
    this.resetWebLinkError();
    this.unityForm.productForm.webLink = this.unityForm.productForm.webLink.toLowerCase();
    if (this.unityForm.productForm.webLink) {
      if (URL.isNotPrefixedWithProtocol(this.unityForm.productForm.webLink)) {
        this.unityForm.productForm.webLink = 'http://' + this.unityForm.productForm.webLink;
      }

      if (URL.isValidURL(this.unityForm.productForm.webLink)) {
        this.webLinkError = new FormInputError('valid', '');
      } else {
        this.webLinkError = new FormInputError('warning', DashboardConst.editForm.errors.webLinkInvalid);
      }

    }
  }
  public canUpdateBrand(product: any): boolean {
    return !this.productsSvc.isProductResellerWithoutBrand(product.productResellerType);
  }
  public removeNetContent(netContent: any, index: number) {
    this.unityForm.productForm.netContents = this.unityForm.productForm.netContents.filter((item: any) => {
      return netContent !== item;
    });

    this.measurementUnitsList = this.measurementUnitsList.filter((item: any, i) => {
      return index !== i;
    });
    this.netContentPattern = this.netContentPattern.filter((item: string, i: number) => {
      return index !== i;
    });

    this.updateMeasurementUnitList();
  }

  public addNetContent() {
    let netContent: NetContentGenericProduct | NetContent ;
    if (this.isVcs){
      this.netContentPattern.push('^([0-9]{1,}(,\\d{1,2})?)$');
      netContent = new NetContent();
    }else{
      netContent = new NetContentGenericProduct();
      if (netContent.isItemOrArticleUnits()){
        this.netContentPattern.push('^[0-9]*$');
      }else{
        this.netContentPattern.push('^([0-9]{1,}(,\\d{1,5})?)$');
      }
    }
    this.unityForm.productForm.netContents.push(netContent);
    this.measurementUnitsList.push(this.NetContentConstants);
    this.updateMeasurementUnitList();
  }

  public submitForm() {
    this.unityForm.productForm.productNames = _.filter(this.unityForm.productForm.productNames, (productName) => !_.isEmpty(productName.value) && !_.isEmpty(productName.lang));
    if (this.checkNetContents() && this.checkError()) {
      this.unityForm.productForm.netContents = _.filter(this.unityForm.productForm.netContents, (netContent) => netContent.netContent && netContent.measurementUnitCode);
      return this.unityForm.submitForm();
    }
    return false;
  }

  public updateMeasurementUnitList() {
    let hasAtLeastOneValidItem = false;
    const usedMeasurementUnitList = _.map(this.unityForm.productForm.netContents, 'measurementUnitCode');
    const usedMeasurementCategoriesList = _.reduce(this.NetContentConstants, (categories: any, item: any) => {
      if (usedMeasurementUnitList.indexOf(item.code) >= 0) {
        categories[item.category] = true;
      }
      return categories;
    }, {});
    for (let i = 0; i < this.unityForm.productForm.netContents.length; i++) {
      const selectedNetContent = this.unityForm.productForm.netContents[i];
      const selectedCategory: any = _.find(this.NetContentConstants, {code: selectedNetContent.measurementUnitCode}) || {};
      this.measurementUnitsList[i] = this.NetContentConstants.filter((item: any) => {
        return !usedMeasurementCategoriesList[item.category] || item.category === selectedCategory.category;
      });

      if (!selectedNetContent.isEmpty()) {
        hasAtLeastOneValidItem = true;
      }
    }
    this.makeNetContentRequired(!hasAtLeastOneValidItem);
    this.canAddNetContent = this.unityForm.productForm.netContents.length < 5;
  }

  public makeNetContentRequired(state: any) {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#net-content-item-0');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      const selectElement: HTMLSelectElement = divElement.querySelector('select');
      inputElement.required = state;
      selectElement.required = state;
    }
  }

  public productCategory(): string {
    let productCategory = this.unityForm.productForm.category;
    const codeGpc = this.unityForm.productForm.codeGpc;
    if (_.isEmpty(productCategory) && !_.isEmpty(codeGpc)) {
      this.productCategorySvc.categories.forEach((obj: Category) => {
        if (obj.codes.indexOf(codeGpc) !== -1) {
          productCategory = obj.wording;
          return;
        }
      });
    }

    if ((_.isEmpty(productCategory) && _.isEmpty(codeGpc)) || codeGpc === '99999999') {
      return 'Non spécifié';
    }
    return productCategory;
  }

  public showChangeCategory(): boolean {
    return _.isEmpty(this.unityForm.productForm.codeGpc) || this.unityForm.productForm.codeGpc === '99999999' || this.unityForm.productForm.codeGpc === '0';
  }

  public modifyCategory() {
    this.onModifyCategory.emit(true);
  }

  // IMAGES
  public addImageUrl() {
    let firstIndexToFill = this.unityForm.productForm.imagesAddedByUrl.length;

    for (let i = 0; i < this.unityForm.productForm.imagesAddedByUrl.length; i++) {
      const image = this.unityForm.productForm.imagesAddedByUrl[i];
      if (image.isEmpty() || image.hasError()) {
        firstIndexToFill = i;
        break;
      }
    }

    if (firstIndexToFill === this.unityForm.productForm.imagesAddedByUrl.length) {
      this.unityForm.productForm.imagesAddedByUrl.push(new ImageUrl(''));
    } else {
      const lastUrlElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#image-url-item-input-${firstIndexToFill}`);
      lastUrlElement.focus();
    }
  }

  public getImageUrlInputStatus(index: number) {
    return index >= this.nbrFiles;
  }

  public nbrFilesChanged(nbr: any) {
    this.nbrFiles = this.uploadLimit - (nbr + this.unityForm.productForm.images.length);
  }

  public updateNbrProductImages() {
    this.nbrProductImages = this.unityForm.productForm.imagesAddedByUrl.filter((i: any) => i.url.length > 0).length;
  }

  public canAddImageUrl() {
    return this.unityForm.productForm.imagesToUpload.length + this.unityForm.productForm.images.length + this.unityForm.productForm.imagesAddedByUrl.length < 3;
  }

  public removeImageUrl(index: number) {
    this.unityForm.productForm.imagesAddedByUrl.splice(index, 1);
    if (this.unityForm.productForm.imagesAddedByUrl.length === 0) {
      this.unityForm.productForm.imagesAddedByUrl.push(new ImageUrl(''));
    }
  }

  public removeS3Image(index: number): void {
    this.nbrFiles++;
    this.unityForm.productForm.images.splice(index, 1);
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public validateImageUrl(index: any) {
    return this.unityForm.productForm.images[index].url.endsWith('.jpg') || this.unityForm.productForm.images[index].url.endsWith('.jpeg') || this.unityForm.productForm.images[index].url.endsWith('.png');
  }

  public makeElementAsRequired(index: string) {
    const productName = this.unityForm.productForm.productNames[index];
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-' + index);
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      const selectElement: HTMLSelectElement = divElement.querySelector('select');
      if (!_.isEmpty(productName.value)) {
        selectElement.required = true;
        return;
      }

      if (!_.isEmpty(productName.lang)) {
        inputElement.required = true;
        return;
      }


      inputElement.required = false;
      selectElement.required = false;
    }

  }

  public makeNetContentElementAsRequired(index: number) {
    const obj = this.unityForm.productForm.netContents[index];
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#net-content-item-' + index);
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      const selectElement: HTMLSelectElement = divElement.querySelector('select');
      if (!_.isEmpty(obj.measurementUnitCode)) {
        inputElement.required = true;
        return;
      }

      if (!_.isEmpty(obj.netContent)) {
        selectElement.required = true;
        return;
      }


      inputElement.required = false;
      selectElement.required = false;
    }

  }

  public checkError() {
    let hasAtLeastOneValidItem = false;
    this.updateListLang();
    for (const item of this.unityForm.productForm.productNames) {
      if (!_.isEmpty(item)) {
        hasAtLeastOneValidItem = true;
      }
    }
    this.makeProductNameRequired(!hasAtLeastOneValidItem);
    return false;
  }

  public makeProductNameRequired(state: boolean) {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-0');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      const selectElement: HTMLSelectElement = divElement.querySelector('select');
      inputElement.required = state;
      selectElement.required = state;
    }
  }

  public removeProductName(productName: any, index: any) {
    this.unityForm.productForm.productNames = this.unityForm.productForm.productNames.filter((item: any) => {
      return productName !== item;
    });

    this.checkError();
  }

  public updateListLang() {
    const usedLanguageList = _.map(this.unityForm.productForm.productNames, 'lang');
    for (let i = 0; i < this.unityForm.productForm.productNames.length; i++) {
      const selectedProductName = this.unityForm.productForm.productNames[i];
      this.languageList[i] = LanguageConstants.filter((item: any) => {
        return usedLanguageList.indexOf(item.code) === -1 || selectedProductName.lang === item.code;
      });
    }
  }

  public addProductName() {
    const lastProductName: any = _.last(this.unityForm.productForm.productNames);
    if (!_.isEmpty(lastProductName.value) && !_.isEmpty(lastProductName.lang)) {
      this.unityForm.productForm.productNames.push(new ProductName());
      this.checkError();
      return;
    }

    this.showErrorNotificationAndFocusElement(lastProductName);
  }

  public changeStateLastProductName(state: boolean) {
    const index = _.size(this.unityForm.productForm.productNames) - 1;
    if (index !== 0) {
      const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-' + index);
      if (divElement) {
        const inputElement: HTMLInputElement = divElement.querySelector('input');
        const selectElement: HTMLSelectElement = divElement.querySelector('select');
        inputElement.required = state;
        selectElement.required = state;
      }
    }
  }

  public onChangeLanguageList() {
    this.checkError();
  }

  public onNetContentChange() {
    if (!this.isVcs){
      this.refreshNetContentPattern();
    }
    this.showCodificationRule = true;
  }

  public productNamesChanged() {
    return JSON.stringify(this.unityForm.initialProductForm.productNames) !== JSON.stringify(this.unityForm.productForm.productNames);
  }

  private makeTargetMarketElementRequired() {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.targetMarket-input');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      if (!this.unityForm.productForm.targetMarket || this.unityForm.productForm.targetMarket.length === 0) {
        inputElement.required = true;
        return;
      } else {
        inputElement.required = false;
        return;
      }
    }
  }

  private checkNetContents() {
    for (const currentNetContent of this.unityForm.productForm.netContents) {
      if (currentNetContent.hasError()) {
        return false;
      }
    }
    return true;
  }

  private initImages() {
    if (_.isEmpty(this.product.imagesAddedByUrl)) {
      this.unityForm.productForm.imagesAddedByUrl = [new ImageUrl('')];
      return;
    }
    this.unityForm.productForm.imagesAddedByUrl = _.map(this.product.imagesAddedByUrl, (url) => {
      return new ImageUrl(url);
    });
  }

  private initNetContents() {
    if (this.unityForm.productForm.netContents.length === 0) {
      this.unityForm.productForm.netContents.push(new NetContent('1', 'H87'));
    }
    this.unityForm.productForm.netContents = _.map(this.unityForm.productForm.netContents, ({
                                                                                              netContent,
                                                                                              measurementUnitCode
                                                                                            }) => {
      const netContentStr = `${netContent}`.replace('.', ',');
      return new NetContent(netContentStr, measurementUnitCode);
    });

    this.measurementUnitsList = new Array(this.unityForm.productForm.netContents.length).fill(this.NetContentConstants);

    this.updateMeasurementUnitList();
  }

  private resetWebLinkError() {
    this.webLinkError = new FormInputError('', '');
  }

  private showErrorNotificationAndFocusElement(lastProductName: { value: any; lang: any; }) {
    this.notificationsSvc.mandatoryProductName(this.isBundle);
    const index = _.size(this.unityForm.productForm.productNames) - 1;
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#product-name-item-' + index);
    if (divElement) {
      if (_.isEmpty(lastProductName.value)) {
        const inputElement: HTMLInputElement = divElement.querySelector('input');
        inputElement.focus({
          preventScroll: true
        });
        return;
      }
      if (_.isEmpty(lastProductName.lang)) {
        const selectElement: HTMLSelectElement = divElement.querySelector('select');
        selectElement.focus({
          preventScroll: true
        });

        return;
      }
    }
  }

  private changeBehaviorNetContent(){
    const instancesNetContent: NetContent[] = this.unityForm.productForm.netContents;
    this.unityForm.productForm.netContents = instancesNetContent.map((instanceNetContent: NetContent) => {
      const instanceNetContentGenericProduct = Object.assign(new NetContentGenericProduct(), instanceNetContent);
      instanceNetContentGenericProduct.netContent = String(instanceNetContentGenericProduct.netContent).replace('.', ',');
      if (instanceNetContentGenericProduct.isItemOrArticleUnits()){
        this.netContentPattern.push('^[0-9]*$');
      }else{
        this.netContentPattern.push('^([0-9]{1,}(,\\d{1,5})?)$');
      }
      return instanceNetContentGenericProduct;
    });
  }
  private refreshNetContentPattern(){
    this.netContentPattern = [];
    this.unityForm.productForm.netContents.forEach((netContent: NetContentGenericProduct) => {
      if (netContent.isItemOrArticleUnits()){
        this.netContentPattern.push('^[0-9]*$');
      }else{
        this.netContentPattern.push('^([0-9]{1,}(,\\d{1,5})?)$');
      }
    });
  }
}
