import {Injectable} from '@angular/core';
import {User} from '../models/user.model';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {AuthGuard} from './auth-guard';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

const Promise = require('bluebird');
import * as _ from 'lodash';

@Injectable()
export class UserSvc {
  public user: User = new User(0, '', '', '', false,
    false, false, {}, false, '',
    false, false, false, false,
    false, false, true, false,
    false, false);

  constructor(private httpClient: HttpClient, private router: Router, private oidcSecurityService: OidcSecurityService, private authGuard: AuthGuard) {
  }

  public getUser(): Promise<User> {
    return new Promise((resolve: (arg0: User) => void, reject: (arg0: any) => void) => {
      const requestUrl = environment.API_URL + '/user';
      this.httpClient.get(requestUrl)
        .subscribe(
          (data: any) => {
            const disclaimerAccepted = data.barcodeDisclaimerAccepted ? data.barcodeDisclaimerAccepted : false;
            const applicationDisclaimerAccepted = data.applicationDisclaimerAccepted ? data.applicationDisclaimerAccepted : false;
            const userPreferenceChecked = data.userPreferenceChecked ? data.userPreferenceChecked : false;
            const hasSeenQrExperience = data.hasSeenQrExperience ? data.hasSeenQrExperience : false;
            this.user = new User(data.id, data.firstName, data.lastName, data.email, disclaimerAccepted, applicationDisclaimerAccepted,
              userPreferenceChecked, data.authorizations, data.onBording, data.sectorCode, data.showProductOnBoarding, data.showFoodOnBoarding,
              data.arrayTargetMarketSeen, data.haveSeenProductNames, data.hasSeenAlertNonPayer, data.hasClickedAlertNonPayer, data.noShowNPAlerte,
              data.hasSeenCosmeticsQrExperience, data.hasSeenVCSProduct, hasSeenQrExperience);
            resolve(this.user);
          },
          error => reject(error)
        );
    });
  }

  public setUser(user: User) {
    this.user = user;
  }

  public getUserByGuid(email: string, guid: string, idToken: string, token: string): Promise<User> {
    return new Promise((resolve: (arg0: User) => void, reject: (arg0: any) => void) => {

      const requestUrl = environment.API_URL + '/user/account';
      const params = new HttpParams().append('email', email).append('guid', guid).append('idToken', idToken).append('token', token);
      const options = {params};
      this.httpClient.get(requestUrl, options)
        .subscribe(
          (dataReceived: any) => {
            this.authGuard.jwt = dataReceived.token;
            const disclaimerAccepted = dataReceived.barcodeDisclaimerAccepted ? dataReceived.barcodeDisclaimerAccepted : false;
            const applicationDisclaimerAccepted = dataReceived.user.applicationDisclaimerAccepted ? dataReceived.user.applicationDisclaimerAccepted : false;
            const userPreferenceChecked = dataReceived.user.userPreferenceChecked ? dataReceived.user.userPreferenceChecked : false;
            this.user = new User(dataReceived.user.id, dataReceived.user.firstName,
              dataReceived.user.lastName, dataReceived.user.email, disclaimerAccepted, applicationDisclaimerAccepted,
              userPreferenceChecked, dataReceived.user.authorizations, dataReceived.user.onBording,
              dataReceived.user.sectorCode, dataReceived.user.showProductOnBoarding,
              dataReceived.user.showFoodOnBoarding, dataReceived.user.arrayTargetMarketSeen, dataReceived.user.haveSeenProductNames,
              dataReceived.user.hasSeenAlertNonPayer, dataReceived.user.hasClickedAlertNonPayer, dataReceived.user.noShowNPAlerte);
            return resolve(this.user);
          },
          error => {
            return reject(error);
          }
        );
    });

  }

  public updateUser(updatedAttributes: Object): Promise<User> {
    return new Promise((resolve: (arg0: any) => void, reject: (arg0: any) => void) => {

      const requestUrl = environment.API_URL + '/user';
      const body = JSON.stringify({
        UpdatedAttributes: JSON.stringify(updatedAttributes)
      });
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      };

      return this.httpClient.put(requestUrl, body, httpOptions)
        .subscribe(
          (data: any) => {
            this.user = data;
            resolve(data);
          },
          error => reject(error)
        );
    });
  }

  public disconnectUser(autoLogout: boolean) {
    this.authGuard.removeJwt();
    if (!autoLogout) {
      this.oidcSecurityService.logoff();
    }
  }

  public getBlockedList(): [String] {
    return new Promise((resolve: (arg0: [String]) => void, reject: (arg0: any) => void) => {
      this.httpClient.get('./blocked_list.json')
        .subscribe((data: any) => {
          const blockedList: [String] = data.blockedList || [];
          return resolve(blockedList);
        }, err => {
          return reject(err);
        });
    });
  }
}
