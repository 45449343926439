import {Component} from '@angular/core';

import {ActivatedRoute, Router, UrlSegment} from '@angular/router';

@Component({
  selector: 'product-creation',
  template: `
<div class='ProductCategoryImport'>
    <product-category [isBundle]="isBundle" [isNotNew]="isNotNew" [showForCreation]="false"></product-category>
</div>`
})
export class ProductCategoryImportComponent {
  public isBundle : boolean;
  public isNotNew: boolean;
  constructor( public _router: Router, private route: ActivatedRoute) {
    this.route.url.subscribe((urlSegments: UrlSegment[]) => {
       this.isBundle = urlSegments[2].path === 'bundle';
       this.isNotNew = urlSegments[2].path === 'non-new';
    });
  }
}
