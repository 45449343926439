import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.less']
})
export class InfoAlertComponent implements OnInit {
  @Input() public title: any;
  @Input() public withIcon: Boolean = true;
  @Input() public cssClass : string;
  constructor() { }

  public ngOnInit(): void {
  }

}
