const PATTERN = '^([0-9]{1,}((,|\.)[0-9]{1,3})?)$';

export class NutrimentItem {
    constructor(
        public nutrientTypeCode?: string,
        public measurementPrecisionCode?: string,
        public quantityContained?: string,
        public optionalQuantityContained: string = null,
        public measurementUnitCode?: string,
        public dailyValueIntakeReference?: string,
        public isRequired: boolean = true
    ) {
    }

    public hasErrorQuantityContained() {
        if (this.quantityContained) {
            const decimalRegex = new RegExp(PATTERN);
            if (!decimalRegex.test(this.quantityContained)) {
                return true;
            }
        }
        return false;
    }


    public hasErrorDailyValueIntakeReference() {
        if (this.dailyValueIntakeReference) {
            const decimalRegex = new RegExp(PATTERN);
            if (!decimalRegex.test(this.dailyValueIntakeReference)) {
                return true;
            }
            if (!decimalRegex.test(this.dailyValueIntakeReference)) {
                return true;
            }
            const value = this.dailyValueIntakeReference.replace(',', '.');
            if (parseFloat(value) < 0) {    // removed "|| parseFloat(value) > 100" from the is condition because it's not needed anymore (see story [4381])
                return true;
            }
        }
        return false;
    }
    public hasErrorOptionalQuantityContained() {
        if (this.optionalQuantityContained) {
            const decimalRegex = new RegExp(PATTERN);
            if (!decimalRegex.test(this.optionalQuantityContained)) {
                return true;
            }
        }
        return false;
    }
}






