import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ProductsSvc} from './products.service';
import {HttpClient} from '@angular/common/http';
import { DigitalLink } from 'digital-link.js';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class QrcodeGenerationService {

  constructor(private productSvc: ProductsSvc, private httpClient: HttpClient) {
  }

  public generateQRcode(size, type) {
    const gtin = this.productSvc.product.gtin.padStart(14, '0');
    const dl = DigitalLink({
      domain: environment.QR_BASE_URL,
      identifier: {
        '01': gtin
      }
    });
    const URL = `${environment.API_URL}/qrcode?size=${size}&url=${encodeURI(dl.toWebUriString())}&type=${type}&gtin=${gtin}&download=false`;
    return this.httpClient.get(URL, {responseType: 'blob'});
  }
  public downloadQRcode(size, type, productLink = null){
    const gtin = this.productSvc.product.gtin.padStart(14, '0');
    const dl = DigitalLink({
      domain: environment.QR_BASE_URL,
      identifier: {
        '01': gtin
      }
    });
    const productCategory = this.productSvc.product.productType !== 'VCS' ? 'COSMETIC' : 'VCS';
    const codeGpc = this.productSvc.product ? this.productSvc.product.codeGpc : null;
    const optionalQueryUrl = _.isNil(productLink) ? '' : `&productLink=${productLink}`;
    let URL = `${environment.API_URL}/qrcode?size=${size}&url=${encodeURI(dl.toWebUriString())}&type=${type}&gtin=${this.productSvc.product.gtin}&download=true&productCategory=${productCategory}&codeGpc=${codeGpc}`;
    URL += optionalQueryUrl;
    return this.httpClient.get(URL, {responseType: 'blob'});
  }
}
