'use strict';

export class User {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public barcodeDisclaimerAccepted: boolean,
    public applicationDisclaimerAccepted: boolean,
    public userPreferenceChecked: boolean,
    public authorizations: Object,
    public onBording: boolean,
    public sectorCode: string,
    public showProductOnBoarding: boolean,
    public showFoodOnBoarding: boolean,
    public arrayTargetMarketSeen: boolean,
    public haveSeenProductNames: boolean,
    public hasSeenAlertNonPayer?: boolean,
    public hasClickedAlertNonPayer?: boolean,
    public noShowNPAlerte?: boolean,
    public hasSeenCosmeticsQrExperience?: boolean,
    public hasSeenVCSProduct?: boolean,
    public hasSeenQrExperience?: boolean
  ) {}
}
