import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';
import {DashboardConst} from '../../../models/dashboard.constants';
import {ProductImportForm} from '../../../models/index';
import {ProductsSvc} from '../../../services/index';
import {NotificationsSvc} from '../../../../shared/services/index';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';
import {ImportTypes} from '../../../../shared/models/index';
import {ProductExportModal} from '../../product-export-modal/product-export-modal.component';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import * as $ from 'jquery';
import {environment} from '../../../../../environments/environment';
@Component({
  selector: 'update-my-gtin',
  styleUrls: ['./update-my-gtin.less'],
  templateUrl: './update-my-gtin.tpl.html'
})
export class UpdateMyGtinComponent implements OnInit {
  public constants = DashboardConst;
  public isMoreSectionOpened: boolean = false;
  @ViewChild(ProductExportModal, {static: true})
  private productExportModal: ProductExportModal;

  constructor(private router: Router, public productsSvc: ProductsSvc, private notificationsSvc: NotificationsSvc,
              private route: ActivatedRoute, private headerSvc: DashboardHeaderSvc, private featureToggleSvc: FeatureToggleSvc) {
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData('Mettre à jour des produits', 'assets/icons/ico_import.svg');
  }

  public toggleMoreSection() {
    $('#importPanel').animate({
      scrollTop: document.getElementById('moreSectionLink').offsetTop + 450
    }, 800);
    this.isMoreSectionOpened = !this.isMoreSectionOpened;
  }


  public downloadFileTemplate() {
    window.open(environment.IMPORT_XLS_TEMPLATE_URL);
  }

  public exportAllProducts() {
    this.productExportModal.show([]);
  }

  public goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  public isVcs(): boolean {
    return this.featureToggleSvc.canSeeVcs();
  }

}


