import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  Input, Output, EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  VcsCreationUnityForm,
  nutrimentServingSizeConst,
  optionalNutrimentItemsConst,
  nutrimentItemsConst,
  nutrimentMeasurementUnitConst,
  nutrimentMeasurementPrecisionConst
} from '../../../../../models/index';
import { CompanySvc, NotificationsSvc } from '../../../../../../shared/services/index';
import { ProductsSvc } from '../../../../../services/index';
import { FormInputError } from '../../../../../models/formInputError';
import { DashboardConst } from '../../../../../models/dashboard.constants';
import { FeatureToggleSvc } from '../../../../../../shared/services/feature-toggle.service';
import { VintageSvc } from '../../../../../../shared/services/vintages.service';
import { Classification } from '../../../../../../shared/models/Classification.model';
import { AgeOfSpiritModel } from '../../../../../models/ageOfSpirit.model';
import { Varietal } from '../../../../../models/varietals.constants';
import { Aoc } from '../../../../../models/aoc.constants';

import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { NutrimentItem } from 'src/app/dashboard/models/nutrimentItem.model';


@Component({
  selector: 'unity-consumer-creation-vcs',
  styleUrls: ['./unity-creation-vcs.less'],
  templateUrl: './unity-consumer-creation-vcs.tpl.html'
})
export class UnityConsumerCreationVcsComponent implements OnInit, AfterViewInit {
  @ViewChild('formProduct', { static: true }) public form;
  public currentStep: number = 1;
  public showCategory: boolean;
  public webLinkError: FormInputError;
  @Input() public isSpi: boolean;
  @Input() public isWine: boolean;
  @Output() public onNavigateToFinalStep = new EventEmitter<any>();
  @Output() public onReturnToPreviousStep = new EventEmitter<any>();
  public multiSelectConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 15
  });
  public multiSelectOptions: any = Varietal;
  @Input() public unityForm: VcsCreationUnityForm;
  public allergenIsSelected: boolean;

  public aocSelectConfig: any = Object.assign({}, {
    openOnFocus: false,
    highlight: false,
    create: false,
    persist: true,
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input aoc-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: 'wording',
    maxItems: 1
  });
  public aocSelectOptions: any = Aoc;
  public companySvcSubscription: Subscription;
  public nbYears = new AgeOfSpiritModel().nbYears;
  public constants = DashboardConst;
  public ingredientPlaceholder: string = '';
  public nutrimentServingSize = nutrimentServingSizeConst;
  public optionalColumnHeight: number;
  public canSeeOptionalNutriment: boolean = false;
  public showAddNutriment: boolean = true;
  public optionalNutrimentItems = optionalNutrimentItemsConst;
  public requiredNutrimentItems: any = nutrimentItemsConst;
  public measurementUnit = nutrimentMeasurementUnitConst;
  public selectedNutriment: any;
  public measurementPrecision = nutrimentMeasurementPrecisionConst;


  constructor(public productsSvc: ProductsSvc,
    public companySvc: CompanySvc,
    public _router: Router,
    public route: ActivatedRoute,
    public notificationsSvc: NotificationsSvc,
    public featureToggleSvc: FeatureToggleSvc,
    public vintageSvc: VintageSvc,
    private elRef: ElementRef) {
  }

  @Input()
  public setGpcCodes(codes: Classification[]) {
    this.unityForm.gpcCodes = codes;
  }

  public ngOnInit(): void {
    this.unityForm.onGpcChange();
    this.unityForm.productForm.productCountry = '250';
    if (this.unityForm.productForm.nutriment.servingSizeDescription && this.unityForm.productForm.nutriment.servingSizeDescription.length !== 0) {
      this.canSeeOptionalNutriment = true;
    }
    if (this.unityForm.productForm && this.unityForm.productForm.ingredientStatement && this.unityForm.productForm.ingredientStatement.length) {
      this.ingredientPlaceholder = this.unityForm.productForm.ingredientStatement;
    }
    this.allergenIsSelected = false;
  }

  public ngAfterViewInit() {
  }

  public next(submitting: boolean = true, form: NgForm) {
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) this.unityForm.productForm.preparationStateCode = 'UNPREPARED';
    if (this.hasSetNutrients() && !this.checkErrorsInQuantities()) {
      this.notificationsSvc.wrongNumberOfDecimalsDigits();
      return false;
    }
    if (this.hasSetNutrients() && !this.checkSugar()) {
      return false;
    }
    if (this.hasSetNutrients() && !this.isValidFatNutriments()) {
      this.notificationsSvc.wrongFoodNutriment();
      return false;
    }

    if (!this.hasSetNutrients()) {
      this.unityForm.productForm.preparationStateCode = null;
      this.unityForm.productForm.nutriment = null;
    }

    this.unityForm.submitting = submitting;
    this.onNavigateToFinalStep.emit({ submitting, form });
  }

  public returnToPreviousStep(submitting: boolean = true) {
    this.onReturnToPreviousStep.emit({ submitting });
  }


  public showIG() {
    const toReturn = this.isWine && this.unityForm.productForm.productCountry === '250';
    if (!toReturn) {
      this.unityForm.productForm.gis = '';
    }
    return this.isWine && this.unityForm.productForm.productCountry === '250';
  }

  public showAOC() {
    const toReturn = this.showIG() && ['AOC', 'AOP', 'IG', 'IGP'].indexOf(this.unityForm.productForm.gis) >= 0;
    if (!toReturn) {
      this.unityForm.productForm.aoc = '';
    }
    return toReturn;
  }

  public updateTextArea(editableContent: HTMLDivElement) {
    this.unityForm.productForm.ingredientStatement = editableContent.textContent;
  }

  public findNutrimentWording(code: string, measurementUnitCode: string): string {
    const nutriment: any = _.find(this.requiredNutrimentItems, c => c.code === code && c.measurementUnitCode === measurementUnitCode) || {};
    return nutriment.wording;
  }

  public findServingSizeWording(code: string): string {
    const returnedObject: any = _.find(this.nutrimentServingSize, c => c.code === code) || {};
    return `${returnedObject.wording}`;
  }

  public checkEnergeticValue(item: any, key: any) {
    const energyKeys = ['KJO', 'E14'];
    const indexKey = energyKeys.indexOf(item.measurementUnitCode);
    if (indexKey >= 0) {
      this.manageEnergeticValues(item, energyKeys[1 - indexKey], key);
    }
  }

  public manageEnergeticValues(item: { [x: string]: any; }, type: string, key: string | number) {
    if (item[key]) {
      let value = item[key];

      const hasComma = value.indexOf(',') !== -1;
      // value: , => .
      value = value.replace(',', '.');

      let otherValue = '' + (
        type === 'E14' ?
          (parseFloat(value) * 0.239006) :
          (parseFloat(value) / 0.239006)
      ).toFixed(1);

      // otherValue: . => ,
      if (hasComma) {
        otherValue = otherValue.replace('.', ',');
      }

      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = otherValue;
    } else {
      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = '';
    }
  }

  public resetNutrimentClass(item: NutrimentItem, type: string) {
    const selector = item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode;
    const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${type}_${selector}`);
    element.className = element.className.replace('inputError', '');
  }

  public findMeasurementUnitWording(code: string): string {
    const measurementUnit: any = _.find(this.measurementUnit, c => c.code === code) || {};
    return measurementUnit.wording;
  }

  public hideOptionalNutriment() {
    this.canSeeOptionalNutriment = false;
  }

  public showOptionalNutriment() {
    this.canSeeOptionalNutriment = true;
  }

  public reValidateOptionalQuantityContained() {
    if (!this.isRequiredOptionalQuantityContained()) {
      const optionalQuantityContainedInputs: HTMLInputElement[] = this.elRef.nativeElement.querySelectorAll('.optionalQuantityContained');
      optionalQuantityContainedInputs.forEach((element: HTMLInputElement) => {
        element.className = `optionalQuantityContained specificWidth`;
      });
    }
  }

  public isRequiredOptionalQuantityContained(): boolean {
    if (this.unityForm.productForm.nutriment.servingSizeDescription === null) {
      return false;
    }

    if (this.unityForm.productForm.nutriment.servingSizeDescription.length === 0) {
      return false;
    }

    return true;
  }

  public addNutriment() {
    this.showAddNutriment = false;
  }

  public selectNutriment(element: HTMLDivElement) {
    this.showAddNutriment = true;
    this.requiredNutrimentItems.push(this.selectedNutriment);
    this.optionalNutrimentItems = _.filter(this.optionalNutrimentItems, (nutrimentItem) => nutrimentItem.code !== this.selectedNutriment.code);
    const nutriment = new NutrimentItem(this.selectedNutriment.code, 'APPROXIMATELY', null, null, this.selectedNutriment.measurementUnitCode, null, false);
    this.unityForm.productForm.nutriment.items.push(nutriment);
    this.optionalColumnHeight = element.offsetHeight + 60;
  }

  public removeNutriment(nutriment: NutrimentItem, index: number) {
    const itemRemoved: any = _.filter(this.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === nutriment.nutrientTypeCode)[0] || {};
    this.requiredNutrimentItems = _.filter(this.requiredNutrimentItems, (nutrimentItem: NutrimentItem) => nutrimentItem.nutrientTypeCode !== nutriment.nutrientTypeCode);
    this.optionalNutrimentItems.push(itemRemoved);
    this.unityForm.productForm.nutriment.items = this.unityForm.productForm.nutriment.items.filter((item: any) => {
      return nutriment !== item;
    });
    this.selectedNutriment = null;
    this.optionalColumnHeight -= 60;
  }

  public checkValue(event: any) {
    return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 44 || event.charCode === 46;
  }

  public checkSugar() {
    const glucidesItem = this.unityForm.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'CHOAVL');
    const sugarItem = this.unityForm.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'SUGAR-');
    const keysToCheck = ['quantityContained'];         // , ['optionalQuantityContained', 'dailyValueIntakeReference']
    let checker = false;

    for (const key of keysToCheck) {
      const glucidesValue = parseFloat(glucidesItem[key].replace(',', '.'));
      const sugarValue = parseFloat(sugarItem[key].replace(',', '.'));
      checker = checker || (sugarValue > glucidesValue);
      if (sugarValue > glucidesValue) {
        const sugarElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_SUGAR-`);
        const glucidesElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_CHOAVL`);
        sugarElement.className += ' inputError';
        glucidesElement.className += ' inputError';
        sugarElement.scrollIntoView({
          block: 'center'
        });
      }
    }

    if (checker) {
      this.notificationsSvc.sugarSupToCarbohydrates();
      return false;
    }
    return true;
  }

  public hasSetNutrients(): boolean {
    let toReturn = false;
    this.unityForm.productForm.nutriment?.items.forEach(item => {
      toReturn = toReturn || !_.isEmpty(item.quantityContained);
    });
    return toReturn;
  }

  public hasSetAllNutrients(): boolean {
    let toReturn = true;

    for (const item of this.unityForm.productForm.nutriment?.items) {
      if (_.isEmpty(item.quantityContained)) {
        toReturn = false;
      }
    }

    return toReturn;
  }

  public hasSetAllAllergens(): boolean {
    if (!this.isSpi) {
      return Object.keys(this.unityForm.productForm.allergen).length === 3;
    }

    return Object.keys(this.unityForm.productForm.allergen).length === 7;
  }

  public onKeyDwn(editableContent: HTMLDivElement, e) {
    if (editableContent.textContent.length >= 5000 && !this.isAllowedKeyCode(e)) {
      e.preventDefault();
    }
  }


  public setAdhereToTheSectoralAgreement(isVintageChanged: boolean) {

    if (!_.isEmpty(this.unityForm.productForm.spiritCodeGpc)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.spiritStyle)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.gis)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.aoc) && this.showAOC()) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.ageOfSpirit)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (typeof this.unityForm.productForm.vintage === 'number' && isVintageChanged) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.wineColor)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.alcoholDegree)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.container)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.sugarContent)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.varietals)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }


    if (this.hasSetAtLeastOneCertifications()) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    this.unityForm.productForm.adhereToTheSectoralAgreement = false;
  }

  public hasSetAtLeastOneCertifications(): boolean {
    if (_.isEmpty(this.unityForm.productForm.certifications)) {
      return false;
    }

    for (const key of Object.keys(this.unityForm.productForm.certifications)) {
      if (this.unityForm.productForm.certifications[key] === true) {
        return true;
      }

      if (!_.isEmpty(this.unityForm.productForm.certifications.AUTRE_CERTIF) || !_.isEmpty(this.unityForm.productForm.certifications.AUTRE_LABEL)) {
        return true;
      }
    }


    return false;
  }

  public setAdhereToRegulatoryAgreement() {
    const ingredientStatementOrHasSetNutrients: boolean = !_.isEmpty(this.unityForm.productForm.ingredientStatement) || this.hasSetNutrients();
    this.unityForm.productForm.adhereToRegulatoryAgreement = ingredientStatementOrHasSetNutrients ;
  }

  public hasSetAllRegulatoryAttribute() {
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) {
      return !_.isEmpty(this.unityForm.productForm.ingredientStatement) && this.hasSetAllNutrients() && this.hasSetAllAllergens();
    }
    return false;
  }

  public showBtn(formProduct: NgForm): boolean {
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) {
      if (!this.hasSetAllRegulatoryAttribute()) {
        return false;
      }
      if (this.unityForm.productForm.adhereToTheSectoralAgreement === true && formProduct.form.valid === true) {
        return true;
      }

      if (this.unityForm.productForm.adhereToTheSectoralAgreement === true && formProduct.form.valid === false) {
        return false;
      }

      return true;
    }

    if (!this.unityForm.productForm.adhereToRegulatoryAgreement) {
      if (this.unityForm.productForm.adhereToTheSectoralAgreement === true && formProduct.form.valid === false) {
        return false;
      }
    }

    return true;
  }

  public allergenClicked($event) {
    const value = ($event.currentTarget as HTMLInputElement).value;
    const checked = ($event.currentTarget as HTMLInputElement).checked;
    const name = ($event.currentTarget as HTMLInputElement).name;
    this.affectAlergen(name, !checked ? null : value === 'true');
    this.allergenIsSelected = Object.keys(this.unityForm.productForm.allergen).length > 0;
  }

  public allergensAreRequired(allergenName: boolean): boolean {
    if (this.unityForm.productForm.adhereToTheSectoralAgreement || this.unityForm.productForm.adhereToRegulatoryAgreement || this.allergenIsSelected) {
      return _.isNil(allergenName);
    }
    return false;
  }

  private checkErrorsInQuantities(): boolean {
    let toReturn = true;
    this.unityForm.productForm.nutriment.items.forEach((i: any) => {
      toReturn = toReturn && (!i.hasErrorQuantityContained() && !i.hasErrorOptionalQuantityContained() && !i.hasErrorDailyValueIntakeReference());
      if (i.hasErrorQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#quantityContained_${selector}`);
        if (!element.className.includes('inputError')) {
          element.className += ' inputError';
        }
      }
      if (i.hasErrorOptionalQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#optionalQuantityContained_${selector}`);
        if (!element.className.includes('inputError')) {
          element.className += ' inputError';
        }
      }
    });
    return toReturn;
  }

  private isValidFatNutriments(): boolean {

    const FASATNutriments = _.filter(this.unityForm.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FASAT');
    const FATNutriments = _.filter(this.unityForm.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FAT');
    const FASATNutriment: NutrimentItem = FASATNutriments[0];
    const FATNutriment: NutrimentItem = FATNutriments[0];
    const FASATQuantityContained = FASATNutriment.quantityContained ? FASATNutriment.quantityContained : '';
    const FATQuantityContained = FATNutriment.quantityContained ? FATNutriment.quantityContained : '';
    const toReturn = parseFloat(FASATQuantityContained.replace(/,/g, '.')) <= parseFloat(FATQuantityContained.replace(/,/g, '.'));
    const fasatElement: HTMLInputElement = this.elRef.nativeElement.querySelector('#quantityContained_FASAT');
    const fatElement: HTMLInputElement = this.elRef.nativeElement.querySelector('#quantityContained_FAT');
    if (!toReturn) {
      fasatElement.className += ' inputError';
      fatElement.className += ' inputError';
      fasatElement.scrollIntoView({
        block: 'center'
      });
    }
    return toReturn;
  }

  private isAllowedKeyCode(event) {
    event = event || window.event;
    const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
    return charCode === 8 ||
      charCode === 38 ||
      charCode === 39 ||
      charCode === 37 ||
      charCode === 40 || (event.ctrlKey && charCode !== 86) ||
      event.metaKey;
  }
  private affectAlergen(name, value) {
    switch (name) {
      case 'sulfit': !_.isNil(value) ? this.unityForm.productForm.allergen.AU = value : delete this.unityForm.productForm.allergen.AU;
        break;
      case 'oeuf': !_.isNil(value) ? this.unityForm.productForm.allergen.AE = value : delete this.unityForm.productForm.allergen.AE;
        break;
      case 'lait': !_.isNil(value) ? this.unityForm.productForm.allergen.AM = value : delete this.unityForm.productForm.allergen.AM;
        break;
      case 'gluten': !_.isNil(value) ? this.unityForm.productForm.allergen.AW = value : delete this.unityForm.productForm.allergen.AW;
        break;
      case 'crustace': !_.isNil(value) ? this.unityForm.productForm.allergen.UN = value : delete this.unityForm.productForm.allergen.UN;
        break;
      case 'arachide': !_.isNil(value) ? this.unityForm.productForm.allergen.AP = value : delete this.unityForm.productForm.allergen.AP;
        break;
      case 'fruitCoque': !_.isNil(value) ? this.unityForm.productForm.allergen.AN = value : delete this.unityForm.productForm.allergen.AN;
        break;
      default:
        break;
    }
  }
}
