import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Router} from '@angular/router';

import {CompanySvc, NotificationsSvc, UserSvc} from '../../../../../../shared/services';
import {ProductsSvc} from '../../../../../services';
import {FeatureToggleSvc} from '../../../../../../shared/services/feature-toggle.service';
import {DashboardHeaderSvc} from '../../../../../services/dashboard-header.service';
import {FoodCreationUnityForm} from '../../../../../models/foodCreationUnityForm.model';
import {FoodOnBoardingModal} from '../../../../food-onboarding-modal/food-onboarding-modal.component';
import {ProductFormModel, VcsCreationUnityForm} from '../../../../../models';
import {FoodProductFormModel} from '../../../../../models/foodProductCreationForm.model';

import * as _ from 'lodash';

@Component({
    selector: 'unity-consumer-creation-food',
    styleUrls: ['./unity-consumer-creation-food.less'],
    templateUrl: './unity-consumer-creation-food.tpl.html'
})
export class UnityConsumerCreationFoodComponent implements OnInit, AfterViewInit {

    @ViewChild('formProduct', {static: true}) public form;

    public currentStep: number = 1;
    public showCategory: boolean;
    @Output() public onModifyCategory = new EventEmitter<any>();
    @Output() public onReturnToGenericStep = new EventEmitter<any>();
    @Output() public onNavigateToFinalStep = new EventEmitter<any>();
    @Input() public unityForm: FoodCreationUnityForm;
    @Input() public selectedCategory: {
        breadcrumbs: string,
        code: string,
        definition: string,
        parentId: string,
        text: string,
        type: string
    } = {
        breadcrumbs: '',
        code: '',
        definition: '',
        parentId: '',
        text: '',
        type: ''
    };


    constructor(public productsSvc: ProductsSvc,
                public companySvc: CompanySvc,
                public _router: Router,
                public notificationsSvc: NotificationsSvc,
                public featureToggleSvc: FeatureToggleSvc,
                private headerSvc: DashboardHeaderSvc,
                private elRef: ElementRef,
                public userSvc: UserSvc) {
    }

    public ngOnInit() {
        this.currentStep = this.unityForm.lastVisitedStep;
    }

    public ngAfterViewInit() {
        const user = this.userSvc.user;
    }

    public onCloseFoodModal(noShow) {
        if (noShow) {
            this.userSvc.updateUser({showFoodOnBoarding: false});
        }
    }

    public modifyCategory() {
        this.onModifyCategory.emit(true);
    }

    public onNext(submitting: boolean = true) {
        this.unityForm.submitting = submitting;
        this.unityForm.lastVisitedStep = this.currentStep;
        if (this.currentStep < 4) {
            this.unityForm.submitting = true;
            this.currentStep++;
            return;
        }
        this.onNavigateToFinalStep.emit({submitting});
    }

    public onPrevious(submitting: boolean = true) {
        this.unityForm.submitting = submitting;
        this.unityForm.lastVisitedStep = this.currentStep;
        if (this.currentStep > 0) {
            this.currentStep--;
        }
        if (this.currentStep === 0) {
            this.onReturnToGenericStep.emit({submitting});
        }
    }

    public drainedWeightChangedHandler(hasDrainedWeight) {
        this.unityForm.hasDrainedWeight = hasDrainedWeight;
    }

    public alcoholChangedHendler(hasAlcohol) {
        this.unityForm.hasAlcohol = hasAlcohol;
    }
}
