import {Component, Input, OnInit, AfterViewInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';

@Component({
  selector: 'classification-onboarding-modal',
  styleUrls: ['classification-onboarding-modal.less'],
  template: `
    <div class="ClassificationOnBoardingModalContainer">
      <bs-modal #modal id="first-modal" class="ClassificationOnBoardingModal" (onClose)="onClose()"
                (onDismiss)="onDismiss()" [animation]="false" [keyboard]="true" [backdrop]="'static'" [size]="'md'">
        <bs-modal-header class="ClassificationOnBoardingModalHeader" [showDismiss]="false">Visite Guidée 1/2
        </bs-modal-header>
        <bs-modal-body>
          <div class="ClassificationOnBoardingModalBody">
            <h3>Déterminer la catégorie</h3>
            <div class="ClassificationOnBoardingModalText" *ngIf="!isBundle">
              Cherchez ici la catégorie de votre produit en tapant
              La désignation générique de l'article (exemples:
              café, stylo, pantalon, … )
            </div>
            <div class="ClassificationOnBoardingModalText" *ngIf="isBundle">
              Cherchez ici la catégorie des produits de votre assortiment en tapant la désignation générique de l'article (exemples : Assortiments de produits cosmétiques, Lot de cafés...)
            </div>
          </div>
        </bs-modal-body>
        <bs-modal-footer>
          <button class="ClassificationOnBoardingModal" (click)="understood()">J'ai compris</button>
        </bs-modal-footer>
      </bs-modal>
    </div>`
})

export class ClassificationOnBoardingModal implements OnInit, AfterViewInit {
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;
  @Output()
  public onCloseFirstModal = new EventEmitter<any>();

  @Input() public isBundle: boolean = false;

  constructor() {
  }

  public ngOnInit() {
    const categoryInput: HTMLElement = document.getElementById('categoryInput');
    categoryInput.className = 'col-md-11 onTopOf-backdrop';
    this.modal.open();
  }

  public ngAfterViewInit() {
    const modal = document.getElementById('first-modal');
    const input = document.getElementById('categoryInput');
    if (input && modal) {
      const inputCoordinates = input.getBoundingClientRect();
      const inputTopCoordinates = Math.round(inputCoordinates.top);
      const modalTopInitial = 198;
      let browserRelative = 0;
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      if (isIEOrEdge) {
        browserRelative = 216;
      }
      modal.style.top = (inputTopCoordinates - modalTopInitial - browserRelative) + 'px';
    }
  }

  public openModal() {
    this.modal.open();
  }

  public understood() {
    this.modal.close();
  }

  public onClose() {
    const categoryInput: HTMLElement = document.getElementById('categoryInput');
    categoryInput.className = 'col-md-11';
    this.onCloseFirstModal.emit();
  }

  public onDismiss() {
    const categoryInput: HTMLElement = document.getElementById('categoryInput');
    categoryInput.className = 'col-md-11';
    this.onCloseFirstModal.emit();
  }
}
