import { Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'unreachable-product-modal',
  templateUrl: './unreachable-product-modal.component.html',
  styleUrls: ['./unreachable-product-modal.component.less']
})
export class UnreachableProductModalComponent {
  constructor(private _router: Router,   @Inject(MAT_DIALOG_DATA) public data: {
    productId: string
  }) {
  }

  public navigateToDashboard() {
    this._router.navigate([('/dashboard')]);
  }

  public updateCategory(id: string) {
    const routeName = 'dashboard/product/update-category';
    this._router.navigate([routeName, id]);
  }
}
