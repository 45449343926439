export const LanguageConstants = [
  {
    code: 'fr',
    englishName: 'French',
    frenchName: 'français',
    germanName: 'Französisch'
  },
  {
    code: 'sq',
    englishName: 'Albanian',
    frenchName: 'albanais',
    germanName: 'Albanisch'
  },
  {
    code: 'ar',
    englishName: 'Arabic',
    frenchName: 'arabe',
    germanName: 'Arabisch'
  },
  {
    code: 'hy',
    englishName: 'Armenian',
    frenchName: 'arménien',
    germanName: 'Armenisch'
  },
  {
    code: 'az',
    englishName: 'Azerbaijani',
    frenchName: 'azéri',
    germanName: 'Aserbeidschanisch'
  },
  {
    code: 'eu',
    englishName: 'Basque',
    frenchName: 'basque',
    germanName: 'Baskisch'
  },
  {
    code: 'be',
    englishName: 'Belarusian',
    frenchName: 'biélorusse',
    germanName: 'Weißrussisch'
  },
  {
    code: 'bs',
    englishName: 'Bosnian',
    frenchName: 'bosniaque',
    germanName: 'Bosnisch'
  },
  {
    code: 'br',
    englishName: 'Breton',
    frenchName: 'breton',
    germanName: 'Bretonisch'
  },
  {
    code: 'bg',
    englishName: 'Bulgarian',
    frenchName: 'bulgare',
    germanName: 'Bulgarisch'
  },
  {
    code: 'ca',
    englishName: 'Catalan; Valencian',
    frenchName: 'catalan; valencien',
    germanName: 'Katalanisch'
  },
  {
    code: 'zh',
    englishName: 'Chinese',
    frenchName: 'chinois',
    germanName: 'Chinesisch'
  },
  {
    code: 'co',
    englishName: 'Corsican',
    frenchName: 'corse',
    germanName: 'Korsisch'
  },
  {
    code: 'hr',
    englishName: 'Croatian',
    frenchName: 'croate',
    germanName: 'Kroatisch'
  },
  {
    code: 'cs',
    englishName: 'Czech',
    frenchName: 'tchèque',
    germanName: 'Tschechisch'
  },
  {
    code: 'da',
    englishName: 'Danish',
    frenchName: 'danois',
    germanName: 'Dänisch'
  },
  {
    code: 'nl',
    englishName: 'Dutch; Flemish',
    frenchName: 'néerlandais; flamand',
    germanName: 'Niederländisch'
  },
  {
    code: 'en',
    englishName: 'English',
    frenchName: 'anglais',
    germanName: 'Englisch'
  },
  {
    code: 'et',
    englishName: 'Estonian',
    frenchName: 'estonien',
    germanName: 'Estnisch'
  },
  {
    code: 'fo',
    englishName: 'Faroese',
    frenchName: 'féroïen',
    germanName: 'Färöisch'
  },
  {
    code: 'fj',
    englishName: 'Fijian',
    frenchName: 'fidjien',
    germanName: 'Fidschi-Sprache'
  },
  {
    code: 'fi',
    englishName: 'Finnish',
    frenchName: 'finnois',
    germanName: 'Finnisch'
  },
  {
    code: 'gd',
    englishName: 'Gaelic; Scottish Gaelic',
    frenchName: 'gaélique; gaélique écossais',
    germanName: 'Gälisch-Schottisch'
  },
  {
    code: 'ka',
    englishName: 'Georgian',
    frenchName: 'géorgien',
    germanName: 'Georgisch'
  },
  {
    code: 'de',
    englishName: 'German',
    frenchName: 'allemand',
    germanName: 'Deutsch'
  },
  {
    code: 'el',
    englishName: 'Greek, Modern (1453-)',
    frenchName: 'grec moderne (après 1453)',
    germanName: 'Neugriechisch'
  },
  {
    code: 'ht',
    englishName: 'Haitian; Haitian Creole',
    frenchName: 'haïtien; créole haïtien',
    germanName: 'Haïtien (Haiti-Kreolisch)'
  },
  {
    code: 'he',
    englishName: 'Hebrew',
    frenchName: 'hébreu',
    germanName: 'Hebräisch'
  },
  {
    code: 'hi',
    englishName: 'Hindi',
    frenchName: 'hindi',
    germanName: 'Hindi'
  },
  {
    code: 'hu',
    englishName: 'Hungarian',
    frenchName: 'hongrois',
    germanName: 'Ungarisch'
  },
  {
    code: 'is',
    englishName: 'Icelandic',
    frenchName: 'islandais',
    germanName: 'Isländisch'
  },
  {
    code: 'id',
    englishName: 'Indonesian',
    frenchName: 'indonésien',
    germanName: 'Bahasa Indonesia'
  },
  {
    code: 'ga',
    englishName: 'Irish',
    frenchName: 'irlandais',
    germanName: 'Irisch'
  },
  {
    code: 'it',
    englishName: 'Italian',
    frenchName: 'italien',
    germanName: 'Italienisch'
  },
  {
    code: 'ja',
    englishName: 'Japanese',
    frenchName: 'japonais',
    germanName: 'Japanisch'
  },
  {
    code: 'jv',
    englishName: 'Javanese',
    frenchName: 'javanais',
    germanName: 'Javanisch'
  },
  {
    code: 'kl',
    englishName: 'Kalaallisut; Greenlandic',
    frenchName: 'groenlandais',
    germanName: 'Grönländisch'
  },
  {
    code: 'kk',
    englishName: 'Kazakh',
    frenchName: 'kazakh',
    germanName: 'Kasachisch'
  },
  {
    code: 'rw',
    englishName: 'Kinyarwanda',
    frenchName: 'rwanda',
    germanName: 'Rwanda-Sprache'
  },
  {
    code: 'ky',
    englishName: 'Kirghiz; Kyrgyz',
    frenchName: 'kirghiz',
    germanName: 'Kirgisisch'
  },
  {
    code: 'ko',
    englishName: 'Korean',
    frenchName: 'coréen',
    germanName: 'Koreanisch'
  },
  {
    code: 'ku',
    englishName: 'Kurdish',
    frenchName: 'kurde',
    germanName: 'Kurdisch'
  },
  {
    code: 'lo',
    englishName: 'Lao',
    frenchName: 'lao',
    germanName: 'Laotisch'
  },
  {
    code: 'lv',
    englishName: 'Latvian',
    frenchName: 'letton',
    germanName: 'Lettisch'
  },
  {
    code: 'lt',
    englishName: 'Lithuanian',
    frenchName: 'lituanien',
    germanName: 'Litauisch'
  },
  {
    code: 'lb',
    englishName: 'Luxembourgish; Letzeburgesch',
    frenchName: 'luxembourgeois',
    germanName: 'Luxemburgisch'
  },
  {
    code: 'mk',
    englishName: 'Macedonian',
    frenchName: 'macédonien',
    germanName: 'Makedonisch'
  },
  {
    code: 'mg',
    englishName: 'Malagasy',
    frenchName: 'malgache',
    germanName: 'Malagassi-Sprache'
  },
  {
    code: 'ms',
    englishName: 'Malay',
    frenchName: 'malais',
    germanName: 'Malaiisch'
  },
  {
    code: 'mt',
    englishName: 'Maltese',
    frenchName: 'maltais',
    germanName: 'Maltesisch'
  },
  {
    code: 'mi',
    englishName: 'Maori',
    frenchName: 'maori',
    germanName: 'Maori-Sprache'
  },
  {
    code: 'mn',
    englishName: 'Mongolian',
    frenchName: 'mongol',
    germanName: 'Mongolisch'
  },
  {
    code: 'ne',
    englishName: 'Nepali',
    frenchName: 'népalais',
    germanName: 'Nepali'
  },
  {
    code: 'no',
    englishName: 'Norwegian',
    frenchName: 'norvégien',
    germanName: 'Norwegisch'
  },
  {
    code: 'fa',
    englishName: 'Persian',
    frenchName: 'persan',
    germanName: 'Persisch'
  },
  {
    code: 'pl',
    englishName: 'Polish',
    frenchName: 'polonais',
    germanName: 'Polnisch'
  },
  {
    code: 'pt',
    englishName: 'Portuguese',
    frenchName: 'portugais',
    germanName: 'Portugiesisch'
  },
  {
    code: 'ro',
    englishName: 'Romanian; Moldavian; Moldovan',
    frenchName: 'roumain; moldave',
    germanName: 'Rumänisch'
  },
  {
    code: 'ru',
    englishName: 'Russian',
    frenchName: 'russe',
    germanName: 'Russisch'
  },
  {
    code: 'sm',
    englishName: 'Samoan',
    frenchName: 'samoan',
    germanName: 'Samoanisch'
  },
  {
    code: 'sc',
    englishName: 'Sardinian',
    frenchName: 'sarde',
    germanName: 'Sardisch'
  },
  {
    code: 'sr',
    englishName: 'Serbian',
    frenchName: 'serbe',
    germanName: 'Serbisch'
  },
  {
    code: 'sl',
    englishName: 'Slovenian',
    frenchName: 'slovène',
    germanName: 'Slowenisch'
  },
  {
    code: 'so',
    englishName: 'Somali',
    frenchName: 'somali',
    germanName: 'Somali'
  },
  {
    code: 'su',
    englishName: 'Sundanese',
    frenchName: 'soundanais',
    germanName: 'Sundanesisch'
  },
  {
    code: 'sv',
    englishName: 'Swedish',
    frenchName: 'suédois',
    germanName: 'Schwedisch'
  },
  {
    code: 'ty',
    englishName: 'Tahitian',
    frenchName: 'tahitien',
    germanName: 'Tahitisch'
  },
  {
    code: 'tg',
    englishName: 'Tajik',
    frenchName: 'tadjik',
    germanName: 'Tadschikisch'
  },
  {
    code: 'ta',
    englishName: 'Tamil',
    frenchName: 'tamoul',
    germanName: 'Tamil'
  },
  {
    code: 'tr',
    englishName: 'Turkish',
    frenchName: 'turc',
    germanName: 'Türkisch'
  },
  {
    code: 'tk',
    englishName: 'Turkmen',
    frenchName: 'turkmène',
    germanName: 'Turkmenisch'
  },
  {
    code: 'uk',
    englishName: 'Ukrainian',
    frenchName: 'ukrainien',
    germanName: 'Ukrainisch'
  },
  {
    code: 'ur',
    englishName: 'Urdu',
    frenchName: 'ourdou',
    germanName: 'Urdu'
  },
  {
    code: 'uz',
    englishName: 'Uzbek',
    frenchName: 'ouszbek',
    germanName: 'Usbekisch'
  },
  {
    code: 'vi',
    englishName: 'Vietnamese',
    frenchName: 'vietnamien',
    germanName: 'Vietnamesisch'
  },
  {
    code: 'es',
    englishName: 'Spanish; Castilian' ,
    frenchName: 'espagnol; castillan' ,
    germanName:  'Spanisch'
  }
];
