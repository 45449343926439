import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class DateSvc {

    constructor(private httpClient: HttpClient) {
    }

    public isCorrectDate(): Observable<Boolean> {
        const requestUrl = environment.API_URL + '/currentDate';
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(requestUrl, httpOptions).pipe(
            map(
                (data: any) => {
                    const currentDate = data.currentDate;
                    const date = new Date();
                    const systemDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}`;
                    const isSameDate: boolean = systemDate === currentDate;
                    return isSameDate;
                }));
    }

    public getCurrentDate(): Observable<String> {
        const requestUrl = environment.API_URL + '/currentDate';
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(requestUrl, httpOptions).pipe(
            map(
                (data: any) => {
                    const currentDate = data.currentDate;
                    return currentDate;
                }));
    }

    public getCurrentYear(): Observable<number> {
        const requestUrl = environment.API_URL + '/currentYear';
        const httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(requestUrl, httpOptions).pipe(
            map(
                (data: any) => {
                    return data.currentYear;
                }));
    }
}
