import {Component, ViewChild} from '@angular/core';

import {BsModalComponent} from 'ng2-bs3-modal';

import {DashboardConst, Product} from '../../models/index';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'barcode-modal',
  styleUrls: ['./barcode-modal.less'],
  template: `
<div class="BarcodeModal">
    <bs-modal class="barcodeModal" [keyboard]="false" [backdrop]="'static'"
           [size]="'lg'" #barcodeModal>
        <form (ngSubmit)="submitForm()" #formBarcode="ngForm" ngNativeValidate>
            <bs-modal-header [showDismiss]="true">
                <div class="modal-title">Créer le codes-barres</div>
                <ul class="barcodeModal-nav">
                    <li *ngFor="let category of categories"
                        class="barcodeModal-nav-item"
                        [ngClass]="{'active': category.id === activeCategory.id}">

                        <button type="button" (click)="changeCategory(category)">{{category.libelle}}</button>
                    </li>
                </ul>
            </bs-modal-header>
            <bs-modal-body>

                <!-- Image form-->
                <div class="container-fluid">
                    <barcode-modal-form-img *ngIf="activeCategory.id === 0" [(codeWidth)]="codeWidth" [imgPreviewUrl]="imgPreviewUrl"></barcode-modal-form-img>
                    <barcode-modal-form-vecto *ngIf="activeCategory.id === 1" [(format)]="activeCategory.format"></barcode-modal-form-vecto>
                </div>
            </bs-modal-body>
            <bs-modal-footer [showDefaultButtons]="false">
                <button type="submit" class="barcodeModal-submitButton">
                    <img src="assets/icons/generateCodeWhite.svg" />Télécharger le code-barres
                </button>
            </bs-modal-footer>
        </form>
    </bs-modal>
</div>
`
})
export class BarcodeModal {
  @ViewChild('barcodeModal', {static: true})
  public modal: BsModalComponent;
  @ViewChild('formBarcode', {static: true}) public form: any;
  public constants = DashboardConst;
  public categories = [
    {id: 0, libelle: this.constants.generateForm.categoriesLibelles.img, format: 'png'},
    {id: 1, libelle: this.constants.generateForm.categoriesLibelles.vecto, format: 'eps'}
  ];
  public activeCategory = this.categories[0];
  public codeWidth: number = 40;
  public imgPreviewUrl: string;
  public product: Product;

  constructor() {}

  public changeCategory(category: any) {
    this.activeCategory = category;
  }

  public openModal(product: Product) {
    this.product = product;
    this.imgPreviewUrl = environment.API_URL + '/barcode/' + product.gtin;
    this.modal.open();
  }

  public submitForm() {
    const format = this.activeCategory.format;
    const name = _.isEmpty(this.product.productNames) ? this.product.name : _.get(_.first(this.product.productNames), 'value', '');
    const productName = this.replaceSpecialCharsWith_(name);
    const filename = `${this.product.gtin}_${productName}`;

    const link = document.createElement('a');

    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.href = `${this.imgPreviewUrl}?mime=${format}&width=${this.codeWidth}&filename=${filename}`;
    link.click();
    link.remove();
  }

  public replaceSpecialCharsWith_(filename: string) {
    return filename.replace(/[<>%:"'\/\\|?*\s]+/gi, '_');
  }
}
