export class Gtin {
  constructor(
    public prefix: string,
    public cip: string,
    public key: string,
    public available: boolean,
    public isGtin8: boolean = false,
    public cipSize?: number
  ) {
    this.cipSize = this.isGtin8 ? null : 12 - this.prefix.length;
  }

  public toString() {
    if (this.isGtin8) {
      return this.prefix;
    }
    return this.prefix + this.cip + this.key;
  }
}
