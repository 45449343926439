import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qr-experience-vcs-dialog',
  templateUrl: './qr-experience-modal.component.html',
  styleUrls: ['./qr-experience-modal.component.less']
})
export class QrExperienceModalVCS {
  public step = 1;
  constructor() { }

  public goNextStep(){
    this.step++;
  }
  public goPrevStep(){
    this.step--;
  }

}
