import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'fileSize'})
export class FileSize implements PipeTransform {
  public transform(bytes: number, precision: number = 1): any {
    if (!isFinite(bytes)) { return '?'; }
    if (typeof precision === 'undefined') { precision = 1; }
    const units = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po'];
    const fileSize = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(fileSize))).toFixed(precision) + ' ' + units[fileSize];
  }
}
