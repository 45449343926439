import {Component, ViewChild, ElementRef, Input, OnInit, OnDestroy, AfterViewChecked} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationModal } from '../../../../shared/components/index';
import {NotificationsSvc, CompanySvc} from '../../../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../../../services/index';
import {FormInputError} from '../../../models/formInputError';
import {DashboardConst} from '../../../models/dashboard.constants';
import URL from '../../../../shared/utils/url';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {VintageSvc} from '../../../../shared/services/vintages.service';
import GtinUtils from '../../../../shared/utils/gtin';
import {FoodEditionUnityFormModel} from '../../../models/foodEditionUnityForm.model';
import {NetContent} from '../../../../dashboard/models/NetContent.model';
import {NetContentConst} from '../../../../dashboard/models/netContent.constants';
import {Nutriment} from '../../../../dashboard/models/nutriment.model';
import {NutrimentItem} from '../../../../dashboard/models/nutrimentItem.model';
import {AllergenFoodModel} from '../../../models/allergenFood.model';
import {PackagingMarkedLabelAccreditationCode} from '../../../models/packagingMarkedLabelAccreditationCode.constants';
import {Countries} from '../../../models/country.constants';
import {StructuredAddress} from '../../../../dashboard/models/StructuredAddress.model';
import {optionalNutrimentItemsConst} from '../../../models';
import {ImageUrl} from '../../../../dashboard/models/ImageUrl.model';
import {Observable, Subscription} from 'rxjs';
import {DashboardHeaderSvc} from '../../../services/dashboard-header.service';
import * as _ from 'lodash';
import {environment} from 'src/environments/environment';

enum ImageUploader {
  DRAG_DROP,
  URL,
  NOT_SELECTED
}


@Component({
  selector: 'unity-consumer-edition-food',
  styleUrls: ['./unity-consumer-edition-food.less'],
  templateUrl: './unity-consumer-edition-food.tpl.html'
})
export class UnityConsumerEditionFoodComponent implements AfterViewChecked, OnInit, OnDestroy {
  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;
  @ViewChild('formProduct', {static: true})
  public form: any;
  public webLinkError: FormInputError;
  @Input() public product: any;
  @Input() public unityForm: FoodEditionUnityFormModel | any;
  public measurementUnitsList: Array<{}>[] = new Array();
  public canAddNetContent: boolean = true;
  public packagingMarkedLabelAccreditationCodeConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 50
  });
  public packagingMarkedLabelAccreditationCodeOptions: any = PackagingMarkedLabelAccreditationCode;
  public countryOfOriginConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input countryOfOrigin-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 15
  });
  public countryOfOriginOptions: any = Countries;
  public countryCodeConfig: any = Object.assign({}, {
    openOnFocus: false,
    highlight: false,
    create: false,
    persist: true,
    dropdownDirection: 'down',
    plugins: ['remove_button'],
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input countryCode-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 1
  });
  public canChangeDryWeight: boolean = false;
  public canChangeAlcoholPerVolume: boolean = false;
  public canSeeOptionalNutriment: boolean = false;
  public alternativeImageUrl: string = '';
  public barcodeUrl: string = '';

  public imageUploader: ImageUploader = ImageUploader.NOT_SELECTED;
  public ImageUploader: typeof ImageUploader = ImageUploader;

  public activeNutriScore: boolean = false;
  public showAddNutriment: boolean = true;
  public optionalNutrimentItems = optionalNutrimentItemsConst;
  public selectedNutriment: any;
  public optionalColumnHeight: number;
  public dailyValueIntakeReferenceEditable: boolean = false;
  public isSubmitting = false;
  public ingredientPlaceholder: string = '';
  @Input() public submitEvent: Observable<void>;

  public submitEventSubscription: Subscription;
  public NetContentConstants = NetContentConst.all;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public featureToggle: FeatureToggleSvc,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              private notificationsSvc: NotificationsSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              private elRef: ElementRef,
              private headerSvc: DashboardHeaderSvc) {
    this.resetWebLinkError();
  }

  public ngOnDestroy() {
    if (this.submitEventSubscription) {
      this.submitEventSubscription.unsubscribe();
    }
  }

  public ngOnInit() {
    // subscribe to router event
    this.headerSvc.setHeaderData('Modification fiche produit', 'assets/icons/editCode.svg');
    this.submitEventSubscription = this.submitEvent.subscribe(() => this.submitForm());
    this.unityForm.id = this.product.id;
    this.changeAdhereSectoralValueBasedOnMandatoryAtt();

        this.unityForm.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
        this.unityForm.productForm.alcoholDegree = !_.isNil(this.product.alcoholDegree) ? `${this.product.alcoholDegree}`.replace('.', ',') : null;
        const {streetAddress, postalCode, countryCode, city} = this.product.structuredAddress || {
            streetAddress: '',
            postalCode: '',
            countryCode: '',
            city: ''
        };
        this.unityForm.productForm.structuredAddress = new StructuredAddress(streetAddress || '', postalCode || '', countryCode || '', city || '');
        this.initNetContents();
        this.initNutriment();
        this.initAllergenFood();
        this.initingredientStatement();
        this.initImages();
        this.canSeeOptionalNutriment = this.unityForm.productForm.nutriment.servingSizeDescription ? true : false;

    if (this.unityForm.productForm.drainedWeight.quantityContained) {
      this.canChangeDryWeight = true;
    }

    if (this.unityForm.productForm.alcoholDegree) {
      this.canChangeAlcoholPerVolume = true;
    }

    if (this.unityForm.productForm.nutriScore && this.unityForm.productForm.nutriScore.length) {
      this.activeNutriScore = true;
      // this.selectNutriscore(this.unityForm.productForm.nutriScore);
    }
    if (this.unityForm.productForm.ingredientStatement.length !== 0) {
      this.ingredientPlaceholder = this.unityForm.productForm.ingredientStatement;
    }
    this.getAddedNutriments();

    // just to display target market label
    // Saving values that should not be changed
    this.unityForm.initialBrand = this.product.brand;
    this.unityForm.initialName = this.product.name;
    if (_.isUndefined(this.product.codeGpc) || _.isEmpty(this.product.codeGpc)) {
      this.unityForm.productForm.codeGpc = '0';
    }
    this.barcodeUrl = `${environment.API_URL}/barcode/${this.unityForm.productForm.gtin}`;

  }


  public ngAfterViewChecked() {
    this.makeCountryOfOriginElementRequired();
    this.makeCountryCodeElementRequired();
  }

  public editDailyValueIntakeReference() {
    this.dailyValueIntakeReferenceEditable = true;
  }

  public validateURL() {
    this.resetWebLinkError();
    this.unityForm.productForm.webLink = this.unityForm.productForm.webLink.toLowerCase();
    if (this.unityForm.productForm.webLink) {
      if (URL.isNotPrefixedWithProtocol(this.unityForm.productForm.webLink)) {
        this.unityForm.productForm.webLink = 'http://' + this.unityForm.productForm.webLink;
      }

      if (URL.isValidURL(this.unityForm.productForm.webLink)) {
        this.webLinkError = new FormInputError('valid', '');
      } else {
        this.webLinkError = new FormInputError('warning', DashboardConst.editForm.errors.webLinkInvalid);
      }

    }
  }

  public checkValue(event: { charCode: number; }) {
    return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 44;
  }

  public checkEnergeticValue(item: { measurementUnitCode: string; }, key: any) {
    const energyKeys = ['KJO', 'E14'];
    const indexKey = energyKeys.indexOf(item.measurementUnitCode);
    if (indexKey >= 0) {
      this.manageEnergeticValues(item, energyKeys[1 - indexKey], key);
    }
  }

  public manageEnergeticValues(item: { [x: string]: any; }, type: string, key: string | number) {
    if (item[key]) {
      let value = item[key];
      let otherValue: any = this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key];

      // value: , => .
      value = value.replace(',', '.');

      otherValue = '' + (
        type === 'E14' ?
          (parseFloat(value) * 0.239006) :
          (parseFloat(value) / 0.239006)
      ).toFixed(1);

      // otherValue: . => ,
      otherValue = otherValue.replace('.', ',');

      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = otherValue;
    } else {
      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = '';
    }
  }


  public calculateDailyValues(item: any) {
    const dailyReferences: any = {
      'KJO': 8400,
      'E14': 2000,
      'FAT': 70,
      'PRO-': 50,
      'SALTEQ': 6,
      'CHOAVL': 260,
      'SUGAR-': 90,
      'FASAT': 20
    };

    // the first two nutriments (kilojoule and kilocalories) have the same nutriment type code, so I used their measurement unit code in the dailyReferences object
    const key = item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode;
    if (_.isEmpty(item.optionalQuantityContained)) {
      item.dailyValueIntakeReference = null;
      return;
    }

    if (_.isNil(dailyReferences[key])) {
      return;
    }
    // the value to set
    let valueToSet = '';
    if (item.optionalQuantityContained && item.optionalQuantityContained.length) {
      valueToSet = '' + ((parseFloat(item.optionalQuantityContained) / dailyReferences[key]) * 100).toFixed(1);
      valueToSet = valueToSet.replace('.', ',');
    }
    // in the array for the brought references, and for the energetic values, we only use the first item (the one with KJO unit)
    if (key !== 'E14') {
      item.dailyValueIntakeReference = valueToSet;
    } else {
      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === 'KJO').dailyValueIntakeReference = valueToSet;
    }
  }

  public calculateAllDailyValues() {
    this.unityForm.productForm.nutriment.items.forEach((item: any) => {
      this.calculateDailyValues(item);
    });
  }

  public ngAfterViewInit() {
    // this.form.control.valueChanges
    //     .subscribe(() => {
    //         this.unityForm.productForm.completionLevel = this.productsSvc.getLevelCompletion(this.unityForm.productForm);
    //     });
  }

  public onChangeServingSizeDescription() {
    if (!this.unityForm.productForm.nutriment.servingSizeDescription || this.unityForm.productForm.nutriment.servingSizeDescription.length === 0) {
      this.unityForm.productForm.nutriment.servingSizeDescription = null;
      this.unityForm.productForm.nutriment.items.forEach((item: any) => {
        item.optionalQuantityContained = null;
      });
    }
  }

  public removeNetContent(netContent: any, index: number) {
    this.unityForm.productForm.netContents = this.unityForm.productForm.netContents.filter((item: any) => {
      return netContent !== item;
    });

    this.measurementUnitsList = this.measurementUnitsList.filter((item: any, i) => {
      return index !== i;
    });

    this.updateMeasurementUnitList();
  }

  public addNetContent() {
    this.unityForm.productForm.netContents.push(new NetContent());
    this.measurementUnitsList.push(this.NetContentConstants);
    this.updateMeasurementUnitList();
  }

  public onChangeMeasurementUnit() {
    this.updateMeasurementUnitList();
  }

  public submitForm() {
    this.unityForm.productForm.productNames = _.filter(this.unityForm.productForm.productNames, (productName) => !_.isEmpty(productName.value) && !_.isEmpty(productName.lang));
    if (!this.unityForm.productForm.adhereToTheSectoralAgreement) {
      this.unityForm.resetFormProductWitBaseAttribute();
      return this.unityForm.submitForm();
    }
    if (!this.checkErrorsInQuantities()) {
      this.notificationsSvc.wrongNumberOfDecimalsDigits();
      return false;
    }
    if (this.unityForm.productForm.preparationStateCode === 'PREPARED' && _.isEmpty(_.trim(this.unityForm.productForm.preparationInstructions))) {
      return false;
    }

    if (!this.isValidFatNutriments()) {
      this.notificationsSvc.wrongFoodNutriment();
      return false;
    }

    if (!this.checkSugar()) {
      return false;
    }

    if (this.checkNetContents() && this.isValidAlcoholDegree() && this.checkAllergen() && this.checkDrainedWeight() && this.checkNutriscore()) {
      if (!this.canSeeOptionalNutriment) {
        this.cleanNutrimentAttribute();
      }
      this.unityForm.productForm.netContents = _.filter(this.unityForm.productForm.netContents, (netContent) => netContent.netContent && netContent.measurementUnitCode);
      this.unityForm.productForm.ingredientStatement = !_.isEmpty(this.unityForm.productForm.ingredientStatement) ? 'Ingrédients : ' + this.unityForm.productForm.ingredientStatement : this.unityForm.productForm.ingredientStatement;
      this.unityForm.productForm.nutriScore = this.activeNutriScore ? this.unityForm.productForm.nutriScore : null;
      return this.unityForm.submitForm();
    }
    return false;
  }


  public isRequiredStructuredAddress(): boolean {
    const keys = Object.keys(this.unityForm.productForm.structuredAddress);
    for (const key of keys) {
      if (!_.isEmpty(this.unityForm.productForm.structuredAddress[key])) {
        return true;
      }
    }
    return false;
  }

  public updateMeasurementUnitList() {
    let hasAtLeastOneValidItem = false;
    const usedMeasurementUnitList = _.map(this.unityForm.productForm.netContents, 'measurementUnitCode');
    const usedMeasurementCategoriesList = _.reduce(this.NetContentConstants, (categories: any, item: any) => {
      if (usedMeasurementUnitList.indexOf(item.code) >= 0) {
        categories[item.category] = true;
      }
      return categories;
    }, {});
    for (let i = 0; i < this.unityForm.productForm.netContents.length; i++) {
      const selectedNetContent = this.unityForm.productForm.netContents[i];
      const selectedCategory: any = _.find(this.NetContentConstants, {code: selectedNetContent.measurementUnitCode}) || {};
      this.measurementUnitsList[i] = this.NetContentConstants.filter((item: any) => {
        return !usedMeasurementCategoriesList[item.category] || item.category === selectedCategory.category;
      });

      if (!selectedNetContent.isEmpty()) {
        hasAtLeastOneValidItem = true;
      }
    }
    this.makeNetContentRequired(!hasAtLeastOneValidItem);
    this.canAddNetContent = this.unityForm.productForm.netContents.length < 5;
  }

  public makeNetContentRequired(state: boolean) {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('#net-content-item-0');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      const selectElement: HTMLSelectElement = divElement.querySelector('select');
      inputElement.required = state;
      selectElement.required = state;
    }
  }

  public isValidAlcoholDegree() {
    if (this.unityForm.productForm.alcoholDegree !== '' && !_.isNil(this.unityForm.productForm.alcoholDegree)) {
      const decimalRegex = new RegExp(this.unityForm.DECIMAL_PATTERN);
      return decimalRegex.test(this.unityForm.productForm.alcoholDegree);
    }
    return true;
  }

  public checkAllergen(): boolean {
    for (const allergen of this.unityForm.productForm.allergensFood) {
      if (!this.isValidAllergenValues(allergen.allergenTypeCode, allergen.levelOfContainmentCode)) {
        this.notificationsSvc.wrongFoodAllergen();
        return false;
      }
    }
    return true;
  }

  public isValidAllergenValues(allergenTypeCode: string, levelOfContainmentCode: string): boolean {
    const coqFruitsObj = _.find(this.unityForm.productForm.allergensFood, (obj) => obj.allergenTypeCode === 'AN');
    const allergenFoodOptionalCodes = _.map(this.unityForm.optionalAllergenFoodItems, allergen => allergen.code);
    if (allergenFoodOptionalCodes.indexOf(allergenTypeCode) !== -1) {
      if (coqFruitsObj.levelOfContainmentCode !== 'CONTAINS' && levelOfContainmentCode === 'CONTAINS') {
        return false;
      }
    }
    if (coqFruitsObj.allergenTypeCode === allergenTypeCode) {
      let total = 0;
      allergenFoodOptionalCodes.forEach(code => {
        total += this.unityForm.productForm.allergensFood.reduce((count: number, element: { allergenTypeCode: any; levelOfContainmentCode: string; }) => {
          return element.allergenTypeCode === code && element.levelOfContainmentCode === 'CONTAINS' ? count + 1 : count;
        }, 0);
      });
      if (coqFruitsObj.levelOfContainmentCode !== 'CONTAINS' && total !== 0) {
        return false;
      }
    }
    return true;
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

  public hasErrorDrainedWeight(): boolean {
    const DRAINED_WEIGHT_PATTERN = '^([0-9]{1,}(,\\d{1,2})?)$';
    if (this.unityForm.productForm.drainedWeight.quantityContained) {
      const decimalRegex = new RegExp(DRAINED_WEIGHT_PATTERN);
      if (!decimalRegex.test(this.unityForm.productForm.drainedWeight.quantityContained)) {
        return true;
      }
    }
    return false;
  }

  public showDryWeight() {
    if (this.canChangeDryWeight) {
      this.canChangeDryWeight = false;
      this.unityForm.productForm.drainedWeight = {};
    } else {
      this.canChangeDryWeight = true;
    }
  }

  public showAlcoholPerVolume() {
    if (this.canChangeAlcoholPerVolume) {
      this.canChangeAlcoholPerVolume = false;
      this.unityForm.productForm.alcoholDegree = '';
    } else {
      this.canChangeAlcoholPerVolume = true;
    }
  }

  public showOptionalNutriment() {
    this.canSeeOptionalNutriment = true;
  }

  public showDailyValueIntakeReferenceMessage(): boolean {
    if (_.isEmpty(this.unityForm.productForm.nutriment.servingSizeDescription)) {
      this.resetDailyValueIntakeReferenceAndOptionalQuantityContained();
    }
    return !_.isEmpty(this.unityForm.productForm.nutriment.servingSizeDescription);

  }

  public isRequiredDailyValueIntakeReference(): boolean {
    let isRequired = false;
    if (!_.isEmpty(this.unityForm.productForm.nutriment.servingSizeDescription)) {
      const dailyValueIntakeReferences = _.map(this.unityForm.productForm.nutriment.items, item => item.dailyValueIntakeReference) || [];
      for (const dailyValueIntakeReference of dailyValueIntakeReferences) {
        if (!_.isEmpty(dailyValueIntakeReference)) {
          isRequired = true;
          break;
        }
      }
    }
    return isRequired;
  }

  public checkFileUploadValidity(files: any[]) {
    if (files.length > 0) {
      this.imageUploader = ImageUploader.DRAG_DROP;
      this.alternativeImageUrl = '';
      const textContainer = this.elRef.nativeElement.querySelector('.textContainer');
      textContainer.setAttribute('hidden', '');
    } else {
      this.imageUploader = ImageUploader.NOT_SELECTED;
    }
  }

  public fileUploadImageChange() {
    const keptImages = _.filter(this.unityForm.productForm.images, ['keep', true]);

    if (keptImages.length === 0) {
      this.alternativeImageUrl = '';
      this.imageUploader = ImageUploader.NOT_SELECTED;
    }
  }

  public checkImageValidity() {
    this.checkImageType(this.alternativeImageUrl)
      .then(() => this.checkImageUrl(this.alternativeImageUrl, 5000))
      .then(() => {
        this.imageUploader = ImageUploader.URL;
        this.unityForm.productForm.images = [{url: this.alternativeImageUrl, keep: true}];
      })
      .catch(() => {
        this.imageUploader = ImageUploader.NOT_SELECTED;
        this.unityForm.productForm.images = [];
        if (this.alternativeImageUrl !== '') {
          this.notificationsSvc.invalidImageUrl();
        }
        this.alternativeImageUrl = '';
      });
    const textContainer = this.elRef.nativeElement.querySelector('.textContainer');
    textContainer.setAttribute('hidden', '');
  }

  public hasNutriScore() {
    this.activeNutriScore = !this.activeNutriScore;
  }

  public selectNutriscore(nsValue: any) {
    if (this.activeNutriScore) {
      this.unityForm.productForm.nutriScore = nsValue;
    }
  }

  public checkNutriscore() {
    if (this.activeNutriScore && this.unityForm.productForm.nutriScore.length === 0) {
      this.notificationsSvc.wrongNutriscore();
      return false;
    }
    return true;
  }

  public resetNutrimentClass(item: NutrimentItem) {
    const selector = item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode;
    const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#quantityContained_${selector}`);
    if (element) {
      element.className = element.className.replace('inputError', '');
    }
  }

  public checkSugar() {
    const glucidesItem = this.unityForm.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'CHOAVL');
    const sugarItem = this.unityForm.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'SUGAR-');
    const keysToCheck = ['quantityContained'];         // , ['optionalQuantityContained', 'dailyValueIntakeReference']
    let checker = false;

    for (const key of keysToCheck) {
      // const key = keysToCheck[i];
      const glucidesValue = parseFloat(glucidesItem[key].replace(',', '.'));
      const sugarValue = parseFloat(sugarItem[key].replace(',', '.'));
      checker = checker || (sugarValue > glucidesValue);
      if (sugarValue > glucidesValue) {
        const sugarElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_SUGAR-`);
        const glucidesElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_CHOAVL`);
        sugarElement.className += ' error';
        glucidesElement.className += ' error';
        sugarElement.scrollIntoView({
          block: 'center'
        });
      }else{
        const sugarElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_SUGAR-`);
        const glucidesElement: HTMLInputElement = this.elRef.nativeElement.querySelector(`#${key}_CHOAVL`);
        sugarElement.className += ' specificWidth';
        glucidesElement.className += ' specificWidth';
      }
    }

    if (checker) {
      this.notificationsSvc.sugarSupToCarbohydrates();
      return false;
    }
    return true;
  }

  public addNutriment() {
    this.showAddNutriment = false;
  }

  public removeNutriment(nutriment: NutrimentItem, index: number) {
    const itemRemoved = _.filter(this.unityForm.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === nutriment.nutrientTypeCode)[0] || {};
    this.unityForm.requiredNutrimentItems = _.filter(this.unityForm.requiredNutrimentItems, (nutrimentItem: NutrimentItem) => nutrimentItem.nutrientTypeCode !== nutriment.nutrientTypeCode);
    this.optionalNutrimentItems.push(itemRemoved);
    this.unityForm.productForm.nutriment.items = this.unityForm.productForm.nutriment.items.filter((item: NutrimentItem) => {
      return nutriment !== item;
    });
    this.selectedNutriment = null;
    this.optionalColumnHeight -= 60;
  }

  public selectNutriment(element: HTMLDivElement) {
    this.showAddNutriment = true;
    this.unityForm.requiredNutrimentItems.push(this.selectedNutriment);
    this.optionalNutrimentItems = _.filter(this.optionalNutrimentItems, (nutrimentItem) => nutrimentItem.code !== this.selectedNutriment.code);
    const nutriment = new NutrimentItem(this.selectedNutriment.code, 'APPROXIMATELY', null, null, this.selectedNutriment.measurementUnitCode, null, false);
    this.unityForm.productForm.nutriment.items.push(nutriment);
    this.optionalColumnHeight = element.offsetHeight + 60;
  }

  public hideOptionalNutriment() {
    this.canSeeOptionalNutriment = false;
  }

  public updateTextArea(editableContent: HTMLDivElement, ingredientStatement: HTMLTextAreaElement) {
    this.unityForm.productForm.ingredientStatement = editableContent.textContent;
  }

  private checkImageType(url: string) {
    return new Promise<void>((resolve, reject) => {
      url.match(/\.(jpeg|jpg|png)$/) != null ? resolve() : reject();
    });
  }

  private checkImageUrl(url: string, timeToWait: number) {
    return new Promise(function(resolve, reject) {
      const timeout = timeToWait || 5000;
      const img = new Image();
      let timer: any;
      img.onerror = img.onabort = function() {
        clearTimeout(timer);
        reject('error');
      };
      img.onload = function() {
        clearTimeout(timer);
        resolve('success');
      };
      timer = setTimeout(function() {
        img.src = '//!!!!/noexist.jpg';
        reject('timeout');
      }, timeout);
      img.src = url;
    });
  }

  private checkNetContents() {
    for (const currentNetContent of this.unityForm.productForm.netContents) {
      if (currentNetContent.hasError()) {
        return false;
      }
    }
    return true;
  }

  private initNetContents() {
    if (_.isEmpty(this.unityForm.productForm.netContents)) {
      this.unityForm.productForm.netContents = [new NetContent('', '')];
    } else {
      this.unityForm.productForm.netContents = _.map(this.unityForm.productForm.netContents, ({
                                                                                                netContent,
                                                                                                measurementUnitCode
                                                                                              }) => {
        const netContentStr = `${netContent}`.replace('.', ',');
        return new NetContent(netContentStr, measurementUnitCode);
      });
    }

    this.measurementUnitsList = new Array(this.unityForm.productForm.netContents.length).fill(this.NetContentConstants);

    this.updateMeasurementUnitList();
  }

  private initImages() {
    if (this.unityForm.productForm.imagesAddedByUrl.length === 0) {
      this.unityForm.productForm.imagesAddedByUrl.push(new ImageUrl(''));
    }
    this.unityForm.productForm.imagesAddedByUrl = _.map(this.unityForm.productForm.imagesAddedByUrl, ({url}) => {
      return new ImageUrl(url);
    });
  }

  private initNutriment() {
    const {servingSize, measurementUnitCode, servingSizeDescription, items} = this.unityForm.productForm.nutriment;

    const builtItems = _.map(items, (item: NutrimentItem) => {
      const requiredItem = _.filter(this.unityForm.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === item.nutrientTypeCode)[0] || {};
      const isRequired = requiredItem.isRequired ? requiredItem.isRequired : false;
      return new NutrimentItem(item.nutrientTypeCode, item.measurementPrecisionCode,
        this.productsSvc.replaceDotByCommaInString(item.quantityContained),
        this.productsSvc.replaceDotByCommaInString(item.optionalQuantityContained), item.measurementUnitCode,
        this.productsSvc.replaceDotByCommaInString(item.dailyValueIntakeReference), isRequired);
    });

    this.unityForm.productForm.nutriment = new Nutriment(servingSize, measurementUnitCode, servingSizeDescription, builtItems);
  }

  private makeCountryCodeElementRequired() {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.countryCode-input');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      if (_.isEmpty(this.unityForm.productForm.structuredAddress.countryCode) && !_.isEmpty(this.unityForm.productForm.structuredAddress.streetAddress)) {
        inputElement.required = true;
        return;
      }

      if (_.isEmpty(this.unityForm.productForm.structuredAddress.countryCode) && !_.isEmpty(this.unityForm.productForm.structuredAddress.postalCode)) {
        inputElement.required = true;
        return;
      }

      if (_.isEmpty(this.unityForm.productForm.structuredAddress.countryCode) && !_.isEmpty(this.unityForm.productForm.structuredAddress.city)) {
        inputElement.required = true;
        return;
      }
      inputElement.required = false;
    }
  }

  private initAllergenFood() {
    this.unityForm.productForm.allergensFood = _.map(this.unityForm.productForm.allergensFood, ({
                                                                                                  allergenTypeCode,
                                                                                                  levelOfContainmentCode
                                                                                                }) => {
      return new AllergenFoodModel(allergenTypeCode, levelOfContainmentCode);
    });
    const coqFruitsObj: AllergenFoodModel = _.find(this.unityForm.productForm.allergensFood, (obj) => obj.allergenTypeCode === 'AN') || {};
    const allergenFoodOptionalCodes = _.map(this.unityForm.optionalAllergenFoodItems, allergen => allergen.code);
    for (const code of allergenFoodOptionalCodes) {
      const optionalObj: AllergenFoodModel = _.find(this.unityForm.productForm.allergensFood, (obj) => obj.allergenTypeCode === code) || {};
      this.unityForm.opticalSpecifiedAllergen.push(new AllergenFoodModel(code, optionalObj.levelOfContainmentCode ? optionalObj.levelOfContainmentCode : ''));
      _.remove(this.unityForm.productForm.allergensFood, (obj: any) => obj.allergenTypeCode === code);
    }
    const authorizedLevelOfContainmentCodes = ['CONTAINS', 'MAY_CONTAIN'];
    if (authorizedLevelOfContainmentCodes.indexOf(coqFruitsObj.levelOfContainmentCode) !== -1) {
      this.unityForm.addOrRemoveOptionalAllergen(coqFruitsObj.allergenTypeCode, coqFruitsObj.levelOfContainmentCode);
    }
  }

  private initingredientStatement() {
    const firstPattern = 'Ingrédient :'.toUpperCase();
    const secondPattern = 'Ingrédients :'.toUpperCase();
    const firstIndex = this.unityForm.productForm.ingredientStatement.toUpperCase().indexOf(firstPattern);
    const secondIndex = this.unityForm.productForm.ingredientStatement.toUpperCase().indexOf(secondPattern);

    if (firstIndex !== -1) {
      this.unityForm.productForm.ingredientStatement = this.unityForm.productForm.ingredientStatement.slice(firstPattern.length, this.unityForm.productForm.ingredientStatement.length).trim();
    } else if (secondIndex !== -1) {
      this.unityForm.productForm.ingredientStatement = this.unityForm.productForm.ingredientStatement.slice(secondPattern.length, this.unityForm.productForm.ingredientStatement.length).trim();
    }
  }

  private getAddedNutriments() {
    if (this.unityForm.productForm.nutriment.items.length > 8) {
      const el = this.elRef.nativeElement.querySelector('.optionalColumn');
      if (el) {
        const baseHeight = el.offsetHeight;
        this.optionalColumnHeight = baseHeight;
        for (let i = 0; (i + 8) < this.unityForm.productForm.nutriment.items.length; i++) {
          this.optionalColumnHeight += 60;
        }
      }

    }
  }

  private resetWebLinkError() {
    this.webLinkError = new FormInputError('', '');
  }

  private makeCountryOfOriginElementRequired() {
    const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.countryOfOrigin-input');
    if (divElement) {
      const inputElement: HTMLInputElement = divElement.querySelector('input');
      if (!this.unityForm.productForm.countryOfOrigin || this.unityForm.productForm.countryOfOrigin.length === 0)
        inputElement.required = true;
      else
        inputElement.required = false;

    }
  }

  private checkDrainedWeight(): boolean {
    if (this.hasErrorDrainedWeight()) {
      this.notificationsSvc.drainedWeightError();
      return false;
    }

    if (this.unityForm.productForm.drainedWeight.quantityContained && _.isEmpty(this.unityForm.productForm.drainedWeight.measurementUnitCode)) {
      this.notificationsSvc.drainedWeightError();
      return false;
    }

    if (this.unityForm.productForm.drainedWeight.measurementUnitCode && _.isEmpty(this.unityForm.productForm.drainedWeight.quantityContained)) {
      this.notificationsSvc.drainedWeightError();
      return false;
    }
    return true;
  }

  private resetDailyValueIntakeReferenceAndOptionalQuantityContained() {
    this.unityForm.productForm.nutriment.items.forEach((item: { dailyValueIntakeReference: any; optionalQuantityContained: any; }) => {
      item.dailyValueIntakeReference = null;
      item.optionalQuantityContained = null;
    });
  }

  private isValidFatNutriments(): boolean {
    const FASATNutriments = _.filter(this.unityForm.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FASAT');
    const FATNutriments = _.filter(this.unityForm.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FAT');
    const FASATNutriment: NutrimentItem = FASATNutriments[0];
    const FATNutriment: NutrimentItem = FATNutriments[0];
    const toReturn = parseFloat(FASATNutriment.quantityContained.replace(/,/g, '.')) <= parseFloat(FATNutriment.quantityContained.replace(/,/g, '.'));
    const fasatElement: HTMLElement = document.getElementById('quantityContained_FASAT');
    const fatElement: HTMLElement = document.getElementById('quantityContained_FAT');
    if (fasatElement && fatElement) {
      fasatElement.className = 'specificWidth';
      fatElement.className = 'specificWidth';
    }

    if (!toReturn) {
      fasatElement.className = 'specificWidth error';
      fatElement.className = 'specificWidth error';
    }
    return toReturn;
  }

  private cleanNutrimentAttribute() {
    this.unityForm.productForm.nutriment.servingSizeDescription = null;
    this.unityForm.productForm.nutriment.dailyValueIntakeReference = null;
    this.unityForm.productForm.dailyValueIntakeReference = null;
    this.unityForm.productForm.nutriment.items.forEach((item: NutrimentItem) => {
      item.dailyValueIntakeReference = null;
      item.optionalQuantityContained = null;
    });
  }

  private checkErrorsInQuantities(): boolean {
    let toReturn = true;
    this.unityForm.productForm.nutriment.items.forEach((i: { hasErrorQuantityContained: () => any; nutrientTypeCode: string; measurementUnitCode: any; }) => {
      toReturn = toReturn && !i.hasErrorQuantityContained();
      if (i.hasErrorQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#quantityContained_${selector}`);
        element.className += ' inputError';
      }
    });
    return toReturn;
  }

  /**
   * we use this method to update the value of adhereToTheSectoralAgreement attribute
   * to avoid changing the value of adhereToTheSectoralAgreement when
   * the user creates a product with all food attributes and ofter he makes an import food
   * Nowadays, when the user makes an import food the adhereToTheSectoralAgreement attribute is set to false
   */
  private changeAdhereSectoralValueBasedOnMandatoryAtt() {
    this.unityForm.productForm.adhereToTheSectoralAgreement = _.isEmpty(this.unityForm.productForm.regulatedProductName) ? this.unityForm.productForm.adhereToTheSectoralAgreement : true;
  }
}
