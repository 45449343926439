import {Gtin} from '../../shared/models/gtin.model';
import {FormInputError} from './formInputError';
import {Allergen} from './Allergen.model';
import {Certifications} from './certifications.model';
import {ProductFormModel} from './productCreationForm.model';
import {DietTypes} from './dietTypes.model';
import {Nutriment} from './nutriment.model';
import {NetContent} from './NetContent.model';
import {StructuredAddress} from './StructuredAddress.model';
import {AllergenFoodModel} from './allergenFood.model';
import {DrainedWeight} from './DrainedWeight.model';
import {ImageUrl} from './ImageUrl.model';
import {ProductName} from './ProductName.model';

export class FoodProductFormModel extends ProductFormModel {
    constructor(
        public gtin: Gtin,
        public cipError: FormInputError,
        public mpn: string,
        public sku: string,
        public brand: string,
        public subBrand: string,
        public name: string,
        public productNames: ProductName[],
        public imagesToUpload: Object[],
        public description: string,
        public webLink: string,
        public completionLevel: number,
        public companyId?: number,
        public targetMarket: string[] = ['250'],
        public codeGpc: string = '',
        public isPrivate: boolean = false,
        public structuredAddress: StructuredAddress = new StructuredAddress('', '', '', ''),
        public dietTypes: DietTypes = {},
        public contactName: string = '',
        public nutriment: Nutriment = {},
        public category: string = '',
        public netContents: NetContent[] = [],
        public adhereToTheSectoralAgreement?: boolean,
        public vbGs1CompletionLevel?: number,
        public imagesAddedByUrl: ImageUrl[] = [],
        public regulatedProductName: string = '',
        public allergensFood: AllergenFoodModel[] = [],
        public alcoholDegree: string = '',
        public preparationStateCode: string = '',
        public consumerUsageInstructions: string = '',
        public consumerStorageInstructions: string = '',
        public ingredientStatement: string = '',
        public preparationInstructions: string = '',
        public packagingMarkedLabelAccreditationCode: string[] = [],
        public compulsoryAdditiveLabelInformation: string = '',
        public countryOfOrigin: string[] = [],
        public drainedWeight: DrainedWeight = {},
        public dailyValueIntakeReference: string = 'Apports de référence pour un adulte-type (8400 kj/2000 kcal)',
        public contentDescription: string = '',
        public nutriScore: string = '',
        public productResellerType?: string,
        public originGtin?: string
    ) {
        super(gtin, cipError, mpn, sku, brand, subBrand, name, productNames, imagesToUpload, description, webLink, completionLevel,
            companyId,
            targetMarket,
            codeGpc,
            isPrivate, category, netContents, adhereToTheSectoralAgreement, vbGs1CompletionLevel, imagesAddedByUrl, productResellerType);
    }
}
