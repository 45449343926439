export const Spirits = [{code: '30003062', wording: 'Applejack / Calvados'},
    {code: '30002688', wording: 'Arak / Raki / Ouzo'},
    {code: '30000210', wording: 'Armagnac'},
    {code: '30000339', wording: 'Bitters'},
    {code: '30002987', wording: 'Bourbon'},
    {code: '30003013', wording: 'Brandy'},
    {code: '30000493', wording: 'Cachaca'},
    {code: '30000512', wording: 'Calvados'},
    {code: '30000706', wording: 'Cognac'},
    {code: '30017052', wording: 'Eau-de-vie de vin'},
    {code: '30001073', wording: 'Eau-de-vie de fruit'},
    {code: '30001117', wording: 'Genever'},
    {code: '30001116', wording: 'Gin'},
    {code: '30001165', wording: 'Grappa'},
    {code: '30015720', wording: 'Korn / Kornabrand'},
    {code: '30001533', wording: 'Marc'},
    {code: '30001583', wording: 'Mezcal / Mescal'},
    {code: '30016928', wording: 'Pack Combiné'},
    {code: '30006763', wording: 'Pastis / Anis'},
    {code: '30002073', wording: 'Rhum'},
    {code: '30014834', wording: 'Sake'},
    {code: '30006762', wording: 'Sambuca'},
    {code: '30002152', wording: 'Schnapps / Aquavit'},
    {code: '30002429', wording: 'Tequila'},
    {code: '30000105', wording: 'Vodka'},
    {code: '30002607', wording: 'Whisky / Whiskey'},
    {code: '30002515', wording: 'Autre'}];
