import {Injectable} from '@angular/core';
import {Account} from '../models/index';
import {UserSvc} from '../../shared/services/user.service';
import {AuthGuard} from '../../shared/services/auth-guard';
import {HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
const Promise = require('bluebird');

@Injectable()
export class Authentication {

  constructor(private httpClient: HttpClient, private userSvc: UserSvc, private authGuard: AuthGuard) {
  }
  public ssoLogin(idToken: string, token: string, companyGuid: string): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      const requestUrl = environment.API_URL + '/auth/sso/signin';
      const body = JSON.stringify({
        idToken,
        token,
        companyGuid
      });
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        }),
        observe: 'response' as 'body'
      };
      return this.httpClient.post(requestUrl, body, httpOptions)
        .subscribe(
          (res: any) => {
            if (res.status === 204) {
              resolve(null);
              return null;
            }
            const dataReceived = res.body;
            const jwt = dataReceived.token;
            const accounts = dataReceived.accounts;
            if (jwt === 'Bad credentials') {
              reject(new Error('Bad credentials'));
              return null;
            }
            // Hack for Edge, not catching the 403
            const returnedUser = dataReceived.user;
            // sessionStorage.setItem('token', jwt);
            this.authGuard.jwt = jwt;
            this.userSvc.user.email = dataReceived.user.email;
            resolve(accounts);
            return null;
          },
          error => {
            reject(error);
            return null;
          }
        );
    });
  }

}
