export const certificationsConstants = [
    {code: 'AGRICULTURE_BIOLOGIQUE', categorie: 'certification', wording: 'Agriculture biologique'},
    {code: 'HAUTE_VALEUR_ENVIRONNEMENTALE', categorie: 'certification', wording: 'Haute Valeur Environnementale'},
    {code: 'DEMETER_LABEL', categorie: 'certification', wording: 'Demeter'},
    {code: 'EUROFEUILLE', categorie: 'certification', wording: 'Eurofeuille'},
    {code: 'TERRA_VITIS', categorie: 'certification', wording: 'Terra Vitis'},
    {code: 'VITICULTURE_DURABLE', categorie: 'certification', wording: 'Viticulturedurable'},
    {code: 'AUTRE_CERTIF', categorie: 'certification', wording: 'Champ libre'},
    {code: 'BIODYVIN', categorie: 'label', wording: 'Biodyvin'},
    {code: 'ECOCERT_CERTIFICATE', categorie: 'label', wording: 'Ecocert'},
    {code: 'NATURE_ET_PROGRES', categorie: 'label', wording: 'Nature et Progrès'},
    {code: 'ORIGINE_FRANCE_GARANTIE', categorie: 'label', wording: 'Origine France Garantie'},
    {code: 'VIN_NATUREL', categorie: 'label', wording: 'Vin naturel'},
    {code: 'AUTRE_LABEL', categorie: 'label', wording: 'Champ libre'}];
