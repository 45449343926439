export const AllergensFoodConstants = {
    requiredAllergen: [
        {
            wording: 'Crustacés et produits à base de crustacés',
            code: 'AC'
        },
        {
            wording: 'Œufs et produits à base d’œufs',
            code: 'AE'
        },
        {
            wording: 'Poissons et produits à base de poissons',
            code: 'AF'
        },
        {
            wording: 'Lait et produits à base de lait (y compris le lactose)',
            code: 'AM'
        },
        {
            wording: 'Arachides et produits à base d’arachides',
            code: 'AP'
        },
        {
            wording: 'Graines de sésame et produits à base de graines de sésame',
            code: 'AS'
        },
        {
            wording: 'Anhydride sulfureux et sulfites',
            code: 'AU'
        },
        {
            wording: 'Céréales contenant du gluten',
            code: 'AW'
        },
        {
            wording: 'Soja et produits à base de soja',
            code: 'AY'
        },
        {
            wording: 'Céleri et produits à base de céleri',
            code: 'BC'
        },
        {
            wording: 'Moutarde et produits à base de moutarde',
            code: 'BM'
        },
        {
            wording: 'Lupin et produits à base de lupin',
            code: 'NL'
        },
        {
            wording: 'Mollusques et produits à base de mollusques',
            code: 'UM'
        },
        {
            wording: 'Fruits à coque',
            code: 'AN'
        }
    ],
    optionalAllergen: [
        {
            wording: 'Amandes et produits à base d\'amandes',
            code: 'SA'
        },
        {
            wording: 'Noisettes et produits à base de noisettes',
            code: 'SH'
        },
        {
            wording: 'Noix et produits à base de noix',
            code: 'SW'
        },
        {
            wording: 'Noix de cajou et produits à base de noix de cajou',
            code: 'SC'
        },
        {
            wording: 'Noix de pécan et produits à base de noix de pécan',
            code: 'SP'
        },
        {
            wording: 'Pistaches et produits à base de pistaches',
            code: 'ST'
        },
        {
            wording: 'Noix de Macadamia et produits à base de noix de Macadamia',
            code: 'SM'
        },
        {
            wording: 'Noix de Queensland et produits à base de noix de Queensland',
            code: 'SQ'
        }
    ],
    containmentLevel: [
        {
            wording: 'Contient',
            code: 'CONTAINS'
        },
        {
            wording: 'Ne contient pas',
            code: 'FREE_FROM'
        },
        {
            wording: 'Peut contenir',
            code: 'MAY_CONTAIN'
        },
        {
            wording: 'Non déclaré',
            code: 'UNDECLARED'
        }
    ]
};
