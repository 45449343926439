import {Component, ViewChild} from '@angular/core';

import {BsModalComponent} from 'ng2-bs3-modal';

import {Product, DashboardConst} from '../../models/index';
import {UserSvc} from '../../../shared/services/index';
import {BarcodeGenerationSvc} from '../../services/index';

@Component({
  selector: 'barcode-disclaimer-modal',
  styleUrls: ['./barcode-disclaimer-modal.less'],
  template: `
<div class="BarcodeDisclaimerModal">
    <bs-modal class="disclaimerModal" [animation]="true" [keyboard]="false"
           [backdrop]="'static'" [size]="'sm'" #disclaimerModal>
        <bs-modal-body>
            <div class="BarcodeDisclaimerModal-body">
                <img class="BarcodeDisclaimerModal-body-icon" src="assets/icons/warningTriangleWhite.svg" alt="">
                <div class="BarcodeDisclaimerModal-body-title">{{constants.disclaimer.title}}</div>
                <div class="BarcodeDisclaimerModal-body-content">{{constants.disclaimer.content}}</div>
                <div class="BarcodeDisclaimerModal-body-checkbox">
                    <input type="checkbox" name="disclaimerAccepted" [(ngModel)]="disclaimerAccepted">
                    {{constants.disclaimer.checkBoxText}}
                </div>
            </div>
        </bs-modal-body>
        <bs-modal-footer class="BarcodeDisclaimerModal-footer" [showDefaultButtons]="false">
            <div class="BarcodeDisclaimerModal-footer-buttons" role="group">
                <button type="button" class="cancelButton" (click)="disclaimerModal.dismiss()">Annuler</button>
                <button type="submit" class="submitButton" [disabled]="!disclaimerAccepted" (click)="validateDisclaimer()">Valider</button>
            </div>
        </bs-modal-footer>
    </bs-modal>
</div>
  `
})
export class BarcodeDisclaimerModal {
  @ViewChild('disclaimerModal', {static: true})
  public modal: BsModalComponent;

  public constants = DashboardConst;
  public disclaimerAccepted: boolean = false;
  private product: Product;

  constructor(public userSvc: UserSvc, private barcodeSvc: BarcodeGenerationSvc) {
  }

  public openModal(product: Product) {
    this.product = product;
    this.modal.open();
  }

  public validateDisclaimer() {
    if (this.disclaimerAccepted) {
      this.userSvc.updateUser({barcodeDisclaimerAccepted: true})
        .then(() => {
          this.modal.dismiss();
          this.barcodeSvc.generateBarcode(this.product.id);
        })
        .catch((err) => console.error(err));
    }
  }
}
