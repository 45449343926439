import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {HelpCenterConstants, TopicModel} from '../../models';

@Component({
  selector: 'topic',
  styleUrls: ['./helpCenter-topicCard.less'],
  template: `
    <div class="Topic">
      <h1 class="Topic-title">{{ topic.subTitle }}</h1>
      <div class="Topic-content" [innerHTML]="topic.content"></div>
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class Topic {
  public topic: TopicModel = new TopicModel(0, '', '', '');
  public constants = HelpCenterConstants;
  private topicId: number;

  constructor(public route: ActivatedRoute) {
  }

  public ngOnInit() {
    this.route.params
      // (+) converts string 'id' to a number
      .subscribe((params: Params) => {
        this.topicId = +params['id'];
        if (this.constants.topics[this.topicId] === undefined) {
          this.topic = this.constants.topicNotFound;
          return;
        }
        this.topic = this.constants.topics[this.topicId];
        return;
      });
  }
}
