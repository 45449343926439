const NET_CONTENT_PATTERN = '^([0-9]{1,}(,\\d{1,2})?)$';
export class NetContent {
  constructor(
    public netContent?: string,
    public measurementUnitCode?: string
  ) {}

  public hasErrorNetContent() {
    if (this.netContent) {
      const decimalRegex = new RegExp(NET_CONTENT_PATTERN);
      if (!decimalRegex.test(this.netContent)) {
        return true;
      }
    }
    return false;
  }

  public hasError() {
    if (this.hasErrorNetContent()) {
      return true;
    }

    if (this.measurementUnitCode && !this.netContent || !this.measurementUnitCode && this.netContent) {
      return true;
    }
    return false;
  }

  public isEmpty() {
    return !this.netContent && !this.measurementUnitCode;
  }

  public isItemOrArticleUnits() {
    return this.measurementUnitCode === 'H87' || this.measurementUnitCode === 'EA';
  }
}

