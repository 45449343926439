export const drainedWeightMeasurementUnits = [
  {
    code: 'GRM',
    wording: 'Gramme(s)'
  },
  {
    code: 'KGM',
    wording: 'Kilogramme(s)'
  }
];
