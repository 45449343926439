import { Output, EventEmitter, Input, ElementRef, Directive } from '@angular/core';
import {BaseCreationUnityForm, DashboardConst, ProductFormModel, ProductTypes} from '../../../models';
import {ProductsSvc} from '../../../services';
import {CompanySvc, FeatureToggleSvc, NotificationsSvc, UserSvc} from '../../../../shared/services';
import {Router} from '@angular/router';
import {Classification} from '../../../../shared/models/Classification.model';

@Directive()
export class InitiatorStepComponent {

    @Input() public unityForm: any;
    @Input() public hasDrainedWeight: boolean;
    @Input() public hasAlcohol: boolean;
    @Input() public productType: ProductTypes;
    @Output() public onHasDrainedWeightChanged: EventEmitter<boolean> = new EventEmitter();
    @Output() public onHasAlcoholChanged: EventEmitter<boolean> = new EventEmitter();
    @Input() public gpcCodes: Classification[];
    @Input() public isSpi: boolean;
    @Input() public isWine: boolean;
    @Input() public isBundle: boolean;
    @Input() public submitting: boolean;
    @Output() public onNext = new EventEmitter<any>();
    @Output() public onPrevious = new EventEmitter<any>();
    @Output() public onPrefixChange: EventEmitter<any> = new EventEmitter();
    @Input() public cipLoaded: boolean;
    public constants = DashboardConst;

    constructor(public productsSvc: ProductsSvc,
                public companySvc: CompanySvc,
                public _router: Router,
                public notificationsSvc: NotificationsSvc,
                public featureToggleSvc: FeatureToggleSvc,
                public userSvc: UserSvc) {
    }

    public submitForm(submitting: boolean = true, form: HTMLFormElement = null, adhereToTheSectoralAgreement: boolean, forceToGoToLastStep: boolean = false) {
        if (form && form.checkValidity() === false) {
            return;
        }
        this.onNext.emit({submitting, adhereToTheSectoralAgreement, forceToGoToLastStep});
        return false;
    }

    public returnBack(submitting: boolean = true) {
        this.onPrevious.emit({submitting});
        return false;
    }

    public updateUser() {
        if (!this.userSvc.user.arrayTargetMarketSeen) {
            this.userSvc.updateUser({arrayTargetMarketSeen: true});
        }
    }
}
