import {
    Component, NgModule, ViewChild, ElementRef, OnInit, AfterViewInit, AfterViewChecked, Input
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TooltipDirective, TooltipModule} from 'ngx-bootstrap/tooltip';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {ConfirmationModal, FileUpload, Loader, ShareModal} from '../../../../shared/components';
import {NotificationsSvc, ProductCategorySvc, UserSvc} from '../../../../shared/services';
import {BarcodeGenerationSvc, ProductsSvc} from '../../../services';
import {ClassificationSvc} from '../../../../shared/services/classification.service';
import {CompanySvc} from '../../../../shared/services/company.service';
import {FeatureToggleSvc} from '../../../../shared/services/feature-toggle.service';
import {VintageSvc} from '../../../../shared/services/vintages.service';
import {containerCodes} from '../../../models/container.constants' ;
import {Varietal} from '../../../models/varietals.constants';
import {Aoc} from '../../../models/aoc.constants';
import {GisCodes} from '../../../models/gis.constants' ;
import * as _ from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {DashboardConst, FoodEditionUnityFormModel} from '../../../models';
import GtinUtils from '../../../../shared/utils/gtin';
import {StructuredAddress} from '../../../models/StructuredAddress.model';
import {NetContent} from '../../../models/NetContent.model';
import {NetContentConst} from '../../../models/netContent.constants';
import {AllergenFoodModel} from '../../../models/allergenFood.model';
import {NutrimentItem} from '../../../models/nutrimentItem.model';
import {Nutriment} from '../../../models/nutriment.model';
import {Countries} from '../../../models/country.constants';
import {PackagingMarkedLabelAccreditationCode} from '../../../models/packagingMarkedLabelAccreditationCode.constants';
import {TargetMarketCountries} from '../../../models/targetMarket-country.constants';
import {environment} from '../../../../../environments/environment';
import {QrCodeCreationComponent} from '../../qr-code-creation/qr-code-creation.component';

@Component({
  selector: 'product-view-food',
  styleUrls: ['./product-view-food.less'],
  templateUrl: './product-view-food.tpl.html',
  providers: [{provide: CarouselConfig, useValue: {interval: 5000, noPause: false, showIndicators: false}}]
})
export class ProductViewFoodComponent extends FoodEditionUnityFormModel implements OnInit {

  @ViewChild('confirmationModal', {static: true})
  public confirmationModal: ConfirmationModal;


  @ViewChild('shareModal', {static: true})
  public shareModal: ShareModal;

  @ViewChild('formProduct', {static: true})
  public form: any;
  public multiSelectOptions: any = Varietal;
  public aocSelectOptions: any = Aoc;
  public containerCodes = containerCodes;
  public gisCodes = GisCodes;
  public shareUrl = '';
  public barcodeUrl = '';
  @Input() public product: any;
  public measurementUnitsList: Array<Array<{}>> = [];
  public packagingMarkedLabelAccreditationCodeOptions: any = PackagingMarkedLabelAccreditationCode;
  public constants = DashboardConst;
  public copyGtinToolTip: string = this.constants.copyGtinLabel;
  @ViewChild('image', {static: true})
  public headerHeight: any;
  public imagesToDisplay: any[];
  public languageProductName: string;
  public NetContentConstants = NetContentConst.all;
  @ViewChild(QrCodeCreationComponent, {static: true})
  private QrCodeCreationComponent: QrCodeCreationComponent;

  constructor(public productsSvc: ProductsSvc,
              public featureToggleSvc: FeatureToggleSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public classificationSvc: ClassificationSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public userSvc: UserSvc,
              private notificationsSvc: NotificationsSvc,
              private productCategorySvc: ProductCategorySvc
  ) {
    super(productsSvc, _router, route, featureToggleSvc, barcodeGenerationSvc, notificationsSvc, companySvc, vintageSvc, userSvc);
  }

  public ngOnInit() {
    this.initProductForm();
    this.imagesToDisplay = _.concat(
      this.productForm.images,
      _.map(this.productForm.imagesAddedByUrl, i => ({url: i}))
    );
    this.languageProductName = _.isEmpty(this.productForm.productNames) ? 'fr' : _.get(_.first(this.productForm.productNames), 'lang', '');
  }


  public showBarcode() {
    if (this.barcodeUrl.length !== 0) {
      return true;
    }
    if (this.barcodeUrl.length === 0 && this.productForm.gtin.prefix) {
      this.barcodeUrl = `${environment.API_URL}/barcode/${this.productForm.gtin.prefix}${this.productForm.gtin.cip}${this.productForm.gtin.key}`;
      return true;
    }
    return false;
  }

  public findWordingFromCode(constants: Array<{ wording: string, code: any }> = [], code: any = '') {
    const c = _.find(constants, element => element.code.toString() === code.toString()) || {
      wording: ''
    };
    return c.wording;
  }


  public extractUrlFromImage(): string {
    if (this.imagesToDisplay && this.imagesToDisplay[0]) {
      return this.imagesToDisplay[0]['url'];
    }
    return '';
  }

  public findListWording(constants: Array<{ wording: string, code: any }>, listCode: String[]) {
    const toReturn: string[] = [];
    // @ts-ignore
    listCode.forEach((code: string) => {
      const wording = this.findWordingFromCode(constants, code);
      if (!_.isEmpty(wording)) {
        toReturn.push(` ${wording}`);
      }
    });
    return toReturn;
  }

  public getDailyValueIntakeReference(item): string {
    if (item.measurementUnitCode === 'E14') {
      // @ts-ignore
      const KJOIndex = this.productForm.nutriment.items.map(m => {
        return m.measurementUnitCode;
      }).indexOf('KJO');
      // @ts-ignore
      return this.productForm.nutriment.items[KJOIndex].dailyValueIntakeReference;
    } else {
      return item.dailyValueIntakeReference;
    }
  }

  public getCompanyWebsite(): string {
    const url = this.companySvc.company.urlWebsite;
    if (!url) {
      return '';
    }
    if (url.startsWith('http')) {
      return url;
    }
    return '//' + url;
  }

  public constructNetContentWording(): string {
    const netContentValue = this.productForm.netContents[0].netContent;
    const measurementUnitValue = this.findWordingFromCode(this.NetContentConstants, this.productForm.netContents[0].measurementUnitCode);
    return `${netContentValue} ${measurementUnitValue}`;
  }

  public constructStructuredAddressWording(): string {
    const countryValue = this.findWordingFromCode(Countries, this.productForm.structuredAddress.countryCode);
    return `${this.productForm.structuredAddress.streetAddress} ${this.productForm.structuredAddress.postalCode} ${this.productForm.structuredAddress.city} ${countryValue}`;
  }

  public constructContainsAllergenWording(): string[] {
    const contains: string [] = [];

    for (const allergen of this.productForm.allergensFood) {
      if (allergen.levelOfContainmentCode === 'CONTAINS') {
        const allergenWording = `${this.findAllergenWording(allergen.allergenTypeCode)}`;
        contains.push(allergenWording);
      }
    }
    return contains;
  }

  public constructMayContainsAllergenWording(): string[] {
    const mayContains: string [] = [];

    for (const allergen of this.productForm.allergensFood) {
      if (allergen.levelOfContainmentCode === 'MAY_CONTAIN') {
        const allergenWording = `${this.findAllergenWording(allergen.allergenTypeCode)}`;
        mayContains.push(allergenWording);
      }
    }
    return mayContains;
  }

  public constructTargetMarketWording(): string {
    const targetMarket = this.productForm.targetMarket || [];
    let wording = '';
    targetMarket.forEach((item, index) => {
      if (index !== 0) {
        wording = wording + ' | ';
      }
      // @ts-ignore
      wording = wording + _.find(TargetMarketCountries, c => item === c.code)['wording-fr'];
    });
    return wording;
  }

  public constructFreeFromAllergenWording(): string[] {
    const freeFrom: string[] = [];

    for (const allergen of this.productForm.allergensFood) {
      if (allergen.levelOfContainmentCode === 'FREE_FROM') {
        const allergenWording = `${this.findAllergenWording(allergen.allergenTypeCode)}`;
        freeFrom.push(allergenWording);
      }
    }
    return freeFrom;
  }

  public constructUndeclaredAllergenWording(): string[] {
    const undeclared: string[] = [];

    for (const allergen of this.productForm.allergensFood) {
      if (allergen.levelOfContainmentCode === 'UNDECLARED') {
        const allergenWording = `${this.findAllergenWording(allergen.allergenTypeCode)}`;
        undeclared.push(allergenWording);
      }
    }
    return undeclared;
  }

  public constructAllergenWording(): string[] {
    const toReturn: string [] = [];
    for (const allergen of this.productForm.allergensFood) {
      if (allergen.levelOfContainmentCode === 'CONTAINS' || allergen.levelOfContainmentCode === 'MAY_CONTAIN') {
        const allergenWording = `${this.findWordingFromCode(this.containmentLevelConst, allergen.levelOfContainmentCode)} ${this.findAllergenWording(allergen.allergenTypeCode)}`;
        toReturn.push(allergenWording);
      }
    }
    return toReturn;
  }

  public constructDrainedWeightWording(): string {
    if (!_.isEmpty(this.productForm.drainedWeight)) {
      return `${this.productForm.drainedWeight.quantityContained} ${this.findWordingFromCode(this.drainedWeightMeasurementUnitConst, this.productForm.drainedWeight.measurementUnitCode)}`;
    }
    return '';
  }

  public constructPreparationStateWording(): string {
    if (this.productForm.preparationStateCode === 'UNPREPARED') {
      return 'Tel que vendu';
    }

    return 'Préparé';
  }

  public constructHeadersFromCategory(constants: Array<{ wording: string, categorie: string, code: string }>, category: string): Array<{ wording: string, categorie: string, code: string }> {
    return _.filter(constants, element => element.categorie.toString() === category);
  }

  public findAllergenWording(code: any): string {
    const allergen: any = _.find(this.mergedAllergenFoodItems, c => c.code === code) || {};
    return allergen.wording;
  }

  public showMpnSubSection(): boolean {
    return this.showSubSection(this.productForm.mpn);
  }


  public showMpnAndSkuSection(): boolean {
    return this.showSection([this.productForm.mpn, this.productForm.sku]);

  }

  public showSubSection(variable: string) {
    variable = !_.isNil(variable) ? variable.toString().trim() : '';
    return !_.isEmpty(variable);
  }

  public showSection(variables: string[]) {
    let toReturn = false;
    for (const variable of variables) {
      if (this.showSubSection(variable)) {
        toReturn = true;
        break;
      }
    }
    return toReturn;
  }

  public editProduct() {
    const routeName = 'dashboard/product/update';

    // if (this.featureToggleSvc.canSeeVcs()) {
    //     routeName = this.productForm.completionLevel === 4 ? 'dashboard/editionvcs/packing' : 'dashboard/editionvcs/consumer';
    // }
    //
    // if (!this.featureToggleSvc.canSeeVcs()) {
    //     routeName = 'dashboard/product/update';
    // }
    this._router.navigate([routeName, this.id]);
  }

  public showComplementaryInfoSection() {
    return this.showSection([this.productForm.description, this.productForm.webLink, this.extractUrlFromImage(), this.productForm.mpn, this.productForm.sku]);
  }

  public shareCIP() {
    this.shareUrl = `${environment.SHARE_URL_BASE}/gtin/${this.productForm.gtin.prefix}${this.productForm.gtin.cip}${this.productForm.gtin.key}`;
    this.shareModal.openModal();
  }

  public onVisibilityChange(state: boolean) {
    this.productsSvc.updateProductVisibility(this.id, state)
      .subscribe(
        updatedProduct => {
          this.productForm.isPrivate = updatedProduct.isPrivate ?? false;
        },
        err => {
          this.productForm.isPrivate = !state;
          console.error(err);
        }
      );
  }

  public updateMeasurementUnitList() {
    let hasAtLeastOneValidItem = false;
    const usedMeasurementUnitList = _.map(this.productForm.netContents, 'measurementUnitCode');
    const usedMeasurementCategoriesList = _.reduce(this.NetContentConstants, (categories, item) => {
      if (usedMeasurementUnitList.indexOf(item.code) >= 0) {
        categories[item.category] = true;
      }
      return categories;
    }, {});
    for (let i = 0; i < this.productForm.netContents.length; i++) {
      const selectedNetContent = this.productForm.netContents[i];
      const selectedCategory = _.find(this.NetContentConstants, {code: selectedNetContent.measurementUnitCode}) || {category: ''};
      this.measurementUnitsList[i] = this.NetContentConstants.filter((item: any) => {
        return !usedMeasurementCategoriesList[item.category] || item.category === selectedCategory.category;
      });

      if (!selectedNetContent.isEmpty()) {
        hasAtLeastOneValidItem = true;
      }
    }
  }

  public clickCopyGtin(event, button: HTMLButtonElement) {
    this.copyGtinToolTip = this.constants.copiedGtinLabel;
    setTimeout(() => {
      button.blur();
      button.focus();
    }, 100);
    setTimeout(() => {
      this.copyGtinToolTip = this.constants.copyGtinLabel;
      button.blur();
    }, 1000);
    event.stopPropagation();
  }

  public cannotSeeBrand(productForm): boolean {
    return this.productsSvc.isProductResellerWithoutBrand(productForm.productResellerType);
  }

    public isGtin8(){
        return this.productForm.gtin.isGtin8;
    }

  public openQRcodeModal() {
    this.QrCodeCreationComponent.show();
  }

    private initProductForm() {
        this.id = this.product.id;
        _.assign(this.productForm, this.product);
        this.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
        this.productForm.alcoholDegree = !_.isNil(this.product.alcoholDegree) ? `${this.product.alcoholDegree}`.replace('.', ',') : null;
        const {streetAddress, postalCode, countryCode, city} = this.product.structuredAddress || {
            streetAddress: '',
            postalCode: '',
            countryCode: '',
            city: ''
        };
        this.productForm.structuredAddress = new StructuredAddress(streetAddress || '', postalCode || '', countryCode || '', city || '');
        this.productForm.fullAddress = this.constructStructuredAddressWording();
        this.initNetContents();
        this.initNutriment();
        this.initDietType();
        this.initAllergenFood();
        this.initIngredientStatement();
        // just to display target market label
        // Saving values that should not be changed
        this.initialBrand = this.product.brand;
        this.initialName = this.product.name;
        this.productForm.productResellerType = this.product.productResellerType;
        this.servingSizeDescription = _.get(this.product.nutriment, 'servingSizeDescription', null);
        this.dailyValueIntakeReference = this.product.dailyValueIntakeReference;
        if (_.isUndefined(this.product.codeGpc) || _.isEmpty(this.product.codeGpc)) {
            this.productForm.codeGpc = '0';
        }
    }

  private initNetContents() {
    this.productForm.netContents = _.map(this.productForm.netContents, ({netContent, measurementUnitCode}) => {
      const netContentStr = `${netContent}`.replace('.', ',');
      return new NetContent(netContentStr, measurementUnitCode);
    });
    this.measurementUnitsList = new Array(this.productForm.netContents.length).fill(this.NetContentConstants);

    this.updateMeasurementUnitList();
  }

  private initAllergenFood() {
    this.productForm.allergensFood = _.map(this.productForm.allergensFood, ({
                                                                              allergenTypeCode,
                                                                              levelOfContainmentCode
                                                                            }) => {
      return new AllergenFoodModel(allergenTypeCode, levelOfContainmentCode);
    });
    const coqFruitsObj: AllergenFoodModel = _.find(this.productForm.allergensFood, (obj) => obj.allergenTypeCode === 'AN') || new AllergenFoodModel();
    const allergenFoodOptionalCodes = _.map(this.optionalAllergenFoodItems, allergen => allergen.code);
    for (const code of allergenFoodOptionalCodes) {
      const optionalObj: AllergenFoodModel = _.find(this.productForm.allergensFood, (obj) => obj.allergenTypeCode === code) || new AllergenFoodModel();
      this.opticalSpecifiedAllergen.push(new AllergenFoodModel(code, optionalObj.levelOfContainmentCode ? optionalObj.levelOfContainmentCode : ''));
      _.remove(this.productForm.allergensFood, obj => obj.allergenTypeCode === code);
    }
    const authorizedLevelOfContainmentCodes = ['CONTAINS', 'MAY_CONTAIN'];
    if (authorizedLevelOfContainmentCodes.indexOf(coqFruitsObj.levelOfContainmentCode) !== -1) {
      this.addOrRemoveOptionalAllergen(coqFruitsObj.allergenTypeCode ?? '', coqFruitsObj.levelOfContainmentCode);
    }
  }

  private initDietType() {
    for (const code in this.productForm.dietTypes) {
      if (this.productForm.dietTypes[code] === true) {
        // @ts-ignore
        this.productDiet.push(_.find(this.dietTypes, (obj) => obj.code === code));
      }
    }
  }

  private initIngredientStatement() {
    const firstPattern = 'Ingrédient :'.toUpperCase();
    const secondPattern = 'Ingrédients :'.toUpperCase();
    const firstIndex = this.productForm.ingredientStatement.toUpperCase().indexOf(firstPattern);

    if (firstIndex !== -1) {
      this.productForm.ingredientStatement = this.productForm.ingredientStatement.slice(firstPattern.length, this.productForm.ingredientStatement.length).trim();
    } else {
      this.productForm.ingredientStatement = this.productForm.ingredientStatement.slice(secondPattern.length, this.productForm.ingredientStatement.length).trim();
    }
  }

  private initNutriment() {
    const {servingSize, measurementUnitCode, servingSizeDescription, items} = this.productForm.nutriment;

    const builtItems = _.map(items, (item: NutrimentItem) => {
      return new NutrimentItem(item.nutrientTypeCode, item.measurementPrecisionCode,
        this.productsSvc.replaceDotByCommaInString(item.quantityContained ?? '') ?? '',
        this.productsSvc.replaceDotByCommaInString(item.optionalQuantityContained) ?? '',
        item.measurementUnitCode,
        this.productsSvc.replaceDotByCommaInString(item.dailyValueIntakeReference ?? '') ?? '');
    });

    this.productForm.nutriment = new Nutriment(servingSize, measurementUnitCode, servingSizeDescription, builtItems);
  }

}
