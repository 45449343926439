import {Component, ViewChild, Input, ElementRef} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {NotificationsSvc} from '../../services/notifications.service';
@Component({
  selector: 'share-modal',
  styleUrls: ['./share-modal.less'],
  template: `
    <div>
      <bs-modal class="ShareModal" [keyboard]="false" [backdrop]="'static'" [size]="'md'"
            #shareModal>
            <bs-modal-header [showDismiss]="true">
            </bs-modal-header>
            <bs-modal-body>
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="share-description">Partagez la carte d'identité de votre {{isBundle?'assortiment':'produit'}} <br/> à vos partenaires commerciaux</h4>
                        <h5 class="share-note">NB : cette page n'a pas vocation à être partagée à vos consommateurs.</h5>
                    </div>
                    <div class="col-md-12">
                        <input #shareUrlInput class="share-url" type="text" [value]="url"  readonly/>
                    </div>
                    <div class="col-md-12 btn-container">
                        <button class="share-btn" [ngxClipboard]="shareUrlInput" (cbOnSuccess)="shareUrlCopied()" >Copier le lien</button>
                    </div>
                </div>
            </bs-modal-body>
      </bs-modal>
    </div>
  `
})
export class ShareModal {

  @ViewChild('shareModal', {static: true})
  public modal: BsModalComponent;

  @ViewChild('shareUrlInput', {static: true})
  public shareUrlInput: ElementRef;

  @Input() public url: string;
  @Input() public isBundle: boolean;

  constructor(private notifSvc: NotificationsSvc) {
  }

  public openModal() {
    this.modal.open();
  }

  public shareUrlCopied() {
    this.modal.close();
    this.notifSvc.shareUrlCopied();
  }
}
