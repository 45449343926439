import {
    Component,
    EventEmitter,
    Output,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
    AfterContentInit, OnInit, Input
} from '@angular/core';
import {Subject} from 'rxjs';
import {ProductCategorySvc, CompanySvc} from '../../../shared/services/index';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {DashboardConst} from '../../models/dashboard.constants';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {Category} from '../../../shared/models';
import { ClassificationCategoriesModal } from '../classification-categories-modal/classification-categories-modal.component';

@Component({
    selector: 'classification',
    styleUrls: ['./classification.less'],
    templateUrl: './classification.tpl.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassificationComponent implements OnInit {

    @Output() public onSelectCategory = new EventEmitter<any>();
    public constants = DashboardConst;
    public segmentList: any[] = [];
    public classList: any[] = [];
    public brickList: any[] = [];
    public familyList: any[] = [];
    public isPickedCategory: boolean = false;
    public clickedSegment: any = null;
    public clickedFamily: any = {};
    public clickedClass: any = {};
    public categoryDetails: any = {};
    public selectedCategory: any = {};
    public bookmarkedCategories: any[] = [];
    public showSelectCategory: boolean = true;
    public hideSegments: boolean = false;
    public hideSegmentsChanged: boolean = false;
    @ViewChild('shareClassificationCode', {static: true})
    public shareClassificationCode: HTMLInputElement;

    @ViewChild('classificationCategoriesModal', {static: true})

    public classificationCategoriesModal: ClassificationCategoriesModal;
    constructor(private headerSvc: DashboardHeaderSvc, private productCategorySvc: ProductCategorySvc,
                private companySvc: CompanySvc, public _router: Router, private route: ActivatedRoute) {
        this.headerSvc.setHeaderData('Identifier une catégorie GPC', 'assets/icons/newCodeOrange.svg');
    }

    public ngOnInit(): void {
        this.route.url.subscribe((urlSegments: any) => {
            urlSegments.forEach((url: UrlSegment) => {
                if (url.path === 'classifications') {
                    this.showSelectCategory = false;
                }
            });
        });
        this.productCategorySvc.searchByTypeAndGpcCode('segment')
            .then((categories) => {
                this.segmentList = categories;
            })
            .catch((error) => {
                this.segmentList = [];
            });
        this.bookmarkedCategories = this.companySvc.company.bookmarkedCategories;
    }

    public showClasses(code: string) {
        this.classList = [];
        this.brickList = [];
        this.isPickedCategory = false;
        this.clickedFamily = code;
        // deselect the lower levels
        this.clickedClass = {};
        this.productCategorySvc.searchByTypeAndGpcCode('class', code)
            .then((categories) => {
                this.classList = categories;
                this.hideSegmentsChanged = (!this.hideSegments && (this.brickList.length > 0)) || (this.hideSegments && !(this.brickList.length > 0));
                this.hideSegments = (this.brickList.length > 0);
                // this.showSegments(this.brickList.length > 0);
            })
            .catch((error) => {
                this.classList = [];
            });
    }

    public showBricks(code: string) {
        this.brickList = [];
        this.isPickedCategory = false;
        this.clickedClass = code;
        this.productCategorySvc.searchByTypeAndGpcCode('brick', code)
            .then((categories) => {
                this.brickList = categories;
                this.hideSegmentsChanged = (!this.hideSegments && (this.brickList.length > 0)) || (this.hideSegments && !(this.brickList.length > 0));
                this.hideSegments = (this.brickList.length > 0);
                // this.showSegments(this.brickList.length > 0);
            })
            .catch((error) => {
                this.brickList = [];
            });
    }

    public showFamilies(code: string) {
        this.familyList = [];
        this.brickList = [];
        this.classList = [];
        this.isPickedCategory = false;
        this.clickedSegment = code;
        // deselect the lower levels
        this.clickedFamily = {};
        this.clickedClass = {};
        this.productCategorySvc.searchByTypeAndGpcCode('family', code)
            .then((categories) => {
                this.familyList = categories;
            })
            .catch((error) => {
                this.familyList = [];
            });
    }

    // Scroll the first column (the segments) when comming backwards (unselecting families/classes)
    public ngAfterViewChecked() {
        if (!this.hideSegments && this.clickedSegment && this.hideSegmentsChanged) {
            this.hideSegmentsChanged = false;
            const pos = document.getElementById('seg-' + this.clickedSegment).offsetTop;
            document.getElementById('segments').scrollTop = pos - 25;
        }
    }

    public hideFamilies(e: Event) {
        e.stopPropagation();
        this.familyList = [];
        this.brickList = [];
        this.classList = [];
        this.clickedSegment = null;
    }

    public hideClasses(e: Event) {
        e.stopPropagation();
        this.brickList = [];
        this.classList = [];
        this.clickedFamily = {};
        this.hideSegmentsChanged = this.hideSegments;
        this.hideSegments = false;
        // this.showSegments();
    }

    public hideBricks(e: Event) {
        e.stopPropagation();
        this.brickList = [];
        this.clickedClass = {};
        this.hideSegmentsChanged = this.hideSegments;
        this.hideSegments = false;
        // this.showSegments();
    }

    public pickCategory(category: Category) {
        this.categoryDetails = category;
        this.classificationCategoriesModal.openModal();
        this.isPickedCategory = true;
    }

    public isSelectedCategory = (category): boolean => {
        return this.selectedCategory.code === category.code;
    }
    public hasCategory = (catgeory) => {
        const hasCategory = this.bookmarkedCategories.find((item) => {
            return item.code === catgeory.code;
        });
        return hasCategory !== undefined;
    }

    public saveSelectedCategory = (category) => {
        const selectedCategory = {
            breadcrumbs: category.breadcrumbs,
            code: category.code,
            definition: category.definition,
            parentIds: category.parentIds,
            text: category.text,
            type: category.type
        };
        if (!this.isSelectedCategory(category)) {
            const hasCategory = this.hasCategory(category);
            let bookmarkedCategories = this.bookmarkedCategories;
            if (!hasCategory) {
                bookmarkedCategories = this.bookmarkedCategories.concat(category);
            }
            this.companySvc.updateSelectedCategory(selectedCategory, bookmarkedCategories)
                .then((company) => {
                    this.selectedCategory = company.selectedCategory || {};
                    this.onSelectCategory.emit({
                            category: selectedCategory
                        }
                    );
                });
        } else {
            this.onSelectCategory.emit({
                category: selectedCategory
            });
        }
    }

    public closedModal() {
        this.categoryDetails = {};
        this.isPickedCategory = false;
    }

}
