import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpDownloadProgressEvent, HttpEvent, HttpRequest, HttpEventType} from '@angular/common/http';
import {ProductClassificationIndex} from '../models/productClassification.constants';
import {EventSourceSvc} from '../../shared/services';
import {CompanySvc} from '../../shared/services';
import {Gtin, ImportTypes} from '../../shared/models';
import {Product, ProductFormModel, ProductImport, ProductImportForm, SortConf} from '../models';
import {Observable} from 'rxjs';

import {AuthGuard} from '../../shared/services/auth-guard';
import {HttpHeaders} from '@angular/common/http';
import {VcsProductFormModel} from '../models/vcsProductCreationForm.model';
import {VcsProductFormOldModel} from '../models/vcsProductCreationForm-old.model';
import {FoodProductFormModel} from '../models/foodProductCreationForm.model';
import {NutrimentItem} from '../models/nutrimentItem.model';
import {ProductResellerTypes} from '../models/productResellerTypes.model';
import gtinUtils from '../../shared/utils/gtin';

import * as _ from 'lodash';
import {environment} from '../../../environments/environment';

const fileSaver = require('filesaver.js');

@Injectable()
export class ProductsSvc {

  public totalProducts: number = 0;
  public myProducts: Product[] = [];
  public productsLoaded: boolean = false;
  public sortConf: SortConf;
  public lastEvaluatedKey: any;
  public productCategories = ProductClassificationIndex;
  public product: any;
  public totalNewGtin8Products: number = 0;
  public myNewGtin8Products: Product[] = [];
  public totalAllProducts: number = 0;
  public totalProductsWithOldGpcCode: number = 0;

  constructor(private httpClient: HttpClient, private eventSourceSvc: EventSourceSvc, private companySvc: CompanySvc,
              private authGuard: AuthGuard) {
    this.resetMetadata();
    this.getCustomerProducts().subscribe(
      result => {
        // this.totalProducts = result.total;
        this.myProducts = result.products;
        this.lastEvaluatedKey = result.lastEvaluatedKey;
        this.productsLoaded = true;
      });
    this.getNewGtin8Products().subscribe(
      result => {
        this.totalNewGtin8Products = result.total;
        this.myNewGtin8Products = result.products;
      }
    );
    this.getCountAllProducts().subscribe(
      result => {
        this.totalAllProducts = result;
      }
    );
    this.getCountProductsBasedOnAvailablePrefix().subscribe(
      result => {
        this.totalProducts = result;
      });

    this.getCountProductsWithOldCodeGpc().subscribe(
      result => {
        this.totalProductsWithOldGpcCode = result;
      });
  }

  public resetMetadata() {
    this.sortConf = new SortConf('updatedAt', false);
    this.lastEvaluatedKey = undefined;
  }

  public sortList(field: string): Observable<void> {
    let ascending: boolean;
    if (this.sortConf.field === field) {
      ascending = !this.sortConf.ascending;
    } else {
      ascending = this.isFieldInitiallySortedInAscendingOrder(field);
    }
    this.sortConf = new SortConf(field, ascending);

    let sortField = this.sortConf.ascending ? this.sortConf.field : `-${this.sortConf.field}`;
    if (field === 'important') {
      sortField += ',-@timestamp';
    }

    const requestUrl = `${environment.API_URL}/products?sortBy=${sortField}`;
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        this.myProducts = data.products;
        return;
      }));
  }

  public isFieldInitiallySortedInAscendingOrder(field: string): boolean {
    return ['gtin', 'important'].indexOf(field) === -1;
  }

  public searchProducts(input: string): Observable<any> {
    const listOfWords = _.compact(input.split(' '));
    const requestUrl = environment.API_URL + '/search/products?text=' + listOfWords.toString();
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        this.myProducts = data;
        return data;
      }));
  }

  public getCustomerProducts(offset: number = 0, length: number = 100, fromBeginning: boolean = false): Observable<any> {
    const sortField = this.sortConf.ascending ? this.sortConf.field : `-${this.sortConf.field}`;
    if (fromBeginning === true) {
      this.lastEvaluatedKey = undefined;
    }

    let requestUrl = `${environment.API_URL}/products?offset=${offset}&length=${length}&sortBy=${sortField}`;
    if (this.lastEvaluatedKey) {
      requestUrl += `&lastEvaluatedKey=${JSON.stringify(this.lastEvaluatedKey)}`;
    }
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        const result = data;
        this.lastEvaluatedKey = result.lastEvaluatedKey ? result.lastEvaluatedKey : undefined;
        return result;
      }));
  }

  public getProduct(productId: string): Observable<Product> {
    const requestUrl = environment.API_URL + '/products/' + productId;
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        this.product = data;
        return data;
      }));
  }

  public getProductByGtin(gtin: string): Observable<Product> {
    const requestUrl = environment.API_URL + '/products?gtin=' + gtin;
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => data));
  }

  public constructImagesAddedByUrl(productForm: ProductFormModel) {
    const toReturn: string [] = [];
    productForm.imagesAddedByUrl?.forEach(imagesAddedByUrl => {
      toReturn.push(imagesAddedByUrl.url);
    });
    return toReturn;
  }

  public createProduct(productForm: ProductFormModel): Observable<Product> {

    const body = JSON.stringify({
      gtin: productForm.gtin.isGtin8 ? productForm.gtin.toString() : productForm.gtin.prefix + productForm.gtin.cip + productForm.gtin.key,
      prefix: productForm.gtin.prefix,
      mpn: productForm.mpn,
      sku: productForm.sku,
      brand: productForm.brand,
      subBrand: productForm.subBrand,
      productNames: productForm.productNames,
      description: productForm.description,
      webLink: productForm.webLink,
      completionLevel: productForm.completionLevel,
      companyId: productForm.companyId,
      targetMarket: productForm.targetMarket,
      codeGpc: productForm.codeGpc,
      isPrivate: productForm.isPrivate,
      category: productForm.category,
      netContents: productForm.netContents,
      adhereToTheSectoralAgreement: productForm.adhereToTheSectoralAgreement,
      vbGs1CompletionLevel: productForm.vbGs1CompletionLevel,
      imagesAddedByUrl: this.constructImagesAddedByUrl(productForm),
      productResellerType: productForm.productResellerType,
      ...(productForm.productResellerType === ProductResellerTypes.NOT_NEW) && {originGtin : productForm.originGtin}
    });

    const formData = new FormData();
    const requestUrl = environment.API_URL + '/products';
    formData.append('form', body);

    _.forEach(productForm.imagesToUpload, (file) => {
      // @ts-ignore
      return formData.append('files', file, this.makeImageId(file.name));
    });

    return this.httpClient.post(requestUrl, formData).pipe(
      map((data: any) => data));
  }

  public createFoodProduct(productForm: FoodProductFormModel): Observable<Product> {
    const imagesToUpload = _.get(productForm, 'imagesToUpload', []);
    const images = _.get(productForm, 'images', []);
    const contentDescription = imagesToUpload.length === 0 && images.length === 0 ? '' : productForm.contentDescription;
    const body = JSON.stringify({
      gtin: productForm.gtin.isGtin8 ? productForm.gtin.toString() : productForm.gtin.prefix + productForm.gtin.cip + productForm.gtin.key,
      prefix: productForm.gtin.prefix,
      mpn: productForm.mpn,
      sku: productForm.sku,
      brand: productForm.brand,
      subBrand: productForm.subBrand,
      name: productForm.name,
      productNames: productForm.productNames,
      description: productForm.description,
      webLink: productForm.webLink,
      completionLevel: productForm.completionLevel,
      companyId: productForm.companyId,
      targetMarket: productForm.targetMarket,
      codeGpc: productForm.codeGpc,
      isPrivate: productForm.isPrivate,
      structuredAddress: productForm.structuredAddress,
      dietTypes: productForm.adhereToTheSectoralAgreement ? productForm.dietTypes : null,
      contactName: productForm.contactName,
      nutriment: productForm.nutriment,
      category: productForm.category,
      netContents: productForm.netContents,
      regulatedProductName: productForm.regulatedProductName,
      allergensFood: productForm.allergensFood,
      alcoholDegree: productForm.alcoholDegree,
      preparationStateCode: productForm.preparationStateCode,
      consumerUsageInstructions: productForm.consumerUsageInstructions,
      consumerStorageInstructions: productForm.consumerStorageInstructions,
      ingredientStatement: !_.isEmpty(productForm.ingredientStatement) ? 'Ingrédients : ' + productForm.ingredientStatement : productForm.ingredientStatement,
      preparationInstructions: productForm.preparationInstructions,
      packagingMarkedLabelAccreditationCode: productForm.packagingMarkedLabelAccreditationCode,
      compulsoryAdditiveLabelInformation: productForm.compulsoryAdditiveLabelInformation,
      countryOfOrigin: productForm.countryOfOrigin,
      drainedWeight: productForm.drainedWeight,
      dailyValueIntakeReference: productForm.adhereToTheSectoralAgreement ? productForm.dailyValueIntakeReference : null,
      contentDescription,
      nutriScore: productForm.nutriScore,
      adhereToTheSectoralAgreement: productForm.adhereToTheSectoralAgreement,
      vbGs1CompletionLevel: productForm.vbGs1CompletionLevel,
      imagesAddedByUrl: this.constructImagesAddedByUrl(productForm),
      productResellerType: productForm.productResellerType,
      ...(productForm.productResellerType === ProductResellerTypes.NOT_NEW) && {originGtin : productForm.originGtin}
    });
    const formData = new FormData();
    const requestUrl = environment.API_URL + '/products';
    formData.append('form', body);

    _.forEach(productForm.imagesToUpload, (file) => {
      // @ts-ignore
      return formData.append('files', file, this.makeImageId(file.name));
    });

    return this.httpClient.post(requestUrl, formData).pipe(
      map((data: any) => data));
  }

  public createVcsProduct(productForm: VcsProductFormModel): Observable<Product> {
    const body =  JSON.stringify({
      gtin: productForm.gtin.isGtin8 ? productForm.gtin.toString() : productForm.gtin.prefix + productForm.gtin.cip + productForm.gtin.key,
      prefix: productForm.gtin.prefix,
      mpn: productForm.mpn,
      sku: productForm.sku,
      brand: productForm.brand,
      subBrand: productForm.subBrand,
      productNames: productForm.productNames,
      description: productForm.description,
      webLink: productForm.webLink,
      completionLevel: productForm.completionLevel,
      companyId: productForm.companyId,
      targetMarket: productForm.targetMarket,
      spiritCodeGpc: productForm.spiritCodeGpc,
      codeGpc: productForm.codeGpc,
      alcoholDegree: productForm.alcoholDegree,
      productCountry: productForm.productCountry,
      wineColor: productForm.wineColor,
      vintage: productForm.vintage,
      ageOfSpirit: productForm.ageOfSpirit,
      allergen: productForm.allergen,
      vintageName: productForm.vintageName,
      container: productForm.container,
      certifications: productForm.certifications,
      varietals: productForm.varietals,
      sugarContent: productForm.sugarContent,
      aoc: productForm.aoc,
      spiritStyle: productForm.spiritStyle,
      gis: productForm.gis,
      isPrivate: productForm.isPrivate,
      category: productForm.category,
      netContents: productForm.netContents,
      adhereToTheSectoralAgreement: productForm.adhereToTheSectoralAgreement,
      vbGs1CompletionLevel: productForm.vbGs1CompletionLevel,
      imagesAddedByUrl: this.constructImagesAddedByUrl(productForm),
      productResellerType: productForm.productResellerType,
      ingredientStatement: !_.isEmpty(productForm.ingredientStatement) ? 'Ingrédients : ' + productForm.ingredientStatement : productForm.ingredientStatement,
      nutriment: productForm.nutriment,
      preparationStateCode: productForm.preparationStateCode,
      adhereToRegulatoryAgreement: productForm.adhereToRegulatoryAgreement,
      ...(productForm.productResellerType === ProductResellerTypes.NOT_NEW) && {originGtin : productForm.originGtin}
    });

    const formData = new FormData();
    const requestUrl = environment.API_URL + '/products';
    formData.append('form', body);

    _.forEach(productForm.imagesToUpload, (file) => {
      // @ts-ignore
      return formData.append('files', file, this.makeImageId(file.name));
    });

    return this.httpClient.post(requestUrl, formData).pipe(
      map((data: any) => data));
  }

  public createOldVcsProduct(productForm: VcsProductFormOldModel): Observable<Product> {
    const body = JSON.stringify({
      gtin: productForm.gtin.prefix + productForm.gtin.cip + productForm.gtin.key,
      prefix: productForm.gtin.prefix,
      mpn: productForm.mpn,
      sku: productForm.sku,
      brand: productForm.brand,
      subBrand: productForm.subBrand,
      productNames: productForm.productNames,
      description: productForm.description,
      webLink: productForm.webLink,
      completionLevel: productForm.completionLevel,
      companyId: productForm.companyId,
      targetMarket: productForm.targetMarket,
      spiritCodeGpc: productForm.spiritCodeGpc,
      codeGpc: productForm.codeGpc,
      alcoholDegree: productForm.alcoholDegree,
      productCountry: productForm.productCountry,
      wineColor: productForm.wineColor,
      vintage: productForm.vintage,
      ageOfSpirit: productForm.ageOfSpirit,
      allergen: productForm.allergen,
      vintageName: productForm.vintageName,
      container: productForm.container,
      certifications: productForm.certifications,
      varietals: productForm.varietals,
      sugarContent: productForm.sugarContent,
      aoc: productForm.aoc,
      spiritStyle: productForm.spiritStyle,
      gis: productForm.gis,
      isPrivate: productForm.isPrivate,
      category: productForm.category,
      netContents: productForm.netContents,
      adhereToTheSectoralAgreement: productForm.adhereToTheSectoralAgreement,
      vbGs1CompletionLevel: productForm.vbGs1CompletionLevel,
      imagesAddedByUrl: this.constructImagesAddedByUrl(productForm),
      productResellerType: productForm.productResellerType
    });
    const formData = new FormData();
    const requestUrl = environment.API_URL + '/products';
    formData.append('form', body);

    _.forEach(productForm.imagesToUpload, (file) => {
      // @ts-ignore
      return formData.append('files', file, this.makeImageId(file.name));
    });

    return this.httpClient.post(requestUrl, formData).pipe(
      map((data: any) => data));
  }

  public updateProductImportant(id: string, important: boolean): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const requestUrl = environment.API_URL + `/products/${id}/important`;

    return this.httpClient.put(requestUrl, JSON.stringify({UpdatedAttributes: {important}}), httpOptions).pipe(
      map((data: any) => data));
  }

  public updateProductVisibility(id: string, isPublic: boolean): Observable<Product> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const requestUrl = environment.API_URL + `/products/${id}/visibility`;

    return this.httpClient.put(requestUrl, JSON.stringify({UpdatedAttributes: {isPublic}}), httpOptions).pipe(
      map((data: any) => {
        this.product = data;
        return data;
      }));
  }

  public updateProductLink(id: string, productLink: string): Observable<Product> {
    if (!productLink) {
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const requestUrl = environment.API_URL + `/products/${id}/productlink`;

    return this.httpClient.put(requestUrl, JSON.stringify({UpdatedAttributes: {productLink}}), httpOptions).pipe(
      map((data: any) => {
        this.product.productLink = data.productLink;
        return data;
      }));
  }

  public updateProduct(id: string, updatedAttributes: Object, filesToUpload?: Object[]): Observable<Product> {
    const formData = new FormData();
    const requestUrl = environment.API_URL + '/products/' + id;

    formData.append('form', JSON.stringify({UpdatedAttributes: updatedAttributes}));

    if (filesToUpload) {
      _.forEach(filesToUpload, (file) => {
        // @ts-ignore
        return formData.append('files', file, this.makeImageId(file.name));
      });
    }

    return this.httpClient.put(requestUrl, formData).pipe(
      map((data: any) => data));
  }

  public deleteProduct(productId: string): Observable<Product> {
    const requestUrl = environment.API_URL + '/products/' + productId;
    return this.httpClient.delete(requestUrl).pipe(
      map((data: any) => data));
  }

  public getFirstGtinsAvailable(prefix: string): Observable<Gtin> {
    const requestUrl = environment.API_URL + '/company/nextGtin?prefix=' + prefix;
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        return gtinUtils.constructGTINObject(data, prefix);
      }));
  }

  public checkGtinAvailability(gtin: string): Observable<boolean> {
    return this.getProductByGtin(gtin).pipe(
      map(products => _.isEmpty(products)));
  }

  public getLevelCompletion(productForm): number {
    // Mandatory fields
    if (_.isEmpty(productForm.name) || _.isEmpty(productForm.brand)) {
      return 0;
    }

    const fields = [
      productForm.mpn,
      productForm.sku,
      productForm.subBrand,
      productForm.description,
      productForm.webLink
    ];

    const nbFilledFields = _.compact(fields).length;

    // Map number of filled fields / completion level
    const mapFields = {
      0: 1,
      1: 1,
      2: 2,
      3: 2,
      4: 3,
      5: 3
    };

    return mapFields[nbFilledFields];
  }

  public getVbgLevelCompletion(productForm): number {
    // Mandatory fields
    const firstNetContent: any = _.first(productForm.netContents) || {};
    const codeGpc = !_.isEmpty(productForm.codeGpc) && productForm.codeGpc !== '99999999';
    const productNames = _.filter(productForm.productNames, (productName) => !productName?.isEmpty());
    const fields = [
      codeGpc,
      productForm.brand,
      _.size(productForm.targetMarket),
      productNames,
      firstNetContent.netContent,
      _.size(productForm.imagesToUpload) + _.size(productForm.imagesAddedByUrl) > 0
    ];
    return _.compact(fields).length;
  }


  public saveExcelFile(data: Blob, codeGpc: string, isBundle, isNotNew) {
    if (data.size > 0) {
      const fileName = this.getFileName(codeGpc, isBundle, isNotNew) + '.xlsx';
      return fileSaver.saveAs(data, fileName, true);
    }
  }

  public getFileName(codeGpc: string, isBundle: boolean = false, isNotNew: boolean = false) {
    let fileNameExtra;

    if (isBundle) {
      fileNameExtra = '-assortiment';
    } else if (isNotNew) {
      fileNameExtra = '-non-neuf';
    } else {
      switch (codeGpc) {
        case this.productCategories.SPARKLING_WINE_GPC_CODE:
        case this.productCategories.STILL_WINE_GPC_CODE:
        case this.productCategories.LIQUEUR_WINE_GPC_CODE:
          fileNameExtra = '-vins';
          break;
        case this.productCategories.SPIRIT_GPC_CODE:
          fileNameExtra = '-spiritueux';
          break;
        default:
          fileNameExtra = '';
          break;
      }
    }
    return `Mes-produits${fileNameExtra}-${(new Date()).toLocaleDateString()}`;
  }

  public saveCsvFile(data: Blob, codeGpc: string, isBundle: boolean = false, isNotNew: boolean = false) {
    if (data.size > 0) {
      const fileName = this.getFileName(codeGpc, isBundle, isNotNew) + '.csv';
      return fileSaver.saveAs(data, fileName, true);
    }
  }

  public exportProducts(
    products?: Product[],
    csvFormatExcel: boolean = false,
    excelFile: boolean = false, gpcCodes = ['0'],
    isOldGpcExport: boolean = false,
    isBundle: boolean = false,
    isNotNew: boolean = false
  ): Observable<any> {
    const requestUrl = environment.API_URL + '/exportToCsv';
    const jwtToken = this.authGuard.jwt;
    const headers = new HttpHeaders({Authorization: 'Bearer ' + jwtToken});
    const options = {headers, responseType: 'blob' as 'blob'};
    const requestBody = {};

    if (!_.isEmpty(products)) {
      requestBody['products'] = products?.map(product => product.id);
    }

    if (!_.isEmpty(gpcCodes)) {
      requestBody['gpcCodes'] = gpcCodes;
    }

    if (csvFormatExcel) {
      requestBody['csvFormatExcel'] = true;
    }
    if (excelFile) {
      requestBody['excel'] = true;
    }

    if (isBundle) {
      requestBody['isBundle'] = true;
    }

    if (isNotNew) {
      requestBody['isNotNew'] = true;
    }

    if (isOldGpcExport) {
      requestBody['exportProductsWithOldCodeGpc'] = true;
    }

    return this.httpClient.post(requestUrl, requestBody, options).pipe(
      map((data: Blob) => {
        if (excelFile) {
          this.saveExcelFile(data, gpcCodes[0], isBundle, isNotNew);
          return;
        }
        this.saveCsvFile(data, gpcCodes[0], isBundle, isNotNew);
      }));
  }

  public sendProductsByMail(exportProductsWithOldCodeGpc: boolean = false): Promise<any> {
    const requestUrl = environment.API_URL + '/products/sendByMail';
    const jwtToken = this.authGuard.jwt;
    const date = new Date();
    const dateString = [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getFullYear()
    ].join('/');
    const timeString = [
      date.getHours().toString().padStart(2, '0'),
      date.getMinutes().toString().padStart(2, '0')
    ].join(':');

    const body = JSON.stringify({
      dateString,
      timeString,
      exportProductsWithOldCodeGpc
    });
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + jwtToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
    const options = {headers};
    return this.httpClient.post(requestUrl, body, options).toPromise();
  }

  public importProducts(productImportForm: ProductImportForm, isPublic: boolean = true, productResellerType: String = '', importType: ImportTypes = ImportTypes.CREATE_GTINS): Observable<any> {
    let requestUrl: string;
    switch (importType) {
      case ImportTypes.CREATE_GTINS: {
        requestUrl = environment.API_URL + '/products/import';
        break;
      }
      case ImportTypes.UPDATE_DATA_BASE: {
        requestUrl = environment.API_URL + '/products/import/update';
        break;
      }
      case ImportTypes.IMPORT_MY_GTINS: {
        requestUrl = environment.API_URL + '/products/import/add';
        break;
      }
      default: {
        requestUrl = environment.API_URL + '/products/import';
        break;
      }
    }
    const token = this.authGuard.jwt;
    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});
    const options = {headers};
    const date = new Date();
    const dateString = [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getFullYear()
    ].join('/');
    const timeString = [
      date.getHours().toString().padStart(2, '0'),
      date.getMinutes().toString().padStart(2, '0')
    ].join(':');

    const formData = new FormData();
    formData.append('importFile', productImportForm.importFile);
    formData.append('isPublic', isPublic.toString());
    formData.append('dateString', dateString);
    formData.append('timeString', timeString);
    formData.append('importType', importType.toString());
    if (!_.isEmpty(productResellerType)) {
      formData.append('productResellerType', productResellerType.toString());
    }
    formData.append('lastFilledRowIndex', productImportForm?.lastFilledRowIndex?.toString() ?? '');


    return this.httpClient.post(requestUrl, formData, options).pipe(
      map((data: any) => data));
  }

  public streamProductImport(productImportId: string): Observable<ProductImport> {
    const url = `/products/import/${productImportId}/events`;
    return this.eventSourceSvc.createEventSource(url).pipe(
      map((data: any) => {
        return new ProductImport(
          data.id,
          data.value.productCounter,
          data.value.errorCounter,
          data.type === 'import-completed',
          data.type === 'import-failed'
        );
      }));
  }

  public streamProductAnalysis(productImportForm: ProductImportForm, productResellerType: String = '', importType: ImportTypes = ImportTypes.CREATE_GTINS): Observable<any> {
    let requestUrl: string;
    switch (importType) {
      case ImportTypes.CREATE_GTINS: {
        requestUrl = environment.API_URL + '/products/import/analyse';
        break;
      }
      case ImportTypes.UPDATE_DATA_BASE: {
        requestUrl = environment.API_URL + '/products/import/analyse/update';
        break;
      }
      case ImportTypes.IMPORT_MY_GTINS: {
        requestUrl = environment.API_URL + '/products/import/analyse/add';
        break;
      }
      default: {
        requestUrl = environment.API_URL + '/products/import/analyse';
        break;
      }
    }


    const formData = new FormData();
    formData.append('importFile', productImportForm.importFile);
    if (!_.isEmpty(productResellerType)) {
      formData.append('productResellerType', productResellerType.toString());
    }

    return this.streamProduct(requestUrl, formData);
  }

  public streamProduct(url: string, body: any): Observable<any> {
    const request = new HttpRequest('POST', url, body, {
      responseType: 'text',
      reportProgress: true
    });

    return Observable.create(observer => {
      this.httpClient.request(request)
        .subscribe(
          (event: HttpEvent<any>) => {
            if (event.type !== HttpEventType.DownloadProgress) return;
            const text = (event as HttpDownloadProgressEvent).partialText || '';

            const arrayOfLines = text.match(/[^\r\n]+/g);

            const data = _.reduce(arrayOfLines, function(array: any[], line) {
              try {
                const parsedData = JSON.parse(line);
                array.push(parsedData);
              } catch (e) {
              }
              return array;
            }, []);

            const value = _.last(data) || {};
            observer.next(value);
          },
          error => {
            observer.error(error);
          }
        );

      return () => observer.complete();
    });
  }

  public makeImageId(fileName: string): string {
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(fileName)![1];

    let text = 'image_';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text + '.' + ext;
  }

  public getFormatedAlcoholDegree(alcoholDegreeNumber: string) {
    if (alcoholDegreeNumber === null) return null;
    return String(alcoholDegreeNumber || '').replace('.', ',');
  }

  public getFormatedVolume(volumeNumber: string) {
    if (volumeNumber === null) return null;
    return String(volumeNumber || '').replace('.', ',');
  }

  public replaceDotByCommaInString(str: string) {
    if (str === null || str === '') return null;
    return String(str || '').replace('.', ',');
  }

  public formatNutrimentItems(nutrimentItem: NutrimentItem[]): NutrimentItem[] {
    const toReturn: any [] = [];
    for (const item of nutrimentItem) {
      const itemToFormat = item;
      itemToFormat.quantityContained = this.replaceDotByCommaInString(item.quantityContained ?? '') ?? undefined;
      itemToFormat.optionalQuantityContained = this.replaceDotByCommaInString(item.optionalQuantityContained) ?? '';
      toReturn.push(itemToFormat);
    }

    return toReturn;
  }

  public isProductResellerWithoutBrand(productResellerType: string): boolean {
    return productResellerType === ProductResellerTypes.WITHOUT_BRAND_AND_ISNOT_MANUFACTURER || productResellerType === ProductResellerTypes.WITHOUT_BRAND_AND_IS_MANUFACTURER;
  }

  public isRiskyProduct(productResellerType: string): boolean {
    return productResellerType === ProductResellerTypes.WITHOUT_BRAND_AND_ISNOT_MANUFACTURER || productResellerType === ProductResellerTypes.THIRD_PARTY_BRAND;
  }

  public getNewGtin8Products(): Observable<any> {
    const requestUrl = `${environment.API_URL}/newGtin8Products`;

    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        const result = data;
        return result;
      }));
  }

  public getCountProductsBasedOnAvailablePrefix(): Observable<any> {
    const requestUrl = `${environment.API_URL}/countProductAvailablePrefix`;

    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        return data;
      }));
  }

  public getCountAllProducts(): Observable<any> {
    const requestUrl = `${environment.API_URL}/countProducts`;

    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        const result = data;
        return result;
      }));
  }

  public getCountProductsWithOldCodeGpc(): Observable<any> {
    const requestUrl = `${environment.API_URL}/countProductsWithOldCodeGpc`;

    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        return data;
      }));
  }

  public getProductsWithOldCodeGpc(): Observable<any> {
    const requestUrl = `${environment.API_URL}/productsWithOldCodeGpc`;

    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        return data;
      }));
  }
  public getImportProduct(): Observable<any> {
    const requestUrl = `${environment.API_URL}/products/import/company`;
    return this.httpClient.get(requestUrl).pipe(
      map((data: any) => {
        if (data){
          return data[0] ;
        }
      }));
  }
  private _cleanCell(sheet) {
    Object.keys(sheet).forEach((s) => {
      if (sheet[s].w) {
        sheet[s].v = sheet[s].w;
        /* add to structure */
        delete sheet[s].w;
        sheet[s].z = '0';
        sheet[s].t = 's';
      }
    });
  }
}
