export const nutrimentServingSizeConst = [
  {
    code: 'GRM',
    wording: '100g'
  },
  {
    code: 'MLT',
    wording: '100ml'
  }
];
