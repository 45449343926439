export const AllergensConstants = [
  {
    wording: 'Contient des sulfites',
    code: 'AU'
  }, {
    wording: 'Contient de l\'œuf ou dérivé',
    code: 'AE'
  }, {
    wording: 'Contient du lait ou dérivé',
    code: 'AM'
  }, {
    wording: 'Contient Céréale contenant du gluten',
    code: 'AW'
  }, {
    wording: 'Contient des crustacés',
    code: 'UN'
  }, {
    wording: 'Contient des arachides et produits à base d\'arachides',
    code: 'AP'
  }, {
    wording: 'Contient des fruits à coque',
    code: 'AN'
  }];
