
import {finalize} from 'rxjs/operators';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {FileLikeObject} from 'ng2-file-upload/file-upload/file-like-object.class';
import {DashboardConst} from '../../models/dashboard.constants';
import {ProductImportForm} from '../../models/index';
import {ProductsSvc} from '../../services/index';
import {NotificationsSvc} from '../../../shared/services/index';
import {ProductImportTutorialModal} from './product-import-tutorial-modal.component';
import * as $ from 'jquery';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'product-import-upload',
  styleUrls: ['./product-import.less', './product-import-upload.less'],
  templateUrl: './product-import-upload.tpl.html'
})
export class ProductImportUpload {

  public constants = DashboardConst;
  public productImportForm: ProductImportForm;
  public submitting: boolean;
  public uploader: FileUploader;

  public importCsvStep: ImportCsvStep = ImportCsvStep.DOWNLOAD_TEMPLATE;

  @ViewChild(ProductImportTutorialModal, {static: true})
  private tutorialModal: ProductImportTutorialModal;

  private fillTemplateActiveTab: string = 'withoutGtin';
  private isMoreSectionOpened: boolean = false;

  constructor(private notificationsSvc: NotificationsSvc,
              private productsSvc: ProductsSvc,
              private router: Router,
              private route: ActivatedRoute) {
    this.submitting = false;
    this.productImportForm = new ProductImportForm(new Blob());
    this.uploader = new FileUploader({
      maxFileSize: 30 * 1000 * 1000,
      filters: [{
        name: 'badExtension',
        fn: (item?: FileLikeObject, options?: FileUploaderOptions): boolean => item.name.toLowerCase().endsWith('.csv')
          || item.name.split('.').pop().includes('xl')
      }]
    });
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.productImportForm.importFile = fileItem._file;
    };
    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      switch (filter.name) {
        case 'badExtension':
          this.notificationsSvc.wrongImportFileType();
          break;
        case 'fileSize':
          this.notificationsSvc.importFileTooBig();
          break;
        default:
          break;
      }
    };
  }

  public changeTab(tab: string) {
    this.fillTemplateActiveTab = tab;
    this.isMoreSectionOpened = false;
  }

  public downloadCsvTemplate() {
    window.open(environment.IMPORT_CSV_TEMPLATE_URL);
  }

  public toggleMoreSection() {
    $('#importPanel').animate({
      scrollTop: document.getElementById('moreSectionLink').offsetTop + 180
    }, 800);
    this.isMoreSectionOpened = !this.isMoreSectionOpened;
  }

  public toggleFaqSection() {
    const element : HTMLElement  = document.getElementById('faqAnchor');
    let offsetTop = 0 ;
    if (element){
      offsetTop = element.offsetTop;
    }
    $('#importPanel').animate({
      scrollTop: offsetTop + 180
    }, 800);
    this.isMoreSectionOpened = !this.isMoreSectionOpened;
  }

  public submit() {
    this.submitting = true;
    this.productsSvc.importProducts(this.productImportForm).pipe(
      finalize(() => this.submitting = false))
      .subscribe(
        response => {
          this.router.navigate(['../status', response.importId], {
            queryParams: {
              fileName: this.productImportForm.importFile['name'],
              lineCount: response.lineCount
            }, relativeTo: this.route
          });
        },
        error => {
          switch (error.type) {
            case 'IMPORT_IN_PROGRESS' :
              this.notificationsSvc.importInProgress();
              break;

            case 'UNSUPPORTED_ENCODING':
              this.notificationsSvc.importFileUnsupportedEncodingError();
              break;

            case 'EMPTY_FILE':
              this.notificationsSvc.importFileEmptyError();
              break;

            default:
              this.notificationsSvc.importFileValidationError();
              break;
          }
        }
      );
    return false;
  }

  public isShowingDownloadTemplate() {
    return this.importCsvStep === ImportCsvStep.DOWNLOAD_TEMPLATE;
  }

  public goToDownloadTemplate() {
    this.importCsvStep = ImportCsvStep.DOWNLOAD_TEMPLATE;
    this.isMoreSectionOpened = false;
  }

  public isShowingFillTemplateTutorial() {
    return this.importCsvStep === ImportCsvStep.FILL_TEMPLATE_TUTORIAL;
  }

  public goToFillTemplateTutorial() {
    this.importCsvStep = ImportCsvStep.FILL_TEMPLATE_TUTORIAL;
    this.isMoreSectionOpened = false;
  }

  public isShowingUploadCsv() {
    return this.importCsvStep === ImportCsvStep.UPLOAD_CSV;
  }

  public goToUploadCsv() {
    this.importCsvStep = ImportCsvStep.UPLOAD_CSV;
    this.isMoreSectionOpened = false;
  }

  public isLastStep() {
    return this.isShowingUploadCsv();
  }

  public goToNextStep() {
    if (!this.isLastStep()) {
      this.importCsvStep++;
    }
  }

  public openTutorial() {
    this.tutorialModal.show();
  }
}

export enum ImportCsvStep {
  DOWNLOAD_TEMPLATE,
  FILL_TEMPLATE_TUTORIAL,
  UPLOAD_CSV
}
