import { Component } from '@angular/core';
import {Router} from '@angular/router';

import {
  AuthStateResult,
  EventTypes,
  OidcClientNotification,
  OidcSecurityService,
  PublicEventsService
} from 'angular-auth-oidc-client';
import {Subscription} from 'rxjs';
import {Authentication} from './login/services';
import {AuthGuard} from './shared/services/auth-guard';
import {UserSvc, FeatureToggleSvc} from './shared/services';
import {NotificationsSvc} from './shared/services/notifications.service';
import {filter} from 'rxjs/operators';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',

  styles: [`
        .App {
            height: 100%;
            overflow: hidden;
        }
    `],
  template: `
        <main class="App">
            <router-outlet></router-outlet>
            <simple-notifications class="app-notifications" [options]="options"></simple-notifications>
        </main>
    `
})
export class AppComponent {
  public options = {
    timeOut: 5000,
    theClass: 'gs1Notification',
    preventDuplicates: true
  };


  private checkSessionChangedSubscription: Subscription | undefined;


  constructor(public oidcSecurityService: OidcSecurityService,
              private router: Router,
              private authSvc: Authentication,
              private userSvc: UserSvc,
              private featureToggleSvc: FeatureToggleSvc,
              private notifSvc: NotificationsSvc,
              private authGuard: AuthGuard, private readonly eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe((result: OidcClientNotification<AuthStateResult>) => {
        this.onAuthorizationResultComplete(result.value);
      });
  }


  public ngOnInit() {
    if (window.location.href.indexOf('by-key') !== -1) {
      this.router.navigate(['/search/by-key']);
      return;
    }
    if (window.location.href.indexOf('search') !== -1) {
      this.router.navigate(['/search']);
      return;
    }

    if (window.location.href.indexOf('classifications') !== -1 && window.location.href.indexOf('product/classifications') === -1) {
      this.router.navigate(['/classifications']);
      return;
    }

    if (window.location.href.indexOf('loading') !== -1) {
      this.router.navigate(['/loading']);
      return;
    }

    if (window.location.href.indexOf('dashboard/product/categories') !== -1) {
      this.router.navigate([window.location.pathname]);
      return;
    }
    if (window.location.href.indexOf('maintenance') !== -1) {
      this.router.navigate(['/maintenance']);
      return;
    }
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated}) => {
      if (isAuthenticated){
        this.router.navigate(['/loading']);
        return;
      }
      this.authGuard.removeJwt();
      this.oidcSecurityService.authorize();
      return;
    });
    this.router.navigate(['/login']);
  }

  public startSheckSession() {
    this.checkSessionChangedSubscription = this.oidcSecurityService.checkSessionChanged$.subscribe(
      (checksession: boolean) => {
        if (checksession) {
          this.notifSvc.userDisconnected();
          this.userSvc.disconnectUser(true);
        }
      });
  }

  private onAuthorizationResultComplete(authorizationResult: AuthStateResult | undefined) {
    // @ts-ignore
    if (authorizationResult.isAuthenticated) {
      this.startSheckSession();
      this.router.navigate(['/loading']);
    }
  }
}
