import {Component, Input, OnInit} from '@angular/core';
import LocalStorage from '../../utils/localStorage';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BannerService} from '../../services/banner.service';
import {DateSvc} from '../../services/date.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
const moment = require('moment');
import * as _ from 'lodash';
import { CompanySvc } from '../../services';

@Component({
    selector: 'banner',
    styles: [`
        .banner {
            background: #e5f0fc;
            position: relative;
            /* z-index: 1; */
            padding: .75rem 3rem .875rem;
            color: #fff;
            /* font-weight: 700; */
            line-height: 1.5;
            /* cursor: pointer; */
            text-align: center;
            -webkit-transition: .1s;
            -moz-transition: .1s;
            transition: .1s;
        }

        .closeBanner {
            background: #e5f0fc;
            position: relative;
            /* z-index: 1; */
            padding: .75rem 3rem .875rem;
            color: #fff;
            font-weight: 700;
            line-height: 2.5;
            /* cursor: pointer; */
            text-align: center;
            -webkit-transition: .1s;
            -moz-transition: .1s;
            visibility: hidden;
            opacity: 0;
            transition: visibility 2s linear 0.33s, opacity 0.33s linear;
        }

        .closeBtn {
            width: 30px;
            height: 30px;
            padding: 6px 0px;
            border-radius: 15px;
            text-align: center;
            font-size: 12px;
            line-height: 1.42857;
            background: #0c2653;
            border-color: white;
        }
    `],
    template: `
        <div class="banner" #banner *ngIf="showBanner" (click)="closeBanner(banner)">
            <div [innerHtml]="this.content">
                <!--Attention, à partir du 30 mai nous faisons évoluer notre système de connexion, vous serez amené à vous-->
                <!--enregistré à nouveau. Nous vous remercions de votre compréhension.-->
            </div>
        </div>
    `
})
export class BannerComponent {

    @Input() public showBanner: boolean = false;
    public content: SafeHtml;
    public canClose: boolean;
    public disappearanceDate: Date;

    constructor(private bannerSvc: BannerService, private sanitized: DomSanitizer, private dateService: DateSvc, private companySvc: CompanySvc) {

    }

    public loadBanner() {
        this.bannerSvc.getBannerInformation().then((bannerInformation: { forceDisappear: boolean, canClose: boolean, content: string, disappearanceDate: string, GLNs: any }) => {
            if (bannerInformation.forceDisappear) {
                this.showBanner = false;
                return;
            }
            this.canClose = bannerInformation.canClose;
            this.disappearanceDate = moment(bannerInformation.disappearanceDate, 'YYYY-MM-DD');
            // @ts-ignore
          this.dateService.getCurrentDate().toPromise().then((serverDate: string) => {
                const date = moment(serverDate.substr(0, serverDate.indexOf(' ')).split('/').reverse().join('-'), 'YYYY-MM-DD');

                if (date.diff(this.disappearanceDate) >= 0) {
                    this.showBanner = false;
                    return;
                }
                if (!_.isEmpty(bannerInformation.GLNs) && _.indexOf(bannerInformation.GLNs, this.companySvc.company.gln) === -1) {
                    this.showBanner = false;
                    return;
                }
                this.showBanner = this.bannerSvc.showBanner(bannerInformation.content);
                if (this.showBanner) {
                    this.content = this.sanitized.bypassSecurityTrustHtml(bannerInformation.content);
                    LocalStorage.write('bannerContent', bannerInformation.content);
                }
            });


        });
    }

    public closeBanner(bannerElement: HTMLDivElement) {
        if (this.canClose === false) {
            return;
        }
        bannerElement.className = 'closeBanner';
        this.bannerSvc.canShowBanner = false;
        this.bannerSvc.closeBanner();
        setTimeout(() => this.showBanner = false, 150);
    }

}



