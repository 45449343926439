import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  public readonly googleAnalyticsUrl: string;
  constructor(private httpClient: HttpClient) {
    this.googleAnalyticsUrl = `${environment.API_URL}/googleAnalytics`;
  }

  public sendRealTimeEvent(pageTitle: string, pagePath: string, userEmail: string, gtin: string) {
    const body = {
      client_id: userEmail,
      events: [
        {
          name: 'page_view',
          params: {
            page_title: pageTitle,
            page_location: gtin,
            page_path: pagePath
          }
        }
      ]
    };

    return this.httpClient.post(this.googleAnalyticsUrl, body);
  }
}
