import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class BarcodeGenerationSvc {

  // Observable string sources
  public generateBarcodeSource = new Subject<string>();

  // Service message commands
  public generateBarcode(productId: string) {
    this.generateBarcodeSource.next(productId);
  }
}
