import {NetContent} from './NetContent.model';
const NET_CONTENT_PATTERN = '^([0-9]{1,}(,\\d{1,5})?)$';
const NET_CONTENT_PATTERN_ONLY_NUMBERS = '^[0-9]*$';

export class NetContentGenericProduct extends NetContent {
  constructor() {
    super();
  }

  public hasErrorNetContent() {
    if (this.netContent) {
      let decimalRegex: RegExp;
      if (this.isItemOrArticleUnits()){
        decimalRegex = new RegExp(NET_CONTENT_PATTERN_ONLY_NUMBERS);
      }else{
        decimalRegex = new RegExp(NET_CONTENT_PATTERN);
      }
      if (!decimalRegex.test(this.netContent)) {
        return true;
      }
    }
    return false;
  }
}
