import {Component, Input} from '@angular/core';

@Component({
  selector: 'loader',
  styleUrls: ['./loader.less'],
  template: `
<div class="Loader sk-fading-circle" [ngClass]="color" [ngStyle]="{width: size, height: size, top: top}">
  <div class="sk-circle1 sk-circle"></div>
  <div class="sk-circle2 sk-circle"></div>
  <div class="sk-circle3 sk-circle"></div>
  <div class="sk-circle4 sk-circle"></div>
  <div class="sk-circle5 sk-circle"></div>
  <div class="sk-circle6 sk-circle"></div>
  <div class="sk-circle7 sk-circle"></div>
  <div class="sk-circle8 sk-circle"></div>
  <div class="sk-circle9 sk-circle"></div>
  <div class="sk-circle10 sk-circle"></div>
  <div class="sk-circle11 sk-circle"></div>
  <div class="sk-circle12 sk-circle"></div>
</div>
`
})
export class Loader {

  @Input() public color: string;                // orange, blue
  @Input() public size: string;                 // 30px by default
  @Input() public top: string;                  // distance from top
  constructor() {}

}
