import {AfterContentInit, Component, ViewEncapsulation} from '@angular/core';

import {DashboardConst} from '../../../../models/dashboard.constants';
import {CompanySvc, FeatureToggleSvc, NotificationsSvc, UserSvc} from '../../../../../shared/services';
import {Router} from '@angular/router';
import {ProductTypes} from '../../../../../dashboard/models';
import {ProductsSvc} from '../../../../../dashboard/services';
import {InitiatorStepComponent} from '../InitiatorStepComponent';

@Component({
    selector: 'third-step',
    styleUrls: ['./third-step.less'],
    templateUrl: './third-step.tpl.html',
    encapsulation: ViewEncapsulation.None
})
export class ThirdStepComponent extends InitiatorStepComponent implements AfterContentInit{

    public constants = DashboardConst;
    public cipLoaded: boolean = false;
    public cipPattern: string;
    public barcodeUrl: string = '';
    public canShowChoicePrefix: boolean = false;
    public isVcs: boolean;
    public isFood: boolean;

    constructor(public productsSvc: ProductsSvc,
                public companySvc: CompanySvc,
                public _router: Router,
                public notificationsSvc: NotificationsSvc,
                public featureToggleSvc: FeatureToggleSvc,
                public userSvc: UserSvc) {
        super(productsSvc, companySvc, _router, notificationsSvc, featureToggleSvc, userSvc);
    }

    public ngAfterContentInit(): void {
        this.isVcs = this.productType === ProductTypes.VCS;
        this.isFood = this.productType === ProductTypes.FOOD;
    }
}
