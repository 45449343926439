import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class SharedDashboardSvc {
    @Output() public change: EventEmitter<boolean> = new EventEmitter<boolean>();

    public toggle() {
        this.change.emit(true);
    }
}
